import { useState } from 'react'

import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import useClientPriceLists from '@hooks/useClientPriceLists'
import usePriceLists from '@hooks/usePriceLists'
import useSelector from '@hooks/useSelector'

import { ArtworkTemplateGroupLinkModel } from '@models/types'
import useArtworkTemplateGroupLink from '@hooks/useArtworkTemplateGroupLink'
import useArtworkTemplateGroups from '@hooks/useArtworkTemplateGroups'
import useArtworkTemplateGroupLinks from '@hooks/useArtworkTemplateGroupLinks'
import useClients from '@hooks/useClients'
import usePriceListItems from '@hooks/usePriceListItems'
import useProducts from '@hooks/useProducts'

const defaultState = {
  name: '',
}

const useBulkCreateArtworkTemplateGroupLinks = (artworkTemplateGroupLink: Partial<ArtworkTemplateGroupLinkModel> = {}) => {
  const { artworkTemplateGroupId, entityId, entityType } = artworkTemplateGroupLink

  const [selectedEntityIds, setSelectedEntityIds] = useState<number[]>([])

  const [state, setState] = useSetState(defaultState)
  const { name } = state

  const {
    callbacks: {
      bulkCreateArtworkTemplateGroupLinks: bulkCreateFn,
    },
    creating,
  } = useArtworkTemplateGroupLink(artworkTemplateGroupLink)

  const { clients, products } = useSelector(reduxState => reduxState.entities)

  const debouncedString = useDebounce(name, 200)

  const productsPayload = useProducts({
    filters: {
      isArchived: false,
      string: debouncedString,
    },
    performHttpRequests: true,
  })

  const clientsPayload = useClients({
    filters: {
      isArchived: false,
      string: debouncedString,
    },
    performHttpRequests: true,
  })

  const { filteredClients } = clientsPayload
  const { filteredProducts } = productsPayload

  const { filteredArtworkTemplateGroupLinks } = useArtworkTemplateGroupLinks({
    filters: {
      entityType,
      artworkTemplateGroupId,
    },
  })

  const assignedEntityIds = filteredArtworkTemplateGroupLinks.map(p => p.entityId)

  const filteredClientIds = filteredClients?.reduce<number[]>((acc, client) => {
    if (!assignedEntityIds.includes(client.id)) {
      acc.push(client.id)
    }
    return acc
  }, [])

  const filteredProductIds = filteredProducts?.reduce<number[]>((acc, product) => {
    if (!assignedEntityIds.includes(product.id)) {
      acc.push(product.id)
    }
    return acc
  }, [])

  const entityParams = artworkTemplateGroupId
    ? { artworkTemplateGroupIds: [artworkTemplateGroupId], entityIds: selectedEntityIds, entityType }
    : { artworkTemplateGroupIds: selectedEntityIds, entityIds: [entityId], entityType }

  return {
    callbacks: {
      bulkCreateFn,
      setSelectedEntityIds,
      setState,
    },
    creating,
    clients,
    clientsPayload,
    entityParams,
    filteredClientIds,
    filteredClientIdsCount: filteredClientIds?.length,
    filteredProductIds,
    filteredProductIdsCount: filteredProductIds?.length,
    name,
    products,
    productsPayload,
    saveEnabled: (artworkTemplateGroupId || entityId) && !!selectedEntityIds.length,
    selectedEntityIds,
  }
}

export default useBulkCreateArtworkTemplateGroupLinks
