import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faToggleLargeOn, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import { confirmAction, confirmDelete } from '@functions/entity'

import useProductOption from '@hooks/useProductOption'

interface ProductOptionToolsProps {
  productOptionPayload: ReturnType<typeof useProductOption>,
}

const ProductOptionTools = (props: ProductOptionToolsProps) => {
  const {
    productOptionPayload: {
      callbacks: {
        launchDuplicateProductOptionModal,
        updateProductOption: updateFn,
      },
      productOption,
      productOption: {
        isArchived,
      },
      updating,
    },
  } = props

  const confirmArchivePayload = {
    callbacks: {
      actionFn: updateFn,
      deleteFn: updateFn,
    },
    options: {
      entityParams: { ...productOption, isArchived: !isArchived },
    },
    strings: {
      actionString: 'unarchive',
      deleteString: 'archive',
      entityName: 'Product Option',
    },
  }

  const archiveProductOptionFn = isArchived
    ? () => confirmAction(confirmArchivePayload)
    : () => confirmDelete(confirmArchivePayload)

  return (
    <Tools>
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faCopy} />}
        marginBottom="medium"
        onClick={() => launchDuplicateProductOptionModal()}
        size="medium"
      >
        Duplicate Product Option
      </Button>

      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={isArchived ? faToggleLargeOn : faTrashAlt} />}
        loading={updating}
        marginBottom="medium"
        onClick={() => archiveProductOptionFn()}
        size="medium"
      >
        {isArchived ? 'Unarchive' : 'Archive'} Product Option
      </Button>
    </Tools>
  )
}

export default ProductOptionTools
