import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as adTemplateActions from '@redux/modules/adTemplate'

import defaultFormState, { requiredFields } from '@models/adTemplate'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { AdTemplateModel, AdTemplateRequestOptions } from '@models/types'

export const generateUrls = (adTemplate?: Partial<AdTemplateModel>) => {
  const { id } = adTemplate || {}

  return {
    editAdTemplateUrl: `#/adTemplates/${id}/edit/`,
    adTemplatesIndexUrl: '#/adTemplates',
  }
}

type CreateAdTemplateParams = {

  adTemplateParams: Partial<AdTemplateModel>,
  dispatch: AppDispatch,
  requestOptions?: AdTemplateRequestOptions,
}

const createAdTemplate = (params: CreateAdTemplateParams) => {
  const { dispatch, adTemplateParams, requestOptions } = params
  const { createAdTemplate: createFn } = adTemplateActions

  return dispatch(createFn(adTemplateParams, requestOptions))
}

type UpdateAdTemplateParams = {

  adTemplate: AdTemplateModel,
  adTemplateParams: Partial<AdTemplateModel>,
  dispatch: AppDispatch,
  requestOptions?: AdTemplateRequestOptions,

}

const updateAdTemplate = (params: UpdateAdTemplateParams) => {
  const {
    dispatch, adTemplate, adTemplateParams, requestOptions,
  } = params
  const { updateAdTemplate: updateFn } = adTemplateActions

  const updatedParams = {
    id: adTemplate.id,
    ...adTemplateParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteAdTemplateParams = {

  adTemplate: DeleteParams<AdTemplateModel>,
  dispatch: AppDispatch,
}

const deleteAdTemplate = (params: DeleteAdTemplateParams) => {
  const { dispatch, adTemplate } = params
  const { deleteAdTemplate: deleteFn } = adTemplateActions

  return dispatch(deleteFn(adTemplate))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useAdTemplateForm(
  adTemplate: Partial<AdTemplateModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const adTemplateForm = useForm(
    defaultFormState,
    { entity: adTemplate, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [adTemplate.id, adTemplate.cacheKey],
  )

  return {
    ...adTemplateForm,
  }
}

export const useRelations = (adTemplate: Partial<AdTemplateModel> = {}) => {
  const { related_entity_id } = adTemplate

  const { relatedEntities } = useSelector(reduxState => reduxState.entities)

  const relatedEntity = related_entity_id && relatedEntities[related_entity_id] ? relatedEntities[related_entity_id] : {}

  return {
    relatedEntity,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: AdTemplateRequestOptions,
}

export function useAdTemplate(initEntity: Partial<AdTemplateModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: adTemplate }: { entity: AdTemplateModel} = useLatestEntity(initEntity, 'adTemplates')
  const { id } = adTemplate

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { relatedEntity } = useRelations(adTemplate)

  useReduxAction(
    'adTemplates',
    'loadAdTemplate',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.adTemplates)

  return {
    adTemplate,
    callbacks: {
      createAdTemplate: (adTemplateParams: Partial<AdTemplateModel>, entityOptions?: AdTemplateRequestOptions) => (
        createAdTemplate({ adTemplateParams, dispatch, requestOptions: entityOptions })
      ),
      createOrEditAdTemplate: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditAdTemplateModal',
        payload: { adTemplate },
      }),
      deleteAdTemplate: () => deleteAdTemplate({ dispatch, adTemplate }),
      updateAdTemplate: (adTemplateParams: Partial<AdTemplateModel>, entityOptions?: AdTemplateRequestOptions) => (
        updateAdTemplate({
          adTemplate, adTemplateParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    relatedEntity,
    updating,
    urls: generateUrls(adTemplate),
  }
}

export default useAdTemplate
