import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import placeholderImageUrl from '@assets/default_campaign_image_400x400.png'

import buildDataStoreItemSummary, { generateAddress, generateBedBathCarText } from '@functions/dataStoreItem'

import useDataStoreItems from '@hooks/useDataStoreItems'
import useProject from '@hooks/useProject'
import { useRelations } from '@hooks/useCampaign'

import { CampaignModel } from '@models/types'

interface PropertyDetailsProps {
  campaign: CampaignModel,
}

const PropertyDetails = (props: PropertyDetailsProps) => {
  const { campaign } = props

  const {
    coverImage: {
      filePath,
    },
    project,
  } = useRelations(campaign)

  const {
    callbacks: {
      editProject,
    },
  } = useProject(project)

  const { filteredDataStoreItems } = useDataStoreItems({
    filters: {
      projectId: project.id,
    }
  })

  const dataStoreItemSummary = buildDataStoreItemSummary(filteredDataStoreItems)

  const address = generateAddress(dataStoreItemSummary) || 'No Address'

  return (
    <ListItem
      boxProps={{ borderBottom: '1px solid' }}
      flexDirection="column"
      onClick={() => editProject()}
      showAngleIcon
    >
      <Text color="bodyFontLightColor" fontSize="xsmall" padding="large">Real Estate Property</Text>

      <Box paddingX="large" paddingBottom="large">
        <Box width={70} marginRight="large">
          <Image
            borderRadius={5}
            height={40}
            placeholderUrl={placeholderImageUrl}
            url={filePath}
            width={70}
          />
        </Box>

        <Box flexDirection="column" justifyContent="center">
          <Text fontSize="small">{address}</Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">Project No: {project.id}</Text>
        </Box>

        <Box alignItems="end" flexDirection="column" justifyContent="center">
          <Text fontSize="small">{generateBedBathCarText(dataStoreItemSummary)}</Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">{project.customFieldSetId}</Text>
        </Box>
      </Box>
    </ListItem>
  )
}

export default PropertyDetails
