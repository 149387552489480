import { faIdCard, faToggleLargeOn } from '@fortawesome/pro-light-svg-icons'

import { UserModel } from '@models/user'

import { generateUrls } from '@hooks/useUser'

const generateTabBarItems = (user: UserModel) => {
  const urls = generateUrls(user)
  const { editUserRelationsUrl, editUserUrl } = urls

  return [
    {
      icon: faIdCard,
      href: editUserUrl,
      key: 'details',
      title: user?.name || 'User Details',
    },
    {
      icon: faToggleLargeOn,
      href: editUserRelationsUrl,
      key: 'relations',
      title: 'Relations',
    },
  ]
}

export default generateTabBarItems
