import { EntitiesState } from '../entities'
import * as ModuleStates from './types'

// START MODULE IMPORTS
import * as activityLogs from './activityLog'
import * as adAccountLinks from './adAccountLink'
import * as adAccounts from './adAccount'
import * as adCampaignAds from './adCampaignAd'
import * as adCampaignStatuses from './adCampaignStatus'
import * as adCampaigns from './adCampaign'
import * as adQueueStatuses from './adQueueStatus'
import * as adQueues from './adQueue'
import * as adTemplateProducts from './adTemplateProduct'
import * as adTemplates from './adTemplate'
import * as artworkTemplateGroupLinks from './artworkTemplateGroupLink'
import * as artworkTemplateGroups from './artworkTemplateGroup'
import * as artworkTemplates from './artworkTemplate'
import * as artworks from './artwork'
import * as assetTypes from './assetType'
import * as assets from './asset'
import * as autoOrderQueueStatuses from './autoOrderQueueStatus'
import * as autoOrderQueues from './autoOrderQueue'
import * as brands from './brand'
import * as campaignStatuses from './campaignStatus'
import * as campaignTypes from './campaignType'
import * as campaignUsers from './campaignUser'
import * as campaignValidations from './campaignValidation'
import * as campaigns from './campaign'
import * as captions from './caption'
import * as clientPriceLists from './clientPriceList'
import * as clientUsers from './clientUser'
import * as clients from './client'
import * as comments from './comment'
import * as conversations from './conversation'
import * as currentUser from './currentUser'
import * as cutterProfileModules from './cutterProfileModule'
import * as cutterProfiles from './cutterProfile'
import * as entities from '../entities'
import * as externalPlatformEntities from './externalPlatformEntity'
import * as externalPlatforms from './externalPlatform'
import * as landingPages from './landingPage'
import * as navigation from './navigation'
import * as orderItems from './orderItem'
import * as packageProducts from './packageProduct'
import * as priceListItemOptionValues from './priceListItemOptionValue'
import * as priceListItemOptions from './priceListItemOption'
import * as priceListItems from './priceListItem'
import * as priceLists from './priceList'
import * as productOptionKeyTypes from './productOptionKeyType'
import * as productOptionProducts from './productOptionProduct'
import * as productOptionValues from './productOptionValue'
import * as productOptionValuesSubsets from './productOptionValuesSubset'
import * as productOptions from './productOption'
import * as productPackages from './productPackage'
import * as products from './product'
import * as projects from './project'
import * as realhubArtworkTemplates from './realhubArtworkTemplate'
import * as userTypes from './userType'
import * as users from './user'
// END MODULE IMPORTS

export type RootReducerState = {
// START ROOT REDUCER STATE
  activityLogs: ModuleStates.ActivityLogModuleState,
  adAccountLinks: ModuleStates.AdAccountLinkModuleState,
  adAccounts: ModuleStates.AdAccountModuleState,
  adCampaignAds: ModuleStates.AdCampaignAdModuleState,
  adCampaignStatuses: ModuleStates.AdCampaignStatusModuleState,
  adCampaigns: ModuleStates.AdCampaignModuleState,
  adQueueStatuses: ModuleStates.AdQueueStatusModuleState,
  adQueues: ModuleStates.AdQueueModuleState,
  adTemplateProducts: ModuleStates.AdTemplateProductModuleState,
  adTemplates: ModuleStates.AdTemplateModuleState,
  artworkTemplateGroupLinks: ModuleStates.ArtworkTemplateGroupLinkModuleState,
  artworkTemplateGroups: ModuleStates.ArtworkTemplateGroupModuleState,
  artworkTemplates: ModuleStates.ArtworkTemplateModuleState,
  artworks: ModuleStates.ArtworkModuleState,
  assetTypes: ModuleStates.AssetTypeModuleState,
  assets: ModuleStates.AssetModuleState,
  autoOrderQueueStatuses: ModuleStates.AutoOrderQueueStatusModuleState,
  autoOrderQueues: ModuleStates.AutoOrderQueueModuleState,
  brands: ModuleStates.BrandModuleState,
  campaignStatuses: ModuleStates.CampaignStatusModuleState,
  campaignTypes: ModuleStates.CampaignTypeModuleState,
  campaignUsers: ModuleStates.CampaignUserModuleState,
  campaignValidations: ModuleStates.CampaignValidationModuleState,
  campaigns: ModuleStates.CampaignModuleState,
  captions: ModuleStates.CaptionModuleState,
  clientPriceLists: ModuleStates.ClientPriceListModuleState,
  clientUsers: ModuleStates.ClientUserModuleState,
  clients: ModuleStates.ClientModuleState,
  comments: ModuleStates.CommentModuleState,
  conversations: ModuleStates.ConversationModuleState,
  currentUser: ModuleStates.CurrentUserModuleState,
  cutterProfileModules: ModuleStates.CutterProfileModuleModuleState,
  cutterProfiles: ModuleStates.CutterProfileModuleState,
  entities: EntitiesState,
  externalPlatformEntities: ModuleStates.ExternalPlatformEntityModuleState,
  externalPlatforms: ModuleStates.ExternalPlatformModuleState,
  landingPages: ModuleStates.LandingPageModuleState,
  navigation: { showNavigation: boolean },
  orderItems: ModuleStates.OrderItemModuleState,
  packageProducts: ModuleStates.PackageProductModuleState,
  priceListItemOptionValues: ModuleStates.PriceListItemOptionValueModuleState,
  priceListItemOptions: ModuleStates.PriceListItemOptionModuleState,
  priceListItems: ModuleStates.PriceListItemModuleState,
  priceLists: ModuleStates.PriceListModuleState,
  productOptionKeyTypes: ModuleStates.ProductOptionKeyTypeModuleState,
  productOptionProducts: ModuleStates.ProductOptionProductModuleState,
  productOptionValues: ModuleStates.ProductOptionValueModuleState,
  productOptionValuesSubsets: ModuleStates.ProductOptionValuesSubsetModuleState,
  productOptions: ModuleStates.ProductOptionModuleState,
  productPackages: ModuleStates.ProductPackageModuleState,
  products: ModuleStates.ProductModuleState,
  projects: ModuleStates.ProjectModuleState,
  realhubArtworkTemplates: ModuleStates.RealhubArtworkTemplateModuleState,
  userTypes: ModuleStates.UserTypeModuleState,
  users: ModuleStates.UserModuleState,
// END ROOT REDUCER STATE
}

const modules = {
// START MODULES LIST
  activityLogs,
  adAccountLinks,
  adAccounts,
  adCampaignAds,
  adCampaignStatuses,
  adCampaigns,
  adQueueStatuses,
  adQueues,
  adTemplateProducts,
  adTemplates,
  artworkTemplateGroupLinks,
  artworkTemplateGroups,
  artworkTemplates,
  artworks,
  assetTypes,
  assets,
  autoOrderQueueStatuses,
  autoOrderQueues,
  brands,
  campaignStatuses,
  campaignTypes,
  campaignUsers,
  campaignValidations,
  campaigns,
  captions,
  clientPriceLists,
  clientUsers,
  clients,
  comments,
  conversations,
  currentUser,
  cutterProfileModules,
  cutterProfiles,
  entities,
  externalPlatformEntities,
  externalPlatforms,
  landingPages,
  navigation,
  orderItems,
  packageProducts,
  priceListItemOptionValues,
  priceListItemOptions,
  priceListItems,
  priceLists,
  productOptionKeyTypes,
  productOptionProducts,
  productOptionValues,
  productOptionValuesSubsets,
  productOptions,
  productPackages,
  products,
  projects,
  realhubArtworkTemplates,
  userTypes,
  users,
// END MODULES LIST
}

export const reducers = Object.keys(modules).reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules
