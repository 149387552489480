import { DashboardModule } from '@campaignhub/suit-theme'

import useCampaign from '@hooks/useCampaign'

import ArtworkDetails from './ArtworkDetails'
import PropertyDetails from './PropertyDetails'
import WebsiteDetails from './WebsiteDetails'

interface ProjectDetailsProps {
  campaignPayload: ReturnType<typeof useCampaign>,
}

const ProjectDetails = (props: ProjectDetailsProps) => {
  const {
    campaignPayload: {
      campaign,
      loading,
    },
  } = props

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Project Details"
    >
      {!loading && (
        <>
          <PropertyDetails campaign={campaign} />

          <WebsiteDetails campaign={campaign} />

          <ArtworkDetails campaign={campaign} />
        </>
      )}
    </DashboardModule>
  )
}

export default ProjectDetails
