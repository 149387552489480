import { useMemo } from 'react'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'

import { MainContent, ModalContext, TopBar } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useMainNavigation from '@hooks/useMainNavigation'

import ManageCampaignValidationsModal from '@modals/ManageCampaignValidationsModal'

import { CampaignModel } from '@models/types'

import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Admin/components/MainNavigation'

import PageContent from './components/PageContent'

const defaultState = {
  showManageCampaignValidationsModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

// eslint-disable-next-line
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    ManageCampaignValidationsModal: {
      closeModal: () => setState({ showManageCampaignValidationsModal: false }),
      validateCampaign: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Validator = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  const [state, setState] = useSetState(defaultState)
  const {
    showManageCampaignValidationsModal,
  } = state

  const filtersPayload = useFilters({
    persistFilters: true,
  })

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showManageCampaignValidationsModal: (payload: { campaign: CampaignModel }) => {
        setModalData('ManageCampaignValidationsModal', payload)
        setState({ showManageCampaignValidationsModal: true })
      },
      ...filterCallbacks,
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt])

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<TopBar.Search callbacks={{ search: null }} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <PageContext.Provider value={pageContext}>
          <ModalContext.Provider value={modalContext}>
            <PageContent pageFilters={pageFilters} />

            <ManageCampaignValidationsModal
              callbacks={callbacks('ManageCampaignValidationsModal', setState)}
              showModal={showManageCampaignValidationsModal}
            />
          </ModalContext.Provider>
        </PageContext.Provider>
      </MainContent>
    </>
  )
}

export default Validator
