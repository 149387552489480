import { useLatestEntity } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

import type { ActivityLogModel } from '@models/types'

const destructiveActions = [
  'Archived',
  'Deleted',
]

export const useRelations = (activityLog: Partial<ActivityLogModel> = {}) => {
  const { userId } = activityLog

  const { users } = useSelector(reduxState => reduxState.entities)

  const user = userId && users[userId] ? users[userId] : {}

  return {
    user,
  }
}

function useActivityLog(initEntity: Partial<ActivityLogModel> = {}) {
  const { entity: activityLog }: { entity: ActivityLogModel } = useLatestEntity(initEntity, 'activityLogs')
  const { action } = activityLog

  const { user } = useRelations(activityLog)

  return {
    isDestructive: destructiveActions.some(destructiveAction => destructiveAction === action),
    user,
  }
}

export default useActivityLog
