import { useEffect, useState } from 'react'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'

import { baseURL } from '@functions/api'
import { getAccessToken } from '@functions/accessToken'

import useDispatch from '@hooks/useDispatch'

import { addAsset } from '@redux/modules/asset'

import type { AppDispatch } from '@redux/store'

type UppyOptions = {
  allowedFileTypes?: string,
  callbacks?: {
    afterAction?: () => any,
  },
  fieldName?: string,
  assetTypeId: string,
  assetableId: number,
  assetableType: string,
  requestOptions?: {
    [key: string]: string,
  },
}

const initializeUppy = (options: UppyOptions, dispatch: AppDispatch) => {
  const {
    allowedFileTypes,
    callbacks,
    fieldName,
    assetTypeId,
    assetableId,
    assetableType,
    requestOptions = {},
  } = options

  const { afterAction } = callbacks || {}

  const accessToken = getAccessToken()

  const uppy = new Uppy({
    debug: false,
    autoProceed: true,
    onBeforeFileAdded: () => true,
    restrictions: {
      allowedFileTypes: allowedFileTypes ? allowedFileTypes.split(',') : ['image/*'],
    },
  })
    .use(XHRUpload, {
      endpoint: `${baseURL}/assets`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      formData: true,
      fieldName: fieldName || 'file',
    })
    .on('file-added', (file) => {
      uppy.setFileMeta(file.id, {
        assetTypeId,
        assetableId,
        assetableType,
        ...requestOptions,
      })
    })
    .on('upload-success', (_, response) => {
      const { body: asset } = response

      dispatch(addAsset(asset))

      if (afterAction) afterAction()
    })

  return uppy
}

const checkRequiredOptions = (options = {}) => {
  const requiredOptions = ['assetTypeId', 'assetableType', 'assetableId']
  const optionKeys = Object.keys(options)

  return requiredOptions.every(key => optionKeys.includes(key))
}

function useUppyAssetUpload(options: UppyOptions, deps: any[] = []) {
  const dispatch = useDispatch()

  const [uppy, setUppy] = useState<Uppy | undefined>(undefined)

  useEffect(() => {
    if (checkRequiredOptions(options)) {
      return setUppy(initializeUppy(options, dispatch))
    }
    return () => {
      if (uppy) {
        uppy.close()
        setUppy(undefined)
      }
    }
  }, [...deps])

  return uppy
}

export default useUppyAssetUpload
