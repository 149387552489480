import { useMemo } from 'react'

import { formatDate } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import { StatusBadge, Table, Text } from '@campaignhub/suit-theme'

import getAdCampaignChannel from '@functions/adCampaign'
import systemColours from '@functions/colours'
import getStatusBadgeProps from '@functions/statusBadge'

import { FAILED_STATUS, PENDING_STATUS, REVIEW_STATUS } from '@functions/adCampaignStatus'

import useSelector from '@hooks/useSelector'

import { AdCampaignModel } from '@models/types'

import type { EntitiesState } from '@redux/entities'

import Actions from './Actions'

const generateTitle = (adCampaign: AdCampaignModel, campaigns: EntitiesState['campaigns']) => {
  const {
    adCampaignName, adChannelTypeId, campaignId, errorMessage, status,
  } = adCampaign

  if (status === PENDING_STATUS){
    const campaign = campaigns[campaignId] || {}
    return (
      <Text variant="ellipsis">
        {campaign.name}
      </Text>
    )
  }

  if (status === FAILED_STATUS){
    return (
      <Text color={systemColours.red} variant="ellipsis">
        {errorMessage}
      </Text>
    )
  }

  if (status === REVIEW_STATUS){
    return (
      <Text color={systemColours.orange} variant="ellipsis">
        {`Under review by ${adChannelTypeId}`}
      </Text>
    )
  }

  return adCampaignName
}

const buildColumns = (entities: EntitiesState) => {
  const { campaigns } = entities

  return [
    {
      title: 'Title',
      dataKey: 'campaignName',
      cellProps: {
        verticalAlign: 'middle',
      },
      render: (_: any, adCampaign: AdCampaignModel) => generateTitle(adCampaign, campaigns),
    },
    {
      title: 'AIM#',
      dataKey: 'campaignId',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (campaignId: number) => (
        <Text variant="ellipsis">
          {campaignId}
        </Text>
      ),
    },
    {
      title: 'Start',
      dataKey: 'startsAt',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (startsAt: string) => (
        <StatusBadge
          boxProps={{
            backgroundColor: 'backgroundColor',
            borderColor: 'lineColor',
            color: 'black',
          }}
          text={formatDate(startsAt, 'ISO8601', 'dd/MM/yyyy')}
        />
      ),
    },
    {
      title: 'Channel',
      dataKey: 'adChannelTypeId',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (_: any, adCampaign: AdCampaignModel) => (
        <Text variant="ellipsis">
          {getAdCampaignChannel(adCampaign)}
        </Text>
      ),
    },
    {
      title: 'Ad Account',
      dataKey: 'adAccountId',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (adAccountId: number) => {
        return (
          <Text variant="ellipsis">
            {adAccountId}
          </Text>
        )
      },
    },
    {
      title: 'Status',
      dataKey: 'status',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (_: any, adCampaign: AdCampaignModel) => {
        const { status } = adCampaign

        const statusBadgeProps = getStatusBadgeProps(status)

        return (
          <StatusBadge
            boxProps={{ borderWidth: 2, width: '92px' }}
            ghost
            {...statusBadgeProps}
          />
        )
      },
    },
    {
      title: 'Actions',
      dataKey: 'id',
      cellProps: {
        key: 'actions',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 20,
      },
      render: (adCampaignId: number) => <Actions adCampaignId={adCampaignId} />,
    },
  ]
}

const watchEntityKeys = ['adCampaigns', 'campaigns']

interface LoadedCampaignsTableProps {
  adCampaigns: AdCampaignModel[],
}

const LoadedCampaignsTable = (props: LoadedCampaignsTableProps) => {
  const { adCampaigns } = props

  const {
    updatedEntities: {
      adCampaigns: adCampaignsUpdatedAt,
      campaigns: campaignsUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)

  const memoColumns = useMemo(() => (
    buildColumns(entities)
  ), [adCampaignsUpdatedAt, campaignsUpdatedAt])

  return (
    <Table
      boxProps={{ border: 'none', maxHeight: 1600, overflowY: 'auto' }}
      columns={memoColumns}
      data={adCampaigns}
      scroll={{ x: 800 }}
      stickyHeader
    />
  )
}

export default LoadedCampaignsTable
