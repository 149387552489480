import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

const watchEntityKeys = ['campaignValidations']

type UseCampaignValidationsOptions = {
  filters?: {
    campaignId?: number,
  },
  performHttpRequests?: boolean,
}

function useCampaignValidations(options: UseCampaignValidationsOptions) {
  const { filters = {} } = options
  const {
    campaignId: filterCampaignId,
  } = filters

  const {
    updatedEntities: { campaignValidations: campaignValidationsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { campaignValidations } = useSelector(reduxState => reduxState.entities)

  const filteredCampaignValidations = useMemo(() => {
    const filtered = Object.values(campaignValidations).filter((campaignValidation) => {
      const { campaignId } = campaignValidation

      const matchCampaignId = matchFilterNumber(campaignId, filterCampaignId)

      return matchCampaignId
    })

    return sortArrayBy(filtered, 'asc', 'displayName')
  }, [campaignValidationsUpdatedAt, JSON.stringify(filters)])

  const filteredCampaignValidationsCount = filteredCampaignValidations.length
  const hasFilteredCampaignValidations = !!filteredCampaignValidationsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredCampaignValidationsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingCampaignValidations } = useReduxAction(
    'campaignValidations',
    'loadCampaignValidations',
    {
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCampaignValidations,
    filteredCampaignValidationsCount,
    hasFilteredCampaignValidations,
    loading: loadingCampaignValidations,
  }
}

export default useCampaignValidations
