export type ProductOptionModel = {
  cacheKey: number,
  description: string,
  displayType: string,
  id: number,
  isArchived: boolean,
  isSelectable: boolean,
  key: string,
  name: string,
}

export type ProductOptionRequestOptions = {}

const state = {
  description: '',
  displayType: '',
  id: null,
  isArchived: false,
  isSelectable: true,
  key: '',
  name: '',
}

export const requiredFields = [
  {
    key: 'description',
    validation: (value: string) => value.length <= 2000,
    invalidMessage: 'Must be 2000 chars or less',
  },
  { key: 'displayType' },
  { key: 'key' },
  {
    key: 'name',
    validation: (value: string) => value && value.length <= 250,
    invalidMessage: 'Must be 250 chars or less',
  },
]

export default state
