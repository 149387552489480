const realhubUrls = {
  development: 'https://realhub.staging.realbase.io/',
  production: 'https://realhub.realbase.io/',
  staging: 'https://realhub.staging.realbase.io/',
  test: 'https://realhub.staging.realbase.io/',
}

const realhubUrl = realhubUrls[process.env.REACT_APP_APPLICATION_ENV]

export default realhubUrl
