import { faIdCard, faToggleLargeOn } from '@fortawesome/pro-light-svg-icons'

import { ClientModel } from '@models/client'

import { generateUrls } from '@hooks/useClient'

const generateTabBarItems = (client: ClientModel) => {
  const urls = generateUrls(client)
  const { editClientRelationsUrl, editClientUrl } = urls

  return [
    {
      icon: faIdCard,
      href: editClientUrl,
      key: 'details',
      title: client?.name || 'Organisation Details',
    },
    {
      icon: faToggleLargeOn,
      href: editClientRelationsUrl,
      key: 'relations',
      title: 'Relations',
    },
  ]
}

export default generateTabBarItems
