import { Route, Routes } from 'react-router-dom'

import Details from './screens/Details'

const CutterProfileEditRoutes = () => (
  <Routes>
    <Route index element={<Details />} />
  </Routes>
)

export default CutterProfileEditRoutes
