import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity } from '@redux/entities'

import type { OrderItemOptionValueModel } from '@models/types'
import type { AppDispatch } from '@redux/store'

export function removeOrderItemOptionValue(dispatch: AppDispatch, orderItemOptionValue: OrderItemOptionValueModel) {
  const normalizedJson = normalize(orderItemOptionValue, Schemas.ORDER_ITEM_OPTION_VALUE)
  dispatch(deleteEntity(normalizedJson))
}