import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import { confirmDelete } from '@functions/entity'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useAsset from '@hooks/useAsset'

import type { AssetModel } from '@models/types'

interface DocumentListItemProps {
  asset: AssetModel,
  callbacks: {
    deleteDocument: (payload: HandleCallbackActionParams) => Promise<object>,
  },
}

const DocumentListItem = (props: DocumentListItemProps) => {
  const {
    asset,
    asset: {
      assetTypeId,
      displayName,
      fileName,
      id,
    },
    callbacks: {
      deleteDocument,
    },
  } = props

  const title = displayName || `${assetTypeId} ${id}`

  const {
    callbacks: {
      deleteAsset: deleteFn,
      editDocument,
    },
  } = useAsset(asset)

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deleteDocument,
      deleteFn,
    },
    strings: {
      entityName: 'Document',
    },
  }

  return (
    <DashboardModuleListItem
      boxProps={{ paddingX: 0 }}
      isSidebar
      secondaryText={fileName}
      title={title}
    >
      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon marginRight="medium" onClick={() => editDocument()} type="edit" />

        <DashboardModuleListItem.Icon onClick={() => confirmDelete(confirmDeletePayload)} type="delete" />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default DocumentListItem
