import { useMemo } from 'react'

import {
  formatDate, matchFilterArrayIncludes, matchFilterNumber, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import { getAdQueueStartDate } from '@functions/adQueue'
import { matchFilterBetweenDates } from '@functions/dates'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { AdQueueRequestOptions, CampaignModel } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

export type AdQueueFilters = {
  adType?: string,
  clientId?: number,
  deploymentStack?: string,
  endsAt?: string,
  externalId?: string,
  objective?: string,
  sortBy?: 'desc',
  startsAt?: string,
  status?: string,
  string?: string,
}

type UseAdQueuesOptions = {
  filters?: AdQueueFilters,
  performHttpRequests?: boolean,
  requestOptions?: AdQueueRequestOptions,
}

const watchEntityKeys = ['adQueues']

function useAdQueues(options: UseAdQueuesOptions = {}) {
  const {
    filters = {},
    requestOptions,
  } = options

  const {
    adType: filterAdTypeId,
    clientId: filterClientId,
    deploymentStack: filterDeploymentStack,
    endsAt: filterEndsAt,
    externalId: filterExternalId,
    objective: filterObjective,
    sortBy: filterSortBy,
    startsAt: filterStartsAt,
    status: filterStatus,
    string: filterString,
  } = filters

  const filterEndsAtWithTimezone = filterEndsAt ? formatDate(filterEndsAt, 'yyyy-MM-dd', 'yyyy-MM-dd\'T\'HH:mm:ssZZ') : undefined
  const filterStartsAtWithTimezone = filterStartsAt ? formatDate(filterStartsAt, 'yyyy-MM-dd', 'yyyy-MM-dd\'T\'HH:mm:ssZZ') : undefined

  const {
    updatedEntities: {
      adQueues: adQueuesUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    adQueues,
    campaigns,
    externalPlatformEntities,
    projects,
  } = entities

  const filteredAdQueues = useMemo(() => {
    const filtered = Object.values(adQueues).filter((adQueue) => {
      const {
        adTypeId, campaignId, objective, referenceId, status,
      } = adQueue

      const adQueueCampaign: CampaignModel = campaigns[campaignId] || {}
      const { clientId, projectId } = adQueueCampaign

      const project = projects[projectId] || {}
      const { externalPlatformEntities: projectExternalPlatformEntities } = project || {}

      const externalIds = [] as string[]
      const externalPlatformEntityIds: number[] = projectExternalPlatformEntities || []
      externalPlatformEntityIds.forEach(externalPlatformEntityId => {
        const externalPlatformEntity = externalPlatformEntities[externalPlatformEntityId]
        const { value } = externalPlatformEntity
        externalIds.push(value)
      })

      const matchAdType = matchFilterString(adTypeId, filterAdTypeId)

      const matchClientId = matchFilterNumber(clientId, filterClientId)

      const startDate = getAdQueueStartDate(adQueue)

      const matchDates = matchFilterBetweenDates(startDate, filterStartsAtWithTimezone, filterEndsAtWithTimezone)

      const matchListingId = matchFilterArrayIncludes(externalIds, filterExternalId)

      const matchDeploymentStack = filterDeploymentStack
        ? filterDeploymentStack === 'v1'
          ? campaignId === null
          : campaignId !== null
        : true

      const matchObjective = matchFilterString(objective, filterObjective)

      const matchableId = campaignId ?? referenceId
      const matchId = matchFilterNumber(matchableId, filterString)

      const matchName = matchFilterString(adQueueCampaign.name, filterString)
      const matchString = matchId || matchName

      const matchStatus = matchFilterString(status, filterStatus)

      return matchAdType && matchClientId && matchDates && matchDeploymentStack && matchListingId && matchObjective && matchString && matchStatus
    })

    return sortArrayBy(filtered, filterSortBy ?? 'desc', (adQueue: AdQueueModel) => getAdQueueStartDate(adQueue))
  }, [adQueuesUpdatedAt, JSON.stringify(filters)])

  const filteredAdQueuesCount = filteredAdQueues.length
  const hasFilteredAdQueues = !!filteredAdQueuesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredAdQueuesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingAdQueues } = useReduxAction(
    'adQueues',
    'loadAdQueues',
    {
      ...requestOptions,
      ...filtersWithOffset,
      endsAt: filterEndsAtWithTimezone,
      startsAt: filterStartsAtWithTimezone,
      limit,
      orderBy: "createdAt desc",
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdQueues,
    filteredAdQueuesCount,
    hasFilteredAdQueues,
    loading: loadingAdQueues,
  }
}

export default useAdQueues
