import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities, deleteEntity } from '@redux/entities'

import api from '@functions/api'

import type { AssetModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/asset/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/asset/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/asset/FETCH_FAILURE'

const UPDATE_REQUEST = 'realbase/asset/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/asset/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/asset/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/asset/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/asset/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/asset/DELETE_FAILURE'

export type AssetModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: AssetModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadAssets(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().assets.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/assets', options)
      .then(({ data }: { data: { data: AssetModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.ASSET_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, result: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateAsset(asset: UpdateParams<AssetModel>, options: EntityOptions = {}) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      ...asset,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/assets/${asset.id}`, options, config)
      .then(({ data }: { data: AssetModel }) => {
        const normalizedJson = normalize(data, Schemas.ASSET)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteAsset(asset: DeleteParams<AssetModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/assets/${asset.id}`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        const deletedEntity = { id: asset.id }
        const normalizedJson = normalize(deletedEntity, Schemas.ASSET)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, result: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })
    return promise
  }
}

export function addAsset(asset: AssetModel) {
  const normalizedJson = normalize(asset, Schemas.ASSET)

  return (dispatch: AppDispatch) => {
    dispatch(updateEntities(normalizedJson))
  }
}

type UpdateSortOrderParams = {
  assetableId: number,
  assetableType: string,
  sortOrder: number[],
}

export function updateSortOrder(params: UpdateSortOrderParams, options: EntityOptions = {}) {
  const {
    assetableId,
    assetableType,
    sortOrder,
  } = params

  const config = {
    method: 'PATCH',
    data: JSON.stringify({
      assetableId,
      assetableType,
      sort: sortOrder,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api('/assets/sorting', options, config)
      .then(({ data }: { data: { data: AssetModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.ASSET_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(
  state: AssetModuleState = initialState,
  action: Action = { type: '' },
): AssetModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
