import { Columns } from '@campaignhub/suit-theme'


import useArtworkTemplateGroup from '@hooks/useArtworkTemplateGroup'

import PageLayout from '../../../../components/PageLayout'

import ArtworkTemplateGroupLinkLists from '../ArtworkTemplateGroupLinkLists'

interface PageContentProps {
  artworkTemplateGroupId: number,
}

const PageContent = (props: PageContentProps) => {
  const { artworkTemplateGroupId } = props

  const artworkTemplateGroupPayload = useArtworkTemplateGroup({ id: artworkTemplateGroupId })

  return (
    <PageLayout artworkTemplateGroupPayload={artworkTemplateGroupPayload} activeTabBarItemKey="relations">
      <Columns.Main>
        <ArtworkTemplateGroupLinkLists
          filters={{
            entityTitle: 'Organisations',
            entityType: 'Client',
            artworkTemplateGroupId,
          }}
        />
        <ArtworkTemplateGroupLinkLists
          filters={{
            entityTitle: 'Products',
            entityType: 'Product',
            artworkTemplateGroupId,
          }}
        />
      </Columns.Main>

      <Columns.Sidebar>
        {/* <ClientTools clientPayload={clientPayload} /> */}
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent

