import { useMemo } from 'react'

import {
  formatDate,
  matchFilterArrayIncludes,
  matchFilterNumber,
  matchFilterString,
  sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import { matchFilterBetweenDates } from '@functions/dates'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { CampaignModel, ExternalPlatformEntityModel } from '@models/types'

import type { EntityOptions, ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['campaigns']

export type CampaignFilters = {
  clientId?: number,
  endDate?: string,
  externalPlatformId?: number,
  orderStatus?: string,
  sortBy?: 'desc',
  startDate?: string,
  status?: string,
  string?: string,
  validationStatus?: string,
  externalProjectId?: string,
  externalOrderId?: string,
}

type useCampaignsOptions = {
  filters?: CampaignFilters,
  hidePassedValidations?: boolean,
  performHttpRequests?: boolean,
  requestOptions?: EntityOptions,
}

function useCampaigns(options: useCampaignsOptions = {}) {
  const {
    filters = {},
    hidePassedValidations,
    requestOptions,
  } = options

  const {
    clientId: filterClientId,
    endDate: filterEndDate,
    externalPlatformId: filterExternalPlatformId,
    sortBy: filterSortBy,
    startDate: filterStartDate,
    status: filterStatus,
    string: filterString,
    validationStatus: filterValidationStatus,
    externalProjectId: filterExternalProjectId,
    externalOrderId: filterExternalOrderId,
  } = filters

  const filterEndsDateWithTimezone = filterEndDate ? formatDate(filterEndDate, 'yyyy-MM-dd', 'yyyy-MM-dd\'T\'HH:mm:ssZZ') : undefined
  const filterStartDateWithTimezone = filterStartDate ? formatDate(filterStartDate, 'yyyy-MM-dd', 'yyyy-MM-dd\'T\'HH:mm:ssZZ') : undefined

  const {
    updatedEntities: { campaigns: campaignsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    campaigns, externalPlatformEntities, externalPlatforms, landingPages, orders, orderItems,
  } = entities || {}

  const filteredCampaigns = useMemo((): CampaignModel[] => {
    const filtered = Object.values(campaigns).filter((campaign: CampaignModel) => {
      const {
        campaignSummary,
        clientId,
        id,
        name,
        orderItemId,
        projectId,
        requiredAt,
        statusId,
      } = campaign

      const projectExternalPlatformEntities = Object.values(externalPlatformEntities).filter(externalPlatformEntity => (
        externalPlatformEntity.entityId === projectId && externalPlatformEntity.entityType === 'Project'
      ))

      const externalPlatform = projectExternalPlatformEntities.length
        ? externalPlatforms[projectExternalPlatformEntities[0].externalPlatformId]
        : undefined

      campaign.landingPage = landingPages[campaign?.landingPageId]

      let orderExternalPlatformEntities: ExternalPlatformEntityModel[] = []

      if (orderItemId){
        const campaignOrderItem = orderItems[orderItemId]
        const campaignOrder = orders[campaignOrderItem?.orderId]

        orderExternalPlatformEntities = campaignOrder
          ? Object.values(externalPlatformEntities).filter(externalPlatformEntity => (
              externalPlatformEntity.entityId === campaignOrder?.id && externalPlatformEntity.entityType === 'Order'
            ))
          : []
      }

      const externalProjectIds = projectExternalPlatformEntities.map(epe => epe.value)
      const externalOrderIds = orderExternalPlatformEntities.map(epe => epe.value)

      const { preflightValidation } = campaignSummary || {}

      const {
        campaignStatus: campaignValidationStatus,
        facebookStatus: facebookValidationStatus,
        googleStatus: googleValidationStatus,
      } = preflightValidation || {}

      const matchExternalProjectId = matchFilterArrayIncludes(externalProjectIds, filterExternalProjectId)
      const matchExternalOrderId = matchFilterArrayIncludes(externalOrderIds, filterExternalOrderId)

      const matchClientId = matchFilterNumber(clientId, filterClientId)

      const matchDates = matchFilterBetweenDates(requiredAt, filterStartDateWithTimezone, filterEndsDateWithTimezone)

      const validationStatuses = [campaignValidationStatus, facebookValidationStatus, googleValidationStatus]

      // The Validator will not show campaigns if all validations are successful
      // so the hidePassedValidations option is used to hide those campaigns on that page
      let matchValidationStatus = true
      if (filterValidationStatus){
        matchValidationStatus = matchFilterArrayIncludes(validationStatuses, filterValidationStatus)
      } else if (hidePassedValidations){
        matchValidationStatus = validationStatuses.some(status => status === 'Error' || status === 'Warning')
      }

      const matchStatus = matchFilterString(statusId, filterStatus)

      const matchId = id.toString() === filterString
      const matchName = matchFilterString(name, filterString)
      const matchString = matchId || matchName

      const matchExternalPlatformId = matchFilterNumber(externalPlatform?.id, filterExternalPlatformId)

      return matchClientId
        && matchDates
        && matchExternalPlatformId
        && matchStatus
        && matchString
        && matchValidationStatus
        && matchExternalProjectId
        && matchExternalOrderId
    })

    return sortArrayBy(filtered, filterSortBy ?? 'desc', 'requiredAt')
  }, [campaignsUpdatedAt, JSON.stringify(filters)])

  const filteredCampaignsCount = filteredCampaigns.length
  const hasFilteredCampaigns = !!filteredCampaignsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredCampaignsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingCampaigns } = useReduxAction(
    'campaigns',
    'loadCampaigns',
    {
      ...filtersWithOffset,
      ...requestOptions,
      endDate: filterEndsDateWithTimezone,
      startDate: filterStartDateWithTimezone,
      limit,
      orderBy: 'createdAt desc',
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { loading, errors } = entityReducer
        return performHttpRequests && !loading && !errors?.length
      },
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCampaigns,
    filteredCampaignsCount,
    hasFilteredCampaigns,
    loading: loadingCampaigns,
  }
}

export default useCampaigns
