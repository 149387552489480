export type AssetModel = {
  id: number,
  assetTypeId: string,
  cacheKey: number,
  category: string,
  assetGroupId: number,
  fileExtension: string,
  displayName?: string,
  fileName: string,
  filePath: string,
  sort?: number,
  realbaseId?: number,
  referenceId?: number,
}

export type AssetRequestOptions = {}

const state = {
  id: null,
  assetTypeId: '',
  displayName: '',
  fileName: '',
}

export const requiredFields = [
  { key: 'assetTypeId' },
]

export default state
