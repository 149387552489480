import { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'

// This hook is intended to be used to manage SortableList
// It handles the sorted list of Ids, updating that list, and sending the sort request to the backend

// Returning an object with success: true so it can be used with handleCallbackAction to launch toast alerts
const modifySort = (
  fromIndex: number,
  toIndex: number,
  currentSortedIds: number[],
  setSortedIds: Dispatch<SetStateAction<number[]>>,
  updateSort: (sortedIds: number[]) => Promise<object>,
) => {
  const sortedIds = [...currentSortedIds]
  const [removedItemId] = sortedIds.splice(fromIndex, 1)
  sortedIds.splice(toIndex, 0, removedItemId)

  setSortedIds(sortedIds)

  if (updateSort) return updateSort(sortedIds)
  return { success: true, data: sortedIds }
}

type UseSortableIdsParams = {
  callbacks: {
    updateSort: (sortedIds: number[]) => Promise<object>,
  },
  ids: number[],
}

const useSortableIds = (params: UseSortableIdsParams) => {
  const {
    callbacks: {
      updateSort,
    },
    ids,
  } = params

  const [sortedIds, setSortedIds] = useState<number[]>(ids)

  // Set sortedIds with the correct value because they may initialise as an empty array due to load time
  useEffect(() => {
    setSortedIds(ids)
  }, [ids.length])

  return {
    callbacks: {
      modifySort: (fromIndex: number, toIndex: number) => (
        modifySort(fromIndex, toIndex, sortedIds, setSortedIds, updateSort)
      ),
    },
    sortedIds,
  }
}

export default useSortableIds
