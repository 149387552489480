import { faIdCard } from '@fortawesome/pro-light-svg-icons'

import { CutterProfileModel } from '@models/cutterProfile'

import { generateUrls } from '@hooks/useCutterProfile'

const generateTabBarItems = (cutterProfile: CutterProfileModel) => {
  const urls = generateUrls(cutterProfile)
  const { editCutterProfileUrl } = urls

  return [
    {
      icon: faIdCard,
      href: editCutterProfileUrl,
      key: 'details',
      title: cutterProfile?.name || 'CutterProfile Details',
    },
  ]
}

export default generateTabBarItems
