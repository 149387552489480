import { useMemo } from 'react'

import { formatDate, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import { matchFilterBetweenDates } from '@functions/dates'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { AutoOrderQueueModel, AutoOrderQueueRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

import { calculateDueDate } from "@functions/autoOrderQueue"

const watchEntityKeys = ['autoOrderQueues']

export type AutoOrderQueueFilters = {
  createdAt?: string,
  endDate?: string,
  referenceId?: string,
  startDate?: string,
  status?: string,
  string?: string,
}

type UseAutoOrderQueuesOptions = {
  filters?: AutoOrderQueueFilters,
  performHttpRequests?: boolean,
  requestOptions?: AutoOrderQueueRequestOptions,
}

function useAutoOrderQueues(options: UseAutoOrderQueuesOptions) {
  const { filters = {}, requestOptions } = options

  const {
    endDate: filterEndDate,
    referenceId: filterReferenceId,
    startDate: filterStartDate,
    string: filterString,
    status: filterStatus,
  } = filters

  const filterStartDateWithTimezone = filterStartDate ? formatDate(filterStartDate, 'yyyy-MM-dd', 'yyyy-MM-dd\'T\'HH:mm:ssZZ') : undefined
  const filterEndsDateWithTimezone = filterEndDate ? formatDate(filterEndDate, 'yyyy-MM-dd', 'yyyy-MM-dd\'T\'HH:mm:ssZZ') : undefined

  const {
    updatedEntities: { autoOrderQueues: autoOrderQueuesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { autoOrderQueues } = useSelector(reduxState => reduxState.entities)

  const filteredAutoOrderQueues = useMemo(() => {
    const filtered = Object.values(autoOrderQueues).filter((autoOrderQueue) => {
      const
        {
          id,
          referenceId,
          status
        } = autoOrderQueue

      const matchAutoOrderQueueId = matchFilterString(id.toString(), filterString)

      const matchReferenceId = matchFilterString(referenceId, filterReferenceId)

      const dueDate = calculateDueDate(autoOrderQueue)

      const matchDueDate = matchFilterBetweenDates(dueDate.toISOString(), filterStartDateWithTimezone, filterEndsDateWithTimezone)

      const matchStatus = matchFilterString(status.toString(), filterStatus)

      return matchAutoOrderQueueId && matchReferenceId && matchDueDate && matchStatus
    })

    return sortArrayBy(filtered, 'desc', (autoOrderQueue: AutoOrderQueueModel) => calculateDueDate(autoOrderQueue).getTime())
  }, [autoOrderQueuesUpdatedAt, JSON.stringify(filters)])

  const filteredAutoOrderQueuesCount = filteredAutoOrderQueues.length
  const hasFilteredAutoOrderQueues = !!filteredAutoOrderQueuesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredAutoOrderQueuesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingAutoOrderQueues } = useReduxAction(
    'autoOrderQueues',
    'loadAutoOrderQueues',
    {
      ...requestOptions,
      ...filtersWithOffset,
      endDate: filterEndsDateWithTimezone,
      startDate: filterStartDateWithTimezone,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAutoOrderQueues,
    filteredAutoOrderQueuesCount,
    hasFilteredAutoOrderQueues,
    loading: loadingAutoOrderQueues,
  }
}

export default useAutoOrderQueues
