import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { useFilters } from '@campaignhub/react-hooks'

import { Box, Button, DashboardModule } from '@campaignhub/suit-theme'

import useClientUser from '@hooks/useClientUser'
import useClientUsers from '@hooks/useClientUsers'

import useUser, { useRelations } from '@hooks/useUser'

import { isAdmin } from '@functions/user'

import DashboardModuleFilters from '@components/DashboardModuleFilters'

import ClientUserListItem from './ClientUserListItem'

const buildPageFilterFields = (isShowingUsers: boolean) => {
  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: isShowingUsers ? 'userName' : 'clientName',
      label: 'Filter by Name',
    },
  ]
}

type ClientUserProps = { clientId: number, userId?: number } | { clientId?: number, userId: number }

const ClientUsers = (props: ClientUserProps) => {
  const { clientId, userId } = props

  const isShowingUsers = !!clientId

  const filterPayload = useFilters()

  const {
    pageFilters,
  } = filterPayload

  const options = {
    filters: {
      clientId,
      userId,
      ...pageFilters,
    },
    performHttpRequests: !!clientId || !!userId,
    requestOptions: {
      include: isShowingUsers ? 'User' : 'Client.Brand',
    },
  }

  const clientUsersPayload = useClientUsers(options)

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredClientUsers,
    filteredClientUsersCount,
    hasFilteredClientUsers,
    loading,
  } = clientUsersPayload

  const {
    callbacks: {
      launchBulkCreateClientUsersModal,
    },
  } = useClientUser({ clientId, userId })

  const { user } = useUser({ id: userId })

  const { userType } = useRelations(user)

  return (
    <DashboardModule
      boxProps={{ borderTop: loading ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={<DashboardModule.FilterHeader {...filterPayload} />}
      loading={loading}
      title={isShowingUsers ? 'Users' : 'Organisations'}
    >
      <DashboardModuleFilters
        filterFields={buildPageFilterFields(isShowingUsers)}
        {...filterPayload}
      />

      <Box flexDirection="column">
        <DashboardModule.ScrollableSection flexDirection="column">
          {!loading && !hasFilteredClientUsers && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>
                No {isShowingUsers ? 'Users' : 'Organisations'} Linked
              </DashboardModule.BlankState.Title>

              <DashboardModule.BlankState.Paragraph>
                {isShowingUsers
                  ? 'Please adjust your filters or link a User'
                  : isAdmin(userType.name)
                    ? 'Admin users cannot be linked to Organisations'
                    : 'Please adjust your filters or link an Organisation'}
              </DashboardModule.BlankState.Paragraph>
            </DashboardModule.BlankState>
          )}

          {!loading && filteredClientUsers.map(clientUser => (
            <ClientUserListItem
              isShowingUsers={isShowingUsers}
              key={clientUser.id}
              clientUser={clientUser}
            />
          ))}
        </DashboardModule.ScrollableSection>

        <DashboardModule.LoadMoreFooter
          boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
          callbacks={{ loadMore }}
          canLoadMore={canLoadMore}
          entityCount={filteredClientUsersCount}
          loading={loading}
        >
          <Button
            buttonStyle="secondaryEdit"
            disabled={isAdmin(userType.name)}
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => launchBulkCreateClientUsersModal()}
            size={['medium', 'small']}
            width={['100%', 'fit-content']}
          >
            {isShowingUsers ? 'Assign Users' : 'Assign Organisations'}
          </Button>
        </DashboardModule.LoadMoreFooter>
      </Box>
    </DashboardModule>
  )
}

export default ClientUsers