import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ExternalPlatformEntityRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['externalPlatformEntities']

type ExternalPlatformEntityFilters = {
  externalEntityId?: string,
  externalPlatformId?: number,
  internalEntityId?: number,
  internalEntityType?: string,
}

type UseExternalPlatformEntitiesOptions = {
  filters?: ExternalPlatformEntityFilters,
  performHttpRequests?: boolean,
  requestOptions?: ExternalPlatformEntityRequestOptions,
}

function useExternalPlatformEntities(options: UseExternalPlatformEntitiesOptions) {
  const { filters = {}, requestOptions } = options
  const {
    externalEntityId: filterExternalEntityId,
    externalPlatformId: filterExternalPlatformId,
    internalEntityId: filterInternalEntityId,
    internalEntityType: filterInternalEntityType,
  } = filters

  const {
    updatedEntities: { externalPlatformEntities: externalPlatformEntitiesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { externalPlatformEntities } = useSelector(reduxState => reduxState.entities)

  const filteredExternalPlatformEntities = useMemo(() => {
    const filtered = Object.values(externalPlatformEntities).filter((externalPlatformEntity) => {
      const { entityId, entityType, externalPlatformId, value } = externalPlatformEntity

      const matchEntityId = matchFilterNumber(entityId, filterInternalEntityId)
      const matchEntityType = filterInternalEntityType ? filterInternalEntityType === entityType : true
      const matchExternalPlatformId = matchFilterNumber(externalPlatformId, filterExternalPlatformId)
      const matchValue = filterExternalEntityId ? filterExternalEntityId === value : true

      return matchEntityId && matchEntityType && matchExternalPlatformId && matchValue
    })

    return sortArrayBy(filtered, 'asc', 'externalPlatformId')
  }, [externalPlatformEntitiesUpdatedAt, JSON.stringify(filters)])

  const filteredExternalPlatformEntitiesCount = filteredExternalPlatformEntities.length
  const hasFilteredExternalPlatformEntities = !!filteredExternalPlatformEntitiesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredExternalPlatformEntitiesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingExternalPlatformEntities } = useReduxAction(
    'externalPlatformEntities',
    'loadExternalPlatformEntities',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredExternalPlatformEntities,
    filteredExternalPlatformEntitiesCount,
    hasFilteredExternalPlatformEntities,
    loading: loadingExternalPlatformEntities,
  }
}

export default useExternalPlatformEntities
