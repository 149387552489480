import { Route, Routes } from 'react-router-dom'

import Details from './screens/Details'
import Items from './screens/Items'
import Relations from './screens/Relations'

const PriceListEditRoutes = () => (
  <Routes>
    <Route index element={<Details />} />
    <Route path="/items" element={<Items />} />
    <Route path="/relations" element={<Relations />} />
  </Routes>
)

export default PriceListEditRoutes
