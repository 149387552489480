import { useContext } from 'react'

import { launchModal } from '@campaignhub/javascript-utils'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as productActions from '@redux/modules/product'

import defaultFormState, { requiredFields } from '@models/product'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { ProductModel, ProductRequestOptions } from '@models/types'

export const generateUrls = (product?: Partial<ProductModel>) => {
  const { id } = product || {}

  return {
    archivedProductsIndexUrl: '#/products?isArchived=true',
    editProductRelationsUrl: `#/products/${id}/edit/relations`,
    editProductUrl: `#/products/${id}/edit/`,
    productsIndexUrl: '#/products',
  }
}

type CreateProductParams = {
  dispatch: AppDispatch,
  productParams: Partial<ProductModel>,
  requestOptions?: ProductRequestOptions,
}

const createProduct = (params: CreateProductParams) => {
  const { dispatch, productParams, requestOptions } = params
  const { createProduct: createFn } = productActions

  return dispatch(createFn(productParams, requestOptions)).then((response) => {
    const { success } = response

    if (success) {
      const { data } = response

      const { editProductUrl } = generateUrls(data)

      return {
        success,
        data,
        redirectUrl: editProductUrl,
      }
    }

    return response
  })
}

type UpdateProductParams = {
  dispatch: AppDispatch,
  product: ProductModel,
  productParams: Partial<ProductModel>,
  requestOptions?: ProductRequestOptions,
}

const updateProduct = (params: UpdateProductParams) => {
  const {
    dispatch, product, productParams, requestOptions,
  } = params
  const { updateProduct: updateFn } = productActions

  const updatedParams = {
    id: product.id,
    ...productParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DuplicateProductParams = {
  dispatch: AppDispatch,
  productParams: Partial<ProductModel>,
  requestOptions?: ProductRequestOptions,
}

const duplicateProduct = (params: DuplicateProductParams) => {
  const { dispatch, productParams, requestOptions } = params
  const { duplicateProduct: duplicateFn } = productActions

  return dispatch(duplicateFn(productParams, requestOptions)).then((response) => {
    const { success } = response

    if (success) {
      const { data } = response

      const { editProductUrl } = generateUrls(data)

      return {
        success,
        data,
        redirectUrl: editProductUrl,
      }
    }

    return response
  })
}

type DeleteProductParams = {
  dispatch: AppDispatch,
  product: DeleteParams<ProductModel>,
}

const deleteProduct = (params: DeleteProductParams) => {
  const { dispatch, product } = params
  const { deleteProduct: deleteFn } = productActions

  return dispatch(deleteFn(product)).then((response) => {
    const { success } = response

    if (success) {
      const { data } = response
      const { productsIndexUrl } = generateUrls(data)

      return {
        success,
        data,
        redirectUrl: productsIndexUrl,
      }
    }

    return response
  })
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useProductForm(
  product: Partial<ProductModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const productForm = useForm(
    defaultFormState,
    { entity: product, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [product.id, product.cacheKey],
  )

  return {
    ...productForm,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ProductRequestOptions,
}

function useProduct(initEntity: Partial<ProductModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: product }: { entity: ProductModel } = useLatestEntity(initEntity, 'products')
  const { id } = product

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'products',
    'loadProduct',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.products)

  return {
    callbacks: {
      createProduct: (productParams: Partial<ProductModel>, entityOptions?: ProductRequestOptions) => (
        createProduct({ productParams, dispatch, requestOptions: entityOptions })
      ),
      deleteProduct: () => deleteProduct({ dispatch, product }),
      duplicateProduct: (productParams: Partial<ProductModel>, entityOptions?: ProductRequestOptions) => (
        duplicateProduct({ productParams, dispatch, requestOptions: entityOptions })
      ),
      launchDuplicateProductModal: () => launchModal({
        callbacks,
        modalKey: 'DuplicateProductModal',
        payload: { product },
      }),
      updateProduct: (productParams: Partial<ProductModel>, entityOptions?: ProductRequestOptions) => (
        updateProduct({
          product, productParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    product,
    updating,
    urls: generateUrls(product),
  }
}

export default useProduct
