import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import type { CutterProfileModuleModel } from '@models/types'

import useCutterProfileModule from '@hooks/useCutterProfileModule'
import { camelCaseToTitleCase } from '@campaignhub/javascript-utils'

interface CutterProfileModuleListItemProps {
  cutterProfileModule: CutterProfileModuleModel,
}

const CutterProfileModuleListItem = (props: CutterProfileModuleListItemProps) => {
  const {
    cutterProfileModule,
    cutterProfileModule: {
      height,
      name,
      width,
      adTypeId,
    },
  } = props

  const {
    callbacks: {
      createOrEditCutterProfileModule,
    },
  } = useCutterProfileModule(cutterProfileModule)

  return (
    <DashboardModuleListItem
      onClick={() => createOrEditCutterProfileModule()}
      secondaryText={camelCaseToTitleCase(adTypeId)}
      title={name}
    >
      <DashboardModuleListItem.TertiaryText>
        {`${width} by ${height}`}
      </DashboardModuleListItem.TertiaryText>
    </DashboardModuleListItem>
  )
}

export default CutterProfileModuleListItem
