import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ArtworkTemplateGroupLinkModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/artworkTemplateGroupLink/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/artworkTemplateGroupLink/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/artworkTemplateGroupLink/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/artworkTemplateGroupLink/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/artworkTemplateGroupLink/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/artworkTemplateGroupLink/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/artworkTemplateGroupLink/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/artworkTemplateGroupLink/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/artworkTemplateGroupLink/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/artworkTemplateGroupLink/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/artworkTemplateGroupLink/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/artworkTemplateGroupLink/DELETE_FAILURE'

export type ArtworkTemplateGroupLinkModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: ArtworkTemplateGroupLinkModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadArtworkTemplateGroupLinks(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().artworkTemplateGroupLinks.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/artwork-template-group-links', options)
      .then(({ data }: { data: { data: ArtworkTemplateGroupLinkModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.ARTWORK_TEMPLATE_GROUP_LINK_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteArtworkTemplateGroupLink(artworkTemplateGroupLink: DeleteParams<ArtworkTemplateGroupLinkModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/artwork-template-group-links/${artworkTemplateGroupLink.id}`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        const deletedEntity = { id: artworkTemplateGroupLink.id }
        const normalizedJson = normalize(deletedEntity, Schemas.ARTWORK_TEMPLATE_GROUP_LINK)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function bulkCreateArtworkTemplateGroupLinks(
  params: { artworkTemplateGroupIds?: number[], entityIds?: number[], entityType: string },
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    data: JSON.stringify(params),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/artwork-template-group-links/bulk', options, config)
      .then(({ data }: { data: ArtworkTemplateGroupLinkModel[] }) => {
        const normalizedJson = normalize(data, Schemas.ARTWORK_TEMPLATE_GROUP_LINK_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function setDefaultArtworkTemplateGroupLink(
  artworkTemplateGroupLinkId: number,
  isDefault: boolean,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())
    const artworkTemplateGroupLinkRoute = `/artwork-template-group-links/${artworkTemplateGroupLinkId}`
    const promise = api(`${artworkTemplateGroupLinkRoute}/default?isDefault=${isDefault}`, options, config)
      .then(({ data }: { data: ArtworkTemplateGroupLinkModel }) => {
        const normalizedJson = normalize(data, Schemas.ARTWORK_TEMPLATE_GROUP_LINK_ARRAY)

        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ArtworkTemplateGroupLinkModuleState = initialState,
  action: Action = { type: '' },
): ArtworkTemplateGroupLinkModuleState {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
