import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { CaptionModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type { Action, EntityOptions, Errors, RootReducerState } from '@redux/modules/types'

import { handleError } from '../utils'

const CREATE_REQUEST = 'realbase/caption/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/caption/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/caption/CREATE_FAILURE'

export type CaptionModuleState = {
  creating: boolean,
  errors: string[],
}

// Initial State
const initialState: CaptionModuleState = {
  creating: false,
  errors: [],
}

// Actions
export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export type CreateOrUpdateParams = {
  callToActions?: string[],
  campaignId: number,
  descriptions?: string[],
  displayUrls?: string[],
  headlines?: string[],
  texts?: string[],
}

export function createOrUpdateCaptions(captionsParams: CreateOrUpdateParams, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      ...captionsParams,
    }),
  }

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    dispatch(createRequest())

    const promise = api('/captions', options, config)
      .then(({ data }: { data: CaptionModel }) => {
        // Delete all captions because they are deleted on the backend with this request
        const { captions } = getState().entities

        Object.values(captions).forEach(caption => {
          if (caption.campaignId === captionsParams.campaignId) {
            removeCaption(dispatch, caption)
          }
        })

        const normalizedJson = normalize(data, Schemas.CAPTION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function removeCaption(dispatch: AppDispatch, caption: CaptionModel) {
  const normalizedJson = normalize(caption, Schemas.CAPTION)
  dispatch(deleteEntity(normalizedJson))
}

// Reducer
export default function reducer(
  state: CaptionModuleState = initialState,
  action: Action = { type: '' },
): CaptionModuleState {
  switch (action.type){
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
