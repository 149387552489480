import { useEffect, useState } from 'react'

import { mapCaptionsToObject } from '@functions/caption'

import useCaptions from '@hooks/useCaptions'

export type CaptionsFormState = {
  callToActions: string[],
  campaignId: number,
  descriptions: string[],
  displayUrls: string[],
  headlines: string[],
  texts: string[],
}

// Map captions array to the data structure required for the POST /captions payload, e.g.
// {
//   "campaignId": 1,
//   "headlines": [
//     "Wonderful Neighborhood"
//   ],
//   "texts": [
//     "Effortless family living"
//   ],
//   "descriptions": [
//     "Sample description"
//   ],
//   "callToActions": [
//     "Enquire Now"
//   ],
//   "displayUrls": [
//     "propertyshowcase.com/6Adelantado"
//   ]
// }
const useAdContent = (campaignId: number) => {
  const captionsPayload = useCaptions({
    filters: {
      campaignId: campaignId,
    },
  })

  const { filteredCaptions, filteredCaptionsCount } = captionsPayload

  const captionsObject = mapCaptionsToObject(filteredCaptions)
  const captionsStateWithCampaignId = { ...captionsObject, campaignId }

  // Map campaign's Captions to data structure required for createOrUpdate request
  const [captionsFormState, setCaptionsFormState] = useState<CaptionsFormState>(captionsStateWithCampaignId)

  // Update captionsFormState when captions have loaded
  useEffect(() => {
    setCaptionsFormState(captionsStateWithCampaignId)
  }, [filteredCaptionsCount])

  return {
    callbacks: {
      setCaptionsFormState,
    },
    captionsFormState
  }
}

export default useAdContent