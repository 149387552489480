import { Route, Routes } from 'react-router-dom'

import ClientEdit from './Edit'
import ClientsList from './List'

const ClientsRoutes = () => (
  <Routes>
    <Route path="/" element={<ClientsList />} />
    <Route path="/:clientId/edit/*" element={<ClientEdit />} />
  </Routes>
)

export default ClientsRoutes
