import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, SaveFormModule, SectionDivider,
} from '@campaignhub/suit-theme'

import usePriceListItemOption, { useRelations } from '@hooks/usePriceListItemOption'
import usePriceListItemOptionValues from '@hooks/usePriceListItemOptionValues'

import { PriceListItemOptionModel } from '@models/types'

import PriceListItemOptionValueListItem from './PriceListItemOptionValueListItem'

interface PriceListItemOptionValueDetailsProps {
  priceListItemOption: PriceListItemOptionModel,
}

const PriceListItemOptionValueDetails = (props: PriceListItemOptionValueDetailsProps) => {
  const { priceListItemOption } = props

  const priceListItemOptionValuesPayload = usePriceListItemOptionValues({
    filters: {
      priceListItemOptionId: priceListItemOption.id,
    },
  })

  const {
    filteredPriceListItemOptionValues,
    hasFilteredPriceListItemOptionValues,
  } = priceListItemOptionValuesPayload

  const { productOption } = useRelations(priceListItemOption)

  const {
    callbacks: {
      launchBulkCreatePriceListItemOptionValuesModal,
    },
  } = usePriceListItemOption(priceListItemOption)

  return (
    <>
      <SectionDivider>Values</SectionDivider>

      {hasFilteredPriceListItemOptionValues && (
        <DashboardModule title={priceListItemOption.nameOverride || productOption.name}>
          <Box flexDirection="column" padding="large">
            {filteredPriceListItemOptionValues.map((priceListItemOptionValue, index) => (
              <PriceListItemOptionValueListItem
                isFirst={index === 0}
                key={priceListItemOptionValue.id}
                priceListItemOptionValue={priceListItemOptionValue}
              />
            ))}
          </Box>
        </DashboardModule>
      )}

      <SaveFormModule>
        <Button
          buttonStyle="secondaryEdit"
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => launchBulkCreatePriceListItemOptionValuesModal()}
          size="small"
        >
          Add Values
        </Button>
      </SaveFormModule>
    </>
  )
}

export default PriceListItemOptionValueDetails
