import {
  Box,
  Image,
  ListItem,
  Text,
} from '@campaignhub/suit-theme'

import type { ArtworkTemplateModel } from '@models/types'

import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDate } from '@campaignhub/javascript-utils'
import useArtworkTemplate from '@hooks/useArtworkTemplate'

interface ArtworkTemplateListItemProps {
  artworkTemplate: ArtworkTemplateModel,
  showBorderBottom?: boolean,
}

const ArtworkTemplateListItem = (props: ArtworkTemplateListItemProps) => {
  const {
    artworkTemplate,
    artworkTemplate: {
      name,
      previewImageUrl,
      realhubId,
      createdAt,
    },
    showBorderBottom,
  } = props

  const {
    callbacks: {
      createOrEditArtworkTemplate,
    },
  } = useArtworkTemplate(artworkTemplate)

  return (
    <ListItem
      boxProps={{
        borderBottom: showBorderBottom ? '1px dashed' : null,
        padding: 'large',
      }}
      onClick={() => createOrEditArtworkTemplate()}
      showAngleIcon
    >
      <Box
        alignItems="center"
        backgroundColor="whiteGrey"
        border="1px solid"
        borderColor="hoverGrey"
        borderRadius={5}
        height={60}
        justifyContent="center"
        marginRight="large"
        width={100}
      >
        {!previewImageUrl && (
          <FontAwesomeIcon icon={faPhotoVideo} />
        )}

        {previewImageUrl && (
          <Image
            borderRadius={5}
            height={60}
            url={previewImageUrl}
          />
        )}
      </Box>

      <Box flexDirection="column" justifyContent="center">
        <Text fontSize="small" color="bodyFontLightColor">
          {name}
        </Text>

        <Text fontSize="xsmall" marginTop="medium" color="faintGrey">
          {`Created ${formatDate(createdAt, 'ISO8601', "ccc, dd LLL yyyy 'at' h:mm a")}`}
        </Text>

        <Text fontSize="xsmall" marginTop="medium" color="bodyFontLightColor">
          Realhub ID #{realhubId}
        </Text>
      </Box>
    </ListItem>
  )
}

export default ArtworkTemplateListItem
