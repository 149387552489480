import { DateTime } from 'luxon'

// startDate and endDate must be ISO8601 format
export const calculateDaysBetweenDates = (startDate: string, endDate: string) => {
  const formattedStartDate = DateTime.fromISO(startDate)
  const formattedEndDate = DateTime.fromISO(endDate)

  return formattedEndDate.diff(formattedStartDate, 'days').values.days
}

export const matchFilterBetweenDates = (
  value: string,
  minDate?: string,
  maxDate?: string,
) => {
  if (!minDate && !maxDate) return true

  if (!value) return false

  const date = DateTime.fromISO(value)

  const min = minDate ? DateTime.fromISO(minDate).startOf('day') : null

  const max = maxDate ? DateTime.fromISO(maxDate).endOf('day') : null

  if (min && !max) return date >= min
  if (!min && max) return date <= max
  if (min && max) return date >= min && date <= max

  return false
}

export default calculateDaysBetweenDates
