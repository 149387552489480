import { useState } from 'react'

import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import useAdTemplates from '@hooks/useAdTemplates'
import useAdTemplateProduct from '@hooks/useAdTemplateProduct'
import useAdTemplateProducts from '@hooks/useAdTemplateProducts'

import useSelector from '@hooks/useSelector'

import { AdTemplateProductModel } from '@models/types'

const defaultState = {
  name: '',
}

const useBulkCreateAdTemplateProducts = (adTemplateProduct: Partial<AdTemplateProductModel> = {}) => {
  const { adTemplateId, productId } = adTemplateProduct

  const [selectedEntityIds, setSelectedEntityIds] = useState<number[]>([])

  const [state, setState] = useSetState(defaultState)
  const { name } = state

  const {
    callbacks: {
      bulkCreateAdTemplateProducts: bulkCreateFn,
    },
    creating,
  } = useAdTemplateProduct(adTemplateProduct)

  const { adTemplates, products } = useSelector(reduxState => reduxState.entities)

  const debouncedString = useDebounce(name, 200)

  const adTemplatesPayload = useAdTemplates({
    filters: {
      name: debouncedString,
    },
    performHttpRequests: true,
  })

  const { filteredAdTemplates } = adTemplatesPayload

  // Filter out Ad Template Ids which are already assigned
  const { filteredAdTemplateProducts } = useAdTemplateProducts({
    filters: {
      productId,
    },
  })

  const assignedAdTemplateIds = filteredAdTemplateProducts.map(adTemplateProduct => adTemplateProduct.adTemplateId)

  const filteredAdTemplateIds = filteredAdTemplates?.reduce<number[]>((acc, adTemplate) => {
    if (!assignedAdTemplateIds.includes(adTemplate.id)){
      acc.push(adTemplate.id)
    }
    return acc
  }, [])

  const entityParams = adTemplateId
    ? { adTemplateIds: [adTemplateId], productIds: selectedEntityIds }
    : { adTemplateIds: selectedEntityIds, productIds: [productId] }

  return {
    adTemplates,
    adTemplatesPayload,
    callbacks: {
      bulkCreateFn,
      setSelectedEntityIds,
      setState,
    },
    creating,
    entityParams,
    filteredAdTemplateIds,
    filteredAdTemplateIdsCount: filteredAdTemplateIds.length,
    name,
    products,
    saveEnabled: (adTemplateId || productId) && !!selectedEntityIds.length,
    selectedEntityIds,
  }
}

export default useBulkCreateAdTemplateProducts
