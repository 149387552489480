import { Route, Routes } from 'react-router-dom'

import OrderEdit from './Edit'
import OrdersList from './List'

const OrdersRoutes = () => (
  <Routes>
    <Route path="/" element={<OrdersList />} />
    <Route path="/:campaignId/*" element={<OrderEdit />} />
  </Routes>
)

export default OrdersRoutes
