import { useMemo } from 'react'

import type { UseFiltersPayload } from '@campaignhub/react-hooks'
import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import CreateProductOptionValueModal from '@modals/CreateProductOptionValueModal'
import DuplicateProductOptionModal from '@modals/DuplicateProductOptionModal'

import type { ProductOptionModel, ProductOptionValueModel } from '@models/types'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    showCreateProductOptionValueModal: (payload: { productOption: ProductOptionValueModel }) => void,
    showDuplicateProductOptionModal: (payload: { productOption: ProductOptionModel }) => void,
  },
}

const defaultState = {
  showCreateProductOptionValueModal: false,
  showDuplicateProductOptionModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateProductOptionValueModal: {
      closeModal: () => setState({ showCreateProductOptionValueModal: false }),
      createProductOptionValue: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    DuplicateProductOptionModal: {
      closeModal: () => setState({ showDuplicateProductOptionModal: false }),
      duplicateProductOption: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Details = () => {
  const { productOptionId } = useNumericParams()

  const [state, setState] = useSetState(defaultState)
  const {
    showCreateProductOptionValueModal,
    showDuplicateProductOptionModal,
  } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      deleteProductOptionValue: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      duplicateProductOptionValue: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      showCreateProductOptionValueModal: (payload: { productOption: ProductOptionModel }) => {
        setModalData('CreateProductOptionValueModal', payload)
        setState({ showCreateProductOptionValueModal: true })
      },
      showDuplicateProductOptionModal: (payload: { productOption: ProductOptionModel }) => {
        setModalData('DuplicateProductOptionModal', payload)
        setState({ showDuplicateProductOptionModal: true })
      },
      updateProductOption: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateProductOptionValue: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent productOptionId={productOptionId} />

        <CreateProductOptionValueModal
          callbacks={callbacks('CreateProductOptionValueModal', setState)}
          showModal={showCreateProductOptionValueModal}
        />

        <DuplicateProductOptionModal
          callbacks={callbacks('DuplicateProductOptionModal', setState)}
          showModal={showDuplicateProductOptionModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details
