import { formatDate } from '@campaignhub/javascript-utils'

import { Comment as CommentBase } from '@campaignhub/suit-theme'

import type { CommentModel } from '@models/comment'

import { useRelations as useCommentRelations } from '@hooks/useComment'
import { useRelations as useUserRelations } from '@hooks/useUser'

interface CommentProps {
  comment: CommentModel,
  isLast: boolean,
}

const Comment = (props: CommentProps) => {
  const {
    comment,
    comment: {
      body,
      createdAt,
      public: isPublic,
    },
    isLast,
  } = props

  const { user } = useCommentRelations(comment)
  const { coverImage } = useUserRelations(user)

  const createdAtString = formatDate(createdAt, 'ISO8601', "ccc, dd LLL yyyy 'at' h:mm a")

  return (
    <CommentBase
      boxProps={{
        borderBottom: isLast ? 'none' : '1px dashed',
        marginBottom: isLast ? 0 : 'large',
      }}
      commentBody={body}
      commentTime={createdAtString}
      fullName={user.name}
      imageUrl={coverImage.filePath}
      isPublic={isPublic}
    />
  )
}

export default Comment
