import { useMemo } from 'react'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'
import type { UseFiltersPayload } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import CreateProductModal from '@modals/CreateProductModal'

import type { ProductModel } from '@models/types'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    showCreateProductModal: () => void,
  },
}

const defaultState = {
  showCreateProductModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateProductModal: {
      closeModal: () => setState({ showCreateProductModal: false }),
      createProduct: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const List = () => {
  const [state, setState] = useSetState(defaultState)
  const { showCreateProductModal } = state

  const filtersPayload = useFilters({ persistFilters: true })
  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showCreateProductModal: (payload: { product: ProductModel }) => {
        setModalData('CreateProductModal', payload)
        setState({ showCreateProductModal: true })
      },
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt]) // modalContext dependency required if layering modals

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <CreateProductModal
          callbacks={callbacks('CreateProductModal', setState)}
          showModal={showCreateProductModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default List
