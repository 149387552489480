import {
  Box, Form, SortableList, Text,
} from '@campaignhub/suit-theme'

import useSelector from '@hooks/useSelector'

import DraggableUser from './DraggableUser'
import type { Payload } from '../hooks/useManageCampaignUsers'

interface DraggableUsersProps {
  callbacks: {
    modifyUserSort: (fromIndex: number, toIndex: number, payload: Payload) => void,
    toggleUser: (userId: number, listName: string) => void,
  },
  userIds: {
    leadUserIds: number[],
    supportUserIds: number[],
  },
}

const DraggableUsers = (props: DraggableUsersProps) => {
  const {
    callbacks: {
      modifyUserSort,
      toggleUser,
    },
    userIds: {
      leadUserIds,
      supportUserIds,
    },
  } = props

  const hasAssignedUsers = !![...leadUserIds, ...supportUserIds].length

  const users = useSelector(reduxState => reduxState.entities.users)

  const selectedLeadUsers = leadUserIds.map(id => users[id]).filter(u => u)
  const selectedSupportUsers = supportUserIds.map(id => users[id]).filter(u => u)

  if (!hasAssignedUsers) return null

  return (
    <SortableList callbacks={{ onDragEnd: modifyUserSort }}>
      <Form>
        <Form.Field label="Agents">
          <SortableList.Dropzone droppableId="leadUserIds">
            <Box flexDirection="column">
              {selectedLeadUsers.map((user, index) => (
                <SortableList.Item draggableId={`User-${user.id}`} key={user.id} index={index}>
                  <DraggableUser
                    callbacks={{ removeUser: (userId: number) => toggleUser(userId, 'leadUserIds') }}
                    user={user}
                  />
                </SortableList.Item>
              ))}

              {hasAssignedUsers && !selectedLeadUsers.length && (
                <Box
                  alignItems="center"
                  backgroundColor="whiteGrey"
                  border="1px dashed"
                  borderColor="lineColor"
                  borderRadius="5px"
                  height="62px"
                  justifyContent="center"
                  marginY="large"
                >
                  <Text color="lightGrey" fontSize="small">
                    Drag user here to add to Agents
                  </Text>
                </Box>
              )}
            </Box>
          </SortableList.Dropzone>
        </Form.Field>

        <Form.Field label="Support">
          <SortableList.Dropzone droppableId="supportUserIds">
            <Box flexDirection="column">
              {selectedSupportUsers.map((user, index) => (
                <SortableList.Item draggableId={`User-${user.id}`} key={user.id} index={index}>
                  <DraggableUser
                    callbacks={{ removeUser: (userId: number) => toggleUser(userId, 'supportUserIds') }}
                    user={user}
                  />
                </SortableList.Item>
              ))}

              {hasAssignedUsers && !selectedSupportUsers.length && (
                <Box
                  alignItems="center"
                  backgroundColor="whiteGrey"
                  border="1px dashed"
                  borderColor="lineColor"
                  borderRadius="5px"
                  height="62px"
                  justifyContent="center"
                  marginY="large"
                >
                  <Text color="lightGrey" fontSize="small">
                    Drag user here to add to Support
                  </Text>
                </Box>
              )}
            </Box>
          </SortableList.Dropzone>
        </Form.Field>
      </Form>
    </SortableList>
  )
}

export default DraggableUsers
