import React from 'react'

import { PageHeader as PageHeaderBase } from '@campaignhub/suit-theme'

type TabBarItem = {
  icon: React.ReactNode,
  href: string,
  key: string,
  title: string,
}

interface PageHeaderProps {
  actionContent?: React.ReactNode,
  activeTabBarItemKey?: string,
  boxProps?: object,
  callbacks?: object,
  heading?: React.ReactNode,
  lowerRightContent?: React.ReactNode,
  nestedNavigation?: boolean,
  offset?: object,
  tabBarItems?: TabBarItem[],
  title?: string,
  width?: string | number,
}

const PageHeader = (props: PageHeaderProps) => {
  const { boxProps, ...restProps } = props

  return (
    <PageHeaderBase
      boxProps={{ height: [112, 105], justifyContent: 'flex-start', ...boxProps }}
      {...restProps}
    />
  )
}

export default PageHeader
