import { formatMoney } from 'accounting'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAlarmClock, faCalendar, faClock, faWallet,
} from '@fortawesome/pro-light-svg-icons'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'

import { camelCaseToTitleCase, formatDate } from '@campaignhub/javascript-utils'

import {
  Box, SidebarModal, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import getStatusBadgeProps from '@functions/statusBadge'

import { useRelations } from '@hooks/useAdQueue'

import { AdQueueModel } from '@models/types'

import DetailsListItem from '@components/DetailsListItem'

const getDurationString = (duration: number | null) => {
  if (duration === null) return 'No Data'
  if (duration === 1) return '1 Day'
  return `${duration} Days`
}

interface AdQueueDetailsProps {
  adQueue: AdQueueModel,
}

const AdQueueDetails = (props: AdQueueDetailsProps) => {
  const { adQueue } = props

  const {
    adPayload,
    adTypeId,
    budget,
    delayStart,
    duration,
    errorMessage,
    objective,
    status,
  } = adQueue

  const {
    adTemplate: {
      name: adTemplateName,
    },
    campaign: {
      name: campaignName,
      requiredAt,
    },
  } = useRelations(adQueue)

  const parsedPayload = JSON.parse(adPayload)
  const { Name, ScheduleStart } = parsedPayload

  const statusBadgeProps = getStatusBadgeProps(status)

  const dueDate = requiredAt || ScheduleStart
  const formattedDueDate = dueDate ? formatDate(dueDate, 'ISO8601', 'dd LLL yyyy t') : '-'

  const delayStartText = delayStart
    ? `${delayStart} ${delayStart === 1 ? 'Hour' : 'Hours'}`
    : '0 Hours'

  return (
    <>
      <Box flexDirection="column">
        <Text fontSize="small">Status</Text>

        <StatusBadge
          boxProps={{ borderWidth: 2, marginTop: 'medium' }}
          ghost
          {...statusBadgeProps}
        />
      </Box>

      {status === 'Failed' && (
        <SidebarModal.NoticeBox boxProps={{ marginBottom: 'none', marginTop: 'large' }}>
          <SidebarModal.NoticeBox.Title>
            <Box alignItems="center">
              <FontAwesomeIcon color="red" icon={faTimesCircle} />
              <Text marginLeft="medium">Failure Details</Text>
            </Box>
          </SidebarModal.NoticeBox.Title>

          <SidebarModal.NoticeBox.Paragraph>
            {errorMessage}
          </SidebarModal.NoticeBox.Paragraph>
        </SidebarModal.NoticeBox>
      )}

      <SidebarModal.ExpandableSection
        defaultOpen
        label="Campaign Position"
        title="Ad Deployer"
        style={{ marginTop: 16 }}
      >
        <SidebarModal.NoticeBox>
          <SidebarModal.NoticeBox.Title>Position</SidebarModal.NoticeBox.Title>

          <SidebarModal.NoticeBox.Paragraph boxProps={{ marginTop: 'none', marginBottom: 'medium' }}>
            Ad Deployer
          </SidebarModal.NoticeBox.Paragraph>

          <SidebarModal.NoticeBox.Title>Ad Channel</SidebarModal.NoticeBox.Title>

          <SidebarModal.NoticeBox.Paragraph boxProps={{ marginTop: 'none', marginBottom: 'medium' }}>
            {camelCaseToTitleCase(adTypeId)}
          </SidebarModal.NoticeBox.Paragraph>

          <SidebarModal.NoticeBox.Title>Objective</SidebarModal.NoticeBox.Title>

          <SidebarModal.NoticeBox.Paragraph boxProps={{ marginTop: 'none', marginBottom: 'medium' }}>
            {objective || 'None'}
          </SidebarModal.NoticeBox.Paragraph>

          <SidebarModal.NoticeBox.Title>Campaign Title</SidebarModal.NoticeBox.Title>

          <SidebarModal.NoticeBox.Paragraph
            boxProps={{ marginTop: 'none', marginBottom: adTemplateName ? 'medium' : 'none' }}
          >
            {campaignName || Name}
          </SidebarModal.NoticeBox.Paragraph>

          {!!adTemplateName && (
            <>
              <SidebarModal.NoticeBox.Title>Source</SidebarModal.NoticeBox.Title>

              <SidebarModal.NoticeBox.Paragraph boxProps={{ marginTop: 'none' }}>
                {adTemplateName}
              </SidebarModal.NoticeBox.Paragraph>
            </>
          )}
        </SidebarModal.NoticeBox>

        <DetailsListItem
          icon={faCalendar}
          label="Due Date"
          text={formattedDueDate}
        />

        <DetailsListItem
          icon={faWallet}
          label="Budget"
          text={formatMoney(budget, '$', 0)}
        />

        <DetailsListItem
          icon={faClock}
          label="Duration"
          text={getDurationString(duration)}
        />

        <DetailsListItem
          icon={faAlarmClock}
          label="Delay Start"
          text={delayStartText}
        />
      </SidebarModal.ExpandableSection>
    </>
  )
}

export default AdQueueDetails
