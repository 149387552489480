import {
  DashboardModule, Link,
} from '@campaignhub/suit-theme'

import useAsset from '@hooks/useAsset'
import useAssets from '@hooks/useAssets'

import DocumentListItem from './DocumentListItem'

interface DocumentsProps {
  filters: {
    assetTypeCategory: string,
    assetableId: number,
    assetableType: string,
  },
}

const Documents = (props: DocumentsProps) => {
  const { filters } = props

  const assetsPayload = useAssets({
    filters,
    performHttpRequests: true,
  })

  const {
    filteredAssets,
    hasFilteredAssets,
    loading,
  } = assetsPayload

  const {
    callbacks: {
      manageDocuments,
    },
  } = useAsset()

  return (
    <DashboardModule
      headerRight={(
        <Link
          onClick={() => manageDocuments(filters)}
          textProps={{ fontSize: 'small' }}
        >
          Manage
        </Link>
      )}
      loading={loading}
      title="Documents"
    >
      {!loading && hasFilteredAssets && (
        <DashboardModule.ScrollableSection>
          {filteredAssets.map(asset => (
            <DocumentListItem asset={asset} key={asset.id} />
          ))}
        </DashboardModule.ScrollableSection>
      )}
    </DashboardModule>
  )
}

export default Documents
