import { Route, Routes } from 'react-router-dom'

import BrandEdit from './Edit'
import BrandsList from './List'

const BrandsRoutes = () => (
  <Routes>
    <Route path="/" element={<BrandsList />} />
    <Route path="/:brandId/edit/*" element={<BrandEdit />} />
  </Routes>
)

export default BrandsRoutes
