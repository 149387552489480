import { toggleArray } from '@campaignhub/javascript-utils'

import { useSetState } from '@campaignhub/react-hooks'

import getAssetEntity from '@functions/asset'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'
import type { AssetFilters } from '@hooks/useAssets'

import * as assetActions from '@redux/modules/asset'

import type { AppDispatch } from '@redux/store'

type SaveSortOrderParams = {
  assetableId: number,
  assetableType: string,
  dispatch: AppDispatch,
  sortOrder: number[],
}

const saveSortOrder = (params: SaveSortOrderParams) => {
  const {
    assetableId,
    assetableType,
    dispatch,
    sortOrder,
  } = params

  const { updateSortOrder: updateSortOrderFn } = assetActions

  return dispatch(updateSortOrderFn({ assetableId, assetableType, sortOrder }))
}

type DefaultStateType = {
  sortOrder: number[],
  sorting: boolean,
  uploading: boolean,
}

const updateSortOrder = (
  imageId: number,
  state: DefaultStateType,
  setState: (s: Partial<DefaultStateType>) => void,
) => {
  const { sortOrder } = state
  const updatedSortOrder = toggleArray(sortOrder, imageId)
  setState({ sortOrder: updatedSortOrder })
}

const defaultState: DefaultStateType = {
  sortOrder: [],
  sorting: false,
  uploading: false,
}

const useManageMediaModal = (filters: Required<AssetFilters>) => {
  const { assetableId, assetableType } = filters

  const [state, setState] = useSetState(defaultState)
  const { sortOrder, sorting, uploading } = state

  const { updating } = useSelector(reduxState => reduxState.assets)

  const dispatch = useDispatch()

  const entities = useSelector(reduxState => reduxState.entities)
  const campaign = getAssetEntity(filters, entities)

  return {
    callbacks: {
      saveSortOrder: () => saveSortOrder({
        assetableId,
        assetableType,
        dispatch,
        sortOrder,
      }),
      setState,
      updateSortOrder: (imageId: number) => updateSortOrder(imageId, state, setState),
    },
    campaign,
    sortOrder,
    sorting,
    updating,
    uploading,
  }
}

export default useManageMediaModal
