import {
  DashboardModule, Form,
} from '@campaignhub/suit-theme'

import { useUserForm } from '@hooks/useUser'

interface UserDetailsProps {
  userFormPayload: ReturnType<typeof useUserForm>,
}

const UserDetails = (props: UserDetailsProps) => {
  const {
    userFormPayload: {
      entityState: {
        countryCallingCode,
        email,
        mobileNumber,
        name,
        username,
      },
      errors,
    },
  } = props

  return (
    <DashboardModule title="Details">
      <Form padding="large">
        <Form.Row>
          <Form.Field errorMessage={errors.name} label="Name">
            <input
              disabled
              value={name}
              type="text"
            />
          </Form.Field>

          <Form.Field errorMessage={errors.email} label="Email">
            <input
              disabled
              value={email}
              type="text"
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field errorMessage={errors.mobileNumber} label="Mobile Number">
            <input
              disabled
              value={`${countryCallingCode || ''} ${mobileNumber}`.trim()}
              type="text"
            />
          </Form.Field>

          <Form.Field errorMessage={errors.username} label="Username">
            <input
              disabled
              value={username}
              type="text"
            />
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default UserDetails
