import { useMemo } from 'react'

import { useModals, useNumericParams } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'

const Details = () => {
  const { brandId } = useNumericParams()

  const modalContext = useModals()
  const {
    callbacks: {},
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {},
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent brandId={brandId} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details
