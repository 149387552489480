import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import { sortArrayBy } from '@campaignhub/javascript-utils'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as userActions from '@redux/modules/user'

import type { UserModel, UserRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

import defaultFormState, { requiredFields } from '@models/user'

import type { AppDispatch } from '@redux/store'

export const generateUrls = (user?: Partial<UserModel>) => {
  const { id } = user || {}

  return {
    archivedUsersIndexUrl: '#/users?isArchived=true',
    editUserRelationsUrl: `#/users/${id}/edit/relations`,
    editUserUrl: `#/users/${id}/edit/`,
    usersIndexUrl: '#/users',
  }
}

// eslint-disable-next-line
export function useRelations(user: UserModel) {
  const { assetGroupId, userTypeId } = user || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets, userTypes } = entities

  const userImages = Object.values(assets).filter(asset => (
    asset.category === 'Image' && asset.assetGroupId === assetGroupId
  ))

  const sortedUserImages = sortArrayBy(userImages, 'asc', ({ sort }) => (sort || Infinity))

  const userType = userTypes[userTypeId] || {}

  return {
    coverImage: sortedUserImages[0] || {},
    images: sortedUserImages,
    userType,
  }
}

type UpdateUserParams = {
  user: UserModel,
  userParams: Partial<UserModel>,
  dispatch: AppDispatch,
  requestOptions?: UserRequestOptions,
}

const updateUser = (params: UpdateUserParams) => {
  const {
    dispatch, user, userParams, requestOptions,
  } = params
  const { updateUser: updateFn } = userActions

  const updatedParams = {
    id: user.id,
    ...userParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useUserForm(
  user: Partial<UserModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const userForm = useForm(
    defaultFormState,
    { entity: user, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [user.id, user.cacheKey],
  )

  return {
    ...userForm,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: UserRequestOptions,
}

export function useUser(initEntity: Partial<UserModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: user }: { entity: UserModel } = useLatestEntity(initEntity, 'users')
  const { id } = user

  const dispatch = useDispatch()

  useReduxAction(
    'users',
    'loadUser',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    loading,
    updating,
  } = useSelector(reduxState => reduxState.users)

  return {
    callbacks: {
      updateUser: (userParams: Partial<UserModel>, entityOptions?: UserRequestOptions) => (
        updateUser({
          user, userParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    user,
    loading,
    updating,
    urls: generateUrls(user),
  }
}

export default useUser
