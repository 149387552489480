import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faToggleLargeOn, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import usePriceList from '@hooks/usePriceList'
import { confirmAction } from '@functions/entity'

interface PriceListToolsProps {
  priceListPayload: ReturnType<typeof usePriceList>,
}

const PriceListTools = (props: PriceListToolsProps) => {
  const {
    priceListPayload: {
      callbacks: {
        launchArchivePriceListModal,
        launchDuplicatePriceListModal,
        updatePriceList: updateFn,
      },
      priceList,
      priceList: {
        isArchived,
      },
      updating,
    },
  } = props

  const unArchivePriceListPayload = {
    callbacks: {
      actionFn: updateFn,
    },
    options: {
      entityParams: { ...priceList, isArchived: false },
    },
    strings: {
      actionString: 'unarchive',
      entityName: 'Price List',
    },
  }

  const archivePriceListFn = isArchived
    ? () => confirmAction(unArchivePriceListPayload)
    : launchArchivePriceListModal

  return (
    <Tools>
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faCopy} />}
        marginBottom="medium"
        onClick={() => launchDuplicatePriceListModal()}
        size="medium"
      >
        Duplicate Price List
      </Button>

      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={isArchived ? faToggleLargeOn : faTrashAlt} />}
        loading={updating}
        marginBottom="medium"
        onClick={() => archivePriceListFn()}
        size="medium"
      >
        {isArchived ? 'Unarchive' : 'Archive'} Price List
      </Button>
    </Tools>
  )
}

export default PriceListTools
