import { useContext } from 'react'

import {
  Button,
  Columns,
  LoadingModule,
  SaveFormModule,
} from '@campaignhub/suit-theme'

import EntityActivity from '@components/EntityActivity'

import PageContext from '@contexts/pageContext'

import usePriceList, { usePriceListForm } from '@hooks/usePriceList'

import PriceListDetails from '../PriceListDetails'
import PriceListTools from '../../../../components/PriceListTools'
import PageLayout from '../../../../components/PageLayout'

interface PageContentProps {
  priceListId: number,
}

const PageContent = (props: PageContentProps) => {
  const { priceListId } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      updatePriceList,
    },
  } = pageContext

  const priceListPayload = usePriceList({ id: priceListId })
  const {
    callbacks: {
      updatePriceList: updateFn,
    },
    loading,
    priceList,
    updating,
  } = priceListPayload

  const priceListFormPayload = usePriceListForm(priceList)
  const { entityState, saveEnabled } = priceListFormPayload

  const updatePriceListPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'Price List Updated',
  }

  return (
    <PageLayout priceListPayload={priceListPayload} activeTabBarItemKey="details">
      <Columns.Main>
        <LoadingModule loading={loading} times={3} />

        {!loading && (
          <>
            <PriceListDetails priceListFormPayload={priceListFormPayload} />

            <EntityActivity subjectId={priceList.id} subjectType="PriceList" />

            <SaveFormModule>
              <Button
                buttonStyle="primaryCreate"
                disabled={!saveEnabled}
                loading={updating}
                marginLeft="auto"
                onClick={() => updatePriceList(updatePriceListPayload)}
                size="medium"
              >
                Save Details
              </Button>
            </SaveFormModule>
          </>
        )}
      </Columns.Main>

      <Columns.Sidebar>
        <PriceListTools priceListPayload={priceListPayload} />
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent
