import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { Box, Link } from '@campaignhub/suit-theme'

import useCampaign from '@hooks/useCampaign'

interface ActionsProps {
  campaignId: number,
}

const Actions = (props: ActionsProps) => {
  const { campaignId } = props

  const {
    callbacks: {
      editStartDate,
    },
  } = useCampaign({ id: campaignId })

  return (
    <Box justifyContent="center" alignItems="center" width="16px">
      <Link
        greyLink
        onClick={() => editStartDate()}
        textProps={{ fontSize: 'medium', height: 'fit-content' }}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Link>
    </Box>
  )
}

export default Actions
