import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { AutoOrderQueueModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/autoOrderQueue/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/autoOrderQueue/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/autoOrderQueue/FETCH_FAILURE'

export type AutoOrderQueueModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: AutoOrderQueueModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadAutoOrderQueues(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().autoOrderQueues.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)) {
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/auto-order-queues', options)
      .then(({ data }: { data: { data: AutoOrderQueueModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.AUTO_ORDER_QUEUE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadAutoOrderQueue(autoOrderQueueId: number, options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.autoOrderQueues.loadedIds]
    const loadedForKeys = [...state.autoOrderQueues.loadedForKeys]

    if (autoOrderQueueId && !loadedIds.includes(autoOrderQueueId)) {
      loadedIds.push(autoOrderQueueId)
    }

    if (entityKey && !loadedForKeys.includes(entityKey)) {
      loadedForKeys.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/auto-order-queues/${autoOrderQueueId}`, options)
      .then(({ data }: { data: AutoOrderQueueModel }) => {
        const normalizedJson = normalize(data, Schemas.AUTO_ORDER_QUEUE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: AutoOrderQueueModuleState = initialState,
  action: Action = { type: '' },
): AutoOrderQueueModuleState {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
