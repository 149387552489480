import { Box, Text } from '@campaignhub/suit-theme'

import { DemographicsModel } from '@models/adCampaign'

interface DemographicsProps {
  demographics: DemographicsModel,
}

const Demographics = (props: DemographicsProps) => {
  const {
    demographics: {
      ageRanges,
      genders,
    },
  } = props

  const ageRangeString = ageRanges.join(', ')
  const gendersString = genders.join(', ')

  return (
    <>
      <Text color="bodyFontLightColor" fontSize="xsmall">Demographics</Text>

      <Box alignItems="center" paddingY="medium">
        <Box justifyContent="space-between">
          <Text fontSize="small" marginRight="large">Age</Text>

          <Text color="bodyFontLightColor" fontSize="small" textAlign="right">{ageRangeString}</Text>
        </Box>
      </Box>

      <Box alignItems="center" paddingY="medium">
        <Box justifyContent="space-between">
          <Text fontSize="small" marginRight="large">Gender</Text>

          <Text color="bodyFontLightColor" fontSize="small" textAlign="right">{gendersString}</Text>
        </Box>
      </Box>
    </>
  )
}

export default Demographics
