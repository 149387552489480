import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { AdAccountModel, ExternalAdAccountModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/adAccount/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/adAccount/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/adAccount/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/adAccount/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/adAccount/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/adAccount/CREATE_FAILURE'

export type AdAccountModuleState = {
  creating: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
}

// Initial State
const initialState: AdAccountModuleState = {
  creating: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadAdAccounts(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().adAccounts.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api(`/ad-accounts`, options)
      .then(({ data }: { data: { data: AdAccountModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.AD_ACCOUNT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadAdAccount(adAccountId: number, options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.adAccounts.loadedIds]
    const loadedForKeys = [...state.priceLists.loadedForKeys]

    if (adAccountId && !loadedIds.includes(adAccountId)) {
      loadedIds.push(adAccountId)
    }

    if (entityKey && !loadedForKeys.includes(entityKey)) {
      loadedForKeys.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/ad-accounts/${adAccountId}`, options)
      .then(({ data }: { data: AdAccountModel }) => {
        const normalizedJson = normalize(data, Schemas.AD_ACCOUNT)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export type LinkAdAccountParams = {
  adAccountId: number,
  linkableId: number,
  linkableType: string,
}

export function linkAdAccount(params: LinkAdAccountParams, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify(params),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/ad-accounts/link', options, config)
      .then(({ data }: { data: AdAccountModel }) => {
        const normalizedJson = normalize(data, Schemas.AD_ACCOUNT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export type CreateAndLinkAdAccountsParams = {
  externalAdAccounts: ExternalAdAccountModel[],
  linkableId: number,
  linkableType: 'Client' | 'User',
}

export function createAndLinkAdAccounts(params: CreateAndLinkAdAccountsParams) {
  const config = {
    method: 'POST',
    data: JSON.stringify(params),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/ad-accounts/create-and-link-multiple', {}, config)
      .then(({ data }: { data: AdAccountModel }) => {
        const normalizedJson = normalize(data, Schemas.AD_ACCOUNT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }  
}

// Reducer
export default function reducer(
  state: AdAccountModuleState = initialState,
  action: Action = { type: '' },
): AdAccountModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
