import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import usePriceList, { usePriceListForm } from '@hooks/usePriceList'

const MODAL_KEY = 'CreatePriceListModal'

type ModalCallbacks = {
  closeModal: () => void,
  createPriceList: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreatePriceListModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createPriceList } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const priceList = digObject(modalPayload, 'priceList', {})

  const {
    callbacks: {
      createPriceList: createFn,
    },
    creating,
  } = usePriceList(priceList)

  const {
    entityState,
    entityState: {
      name,
    },
    errors,
    handlers,
    saveEnabled,
  } = usePriceListForm(priceList, { validateOn: 'change' })

  const createPriceListPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
    toastText: 'Price List Created',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Create" titleSecondLine="Price List" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* Name" errorMessage={errors.name}>
            <input name="name" type="text" value={name} {...handlers} />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => createPriceList(createPriceListPayload)}
          size="large"
        >
          {saveEnabled ? 'Create Price List' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreatePriceListModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
