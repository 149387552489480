export type ArtworkTemplateGroupModel = {
  brandId: number,
  cacheKey: number,
  description: string,
  id: number,
  name: string,
}

export type ArtworkTemplateGroupRequestOptions = {}

const state = {
  description: '',
  id: null,
  name: '',
  campaignTypeId: null,
}

export const requiredFields = [
  { key: 'name' },
]

export default state
