import type { useAssetsOptions } from '@hooks/useAssets'
import type { EntitiesState } from '@redux/entities'

const getAssetEntity = (filters: useAssetsOptions['filters'], entities: EntitiesState) => {
  const {
    assetableId,
    assetableType,
  } = filters || {}

  const assetableTypeToEntitiesMap = {
    Campaign: 'campaigns',
    Product: 'products',
  }

  const entityKey = assetableTypeToEntitiesMap[assetableType]

  if (!entityKey) return {}

  const assetableEntity = entities[entityKey as keyof EntitiesState][assetableId] || {}

  return assetableEntity
}

export default getAssetEntity
