import systemColours from '@functions/colours'

const getStatusBadgeProps = (status: string) => {
  const colours = {
    Cancelled: 'blueGrey',
    Completed: systemColours.teal,
    Deploying: 'blue',
    Expended: systemColours.lightBlue,
    Failed: 'red',
    Live: systemColours.darkPurple,
    Loading: 'blue',
    OnHold: 'tan',
    Paused: 'tan',
    Pending: 'orange',
    Ready: 'purple',
    Review: 'orange',
    Scheduled: 'mysteryGrey',
    Success: systemColours.green,
    TopUp: systemColours.lightBlue,
    Validating: 'lightestGrey',
  }

  const text = {
    Loading: 'Deploying', // for Ad Deployer
  }

  const textProps = {
    color: status === 'Validating' ? 'mysteryGrey' : null,
  }

  return {
    color: colours[status],
    text: text[status] || status || 'No Status',
    textProps,
  }
}

export default getStatusBadgeProps
