import { Columns } from '@campaignhub/suit-theme'

import useProduct from '@hooks/useProduct'

import ProductOptionProducts from '@components/ProductOptionProducts'

import PageLayout from '../../../../components/PageLayout'
import ProductTools from '../../../../components/ProductTools'

import AdTemplateProducts from '../AdTemplateProducts'
import PackageProducts from '../PackageProducts'
import PriceListItems from '../PriceListItems'

interface PageContentProps {
  productId: number,
}

const PageContent = (props: PageContentProps) => {
  const { productId } = props

  const productPayload = useProduct({ id: productId })

  return (
    <PageLayout productPayload={productPayload} activeTabBarItemKey="relations">
      <Columns.Main>
        <AdTemplateProducts productId={productId} />

        <PackageProducts productId={productId} />

        <PriceListItems productId={productId} />

        <ProductOptionProducts productId={productId} />
      </Columns.Main>

      <Columns.Sidebar>
        <ProductTools productPayload={productPayload} />
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent
