import {
  DashboardModule, Form,
} from '@campaignhub/suit-theme'

import { useBrandForm } from '@hooks/useBrand'

interface BrandDetailsProps {
  brandFormPayload: ReturnType<typeof useBrandForm>,
}

const BrandDetails = (props: BrandDetailsProps) => {
  const {
    brandFormPayload: {
      entityState: {
        addressLine1,
        addressLine2,
        businessName,
        name,
        postCode,
        realbaseId,
        region,
        state,
      },
      handlers,
    },
  } = props

  return (
    <DashboardModule title="Details">
      <Form padding="large">
        <Form.Row>
          <Form.Field label="* Name">
            <input
              disabled
              name="name"
              value={name}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="* Business Name" marginTop={['large', 0]}>
            <input
              disabled
              name="businessName"
              value={businessName}
              {...handlers}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="* Address Line 1">
            <input
              disabled
              name="addressLine1"
              value={addressLine1}
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Address Line 2" marginTop={['large', 0]}>
            <input
              disabled
              name="addressLine2"
              value={addressLine2}
              {...handlers}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="* Region">
            <input
              disabled
              name="region"
              value={region}
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="* State" marginTop={['large', 0]}>
            <input
              disabled
              name="state"
              value={state}
              {...handlers}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="* Post Code">
            <input
              disabled
              name="postCode"
              value={postCode}
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Realbase ID" marginTop={['large', 0]}>
            <input
              disabled
              name="realbaseId"
              value={realbaseId}
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default BrandDetails
