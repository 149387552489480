import { Route, Routes } from 'react-router-dom'

import Details from './screens/Details'
import Overview from './screens/Overview'

type CampaignRoutesProps = {
  campaignId: number,
}

const CampaignRoutes = (props: CampaignRoutesProps) => {
  const { campaignId } = props

  return (
    <Routes>
      <Route path="/details" element={<Details campaignId={campaignId} />} />
      <Route path="/overview" element={<Overview campaignId={campaignId} />} />
    </Routes>
  )
}

export default CampaignRoutes
