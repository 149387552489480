import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useProductOptionValue, { useProductOptionValueForm } from '@hooks/useProductOptionValue'

const MODAL_KEY = 'CreateProductOptionValueModal'

type ModalCallbacks = {
  closeModal: () => void,
  createProductOptionValue: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateProductOptionValueModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const {
    closeModal,
    createProductOptionValue,
  } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const productOption = digObject(modalPayload, 'productOption', {})

  const {
    callbacks: {
      createProductOptionValue: createFn,
    },
    creating,
  } = useProductOptionValue()

  const {
    entityState,
    entityState: {
      costPrice,
      name,
      value,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = useProductOptionValueForm({ productOptionId: productOption.id }, { validateOn: 'change' })

  // To be used with handleCallbackAction
  const createProductOptionValuePayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Product Option Value Created',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title="Create"
        titleSecondLine="Product Option Value"
      />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* Name" errorMessage={errors.name}>
            <input name="name" type="text" value={name} {...handlers} />
          </Form.Field>

          <Form.Field label="* Value" errorMessage={errors.value} marginTop="large">
            <input name="value" type="text" value={value} {...handlers} />
          </Form.Field>

          <Form.Field label="Cost Price ($)" errorMessage={errors.costPrice} marginTop="large">
            <input
              name="costPrice"
              type="number"
              value={costPrice}
              onChange={e => setEntityState({ costPrice: Number(e.target.value) || null })}
            />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => createProductOptionValue(createProductOptionValuePayload)}
          size="large"
        >
          {saveEnabled ? 'Create Product Option Value' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateProductOptionValueModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
