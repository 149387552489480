import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faToggleLargeOn, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import { confirmAction, confirmDelete } from '@functions/entity'

import useProduct from '@hooks/useProduct'

interface ProductToolsProps {
  productPayload: ReturnType<typeof useProduct>,
}

const ProductTools = (props: ProductToolsProps) => {
  const {
    productPayload: {
      callbacks: {
        updateProduct: updateFn,
        launchDuplicateProductModal,
      },
      deleting,
      product,
      product: {
        isArchived,
      },
    },
  } = props

  const confirmArchivePayload = {
    callbacks: {
      actionFn: updateFn,
      deleteFn: updateFn,
    },
    options: {
      shouldRedirect: true,
      entityParams: { ...product, isArchived: !isArchived },
    },
    strings: {
      actionString: 'unarchive',
      deleteString: 'archive',
      entityName: 'Product',
    },
  }

  const archiveProductFn = isArchived
    ? () => confirmAction(confirmArchivePayload)
    : () => confirmDelete(confirmArchivePayload)

  return (
    <Tools>
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faCopy} />}
        marginBottom="medium"
        onClick={() => launchDuplicateProductModal()}
        size="medium"
      >
        Duplicate Product
      </Button>

      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={isArchived ? faToggleLargeOn : faTrashAlt} />}
        loading={deleting}
        marginBottom="medium"
        onClick={() => archiveProductFn()}
        size="medium"
      >
        {isArchived ? 'Unarchive' : 'Archive'} Product
      </Button>
    </Tools>
  )
}

export default ProductTools
