import { useContext } from 'react'

import { useLatestEntity } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as captionActions from '@redux/modules/caption'
import type { CreateOrUpdateParams } from '@redux/modules/caption'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { CaptionModel, CaptionRequestOptions } from '@models/types'

type CreateOrUpdateCaptionsParams = {
  captionsParams: CreateOrUpdateParams,
  dispatch: AppDispatch,
  requestOptions?: CaptionRequestOptions,
}

const createOrUpdateCaptions = (params: CreateOrUpdateCaptionsParams) => {
  const { dispatch, captionsParams, requestOptions } = params
  const { createOrUpdateCaptions: createOrUpdateFn } = captionActions

  return dispatch(createOrUpdateFn(captionsParams, requestOptions))
}

function useCaption(initEntity: Partial<CaptionModel> = {}) {
  const { entity: caption }: { entity: CaptionModel } = useLatestEntity(initEntity, 'captions')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { creating } = useSelector(reduxState => reduxState.captions)

  return {
    callbacks: {
      createOrUpdateCaptions: (captionsParams: CreateOrUpdateParams, entityOptions?: CaptionRequestOptions) => (
        createOrUpdateCaptions({ captionsParams, dispatch, requestOptions: entityOptions })
      ),
      manageCaptions: () => launchModal({
        callbacks,
        modalKey: 'ManageCaptionsModal',
        payload: { captionsParams: caption },
      }),
    },
    caption,
    creating,
  }
}

export default useCaption
