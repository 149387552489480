import { useMemo } from 'react'

import type { UseFiltersPayload } from '@campaignhub/react-hooks'
import { useNumericParams, useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import type { AssetFilters } from '@hooks/useAssets'

import CreateOrEditExternalPlatformEntityModal from '@modals/CreateOrEditExternalPlatformEntityModal'
import CreateOrEditImageModal from '@modals/CreateOrEditImageModal'
import DuplicateProductModal from '@modals/DuplicateProductModal'

import type { ExternalPlatformEntityModel, ProductModel } from '@models/types'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    showCreateOrEditExternalPlatformEntityModal: (payload: { externalPlatformEntity: ExternalPlatformEntityModel }) => void,
    showCreateOrEditImageModal: (payload: { filters: AssetFilters }) => void,
    showDuplicateProductModal: (payload: { product: ProductModel }) => void,
    updateProduct: (payload: HandleCallbackActionParams) => Promise<object>,
  },
}

const defaultState = {
  showCreateOrEditExternalPlatformEntityModal: false,
  showCreateOrEditImageModal: false,
  showDuplicateProductModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateOrEditExternalPlatformEntityModal: {
      closeModal: () => setState({ showCreateOrEditExternalPlatformEntityModal: false }),
      createExternalPlatformEntity: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteExternalPlatformEntity: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateExternalPlatformEntity: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    CreateOrEditImageModal: {
      closeModal: () => setState({ showCreateOrEditImageModal: false }),
      deleteAsset: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateAsset: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    DuplicateProductModal: {
      closeModal: () => setState({ showDuplicateProductModal: false }),
      duplicateProduct: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Details = () => {
  const { productId } = useNumericParams()

  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditExternalPlatformEntityModal,
    showCreateOrEditImageModal,
    showDuplicateProductModal,
  } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      deleteExternalPlatformEntity: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteProduct: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      showCreateOrEditExternalPlatformEntityModal: (payload: { externalPlatformEntity: ExternalPlatformEntityModel }) => {
        setModalData('CreateOrEditExternalPlatformEntityModal', payload)
        setState({ showCreateOrEditExternalPlatformEntityModal: true })
      },
      showCreateOrEditImageModal: (payload: { filters: AssetFilters }) => {
        setModalData('CreateOrEditImageModal', payload)
        setState({ showCreateOrEditImageModal: true })
      },
      showDuplicateProductModal: (payload: { product: ProductModel }) => {
        setModalData('DuplicateProductModal', payload)
        setState({ showDuplicateProductModal: true })
      },
      updateProduct: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent productId={productId} />

        <CreateOrEditExternalPlatformEntityModal
          callbacks={callbacks('CreateOrEditExternalPlatformEntityModal', setState)}
          showModal={showCreateOrEditExternalPlatformEntityModal}
        />

        <CreateOrEditImageModal
          callbacks={callbacks('CreateOrEditImageModal', setState)}
          showModal={showCreateOrEditImageModal}
        />

        <DuplicateProductModal
          callbacks={callbacks('DuplicateProductModal', setState)}
          showModal={showDuplicateProductModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details
