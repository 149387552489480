import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { PriceListItemOptionRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['priceListItemOptions']

type PriceListItemOptionFilters = {
  priceListItemId?: number,
}

type UsePriceListItemOptionsOptions = {
  filters?: PriceListItemOptionFilters,
  performHttpRequests?: boolean,
  requestOptions?: PriceListItemOptionRequestOptions,
}

function usePriceListItemOptions(options: UsePriceListItemOptionsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    priceListItemId: filterPriceListItemId,
  } = filters

  const {
    updatedEntities: { priceListItemOptions: priceListItemOptionsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { priceListItemOptions } = useSelector(reduxState => reduxState.entities)

  const filteredPriceListItemOptions = useMemo(() => {
    const filtered = Object.values(priceListItemOptions).filter((priceListItemOption) => {
      const { priceListItemId } = priceListItemOption

      const matchPriceListItemId = matchFilterNumber(priceListItemId, filterPriceListItemId)

      return matchPriceListItemId
    })

    return sortArrayBy(filtered, 'asc', 'nameOverride')
  }, [priceListItemOptionsUpdatedAt, JSON.stringify(filters)])

  const filteredPriceListItemOptionsCount = filteredPriceListItemOptions.length
  const hasFilteredPriceListItemOptions = !!filteredPriceListItemOptionsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredPriceListItemOptionsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingPriceListItemOptions } = useReduxAction(
    'priceListItemOptions',
    'loadPriceListItemOptions',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredPriceListItemOptions,
    filteredPriceListItemOptionsCount,
    hasFilteredPriceListItemOptions,
    loading: loadingPriceListItemOptions,
  }
}

export default usePriceListItemOptions
