import { useContext } from 'react'

import { digObject, formatDate } from '@campaignhub/javascript-utils'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useCampaign, { useCampaignForm } from '@hooks/useCampaign'

const MODAL_KEY = 'EditStartDateModal'

interface EditStartDateModalProps {
  callbacks: {
    closeModal: () => void,
    editStartDate: (payload: HandleCallbackActionParams) => Promise<object>,
  },
  showModal: boolean,
}

const EditStartDateModal = (props: EditStartDateModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, editStartDate } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { campaign } = modalPayload

  const {
    callbacks: {
      patchCampaign: patchFn,
    },
  } = useCampaign(campaign)

  const {
    entityState: {
      requiredAt,
    },
    errors,
    handlers,
    saveEnabled,
  } = useCampaignForm(campaign, { validateOn: 'change' })

  const patchCampaignPayload = {
    callbacks: {
      action: patchFn,
      afterAction: closeModal,
    },
    entityParams: { requiredAt },
    toastText: 'Start Date Updated',
  }

  const formattedRequiredAt = requiredAt
    ? formatDate(new Date(requiredAt).toISOString(), 'ISO8601', 'yyyy-MM-dd')
    : ''

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Edit" titleSecondLine="Start Date" />

      <SidebarModal.Content>
        <Form>
          <Form.Field errorMessage={errors.requiredAt} label="* Start Date">
            <input
              name="requiredAt"
              type="date"
              value={formattedRequiredAt}
              {...handlers} />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          onClick={() => editStartDate(patchCampaignPayload)}
          size="large"
        >
          {saveEnabled ? 'Save Start Date' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal >
  )
}

const LazyLoadedModal = (props: EditStartDateModalProps) => (
  <SidebarModal.RenderController {...props}>
    <EditStartDateModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
