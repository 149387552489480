import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

import Actions from './Actions'

interface DetailsItemProps {
  boxProps?: object,
  campaignId?: number,
  icon: IconDefinition,
  text: string,
  title: string,
}

const DetailsItem = (props: DetailsItemProps) => {
  const {
    boxProps, campaignId, icon, text, title
  } = props

  return (
    <Box
      alignItems="center"
      padding="large"
      width={['100%', 1 / 3]}
      {...boxProps}
    >
      <Text color="bodyFontLightColor" paddingRight="large">
        <FontAwesomeIcon icon={icon} />
      </Text>

      <Box flexDirection="column">
        <Text color="bodyFontLightColor" fontSize="xsmall">{title}</Text>

        <Text fontSize="small" marginTop="medium">{text}</Text>
      </Box>

      {title === 'Schedule' && <Actions campaignId={campaignId!} />}
    </Box>
  )
}

export default DetailsItem
