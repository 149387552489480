import { useContext } from 'react'

import { faHome } from '@fortawesome/pro-light-svg-icons'

import { Box, Columns } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import type { CampaignFilters } from '@hooks/useCampaigns'
import useCampaignStatuses from '@hooks/useCampaignStatuses'
import useExternalPlatformEntities from '@hooks/useExternalPlatformEntities'
import useExternalPlatforms from '@hooks/useExternalPlatforms'

import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import { CampaignStatusModel, ExternalPlatformModel } from '@models/types'

import Orders from '../Orders'

type BuildPageFilterFieldParams = {
  campaignStatuses: CampaignStatusModel[],
  externalPlatforms: ExternalPlatformModel[],
}

const buildPageFilterFields = (params: BuildPageFilterFieldParams) => {
  const { campaignStatuses, externalPlatforms } = params

  const style = { marginBottom: 16 }
  const boxProps = { marginBottom: 'large' }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by campaign name or AIM#',
        style,
      },
      key: 'string',
      label: 'Search',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'status',
      label: 'Status',
      values: campaignStatuses.map(campaignStatus => (
        { key: campaignStatus.name, label: campaignStatus.displayName }
      )),
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'clients',
        entityTitleKey: 'name',
      },
      key: 'clientId',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'External Source Id',
        style,
      },
      key: 'externalProjectId',
      label: 'Source Id',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'External Order Id',
        style,
      },
      key: 'externalOrderId',
      label: 'External Order Id',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        style,
      },
      key: 'startDate',
      label: 'Start Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        style,
      },
      key: 'endDate',
      label: 'End Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'Latest First',
        inputComponent: 'select',
        style,
      },
      key: 'sortBy',
      label: 'Sort By',
      values: [
        { key: 'asc', label: 'Oldest First' },
      ],
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'externalPlatformId',
      label: 'External Source',
      values: externalPlatforms.map(externalplatform => ({ 
        key: externalplatform.id,
        label: externalplatform.name,
      })),
    }
  ]
}

interface PageContentProps {
  pageFilters: CampaignFilters,
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  const { externalPlatforms } = useExternalPlatforms({ performHttpRequests: true })

  const { externalPlatformId } = pageFilters

  const internalEntityType = 'Project'

  useExternalPlatformEntities({ 
    filters: {
      externalPlatformId,
      internalEntityType,
    },
    performHttpRequests: true,
    requestOptions: {
      include: 'Project',
    },
  })

  const { campaignStatuses } = useCampaignStatuses({ performHttpRequests: true })

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  return (
    <>
      <PageHeader
        activeTabBarItemKey="orders"
        tabBarItems={[
          {
            href: '#/orders',
            icon: faHome,
            key: 'orders',
            title: 'Orders',
          },
        ]}
        title="Orders"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <Orders pageFilters={pageFilters} />
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields({ campaignStatuses, externalPlatforms })}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
