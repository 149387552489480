import { useSetState } from '@campaignhub/react-hooks'

import { digObject, toggleArray } from '@campaignhub/javascript-utils'

type DefaultState = {
  selectedEntityIds: number[],
}

type GetSelectableListItemPropsParams = {
  entityId: number,
  setState: (state: Partial<DefaultState>) => void,
  state: DefaultState,
}

const getSelectableListItemProps = (params: GetSelectableListItemPropsParams) => {
  const { entityId, setState, state } = params
  const { selectedEntityIds } = state

  const toggleId = () => setState({ selectedEntityIds: toggleArray(selectedEntityIds, entityId) })

  return {
    checkboxOnClick: toggleId,
    isChecked: selectedEntityIds.includes(entityId),
  }
}

export type ListItemProps = ReturnType<typeof getSelectableListItemProps>

const defaultState: DefaultState = {
  selectedEntityIds: [],
}

function useSelectEntities(options = {}) {
  const [state, setState] = useSetState(defaultState)
  const { selectedEntityIds } = state

  const minimumSelected = digObject(options, 'minimumSelected', 1)

  return {
    callbacks: {
      deselectAll: () => setState({ selectedEntityIds: [] }),
      getSelectableListItemProps: (entityId: number) => getSelectableListItemProps({ entityId, setState, state }),
      isChecked: (entityId: number) => selectedEntityIds.includes(entityId),
      setSelectedIds: (idsArray: number[]) => setState({ selectedEntityIds: idsArray }),
      toggleId: (entityId: number) => setState({ selectedEntityIds: toggleArray(selectedEntityIds, entityId) }),
    },
    hasMinimumSelected: selectedEntityIds.length >= minimumSelected,
    hasSelectedEntities: !!selectedEntityIds.length,
    selectedEntitiesCount: selectedEntityIds.length,
    selectedEntityIds,
  }
}

export default useSelectEntities
