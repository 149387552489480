import { Image } from '@campaignhub/suit-theme'

import useAsset from '@hooks/useAsset'

import type { AssetModel } from '@models/asset'

type MediaImageProps = {
  image: AssetModel,
}

const MediaImage = (props: MediaImageProps) => {
  const { image } = props

  const {
    asset: {
      filePath,
    },
    callbacks: {
      createOrEditImage,
    },
  } = useAsset(image)

  return (
    <Image
      borderRadius={5}
      forwardProps={{
        onClick: () => createOrEditImage(),
        style: { cursor: 'pointer' },
      }}
      height={80}
      url={filePath}
    />
  )
}

export default MediaImage
