import { DashboardModule } from '@campaignhub/suit-theme'

import AddressListItem from './AddressListItem'
import PropertyMap from './PropertyMap'

// TODO: replace mock data with data store items
const property = {
  lat: -33.81711,
  lng: 151.23123,
  postCode: 2100,
  state: 'NSW',
  streetName: 'Shellbank Parade',
  streetNumber: 12,
  suburb: 'Cremorne',
}

export type Property = {
  lat: number,
  lng: number,
  postCode: number,
  state: string,
  streetName: string,
  streetNumber: number,
  suburb: string,
}

interface PropertyAddressProps {
  property: Property,
}

const PropertyAddress = (props: PropertyAddressProps) =>
// const { property } = props

  (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      title="Property Address"
    >
      <AddressListItem property={property} />

      <PropertyMap property={property} />
    </DashboardModule>
  )

export default PropertyAddress
