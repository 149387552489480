import {
  faCalendar, faCog, faImage, faImages,
} from '@fortawesome/pro-light-svg-icons'

import { formatDate } from '@campaignhub/javascript-utils'

import {
  Box, SidebarModal, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import getStatusBadgeProps from '@functions/statusBadge'

import { useRelations as useAdCampaignRelations } from '@hooks/useAdCampaign'
import { useRelations as useCampaignRelations } from '@hooks/useCampaign'

import { AdCampaignModel } from '@models/types'

import DetailsListItem from '@components/DetailsListItem'

interface AdCampaignDetailsProps {
  adCampaign: AdCampaignModel,
}

const AdCampaignDetails = (props: AdCampaignDetailsProps) => {
  const { adCampaign } = props

  const {
    adCampaignName,
    adChannelTypeId,
    createdBy,
    objective,
    startsAt,
    status,
  } = adCampaign

  const { campaign } = useAdCampaignRelations(adCampaign)

  const {
    adTemplate: {
      name: adTemplateName,
    },
  } = useCampaignRelations(campaign)

  const statusBadgeProps = getStatusBadgeProps(status)

  return (
    <>
      <Box flexDirection="column">
        <Text fontSize="small">Status</Text>

        <StatusBadge
          boxProps={{ borderWidth: 2, marginTop: 'medium' }}
          ghost
          {...statusBadgeProps}
        />
      </Box>

      <SidebarModal.ExpandableSection
        defaultOpen
        label="Campaign Position"
        title="Ad Loader"
        style={{ marginTop: 16 }}
      >
        <SidebarModal.NoticeBox>
          <SidebarModal.NoticeBox.Title>Position</SidebarModal.NoticeBox.Title>

          <SidebarModal.NoticeBox.Paragraph boxProps={{ marginTop: 'none', marginBottom: 'medium' }}>
            Ad Loader
          </SidebarModal.NoticeBox.Paragraph>

          <SidebarModal.NoticeBox.Title>Ad Channel</SidebarModal.NoticeBox.Title>

          <SidebarModal.NoticeBox.Paragraph
            boxProps={{ marginTop: 'none', marginBottom: 'medium' }}
          >
            {adChannelTypeId}
          </SidebarModal.NoticeBox.Paragraph>

          {!!objective && (
            <>
              <SidebarModal.NoticeBox.Title>
                Objective
              </SidebarModal.NoticeBox.Title>

              <SidebarModal.NoticeBox.Paragraph boxProps={{ marginTop: 'none', marginBottom: 'medium' }}>
                {objective}
              </SidebarModal.NoticeBox.Paragraph>
            </>
          )}

          <SidebarModal.NoticeBox.Title>Campaign Title</SidebarModal.NoticeBox.Title>

          <SidebarModal.NoticeBox.Paragraph boxProps={{ marginTop: 'none', marginBottom: 'medium' }}>
            {adCampaignName}
          </SidebarModal.NoticeBox.Paragraph>

          <SidebarModal.NoticeBox.Title>Source</SidebarModal.NoticeBox.Title>

          <SidebarModal.NoticeBox.Paragraph boxProps={{ marginTop: 'none' }}>
            {adTemplateName || 'Manually Deployed'}
          </SidebarModal.NoticeBox.Paragraph>
        </SidebarModal.NoticeBox>

        <DetailsListItem
          icon={faCalendar}
          label="Start Date"
          text={formatDate(startsAt, 'ISO8601', 'dd LLL yyyy t')}
        />

        <DetailsListItem
          icon={faCog}
          label="Created by"
          text={createdBy}
        />
      </SidebarModal.ExpandableSection>
    </>
  )
}

export default AdCampaignDetails
