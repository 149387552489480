import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import generateUrls from '@hooks/useClient'

import useClientPriceList from '@hooks/useClientPriceList'

import { ClientPriceListModel } from '@models/types'

interface ClientPriceListListItemProps {
  clientPriceList: ClientPriceListModel,
}

const ClientPriceListListItem = (props: ClientPriceListListItemProps) => {
  const { clientPriceList } = props

  const clientPriceListPayload = useClientPriceList(clientPriceList)

  const {
    client: {
      id,
      name,
    },
  } = clientPriceListPayload

  const {
    urls: {
      editClientUrl,
    },
  } = generateUrls({ id })

  return (
    <DashboardModuleListItem
      title={name}
    >
      <DashboardModuleListItem.TertiaryText>
        {`#${id}`}
      </DashboardModuleListItem.TertiaryText>

      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon
          boxProps={{ marginLeft: 'medium' }}
          href={`${editClientUrl}?redirect=${generateRedirectUrl()}`}
          type="link"
        />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default ClientPriceListListItem
