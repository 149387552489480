import { useContext } from 'react'

import useClientPriceList from '@hooks/useClientPriceList'
import usePriceList from '@hooks/usePriceList'

import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { confirmDelete } from '@functions/entity'

import { ClientPriceListModel } from '@models/types'

interface ClientPriceListListItemProps {
  clientPriceList: ClientPriceListModel,
}

const ClientPriceListListItem = (props: ClientPriceListListItemProps) => {
  const { clientPriceList } = props

  const clientPriceItemPayload = useClientPriceList(clientPriceList)

  const {
    callbacks: {
      deleteClientPriceList: deleteFn,
    },
    priceList: {
      name,
    },
  } = clientPriceItemPayload

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext
  const { deleteClientPriceList } = callbacks || {}

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deleteClientPriceList,
      deleteFn,
    },
    strings: {
      entityName: 'Client Price List',
    },
  }

  const {
    urls: { editPriceListUrl },
  } = usePriceList(clientPriceItemPayload.priceList)

  return (
    <DashboardModuleListItem
      title={name}
    >
      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon
          boxProps={{ marginLeft: 'medium' }}
          onClick={() => confirmDelete(confirmDeletePayload)}
          type="delete"
        />

        <DashboardModuleListItem.Icon
          boxProps={{ marginLeft: 'medium' }}
          href={`${editPriceListUrl}?redirect=${generateRedirectUrl()}`}
          type="link"
        />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default ClientPriceListListItem
