import { useContext } from 'react'

import {
  Button, EntityMultiSelect, Form, ModalContext, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useBulkCreateProductOptionValuesSubsetsModal from './hooks/useBulkCreateProductOptionValuesSubsets'

const MODAL_KEY = 'BulkCreateProductOptionValuesSubsetsModal'

type ModalCallbacks = {
  bulkCreateProductOptionValuesSubsets: (payload: HandleCallbackActionParams) => void,
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const BulkCreateProductOptionValuesSubsetsModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { bulkCreateProductOptionValuesSubsets, closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})

  const {
    callbacks: {
      bulkCreateProductOptionValuesSubsets: bulkCreateFn,
      setSelectedProductOptionValueIds,
      setState,
    },
    creating,
    entityParams,
    filteredProductOptionValueIds,
    name,
    productOptionValues,
    productOptionValuesPayload: {
      callbacks: {
        loadMore,
      },
      canLoadMore,
      filteredProductOptionValuesCount,
      loading,
    },
    saveEnabled,
    selectedProductOptionValueIds,
  } = useBulkCreateProductOptionValuesSubsetsModal(modalPayload)

  const bulkCreateProductOptionValuesSubsetsPayload = {
    callbacks: {
      action: bulkCreateFn,
      afterAction: closeModal,
    },
    entityParams,
    toastText: 'Values Updated',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Add" titleSecondLine="Values" />

      <SidebarModal.Content>
        <Form.Field label="Search Values">
          <input onChange={e => setState({ name: e.target.value })} value={name} />
        </Form.Field>

        <SectionDivider>Multi Select Values</SectionDivider>

        <EntityMultiSelect
          callbacks={{
            loadMore,
            setSelectedIds: setSelectedProductOptionValueIds,
          }}
          canLoadMore={canLoadMore}
          entities={productOptionValues}
          entityCount={filteredProductOptionValuesCount}
          entityTitleKey="name"
          filteredEntityIds={filteredProductOptionValueIds}
          loading={loading}
          selectedIds={selectedProductOptionValueIds}
        />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => bulkCreateProductOptionValuesSubsets(bulkCreateProductOptionValuesSubsetsPayload)}
          size="large"
        >
          {saveEnabled ? 'Save Values' : 'Select Values'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <BulkCreateProductOptionValuesSubsetsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
