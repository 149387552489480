import React, { useContext } from 'react'
import type { DraggableProps, DraggableProvided } from 'react-beautiful-dnd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowsUpDown, faCog, faCopy, faSave, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, IconWithMenu, Form, Link, ListItem,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { confirmDelete } from '@functions/entity'

import useProductOptionValue, { useProductOptionValueForm } from '@hooks/useProductOptionValue'

interface ProductOptionValueListItemProps {
  dragHandleProps: DraggableProvided['dragHandleProps'],
  dragSnapshot: {
    isDragging: boolean,
  },
  draggableProps: DraggableProps,
  productOptionValueId: number,
}

const ProductOptionValueListItem = React.forwardRef((props: ProductOptionValueListItemProps, ref) => {
  const {
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    productOptionValueId,
  } = props

  const { productOptionValue } = useProductOptionValue({ id: productOptionValueId })

  const { isDragging } = dragSnapshot || {}

  const {
    entityState,
    entityState: {
      costPrice,
      name,
      value,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = useProductOptionValueForm(productOptionValue, { validateOn: 'change' })

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      deleteProductOptionValue,
      duplicateProductOptionValue,
      updateProductOptionValue,
    },
  } = pageContext

  const {
    callbacks: {
      deleteProductOptionValue: deleteFn,
      duplicateProductOptionValue: duplicateFn,
      updateProductOptionValue: updateFn,
    },
  } = useProductOptionValue(productOptionValue)

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deleteProductOptionValue,
      deleteFn,
    },
    strings: {
      entityName: 'Product Option Value',
    },
  }

  const updateProductOptionValuePayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'Product Option Value Updated',
  }

  const duplicateProductOptionValuePayload = {
    callbacks: {
      action: duplicateFn,
    },
    entityParams: entityState,
    toastText: 'Product Option Value Duplicated',
  }

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        paddingY: 'small',
      }}
      disableHover
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      ref={ref}
    >
      <Form.Row style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faArrowsUpDown} />

        <Form.Field style={{ flex: 3 }} errorMessage={errors.name}>
          <input
            name="name"
            type="text"
            value={name}
            {...handlers}
          />
        </Form.Field>

        <Form.Field style={{ flex: 1 }} errorMessage={errors.value}>
          <input
            name="value"
            type="text"
            value={value}
            {...handlers}
          />
        </Form.Field>

        <Form.Field style={{ flex: 1 }} errorMessage={errors.costPrice}>
          <input
            name="costPrice"
            type="number"
            value={costPrice}
            onChange={e => setEntityState({ costPrice: Number(e.target.value) || null })}
          />
        </Form.Field>

        <Button
          style={{
            width: '37px', height: '37px', border: '1px solid lightgray', borderRadius: '4px',
          }}
          buttonStyle="secondaryCreate"
          disabled={!saveEnabled}
          onClick={() => updateProductOptionValue(updateProductOptionValuePayload)}
        >
          <FontAwesomeIcon icon={faSave} />
        </Button>

        <Box
          justifyContent="center"
          alignItems="center"
          style={{
            width: '37px', height: '37px', border: '1px solid lightgray', borderRadius: '4px',
          }}
        >
          <IconWithMenu
            icon={(
              <Link greyLink>
                <FontAwesomeIcon icon={faCog} />
              </Link>
            )}
          >
            <IconWithMenu.Menu closeOnClick>
              <Link key="delete" onClick={() => confirmDelete(confirmDeletePayload)}>
                <FontAwesomeIcon icon={faTrashAlt} />
                Delete Value
              </Link>

              <Link key="duplicate" onClick={() => duplicateProductOptionValue(duplicateProductOptionValuePayload)}>
                <FontAwesomeIcon icon={faCopy} />
                Duplicate Value
              </Link>
            </IconWithMenu.Menu>
          </IconWithMenu>
        </Box>
      </Form.Row>
    </ListItem>
  )
})

export default ProductOptionValueListItem
