import { useSetState } from '@campaignhub/react-hooks'

import useAdContent from '@hooks/useAdContent'
import useCaption from '@hooks/useCaption'

const captionMaxLengthsByType = {
  descriptions: 25,
  displayUrls: 240,
  headlines: 90,
  texts: 125,
}

const validateCaptionLength = (captionText: string, captionType: string) => {
  const maxLength = captionMaxLengthsByType[captionType]

  if (!maxLength) return { isValid: true, error: null }

  if (captionText.length > maxLength) {
    return { isValid: false, error: `Must be less than ${maxLength} chars` }
  }

  return { isValid: true, error: null }
}

const defaultState = {
  captionText: '',
  captionType: '',
}

type UseManageCaptionsModalParams = {
  campaignId: number,
}

const useManageCaptionsModal = (params: UseManageCaptionsModalParams) => {
  const { campaignId } = params

  const [state, setState] = useSetState(defaultState)

  const {
    callbacks: {
      setCaptionsFormState,
    },
    captionsFormState,
  } = useAdContent(campaignId)

  const {
    callbacks: {
      createOrUpdateCaptions,
    },
    creating,
  } = useCaption({ campaignId })

  return {
    callbacks: {
      createOrUpdateCaptions,
      setCaptionsFormState,
      setState,
      validateCaptionLength,
    },
    captionsFormState,
    creating,
    state,
  }
}

export default useManageCaptionsModal