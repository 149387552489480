import type { AdQueueModel } from '@models/types'

export const getAdQueueStartDate = (adQueue: AdQueueModel): string | null => {
  const { startsAt } = adQueue

  if (startsAt) return startsAt

  const { adPayload, adTypeId } = adQueue
  const parsedAdPayload = JSON.parse(adPayload)

  
  if (adTypeId === 'Facebook') {
    return parsedAdPayload.adSets.map(adSet => adSet.startsAt).sort()[0]
  }

  if (adTypeId.includes('Google')) {
    return parsedAdPayload.ScheduleStart ?? parsedAdPayload.scheduleStart
  }

  return null
}