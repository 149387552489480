import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { AdQueueStatusModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, Errors, FetchRequestPayload,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/adQueueStatus/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/adQueueStatus/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/adQueueStatus/FETCH_FAILURE'

export type AdQueueStatusModuleState = {
  errors: string[],
  loaded: boolean,
  loading: boolean,
}

// Initial State
const initialState: AdQueueStatusModuleState = {
  errors: [],
  loaded: false,
  loading: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadAdQueueStatuses() {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api('/references/ad-queue-statuses')
      .then(({ data }: { data: AdQueueStatusModel[] }) => {
        const normalizedJson = normalize(data, Schemas.AD_QUEUE_STATUS_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: AdQueueStatusModuleState = initialState,
  action: Action = { type: '' },
): AdQueueStatusModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
