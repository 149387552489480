import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ActivityLogRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['activityLogs']

export type ActivityLogFilters = {
  subjectId?: number,
  subjectType?: string,
}

type UseActivityLogsOptions = {
  filters?: ActivityLogFilters,
  performHttpRequests?: boolean,
  requestOptions?: ActivityLogRequestOptions,
}

function useActivityLogs(options: UseActivityLogsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    subjectId: filterSubjectId,
    subjectType: filterSubjectType,
  } = filters

  const {
    updatedEntities: { activityLogs: activityLogsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { activityLogs } = useSelector(reduxState => reduxState.entities)

  const filteredActivityLogs = useMemo(() => {
    const filtered = Object.values(activityLogs).filter((activityLog) => {
      const { subjectId, subjectType } = activityLog

      const matchSubjectId = matchFilterNumber(subjectId, filterSubjectId)
      const matchSubjectType = matchFilterString(subjectType, filterSubjectType)

      return matchSubjectId && matchSubjectType
    })

    return sortArrayBy(filtered, 'desc', 'id')
  }, [activityLogsUpdatedAt, JSON.stringify(filters)])

  const filteredActivityLogsCount = filteredActivityLogs.length
  const hasFilteredActivityLogs = !!filteredActivityLogsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredActivityLogsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingActivityLogs } = useReduxAction(
    'activityLogs',
    'loadActivityLogs',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredActivityLogs,
    filteredActivityLogsCount,
    hasFilteredActivityLogs,
    loading: loadingActivityLogs,
  }
}

export default useActivityLogs
