import { useEffect } from 'react'

import { capitalizeString } from '@campaignhub/javascript-utils'

import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import searchEndpoint from '@functions/search'

import useAdAccount from '@hooks/useAdAccount'

import type { ClientModel, ExternalAdAccountModel, UserModel } from '@models/types'

const defaultState = {
  loading: false,
  results: [] as ExternalAdAccountModel[],
  searchString: '',
  selectedExternalAdAccountIds: [] as string[],
}

type SearchExternalAdAccountsParams = {
  searchRequestOptions: { [key: string]: any },
  state: typeof defaultState,
  setState: (state: Partial<typeof defaultState>) => void,
}

const searchExternalAdAccounts = async (params: SearchExternalAdAccountsParams) => {
  const {
    searchRequestOptions,
    state: {
      searchString,
    },
    setState,
  } = params

  setState({ loading: true, results: [] })

  const searchResults = await searchEndpoint(searchString, searchRequestOptions, 'ad-accounts/external')
  const { success, data } = searchResults
  if (success) {
    setState({ loading: false, results: data })
    return
  }

  setState({ loading: false, results: [] })
}

type UseLinkAdAccountsModalParams = {
  adAccountType: 'FacebookAdAccount' | 'FacebookPage' | 'GoogleAdAccount',
  linkableEntity: ClientModel | UserModel,
  linkableType: 'Client' | 'User'
}

const useLinkAdAccountsModal = (params: UseLinkAdAccountsModalParams) => {
  const { adAccountType, linkableEntity, linkableType } = params

  const [state, setState] = useSetState(defaultState)
  const {
    loading,
    results,
    searchString,
    selectedExternalAdAccountIds,
  } = state

  const searchRequestOptions = { source: adAccountType }

  const debouncedSearchString = useDebounce(searchString, 300)
  useEffect(() => {
    if (debouncedSearchString.length > 2) {
      searchExternalAdAccounts({ searchRequestOptions, state, setState })
    }
  }, [debouncedSearchString])

  const externalAdAccountsCount = results.length
  const externalAdAccountsIds = results.map(externalAdAccount => externalAdAccount.id)

  const entities = results.reduce((acc, value) => {
    acc[value.id] = value
    return acc
  }, {} as { [key: string]: ExternalAdAccountModel })

  const {
    callbacks: {
      createAndLinkAdAccounts,
    },
    creating,
  } = useAdAccount()

  const entityParams = {
    externalAdAccounts: selectedExternalAdAccountIds.map(id => entities[id]),
    linkableId: linkableEntity.id,
    linkableType,
  }

  const saveEnabled = !!selectedExternalAdAccountIds.length

  return {
    callbacks: {
      createAndLinkAdAccounts,
      searchExternalAdAccounts: () => searchExternalAdAccounts({ searchRequestOptions, state, setState }),
      setSelectedIds: (ids: string[]) => setState({ selectedExternalAdAccountIds: ids }),
      setState,
    },
    creating,
    entities,
    entityParams,
    externalAdAccounts: results,
    externalAdAccountsCount,
    externalAdAccountsIds,
    loading,
    saveEnabled,
    selectedExternalAdAccountIds,
    state,
  }
}

export default useLinkAdAccountsModal