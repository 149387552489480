import { faHome } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, Columns } from '@campaignhub/suit-theme'

import PageHeader from '@components/PageHeader'

import Campaigns from '../Campaigns'

const PageContent = () => (
  <>
    <PageHeader
      actionContent={(
        <Button buttonStyle="primaryCreate" size="medium">
          Create
        </Button>
      )}
      activeTabBarItemKey="dashboard"
      tabBarItems={[
        {
          href: '#/',
          icon: faHome,
          key: 'dashboard',
          title: 'Dashboard',
        },
      ]}
      title="Dashboard"
    />

    <Box paddingX="large" paddingTop={[112, 105]}>
      <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
        <Columns.Main>
          <Campaigns status="Pending" />

          <Campaigns status="Live" />

          <Campaigns status="Completed" />
        </Columns.Main>
      </Columns>
    </Box>
  </>
)

export default PageContent
