import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import type { CutterProfileModel } from '@models/types'

import { generateUrls } from '@hooks/useCutterProfile'

interface CutterProfileListItemProps {
  cutterProfile: CutterProfileModel,
}

const CutterProfileListItem = (props: CutterProfileListItemProps) => {
  const { cutterProfile, cutterProfile: { id, name } } = props

  const { editCutterProfileUrl } = generateUrls(cutterProfile)

  return (
    <DashboardModuleListItem
      href={editCutterProfileUrl}
      title={name}
    >
      <DashboardModuleListItem.TertiaryText>
        {`# ${id}`}
      </DashboardModuleListItem.TertiaryText>
    </DashboardModuleListItem>
  )
}

export default CutterProfileListItem
