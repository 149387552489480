type HandleErrorParams = {
  errors?: {
    [key: string]: string[],
  },
  status: number,
  title: string,
  traceId: string,
  type: string,
}

export const handleError = (data: HandleErrorParams) => {
  console.log('Raw Error', data)

  const { errors, title } = data

  const errorString = !!errors && Object.values(errors)?.flat()?.[0]

  const toastString = errorString || title || 'Something went wrong'

  return [toastString]
}

export default handleError
