import { Columns } from '@campaignhub/suit-theme'

import useCampaign from '@hooks/useCampaign'

import AdCampaigns from '../AdCampaigns'
import AdContent from '../AdContent'
import OrderDetails from '../OrderDetails'
import OrderTools from '../../../../components/OrderTools'
import PageLayout from '../../../../components/PageLayout'
import ProjectDetails from '../ProjectDetails'
import SummaryCards from '../SummaryCards'

interface PageContentProps {
  campaignId: number,
}

const PageContent = (props: PageContentProps) => {
  const { campaignId } = props

  const campaignPayload = useCampaign({ id: campaignId })

  return (
    <PageLayout activeTabBarItemKey="overview" campaignPayload={campaignPayload}>
      <Columns.Main>
        {/* <SummaryCards campaignPayload={campaignPayload} /> */}

        <OrderDetails campaignPayload={campaignPayload} />

        <ProjectDetails campaignPayload={campaignPayload} />

        {/* <AdCampaigns campaignPayload={campaignPayload} /> */}
      </Columns.Main>

      <Columns.Sidebar>
        <OrderTools campaignPayload={campaignPayload} />

        <AdContent campaignPayload={campaignPayload} />
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent
