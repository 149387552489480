export type ProductOptionValueModel = {
  cacheKey: number,
  costPrice: number,
  id: number,
  name: string,
  productOptionId: number,
  value: string,
  sort?: number,
}

export type ProductOptionValueRequestOptions = {}

const state = {
  costPrice: null,
  id: null,
  name: '',
  productOptionId: null,
  value: '',
}

export const requiredFields = [
  {
    key: 'costPrice',
    validation: (costPrice: number) => costPrice === undefined || costPrice > 0,
    invalidMessage: 'Invalid Price',
  },
  { key: 'name' },
  { key: 'productOptionId' },
  { key: 'value' },
]

export default state
