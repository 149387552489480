import { useNumericParams } from '@campaignhub/react-hooks'

import { MainContent, TopBar } from '@campaignhub/suit-theme'

import useCampaign from '@hooks/useCampaign'
import useMainNavigation from '@hooks/useMainNavigation'

import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Admin/components/MainNavigation'

import OrderEditRoutes from './routes'
import Navigation from './components/Navigation'

const OrderEdit = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  const { campaignId } = useNumericParams()

  const campaignPayload = useCampaign(
    {
      id: campaignId,
    },
    {
      performHttpRequests: true,
      requestOptions: {
        include: [
          'AdTemplate',
          'AssetGroup.Assets',
          'CampaignType',
          'Captions',
          'LandingPage',
          'OrderItem.PriceListItem.Product',
          'Project.DataStoreItems',
          'Project.ExternalPlatformEntities.ExternalPlatform',
        ],
      },
    },
  )
  const { campaign } = campaignPayload

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        nestedNavigation
        searchComponent={<TopBar.Search callbacks={{ search: null }} />}
      />

      <MainNavigation minimized nestedNavigation={<Navigation campaign={campaign} />} />

      <MainContent nestedNavigation offset={{ top: TopBar.topBarHeight }}>
        <OrderEditRoutes />
      </MainContent>
    </>
  )
}

export default OrderEdit
