import { useContext } from 'react'

import { launchModal } from '@campaignhub/javascript-utils'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'
import type { AssetFilters } from '@hooks/useAssets'

import defaultFormState, { requiredFields } from '@models/asset'

import * as assetActions from '@redux/modules/asset'

import type { AppDispatch } from '@redux/store'

import type { AssetModel, AssetRequestOptions } from '@models/types'

type DeleteAssetParams = {
  asset: AssetModel,
  dispatch: AppDispatch,
}

const deleteAsset = (params: DeleteAssetParams) => {
  const { asset, dispatch } = params
  const { deleteAsset: deleteFn } = assetActions

  return dispatch(deleteFn(asset))
}

const downloadAsset = (asset: AssetModel) => {
  const { filePath } = asset
  window.open(filePath, '_blank')
}

type UpdateAssetParams = {
  asset: AssetModel,
  assetParams: Partial<AssetModel>,
  dispatch: AppDispatch,
  requestOptions?: AssetRequestOptions,
}

const updateAsset = (params: UpdateAssetParams) => {
  const {
    asset, assetParams, dispatch, requestOptions,
  } = params
  const { updateAsset: updateFn } = assetActions

  const updatedParams = {
    id: asset.id,
    ...assetParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useAssetForm(
  asset: Partial<AssetModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const assetForm = useForm(
    defaultFormState,
    {
      entity: asset,
      requiredFields: [...requiredFields, ...customRequiredFields],
      validateOn,
    },
    [asset.id, asset.cacheKey],
  )

  return {
    ...assetForm,
  }
}

const useAsset = (initEntity: Partial<AssetModel> = {}) => {
  const { entity: asset }: { entity: AssetModel } = useLatestEntity(initEntity, 'assets')

  const dispatch = useDispatch()

  const { deleting, updating } = useSelector(reduxState => reduxState.assets)

  const { callbacks } = useContext(PageContext)

  return {
    asset,
    callbacks: {
      deleteAsset: () => deleteAsset({ asset, dispatch }),
      downloadAsset: () => downloadAsset(asset),
      editDocument: () => launchModal({
        callbacks,
        modalKey: 'EditDocumentModal',
        payload: { asset },
      }),
      createOrEditImage: (filters?: AssetFilters) => launchModal({
        callbacks,
        modalKey: 'CreateOrEditImageModal',
        payload: { filters, asset },
      }),
      manageDocuments: (filters?: AssetFilters) => launchModal({
        callbacks,
        modalKey: 'ManageDocumentsModal',
        payload: { filters },
      }),
      manageMedia: (filters?: AssetFilters) => launchModal({
        callbacks,
        modalKey: 'ManageMediaModal',
        payload: { filters },
      }),
      updateAsset: (assetParams: Partial<AssetModel>, requestOptions?: AssetRequestOptions) => updateAsset({
        asset, assetParams, dispatch, requestOptions,
      }),
    },
    deleting,
    updating,
  }
}

export default useAsset
