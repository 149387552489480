import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as brandActions from '@redux/modules/brand'

import defaultFormState, { requiredFields } from '@models/brand'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { BrandModel, BrandRequestOptions } from '@models/types'

export const generateUrls = (brand?: Partial<BrandModel>) => {
  const { id } = brand || {}

  return {
    archivedBrandsIndexUrl: '#/brands?isArchived=true',
    brandsIndexUrl: '#/brands',
    editBrandUrl: `#/brands/${id}/edit/`,
  }
}

type CreateBrandParams = {
  brandParams: Partial<BrandModel>,
  dispatch: AppDispatch,
  requestOptions?: BrandRequestOptions,
}

const createBrand = (params: CreateBrandParams) => {
  const { dispatch, brandParams, requestOptions } = params
  const { createBrand: createFn } = brandActions

  return dispatch(createFn(brandParams, requestOptions))
}

type UpdateBrandParams = {
  brand: BrandModel,
  brandParams: Partial<BrandModel>,
  dispatch: AppDispatch,
  requestOptions?: BrandRequestOptions,
}

const updateBrand = (params: UpdateBrandParams) => {
  const {
    dispatch, brand, brandParams, requestOptions,
  } = params
  const { updateBrand: updateFn } = brandActions

  const updatedParams = {
    id: brand.id,
    ...brandParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteBrandParams = {
  brand: DeleteParams<BrandModel>,
  dispatch: AppDispatch,
}

const deleteBrand = (params: DeleteBrandParams) => {
  const { dispatch, brand } = params
  const { deleteBrand: deleteFn } = brandActions

  return dispatch(deleteFn(brand))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useBrandForm(
  brand: Partial<BrandModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const brandForm = useForm(
    defaultFormState,
    { entity: brand, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [brand.id, brand.cacheKey],
  )

  return {
    ...brandForm,
  }
}

export const useRelations = (brand: Partial<BrandModel> = {}) => {
  const { related_entity_id } = brand

  const { relatedEntities } = useSelector(reduxState => reduxState.entities)

  const relatedEntity = related_entity_id && relatedEntities[related_entity_id] ? relatedEntities[related_entity_id] : {}

  return {
    relatedEntity,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: BrandRequestOptions,
}

function useBrand(initEntity: Partial<BrandModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: brand }: { entity: BrandModel } = useLatestEntity(initEntity, 'brands')
  const { id } = brand

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { relatedEntity } = useRelations(brand)

  useReduxAction(
    'brands',
    'loadBrand',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.brands)

  return {
    brand,
    callbacks: {
      createBrand: (brandParams: Partial<BrandModel>, entityOptions?: BrandRequestOptions) => (
        createBrand({ brandParams, dispatch, requestOptions: entityOptions })
      ),
      deleteBrand: () => deleteBrand({ brand, dispatch }),
      updateBrand: (brandParams: Partial<BrandModel>, entityOptions?: BrandRequestOptions) => (
        updateBrand({
          brand, brandParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    relatedEntity,
    updating,
    urls: generateUrls(brand),
  }
}

export default useBrand
