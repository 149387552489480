import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as cutterProfileModuleActions from '@redux/modules/cutterProfileModule'

import defaultFormState, { requiredFields } from '@models/cutterProfileModule'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { CutterProfileModuleModel, CutterProfileModuleRequestOptions } from '@models/types'

export const generateUrls = (cutterProfileModule?: Partial<CutterProfileModuleModel>) => {
  const { id } = cutterProfileModule || {}

  return {
    editCutterProfileModuleUrl: `#/cutterProfileModules/${id}/edit/`,
    cutterProfileModulesIndexUrl: '#/cutterProfileModules',
  }
}

type CreateCutterProfileModuleParams = {

  cutterProfileModuleParams: Partial<CutterProfileModuleModel>,
  dispatch: AppDispatch,
  requestOptions?: CutterProfileModuleRequestOptions,
}

const createCutterProfileModule = (params: CreateCutterProfileModuleParams) => {
  const { dispatch, cutterProfileModuleParams, requestOptions } = params
  const { createCutterProfileModule: createFn } = cutterProfileModuleActions

  return dispatch(createFn(cutterProfileModuleParams, requestOptions))
}

type UpdateCutterProfileModuleParams = {

  cutterProfileModule: CutterProfileModuleModel,
  cutterProfileModuleParams: Partial<CutterProfileModuleModel>,
  dispatch: AppDispatch,
  requestOptions?: CutterProfileModuleRequestOptions,
}

const updateCutterProfileModule = (params: UpdateCutterProfileModuleParams) => {
  const {
    dispatch, cutterProfileModule, cutterProfileModuleParams, requestOptions,
  } = params
  const { updateCutterProfileModule: updateFn } = cutterProfileModuleActions

  const updatedParams = {
    id: cutterProfileModule.id,
    ...cutterProfileModuleParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteCutterProfileModuleParams = {

  cutterProfileModule: DeleteParams<CutterProfileModuleModel>,
  dispatch: AppDispatch,
}

const deleteCutterProfileModule = (params: DeleteCutterProfileModuleParams) => {
  const { dispatch, cutterProfileModule } = params
  const { deleteCutterProfileModule: deleteFn } = cutterProfileModuleActions

  return dispatch(deleteFn(cutterProfileModule))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useCutterProfileModuleForm(
  cutterProfileModule: Partial<CutterProfileModuleModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const cutterProfileModuleForm = useForm(
    defaultFormState,
    { entity: cutterProfileModule, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [cutterProfileModule.id, cutterProfileModule.cacheKey],
  )

  return {
    ...cutterProfileModuleForm,
  }
}

export const useRelations = (cutterProfileModule: Partial<CutterProfileModuleModel> = {}) => {
  const { related_entity_id } = cutterProfileModule

  const { relatedEntities } = useSelector(reduxState => reduxState.entities)

  const relatedEntity = related_entity_id && relatedEntities[related_entity_id] ? relatedEntities[related_entity_id] : {}

  return {
    relatedEntity,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: CutterProfileModuleRequestOptions,
}

function useCutterProfileModule(initEntity: Partial<CutterProfileModuleModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: cutterProfileModule }: { entity: CutterProfileModuleModel } = useLatestEntity(initEntity, 'cutterProfileModules')
  const { id } = cutterProfileModule

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { relatedEntity } = useRelations(cutterProfileModule)

  useReduxAction(
    'cutterProfileModules',
    'loadCutterProfileModule',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.cutterProfileModules)

  return {
    callbacks: {
      createCutterProfileModule: (cutterProfileModuleParams: Partial<CutterProfileModuleModel>, entityOptions?: CutterProfileModuleRequestOptions) => (
        createCutterProfileModule({ cutterProfileModuleParams, dispatch, requestOptions: entityOptions })
      ),
      createOrEditCutterProfileModule: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditCutterProfileModuleModal',
        payload: { cutterProfileModule },
      }),
      deleteCutterProfileModule: () => deleteCutterProfileModule({ cutterProfileModule, dispatch }),
      updateCutterProfileModule: (cutterProfileModuleParams: Partial<CutterProfileModuleModel>, entityOptions?: CutterProfileModuleRequestOptions) => (
        updateCutterProfileModule({
          cutterProfileModule, cutterProfileModuleParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },

    creating,
    cutterProfileModule,
    deleting,
    loading,
    relatedEntity,
    updating,
    urls: generateUrls(cutterProfileModule),
  }
}

export default useCutterProfileModule
