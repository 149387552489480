import { camelCaseToTitleCase, capitalizeString } from '@campaignhub/javascript-utils'

import { Box, Image, Text } from '@campaignhub/suit-theme'

import { generateLogoUrl } from '@functions/adAccount'

interface AdChannelCardProps {
  adAccountType: 'FacebookAdAccount' | 'FacebookPage' | 'GoogleAdAccount',
  adChannel: 'facebook' | 'google',
}

const AdChannelCard = (props: AdChannelCardProps) => {
  const { adAccountType, adChannel } = props

  const url = generateLogoUrl(adChannel)

  return (
    <Box
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
    >
      <Box padding="large" width="fit-content">
        <Image backgroundSize="contain" height="40px" width="64px" url={url} />
      </Box>

      <Box
        borderLeft="1px solid"
        borderColor="lineColor"
        flexDirection="column"
        justifyContent="center"
        padding="large"
      >
        <Text fontSize="small">{capitalizeString(adChannel)}</Text>

        <Text fontSize="small" color='bodyFontLightColor' marginTop="small">{camelCaseToTitleCase(adAccountType)}</Text>
      </Box>
    </Box>
  )
}

export default AdChannelCard
