import { useContext } from 'react'

import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { confirmDelete } from '@functions/entity'

import usePriceList from '@hooks/usePriceList'

import usePriceListItem from '@hooks/usePriceListItem'

import { PriceListItemModel } from '@models/types'

interface PriceListListItemProps {
  priceListItem: PriceListItemModel,
}

const PriceListItemListItem = (props: PriceListListItemProps) => {
  const { priceListItem } = props

  const pricelistItemPayload = usePriceListItem(priceListItem)

  const {
    priceList: {
      name,
    },
    callbacks: {
      deletePriceListItem: deleteFn,
    },
  } = pricelistItemPayload

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext
  const { deletePriceListItem } = callbacks || {}

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deletePriceListItem,
      deleteFn,
    },
    strings: {
      entityName: 'Price List Item',
    },
  }

  const {
    urls: { editPriceListUrl },
  } = usePriceList(pricelistItemPayload.priceList)

  return (
    <DashboardModuleListItem
      title={name}
    >
      <DashboardModuleListItem.Icon
        onClick={() => confirmDelete(confirmDeletePayload)}
        type="delete"
      />

      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon
          href={`${editPriceListUrl}?redirect=${generateRedirectUrl()}`}
          type="link"
        />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default PriceListItemListItem
