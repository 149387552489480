import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { PriceListItemRequestOptions } from '@models/types'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['priceListItems']

type PriceListItemFilters = {
  priceListId?: number,
  priceListName?: string,
  productId?: number,
}

type UsePriceListItemsOptions = {
  filters?: PriceListItemFilters,
  performHttpRequests?: boolean,
  requestOptions?: PriceListItemRequestOptions,
}

export function usePriceListItems(options: UsePriceListItemsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    priceListId: filterPriceListId,
    priceListName: filterPriceListName,
    productId: filterProductId,
  } = filters

  const {
    updatedEntities: { priceListItems: priceListItemsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { priceListItems, priceLists } = useSelector(reduxState => reduxState.entities)

  const filteredPriceListItems = useMemo(() => {
    const filtered = Object.values(priceListItems).filter((priceListItem) => {
      const { priceListId, productId } = priceListItem

      const matchPriceListId = matchFilterNumber(priceListId, filterPriceListId)

      const priceListName = priceLists[priceListId]?.name
      const matchPriceListName = matchFilterString(priceListName, filterPriceListName)

      const matchProductId = matchFilterNumber(productId, filterProductId)

      return matchPriceListId && matchPriceListName && matchProductId
    })

    return sortArrayBy(filtered, 'asc', 'nameOverride')
  }, [priceListItemsUpdatedAt, JSON.stringify(filters)])

  const filteredPriceListItemsCount = filteredPriceListItems.length
  const hasFilteredPriceListItems = !!filteredPriceListItemsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredPriceListItemsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingPriceListItems } = useReduxAction(
    'priceListItems',
    'loadPriceListItems',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredPriceListItems,
    filteredPriceListItemsCount,
    hasFilteredPriceListItems,
    loading: loadingPriceListItems,
  }
}

export default usePriceListItems
