import { MainContent, TopBar } from '@campaignhub/suit-theme'

import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Admin/components/MainNavigation'

import useMainNavigation from '@hooks/useMainNavigation'

import CutterProfilesRoutes from './routes'

const CutterProfiles = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<TopBar.Search callbacks={{ search: null }} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <CutterProfilesRoutes />
      </MainContent>
    </>
  )
}

export default CutterProfiles
