import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import { Button, ModalContext, SidebarModal } from '@campaignhub/suit-theme'

import UserInputSearch from '@components/UserInputSearch'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useCampaign from '@hooks/useCampaign'
import useCampaignUser from '@hooks/useCampaignUser'

import DraggableUsers from './DraggableUsers'
import useManageCampaignUsers from './hooks/useManageCampaignUsers'

const MODAL_KEY = 'ManageCampaignUsersModal'

interface ManageCampaignUsersModalProps {
  callbacks: {
    bulkCreateCampaignUsers: (payload: HandleCallbackActionParams) => Promise<object>,
    closeModal: () => void,
  },
  showModal: boolean,
}

const ManageCampaignUsersModal = (props: ManageCampaignUsersModalProps) => {
  const { callbacks, showModal } = props
  const { bulkCreateCampaignUsers, closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { filters } = modalPayload
  const { campaignId } = filters || {}

  const {
    campaign: {
      clientId,
    },
  } = useCampaign({ id: campaignId })

  const manageCampaignUsersPayload = useManageCampaignUsers({ filters })

  const {
    callbacks: {
      modifyUserSort,
      setState,
      toggleUser,
    },
    groupedUserIds,
    state: {
      showUserSearch,
    },
  } = manageCampaignUsersPayload

  const { leadUserIds, supportUserIds } = groupedUserIds

  const {
    callbacks: {
      bulkCreateCampaignUsers: bulkCreateFn,
    },
  } = useCampaignUser()

  const bulkCreatePayload = {
    callbacks: {
      action: bulkCreateFn,
      afterAction: closeModal,
    },
    entityParams: {
      campaignId,
      leadUserIds,
      supportUserIds,
    },
    toastText: 'Users Updated',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Users" />

      <SidebarModal.Content>
        <DraggableUsers
          callbacks={{
            modifyUserSort,
            toggleUser,
          }}
          userIds={groupedUserIds}
        />

        {showUserSearch && (
          <UserInputSearch
            callbacks={{
              selectItem: (selected) => {
                toggleUser(selected.id, 'leadUserIds')
                setState({ showUserSearch: false })
              },
            }}
            clientId={clientId}
            selectedUserIds={[...leadUserIds, ...supportUserIds]}
          />
        )}

        {!showUserSearch && (
          <Button
            buttonStyle="secondaryUtility"
            icon={<FontAwesomeIcon icon={faUserPlus} />}
            onClick={() => setState({ showUserSearch: true })}
            size="medium"
          >
            Click to Add User
          </Button>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => bulkCreateCampaignUsers(bulkCreatePayload)}
          size="large"
        >
          Save Users
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ManageCampaignUsersModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageCampaignUsersModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
