import { useContext } from 'react'

import {
  Button,
  EntityMultiSelect,
  Form,
  ModalContext,
  SectionDivider,
  SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import AdChannelCard from './AdChannelCard'
import useLinkAdAccountsModal from './hooks/useLinkAdAccountsModal'

const MODAL_KEY = 'LinkAdAccountsModal'

type ModalCallbacks = {
  closeModal: () => void,
  createAndLinkAdAccounts: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const LinkAdAccountsModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createAndLinkAdAccounts } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})

  const adAccountType = digObject(modalPayload, 'adAccountType', {})
  const adChannel = digObject(modalPayload, 'adChannel', {})
  const linkableEntity = digObject(modalPayload, 'linkableEntity', {})
  const linkableType = digObject(modalPayload, 'linkableType', {})

  const useLinkAdAccountsModalPayload = useLinkAdAccountsModal({ adAccountType, linkableEntity, linkableType })
  const {
    callbacks: {
      createAndLinkAdAccounts: createAndLinkFn,
      setSelectedIds,
      setState,
    },
    creating,
    entities,
    entityParams,
    externalAdAccountsCount,
    externalAdAccountsIds,
    loading,
    saveEnabled,
    selectedExternalAdAccountIds,
    state: {
      searchString,
    }
  } = useLinkAdAccountsModalPayload

  const createAndLinkAdAccountsPayload = {
    callbacks: {
      action: createAndLinkFn,
      afterAction: closeModal,
    },
    entityParams,
    toastText: 'Ad Accounts Linked',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Link" titleSecondLine="Ad Accounts" />

      <SidebarModal.Content>
        <AdChannelCard adAccountType={adAccountType} adChannel={adChannel} />

        <Form>
          <Form.Field label="Search" marginTop="large">
            <input
              name="search"
              onChange={e => setState({ searchString: e.target.value })}
              placeholder="Search account names or IDs"
              value={searchString}
            />
          </Form.Field>

          <SectionDivider>Multi Select Values</SectionDivider>

          <EntityMultiSelect
            callbacks={{
              setSelectedIds: setSelectedIds,
            }}
            entities={entities}
            entityCount={externalAdAccountsCount}
            entityTitleKey="name"
            filteredEntityIds={externalAdAccountsIds}
            loading={loading}
            selectedIds={selectedExternalAdAccountIds}
          />

          {/* TODO: link to external Ads Manager */}
          {/* <Form.Field label="More Options" marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faShare} />}
              size="medium"
            >
              Create a New Ad Account
            </Button>
          </Form.Field> */}
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => createAndLinkAdAccounts(createAndLinkAdAccountsPayload)}
          size="large"
        >
          {saveEnabled ? 'Link Ad Accounts' : 'Select Ad Accounts'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <LinkAdAccountsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
