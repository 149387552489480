import { useContext } from 'react'

import {
  Box, Button, Line, ListItem, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import CampaignCard from '@components/CampaignCard'

import useAdCampaign from '@hooks/useAdCampaign'

import { AdCampaignModel } from '@models/types'

const MODAL_KEY = 'AdGroupModal'

type ModalCallbacks = {
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const AdGroupModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const initAdCampaign: AdCampaignModel = digObject(modalPayload, 'adCampaign', {})

  const {
    adCampaign,
    callbacks: {
      viewAdSetDetails,
    },
  } = useAdCampaign(initAdCampaign)

  const {
    adChannelTypeId,
    adCount,
    adGroups,
    campaignId,
  } = adCampaign

  const isGoogleAdCampaign = adChannelTypeId === 'Google'

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Ad" titleSecondLine={isGoogleAdCampaign ? 'Groups' : 'Sets'} />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <CampaignCard campaign={{ id: campaignId }} />

          <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />

          <Text color="bodyFontLightColor" fontSize="small">
            {isGoogleAdCampaign ? 'Ad Groups' : 'Ad Sets'} {`(${adCount} ${adCount === 1 ? 'Ad' : 'Ads'})`}
          </Text>

          {adGroups.map(adGroup => (
            <ListItem
              key={adGroup.id}
              onClick={() => viewAdSetDetails(adGroup)}
              showAngleIcon
            >
              <Text fontSize="small" paddingY="medium">{adGroup.name}</Text>
            </ListItem>
          ))}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => closeModal()}
          size="large"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <AdGroupModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
