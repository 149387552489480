import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import type { BrandModel } from '@models/types'

import { generateUrls } from '@hooks/useBrand'

interface BrandListItemProps {
  brand: BrandModel,
}

const BrandListItem = (props: BrandListItemProps) => {
  const { brand, brand: { id, name } } = props

  const { editBrandUrl } = generateUrls(brand)

  return (
    <DashboardModuleListItem
      href={editBrandUrl}
      title={name}
    >
      <DashboardModuleListItem.TertiaryText>
        {`# ${id}`}
      </DashboardModuleListItem.TertiaryText>
    </DashboardModuleListItem>
  )
}

export default BrandListItem
