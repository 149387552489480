import { Route, Routes } from 'react-router-dom'

import AutoOrderQueuesList from './List'

const AutoOrderQueuesRoutes = () => (
  <Routes>
    <Route path="/" element={<AutoOrderQueuesList />} />
  </Routes>
)

export default AutoOrderQueuesRoutes
