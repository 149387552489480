import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, Form, SortableList, Text,
} from '@campaignhub/suit-theme'

import handleCallbackAction from '@functions/handleCallbackAction'

import useProductOption from '@hooks/useProductOption'
import useProductOptionValue from '@hooks/useProductOptionValue'
import useProductOptionValues from '@hooks/useProductOptionValues'
import useSortableIds from '@hooks/useSortableIds'

import ProductOptionValueListItem from './ProductOptionValueListItem'

interface ProductOptionValuesProps {
  productOptionId: number,
}

const ProductOptionValues = (props: ProductOptionValuesProps) => {
  const { productOptionId } = props

  const productOptionValuesPayload = useProductOptionValues({
    filters: {
      productOptionId,
    },
    performHttpRequests: true,
  })

  const {
    filteredProductOptionValues,
    hasFilteredProductOptionValues,
    loading,
  } = productOptionValuesPayload

  const productOptionValueIds = filteredProductOptionValues.map(value => value.id)

  const {
    callbacks: {
      sortProductOptionValues,
    },
  } = useProductOptionValue()

  const sortableIdsPayload = useSortableIds({
    callbacks: {
      updateSort: (sortedIds: number[]) => sortProductOptionValues(productOptionId, sortedIds),
    },
    ids: productOptionValueIds,
  })

  const {
    callbacks: {
      modifySort,
    },
    sortedIds,
  } = sortableIdsPayload

  const {
    callbacks: {
      createProductOptionValue,
    },
  } = useProductOption({ id: productOptionId })

  const generateModifySortPayload = (fromIndex: number, toIndex: number) => ({
    callbacks: {
      action: () => modifySort(fromIndex, toIndex),
    },
    toastText: 'Values Sorted',
  })

  return (
    <DashboardModule title="Value Details" loading={loading}>
      <Box flexDirection="column">
        <DashboardModule.ScrollableSection flexDirection="column">
          {!loading && !hasFilteredProductOptionValues && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>
                No Product Option Values present
              </DashboardModule.BlankState.Title>

              <DashboardModule.BlankState.Paragraph>
                Please create some values
              </DashboardModule.BlankState.Paragraph>
            </DashboardModule.BlankState>
          )}

          {!loading && hasFilteredProductOptionValues && (
            <SortableList
              callbacks={{
                onDragEnd: (fromIndex, toIndex) => handleCallbackAction(
                  generateModifySortPayload(fromIndex, toIndex),
                ),
              }}
            >
              <Box flexDirection="column" padding="large">
                <Form.Row style={{ display: 'flex', alignItems: 'end' }}>
                  <Text
                    color="bodyFontLightColor"
                    fontSize="xsmall"
                    letterSpacing="1px"
                    marginLeft="18px"
                    style={{ flex: 3 }}
                  >
                    Title
                  </Text>

                  <Text
                    color="bodyFontLightColor"
                    fontSize="xsmall"
                    letterSpacing="1px"
                    style={{ flex: 1 }}
                  >
                    Value
                  </Text>

                  <Text
                    color="bodyFontLightColor"
                    fontSize="xsmall"
                    letterSpacing="1px"
                    marginRight="90px"
                    style={{ flex: 1 }}
                  >
                    Cost Price ($)
                  </Text>
                </Form.Row>

                <SortableList.Dropzone droppableId="ProductOptionValues">
                  <Box flexDirection="column">
                    {!loading && sortedIds.map((productOptionValueId, index) => (
                      <SortableList.Item
                        draggableId={`ProductOptionValue-${productOptionValueId}`}
                        index={index}
                        key={`ProductOptionValue-${productOptionValueId}`}
                      >
                        <ProductOptionValueListItem productOptionValueId={productOptionValueId} />
                      </SortableList.Item>
                    ))}
                  </Box>
                </SortableList.Dropzone>
              </Box>
            </SortableList>
          )}
        </DashboardModule.ScrollableSection>

        <DashboardModule.Footer boxProps={{ borderTop: loading ? 'none' : '1px solid' }}>
          <DashboardModule.FooterRight>
            <Button
              buttonStyle="secondaryEdit"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => createProductOptionValue()}
              size={['medium', 'small']}
              width={['100%', 'fit-content']}
            >
              Add Value
            </Button>
          </DashboardModule.FooterRight>
        </DashboardModule.Footer>
      </Box>
    </DashboardModule>
  )
}

export default ProductOptionValues
