import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { Box, Link } from '@campaignhub/suit-theme'

import useAdQueue from '@hooks/useAdQueue'

interface ActionsProps {
  adQueueId: number,
}

const Actions = (props: ActionsProps) => {
  const { adQueueId } = props

  const {
    callbacks: {
      manageAdDeployment,
    },
  } = useAdQueue({ id: adQueueId })

  return (
    <Box justifyContent="center" alignItems="center">
      <Link
        greyLink
        onClick={() => manageAdDeployment()}
        textProps={{ fontSize: 'medium', height: 'fit-content' }}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Link>
    </Box>
  )
}

export default Actions
