import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { CutterProfileModuleRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['cutterProfileModules']

type CutterProfileModuleFilters = {
  cutterProfileId?: number,
}

type UseCutterProfileModulesOptions = {
  filters?: CutterProfileModuleFilters,
  performHttpRequests?: boolean,
  requestOptions?: CutterProfileModuleRequestOptions,
}

function useCutterProfileModules(options: UseCutterProfileModulesOptions) {
  const { filters = {}, requestOptions } = options
  const {
    cutterProfileId: filterCutterProfileId,
  } = filters

  const {
    updatedEntities: { cutterProfileModules: cutterProfileModulesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { cutterProfileModules } = useSelector(reduxState => reduxState.entities)

  const filteredCutterProfileModules = useMemo(() => {
    const filtered = Object.values(cutterProfileModules).filter((cutterProfileModule) => {
      const { cutterProfileId } = cutterProfileModule

      const matchCutterProfileId = matchFilterNumber(cutterProfileId, filterCutterProfileId)

      return matchCutterProfileId
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [cutterProfileModulesUpdatedAt, JSON.stringify(filters)])

  const filteredCutterProfileModulesCount = filteredCutterProfileModules.length
  const hasFilteredCutterProfileModules = !!filteredCutterProfileModulesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredCutterProfileModulesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingCutterProfileModules } = useReduxAction(
    'cutterProfileModules',
    'loadCutterProfileModules',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCutterProfileModules,
    filteredCutterProfileModulesCount,
    hasFilteredCutterProfileModules,
    loading: loadingCutterProfileModules,
  }
}

export default useCutterProfileModules
