import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useClientPriceLists from '@hooks/useClientPriceLists'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'
import type { PriceListRequestOptions } from '@models/types'

const watchEntityKeys = ['priceLists']

type PriceListFilters = {
  clientId?: number,
  isArchived?: boolean,
  string?: string,
}

type UsePriceListsOptions = {
  filters?: PriceListFilters,
  performHttpRequests?: boolean,
  requestOptions?: PriceListRequestOptions,
}

function usePriceLists(options: UsePriceListsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    clientId: filterClientId,
    isArchived: filterIsArchived,
    string: filterString,
  } = filters

  const {
    updatedEntities: { priceLists: priceListsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const clientPriceListsPayload = useClientPriceLists({
    filters: {
      clientId: filterClientId,
    },
  })

  const { filteredClientPriceLists } = clientPriceListsPayload

  const priceListIdsArray = filteredClientPriceLists.map(clientPriceList => clientPriceList.priceListId)

  const { priceLists } = useSelector(reduxState => reduxState.entities)

  const filteredPriceLists = useMemo(() => {
    const filtered = Object.values(priceLists).filter((priceList) => {
      const { id, isArchived, name } = priceList

      const matchClientId = filterClientId ? priceListIdsArray.includes(id) : true

      const matchIsArchived = filterIsArchived !== undefined ? isArchived === filterIsArchived : true

      const matchId = id.toString() === filterString
      const matchTitle = matchFilterString(name, filterString)
      const matchString = matchId || matchTitle

      return matchClientId && matchIsArchived && matchString
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [priceListsUpdatedAt, JSON.stringify(filters)])

  const filteredPriceListsCount = filteredPriceLists.length
  const hasFilteredPriceLists = !!filteredPriceListsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredPriceListsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingPriceLists } = useReduxAction(
    'priceLists',
    'loadPriceLists',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredPriceLists,
    filteredPriceListsCount,
    hasFilteredPriceLists,
    loading: loadingPriceLists,
  }
}

export default usePriceLists
