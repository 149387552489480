import { DashboardModule, LoadingModule } from '@campaignhub/suit-theme'

import ActivityImageUrl from '@assets/activity.svg'

import useActivityLogs from '@hooks/useActivityLogs'

import ActivityItem from './ActivityItem'
import BlankState from '../BlankState'

interface ActivityProps {
  subjectId: number,
  subjectType: string,
}

const Activity = (props: ActivityProps) => {
  const { subjectId, subjectType } = props

  const activityLogsPayload = useActivityLogs({
    filters: {
      subjectId,
      subjectType,
    },
    performHttpRequests: !!subjectId,
    requestOptions: {
      include: 'User.AssetGroup.Assets',
    },
  })

  const {
    filteredActivityLogs,
    filteredActivityLogsCount,
    hasFilteredActivityLogs,
    loading,
  } = activityLogsPayload

  return (
    <DashboardModule.ScrollableSection
      alignItems={hasFilteredActivityLogs ? undefined : 'center'}
      flexDirection="column"
      height="100%"
      justifyContent={hasFilteredActivityLogs ? undefined : 'center'}
      marginTop="large"
      maxHeight={406}
    >
      {loading && <LoadingModule boxProps={{ marginBottom: 0 }} />}

      {!loading && !hasFilteredActivityLogs && (
        <BlankState
          imageUrl={ActivityImageUrl}
          subtitle="Activity will be logged here once work starts."
          title="No Activity"
        />
      )}

      {filteredActivityLogs.map((activityLog, index) => (
        <ActivityItem
          activityLog={activityLog}
          isLast={index + 1 === filteredActivityLogsCount}
          key={activityLog.id}
        />
      ))}
    </DashboardModule.ScrollableSection>
  )
}

export default Activity
