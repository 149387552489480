import { useMemo } from 'react'

import { matchFilterArrayIncludes, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ProductOptionRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['productOptions']

type ProductOptionFilters = {
  displayType?: string,
  isArchived?: boolean,
  productId?: number,
  string?: string,
}

type UseProductOptionsOptions = {
  filters?: ProductOptionFilters,
  performHttpRequests?: boolean,
  requestOptions?: ProductOptionRequestOptions,
}

function useProductOptions(options: UseProductOptionsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    displayType: filterDisplayType,
    isArchived: filterIsArchived,
    productId: filterProductId,
    string: filterString,
  } = filters

  const {
    updatedEntities: { productOptions: productOptionsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { productOptionProducts, productOptions } = useSelector(reduxState => reduxState.entities)
  const productOptionIds = Object.values(productOptionProducts).reduce<number[]>((acc, productOptionProduct) => {
    if (productOptionProduct.productId === filterProductId){
      acc.push(productOptionProduct.productOptionId)
    }

    return acc
  }, [])

  const filteredProductOptions = useMemo(() => {
    const filtered = Object.values(productOptions).filter((productOption) => {
      const {
        displayType, id, isArchived, name,
      } = productOption

      const matchIsArchived = filterIsArchived !== undefined ? isArchived === filterIsArchived : true

      const matchDisplayType = matchFilterString(displayType, filterDisplayType)

      const matchProductId = filterProductId ? matchFilterArrayIncludes(productOptionIds, id) : true

      const matchId = id.toString() === filterString
      const matchName = matchFilterString(name, filterString)
      const stringMatch = matchId || matchName

      return matchIsArchived && matchDisplayType && matchProductId && stringMatch
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [productOptionsUpdatedAt, JSON.stringify(filters)])

  const filteredProductOptionsCount = filteredProductOptions.length
  const hasFilteredProductOptions = !!filteredProductOptionsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredProductOptionsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingProductOptions } = useReduxAction(
    'productOptions',
    'loadProductOptions',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredProductOptions,
    filteredProductOptionsCount,
    hasFilteredProductOptions,
    loading: loadingProductOptions,
  }
}

export default useProductOptions
