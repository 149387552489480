import { Box, DashboardModule } from '@campaignhub/suit-theme'

import usePriceListItems from '@hooks/usePriceListItems'

import PriceListItem from './PriceListItem'

interface PriceListItemListProps {
  priceListItemsPayload: ReturnType<typeof usePriceListItems>,
}

const PriceListItemList = (props: PriceListItemListProps) => {
  const {
    priceListItemsPayload: {
      filteredPriceListItems,
      filteredPriceListItemsCount,
      hasFilteredPriceListItems,
    },
  } = props

  if (!hasFilteredPriceListItems){
    return null
  }

  return (
    <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title="Products">
      <Box flexDirection="column" maxHeight={400} overflowY="auto">
        {filteredPriceListItems.map((priceListItem, index) => (
          <PriceListItem
            isLast={index + 1 === filteredPriceListItemsCount}
            key={priceListItem.id}
            priceListItem={priceListItem}
          />
        ))}
      </Box>
    </DashboardModule>
  )
}

export default PriceListItemList
