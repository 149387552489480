import { useState } from 'react'

import { Box, Link, Text } from '@campaignhub/suit-theme'

import { LocationsModel } from '@models/adCampaign'

interface LocationsProps {
  locations: LocationsModel[],
}

type MappedLocation = {
  name: string | null,
  radius?: number,
}

const Locations = (props: LocationsProps) => {
  const { locations } = props

  const [showMore, setShowMore] = useState(false)

  const mappedLocations = locations.reduce<MappedLocation[]>((acc, value) => {
    if (value.name){
      const newLocation: MappedLocation = { name: null }
      newLocation.name = value.name

      if (value.radius){
        newLocation.radius = value.radius
      }

      acc.push(newLocation)
    }

    return acc
  }, [])

  const displayedLocations = showMore ? mappedLocations : mappedLocations.slice(0, 5)

  const canShowMore = mappedLocations.length > 5

  return (
    <>
      <Box justifyContent="space-between">
        <Text color="bodyFontLightColor" fontSize="xsmall">Targeting Locations</Text>

        {canShowMore && (
          <Link
            onClick={() => setShowMore(!showMore)}
            textProps={{ fontSize: 'xsmall' }}
          >
            {showMore ? 'Show Less' : 'Show more'}
          </Link>
        )}
      </Box>

      {displayedLocations.map(({ name, radius }) => (
        <Box alignItems="center" justifyContent="space-between" paddingY="medium">
          <Text fontSize="small">{name}</Text>

          {!!radius && <Text color="bodyFontLightColor" fontSize="small">{radius} km</Text>}
        </Box>
      ))}
    </>
  )
}

export default Locations
