import { useNumericParams } from '@campaignhub/react-hooks'

import { MainContent, TopBar } from '@campaignhub/suit-theme'

import useMainNavigation from '@hooks/useMainNavigation'

import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Client/components/MainNavigation'

import CampaignRoutes from './routes'

import Navigation from './components/Navigation'

const Campaign = () => {
  const { campaignId } = useNumericParams()

  const { callbacks: { toggleNavigation } } = useMainNavigation()

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        nestedNavigation
        searchComponent={<TopBar.Search callbacks={{ search: null }} />}
      />

      <MainNavigation minimized nestedNavigation={<Navigation campaignId={campaignId} />} />

      <MainContent nestedNavigation offset={{ top: TopBar.topBarHeight }}>
        <CampaignRoutes campaignId={campaignId} />
      </MainContent>
    </>
  )
}

export default Campaign
