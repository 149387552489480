import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as cutterProfileActions from '@redux/modules/cutterProfile'

import defaultFormState, { requiredFields } from '@models/cutterProfile'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { CutterProfileModel, CutterProfileRequestOptions } from '@models/types'

export const generateUrls = (cutterProfile?: Partial<CutterProfileModel>) => {
  const { id } = cutterProfile || {}

  return {
    editCutterProfileUrl: `#/cutterProfiles/${id}/edit/`,
    cutterProfilesIndexUrl: '#/cutterProfiles',
  }
}

type CreateCutterProfileParams = {

  cutterProfileParams: Partial<CutterProfileModel>,
  dispatch: AppDispatch,
  requestOptions?: CutterProfileRequestOptions,

}

const createCutterProfile = (params: CreateCutterProfileParams) => {
  const { dispatch, cutterProfileParams, requestOptions } = params
  const { createCutterProfile: createFn } = cutterProfileActions

  return dispatch(createFn(cutterProfileParams, requestOptions))
}

type UpdateCutterProfileParams = {

  cutterProfile: CutterProfileModel,
  cutterProfileParams: Partial<CutterProfileModel>,
  dispatch: AppDispatch,
  requestOptions?: CutterProfileRequestOptions,

}

const updateCutterProfile = (params: UpdateCutterProfileParams) => {
  const {
    dispatch, cutterProfile, cutterProfileParams, requestOptions,
  } = params
  const { updateCutterProfile: updateFn } = cutterProfileActions

  const updatedParams = {
    id: cutterProfile.id,
    ...cutterProfileParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteCutterProfileParams = {

  cutterProfile: DeleteParams<CutterProfileModel>,
  dispatch: AppDispatch,

}

const deleteCutterProfile = (params: DeleteCutterProfileParams) => {
  const { dispatch, cutterProfile } = params
  const { deleteCutterProfile: deleteFn } = cutterProfileActions

  return dispatch(deleteFn(cutterProfile))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useCutterProfileForm(
  cutterProfile: Partial<CutterProfileModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const cutterProfileForm = useForm(
    defaultFormState,
    { entity: cutterProfile, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [cutterProfile.id, cutterProfile.name],
  )

  return {
    ...cutterProfileForm,
  }
}

export const useRelations = (cutterProfile: Partial<CutterProfileModel> = {}) => {
  const { related_entity_id } = cutterProfile

  const { relatedEntities } = useSelector(reduxState => reduxState.entities)

  const relatedEntity = related_entity_id && relatedEntities[related_entity_id] ? relatedEntities[related_entity_id] : {}

  return {
    relatedEntity,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: CutterProfileRequestOptions,
}

function useCutterProfile(initEntity: Partial<CutterProfileModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: cutterProfile }: { entity: CutterProfileModel } = useLatestEntity(initEntity, 'cutterProfiles')
  const { id } = cutterProfile

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { relatedEntity } = useRelations(cutterProfile)

  useReduxAction(
    'cutterProfiles',
    'loadCutterProfile',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.cutterProfiles)

  return {
    callbacks: {
      createCutterProfile: (cutterProfileParams: Partial<CutterProfileModel>, entityOptions?: CutterProfileRequestOptions) => (
        createCutterProfile({ cutterProfileParams, dispatch, requestOptions: entityOptions })
      ),
      createOrEditCutterProfile: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditCutterProfileModal',
        payload: { cutterProfile },
      }),
      deleteCutterProfile: () => deleteCutterProfile({ cutterProfile, dispatch }),
      updateCutterProfile: (cutterProfileParams: Partial<CutterProfileModel>, entityOptions?: CutterProfileRequestOptions) => (
        updateCutterProfile({
          cutterProfile, cutterProfileParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    cutterProfile,
    deleting,
    loading,
    relatedEntity,
    updating,
    urls: generateUrls(cutterProfile),
  }
}

export default useCutterProfile
