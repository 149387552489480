import { CampaignValidationModel } from '@models/types'

type Accumulator = { [key: string]: CampaignValidationModel[] }

const groupValidationsByType = (campaignValidations: CampaignValidationModel[]) => {
  const groupedValidations = campaignValidations.reduce((acc: Accumulator, validation: CampaignValidationModel) => {
    const lowerCaseValidationType = validation.validationType.toLowerCase()

    // Group Campaign and Product validations together
    if (lowerCaseValidationType === 'campaign' || lowerCaseValidationType === 'product'){
      acc.project.push(validation)
      return acc
    }

    acc[lowerCaseValidationType].push(validation)
    return acc
  }, { project: [], facebook: [], google: [] })

  return groupedValidations
}

export default groupValidationsByType
