import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { generateUrls, useRelations } from '@hooks/useCampaign'

import type { CampaignModel } from '@models/types'

import placeholderImageUrl from '@assets/default_campaign_image_400x400.png'

type CampaignListItemProps = {
  campaign: CampaignModel,
}

const CampaignListItem = (props: CampaignListItemProps) => {
  const { campaign } = props
  const { name } = campaign

  const { campaignOverviewUrl } = generateUrls(campaign)

  const {
    coverImage: {
      filePath,
    },
  } = useRelations(campaign)

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px solid', padding: 'large' }}
      href={campaignOverviewUrl}
      showAngleIcon
    >
      <Box width={110} marginRight="large">
        <Image
          borderRadius={5}
          height={60}
          marginRight="medium"
          placeholderUrl={placeholderImageUrl}
          url={filePath}
          width={100}
        />
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{name}</Text>
      </Box>
    </ListItem>
  )
}

export default CampaignListItem
