import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, EntityMultiSelect, Form, Line, ModalContext, SectionDivider, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useBulkCreatePriceListItemOptions from './hooks/useBulkCreatePriceListItemOptions'

const MODAL_KEY = 'BulkCreatePriceListItemOptionsModal'

type ModalCallbacks = {
  bulkCreatePriceListItemOptions: (payload: HandleCallbackActionParams) => void,
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const BulkCreatePriceListItemOptionsModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { bulkCreatePriceListItemOptions, closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const priceListItemId = digObject(modalPayload, 'priceListItemOption.priceListItemId')

  const {
    callbacks: {
      bulkCreatePriceListItemOptions: bulkCreateFn,
      setSelectedProductOptionIds,
      setState,
    },
    creating,
    entityParams,
    filteredProductOptionIds,
    filteredProductOptionIdsCount,
    name,
    priceListItem,
    product,
    productOptions,
    productOptionsPayload: {
      callbacks: {
        loadMore,
      },
      canLoadMore,
      loading,
    },
    saveEnabled,
    selectedProductOptionIds,
  } = useBulkCreatePriceListItemOptions({ priceListItemId })

  const bulkCreatePriceListItemOptionsPayload = {
    callbacks: {
      action: bulkCreateFn,
      afterAction: closeModal,
    },
    entityParams,
    toastText: 'Options Added',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Add" titleSecondLine="Options" />

      <SidebarModal.Content>
        <Box flexDirection="column" justifyContent="center" minWidth="0">
          <Text color="bodyFontLightColor" fontSize="xsmall">Product</Text>

          <Text marginTop="large">{priceListItem.nameOverride || product.name}</Text>
        </Box>

        <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />

        <Form.Field label="Search Options">
          <input onChange={e => setState({ name: e.target.value })} value={name} />
        </Form.Field>

        <SectionDivider>Multi Select Values</SectionDivider>

        <EntityMultiSelect
          callbacks={{
            loadMore,
            setSelectedIds: setSelectedProductOptionIds,
          }}
          canLoadMore={canLoadMore}
          entities={productOptions}
          entityCount={filteredProductOptionIdsCount}
          entityTitleKey="name"
          filteredEntityIds={filteredProductOptionIds}
          loading={loading}
          selectedIds={selectedProductOptionIds}
        />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => bulkCreatePriceListItemOptions(bulkCreatePriceListItemOptionsPayload)}
          size="large"
        >
          {saveEnabled ? 'Add Options' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <BulkCreatePriceListItemOptionsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
