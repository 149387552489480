import { useContext } from 'react'

import { launchModal } from '@campaignhub/javascript-utils'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as priceListItemOptionActions from '@redux/modules/priceListItemOption'
import type { BulkCreateParams } from '@redux/modules/priceListItemOption'

import defaultFormState, { requiredFields } from '@models/priceListItemOption'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { PriceListItemOptionModel, PriceListItemOptionRequestOptions } from '@models/types'

type CreatePriceListItemOptionParams = {
  dispatch: AppDispatch,
  priceListItemOptionParams: Partial<PriceListItemOptionModel>,
  requestOptions?: PriceListItemOptionRequestOptions,
}

const createPriceListItemOption = (params: CreatePriceListItemOptionParams) => {
  const { dispatch, priceListItemOptionParams, requestOptions } = params
  const { createPriceListItemOption: createFn } = priceListItemOptionActions

  return dispatch(createFn(priceListItemOptionParams, requestOptions))
}

type UpdatePriceListItemOptionParams = {
  dispatch: AppDispatch,
  priceListItemOption: PriceListItemOptionModel,
  priceListItemOptionParams: Partial<PriceListItemOptionModel>,
  requestOptions?: PriceListItemOptionRequestOptions,
}

const updatePriceListItemOption = (params: UpdatePriceListItemOptionParams) => {
  const {
    dispatch, priceListItemOption, priceListItemOptionParams, requestOptions,
  } = params
  const { updatePriceListItemOption: updateFn } = priceListItemOptionActions

  const updatedParams = {
    id: priceListItemOption.id,
    ...priceListItemOptionParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type BulkCreatePriceListItemOptionParams = {
  bulkCreateParams: BulkCreateParams,
  dispatch: AppDispatch,
  requestOptions?: PriceListItemOptionRequestOptions,
}

const bulkCreatePriceListItemOptions = (params: BulkCreatePriceListItemOptionParams) => {
  const {
    bulkCreateParams, dispatch, requestOptions,
  } = params

  const { bulkCreatePriceListItemOptions: bulkCreateFn } = priceListItemOptionActions

  return dispatch(bulkCreateFn(bulkCreateParams, requestOptions))
}

type DeletePriceListItemOptionParams = {
  dispatch: AppDispatch,
  priceListItemOption: DeleteParams<PriceListItemOptionModel>,
}

const deletePriceListItemOption = (params: DeletePriceListItemOptionParams) => {
  const { dispatch, priceListItemOption } = params
  const { deletePriceListItemOption: deleteFn } = priceListItemOptionActions

  return dispatch(deleteFn(priceListItemOption))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function usePriceListItemOptionForm(
  priceListItemOption: Partial<PriceListItemOptionModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const priceListItemOptionForm = useForm(
    defaultFormState,
    { entity: priceListItemOption, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [priceListItemOption.id, priceListItemOption.cacheKey],
  )

  return {
    ...priceListItemOptionForm,
  }
}

export const useRelations = (priceListItemOption: Partial<PriceListItemOptionModel> = {}) => {
  const { priceListItemId, productOptionId } = priceListItemOption

  const { priceListItems, productOptions } = useSelector(reduxState => reduxState.entities)

  const priceListItem = priceListItemId && priceListItems[priceListItemId] ? priceListItems[priceListItemId] : {}
  const productOption = productOptionId && productOptions[productOptionId] ? productOptions[productOptionId] : {}

  return {
    priceListItem,
    productOption,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: PriceListItemOptionRequestOptions,
}

function usePriceListItemOption(initEntity: Partial<PriceListItemOptionModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: priceListItemOption }: { entity: PriceListItemOptionModel } = useLatestEntity(
    initEntity,
    'priceListItemOptions',
  )
  const { id } = priceListItemOption

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'priceListItemOptions',
    'loadPriceListItemOption',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.priceListItemOptions)

  return {
    callbacks: {
      bulkCreatePriceListItemOptions: (
        bulkCreateParams: BulkCreateParams,
        entityOptions?: PriceListItemOptionRequestOptions,
      ) => (
        bulkCreatePriceListItemOptions({ bulkCreateParams, dispatch, requestOptions: entityOptions })
      ),
      createPriceListItemOption: (
        priceListItemOptionParams: Partial<PriceListItemOptionModel>,
        entityOptions?: PriceListItemOptionRequestOptions,
      ) => (
        createPriceListItemOption({ priceListItemOptionParams, dispatch, requestOptions: entityOptions })
      ),
      deletePriceListItemOption: () => deletePriceListItemOption({ priceListItemOption, dispatch }),
      launchBulkCreatePriceListItemOptionsModal: () => launchModal({
        callbacks,
        modalKey: 'BulkCreatePriceListItemOptionsModal',
        payload: { priceListItemOption },
      }),
      launchBulkCreatePriceListItemOptionValuesModal: () => launchModal({
        callbacks,
        modalKey: 'BulkCreatePriceListItemOptionValuesModal',
        payload: { priceListItemOption },
      }),
      updatePriceListItemOption: (
        priceListItemOptionParams: Partial<PriceListItemOptionModel>,
        entityOptions?: PriceListItemOptionRequestOptions,
      ) => (
        updatePriceListItemOption({
          priceListItemOption, priceListItemOptionParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    priceListItemOption,
    updating,
  }
}

export default usePriceListItemOption
