import { useContext } from 'react'

import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import { DashboardModuleListItem, IconWithMenu, Link, StatusBadge } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faCog } from '@fortawesome/pro-light-svg-icons'

import { confirmAction, confirmDelete } from '@functions/entity'

import useAdTemplate from '@hooks/useAdTemplate'

import useAdTemplateProduct from '@hooks/useAdTemplateProduct'

import { AdTemplateProductModel } from '@models/types'

interface AdTemplateProductListItemProps {
  adTemplateProduct: AdTemplateProductModel,
}

const AdTemplateProductListItem = (props: AdTemplateProductListItemProps) => {
  const { adTemplateProduct } = props

  const adTemplateProductPayload = useAdTemplateProduct(adTemplateProduct)

  const {
    adTemplate: {
      name,
    },
    adTemplateProduct: {
      isDefault,
    },
    callbacks: {
      deleteAdTemplateProduct: deleteFn,
      setDefaultAdTemplateProduct: setDefaultFn,
    },
  } = adTemplateProductPayload

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext
  const { deleteAdTemplateProduct } = callbacks || {}

  const setDefaultAdTemplateProductPayload = {
    callbacks: {
      actionFn: setDefaultFn,
    },
    options: {
      entityParams: adTemplateProduct,
    },
    strings: {
      actionString: 'set',
      entityName: 'Default Ad Template Product',
      toastString: 'set',
    },
  }

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deleteAdTemplateProduct,
      deleteFn,
    },
    strings: {
      entityName: 'Ad Template Product',
    },
  }

  const {
    urls: { editAdTemplateUrl },
  } = useAdTemplate(adTemplateProductPayload.adTemplate)

  return (
    <DashboardModuleListItem
      title={name}
      secondaryText={isDefault && 'Default'}
    >
      <DashboardModuleListItem.Actions>
        {!isDefault && (
          <IconWithMenu
            icon={(
              <Link
                greyLink
              >
                <FontAwesomeIcon icon={faCog} />
              </Link>
            )}
            shouldUsePortal
          >
            <IconWithMenu.Menu closeOnClick listStyle={{ right: '-22px' }}>
              <Link key="default" onClick={() => confirmAction(setDefaultAdTemplateProductPayload)}>
                <FontAwesomeIcon icon={faCheckSquare} />
                Set as Default
              </Link>
            </IconWithMenu.Menu>
          </IconWithMenu>
        )}

        <DashboardModuleListItem.Icon
          boxProps={{ marginLeft: 'medium' }}
          onClick={() => confirmDelete(confirmDeletePayload)}
          type="delete"
        />

        <DashboardModuleListItem.Icon
          boxProps={{ marginLeft: 'medium' }}
          href={`${editAdTemplateUrl}?redirect=${generateRedirectUrl()}`}
          type="link"
        />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default AdTemplateProductListItem
