import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ClientPriceListModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/clientPriceList/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/clientPriceList/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/clientPriceList/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/clientPriceList/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/clientPriceList/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/clientPriceList/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/clientPriceList/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/clientPriceList/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/clientPriceList/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/clientPriceList/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/clientPriceList/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/clientPriceList/DELETE_FAILURE'

export type ClientPriceListModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: ClientPriceListModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadClientPriceLists(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().clientPriceLists.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)) {
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/client-price-lists', options)
      .then(({ data }: { data: { data: ClientPriceListModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.CLIENT_PRICE_LIST_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function bulkCreateClientPriceLists(
  params: { clientIds?: number[], priceListIds?: number[] },
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    data: JSON.stringify(params),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/client-price-lists/bulk', options, config)
      .then(({ data }: { data: ClientPriceListModel[] }) => {
        const normalizedJson = normalize(data, Schemas.CLIENT_PRICE_LIST_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateClientPriceList(
  clientPriceList: UpdateParams<ClientPriceListModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      ...clientPriceList,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/client-price-lists/${clientPriceList.id}`, options, config)
      .then(({ data }: { data: ClientPriceListModel }) => {
        const normalizedJson = normalize(data, Schemas.CLIENT_PRICE_LIST)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteClientPriceList(clientPriceList: DeleteParams<ClientPriceListModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/client-price-lists/${clientPriceList.id}`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        const deletedEntity = { id: clientPriceList.id }
        const normalizedJson = normalize(deletedEntity, Schemas.CLIENT_PRICE_LIST)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ClientPriceListModuleState = initialState,
  action: Action = { type: '' },
): ClientPriceListModuleState {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
