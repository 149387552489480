import { faIdCard, faToggleLargeOn } from '@fortawesome/pro-solid-svg-icons'

import { ProductModel } from '@models/product'

import { generateUrls } from '@hooks/useProduct'

const generateTabBarItems = (product: ProductModel) => {
  const { name } = product

  const urls = generateUrls(product)
  const { editProductUrl, editProductRelationsUrl } = urls

  return [
    {
      icon: faIdCard,
      href: editProductUrl,
      key: 'details',
      title: name?.slice(0, 50) || 'Product Details',
    },
    {
      icon: faToggleLargeOn,
      href: editProductRelationsUrl,
      key: 'relations',
      title: 'Relations',
    },
  ]
}

export default generateTabBarItems
