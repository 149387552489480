import {
  DashboardModule, Form,
} from '@campaignhub/suit-theme'

import { useProductForm } from '@hooks/useProduct'

import useCampaignTypes from '@hooks/useCampaignTypes'

interface ProductDetailsProps {
  productFormPayload: ReturnType<typeof useProductForm>,
}

const ProductDetails = (props: ProductDetailsProps) => {
  const {
    productFormPayload: {
      entityState: {
        basePrice,
        campaignTypeId,
        description,
        isArchived,
        name,
      },
      errors,
      handlers,
      setEntityState,
    },
  } = props

  const { campaignTypes } = useCampaignTypes({
    performHttpRequests: true,
  })

  return (
    <DashboardModule title="Details">
      <Form padding="large">
        <Form.Row>
          <Form.Field errorMessage={errors.name} label="* Name">
            <input
              name="name"
              value={name}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field marginTop={['large', 0]} errorMessage={errors.basePrice} label="Base Price">
            <input
              name="basePrice"
              value={basePrice}
              type="number"
              // EntityState set manually to override empty string with null
              onChange={e => setEntityState({ basePrice: Number(e.target.value) || null })}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field errorMessage={errors.description} label="* Description">
            <textarea
              name="description"
              style={{ height: 120, resize: 'vertical' }}
              value={description}
              {...handlers}
            />
          </Form.Field>

          <Form.Field marginTop={['large', 0]} label="Archived">
            <input
              disabled
              value={isArchived ? 'Yes' : 'No'}
              type="text"
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="Campaign Type">
            <select name="campaignTypeId" value={campaignTypeId} {...handlers}>
              <option value="">None</option>
              {campaignTypes.map(campaignType => (
                <option key={campaignType.id} value={campaignType.name}>{campaignType.displayName}</option>
              ))}
            </select>
          </Form.Field>
        </Form.Row>

      </Form>
    </DashboardModule>
  )
}

export default ProductDetails
