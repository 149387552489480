import { useMemo } from 'react'

import { formatDate } from '@campaignhub/javascript-utils'

import {
  StatusBadge, Table,
} from '@campaignhub/suit-theme'

import getStatusBadgeProps from '@functions/statusBadge'

import { calculateDueDate } from '@functions/autoOrderQueue'

import useAutoOrderQueues from '@hooks/useAutoOrderQueues'

import type { AutoOrderQueueModel } from '@models/types'

const buildColumns = () => [
  {
    title: 'ID',
    dataKey: 'id',
    cellProps: {
      verticalAlign: 'middle',
    },
  },
  {
    title: 'Reference ID',
    dataKey: 'referenceId',
    cellProps: {
      verticalAlign: 'middle',
    },
  },
  {
    title: 'Order ID',
    dataKey: 'orderId',
    cellProps: {
      verticalAlign: 'middle',
    },
  },
  {
    title: 'Source',
    dataKey: 'source',
    cellProps: {
      verticalAlign: 'middle',
    },
  },
  {
    title: 'Status',
    dataKey: 'status',
    cellProps: {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 40,
    },
    render: (status: string) => {
      const statusBadgeProps = getStatusBadgeProps(status)

      return (
        <StatusBadge {...statusBadgeProps} />
      )
    },
  },
  {
    title: 'Queue Delay',
    dataKey: 'queueDelay',
    cellProps: {
      verticalAlign: 'middle',
    },
  },
  {
    title: 'Hangfire Job ID',
    dataKey: 'hangfireJobId',
    cellProps: {
      verticalAlign: 'middle',
    },
  },
  {
    title: 'Message',
    dataKey: 'message',
    cellProps: {
      verticalAlign: 'middle',
    },
  },
  {
    title: 'User ID#',
    dataKey: 'userId',
    cellProps: {
      verticalAlign: 'middle',
    },
  },
  {
    title: 'Due',
    dataKey: 'createdAt',
    cellProps: {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 40,
    },
    render: (_: string, autoOrderQueue: AutoOrderQueueModel) => {
      return (
        <StatusBadge
          boxProps={{
            backgroundColor: 'backgroundColor',
            borderColor: 'lineColor',
            color: 'black',
          }}
          text={formatDate(calculateDueDate(autoOrderQueue).toISOString(), 'ISO8601', 'dd/MM/yyyy')}
        />
      )
    },
  },
]

interface AutoOrderQueuesTableProps {
  autoOrderQueuesPayload: ReturnType<typeof useAutoOrderQueues>,
}

const AutoOrderQueuesTable = (props: AutoOrderQueuesTableProps) => {
  const {
    autoOrderQueuesPayload: {
      filteredAutoOrderQueues,
      filteredAutoOrderQueuesCount,
    },
  } = props

  const memoColumns = useMemo(() => buildColumns(), [filteredAutoOrderQueuesCount])

  return (
    <Table
      boxProps={{ border: 'none', maxHeight: 1600, overflowY: 'auto' }}
      columns={memoColumns}
      data={filteredAutoOrderQueues}
      scroll={{ x: 800 }}
      stickyHeader
    />
  )
}

export default AutoOrderQueuesTable
