import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as commentActions from '@redux/modules/comment'

import defaultFormState, { requiredFields } from '@models/comment'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { CommentModel, CommentRequestOptions } from '@models/types'

type CreateCommentParams = {
  commentParams: Partial<CommentModel>,
  dispatch: AppDispatch,
  requestOptions?: CommentRequestOptions,
}

const createComment = (params: CreateCommentParams) => {
  const { dispatch, commentParams, requestOptions } = params
  const { createComment: createFn } = commentActions

  return dispatch(createFn(commentParams, requestOptions))
}

type DeleteCommentParams = {
  comment: DeleteParams<CommentModel>,
  dispatch: AppDispatch,
}

const deleteComment = (params: DeleteCommentParams) => {
  const { dispatch, comment } = params
  const { deleteComment: deleteFn } = commentActions

  return dispatch(deleteFn(comment))
}

export const useRelations = (comment: Partial<CommentModel> = {}) => {
  const { userId } = comment

  const { users } = useSelector(reduxState => reduxState.entities)

  const user = userId && users[userId] ? users[userId] : {}

  return {
    user,
  }
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useCommentForm(
  comment: Partial<CommentModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const commentForm = useForm(
    defaultFormState,
    { entity: comment, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [comment.id, comment.cacheKey],
  )

  return {
    ...commentForm,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: CommentRequestOptions,
}

function useComment(initEntity: Partial<CommentModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: comment }: { entity: CommentModel} = useLatestEntity(initEntity, 'comments')
  const { id } = comment

  const dispatch = useDispatch()

  useReduxAction(
    'comments',
    'loadComment',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const { creating, deleting } = useSelector(reduxState => reduxState.comments)

  return {
    callbacks: {
      createComment: (commentParams: Partial<CommentModel>, entityOptions?: CommentRequestOptions) => (
        createComment({ commentParams, dispatch, requestOptions: entityOptions })
      ),
      deleteComment: () => deleteComment({ dispatch, comment }),
    },
    comment,
    creating,
    deleting,
  }
}

export default useComment
