import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { PriceListItemModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/priceListItem/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/priceListItem/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/priceListItem/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/priceListItem/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/priceListItem/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/priceListItem/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/priceListItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/priceListItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/priceListItem/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/priceListItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/priceListItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/priceListItem/DELETE_FAILURE'

export type PriceListItemModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: PriceListItemModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadPriceListItems(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().priceListItems.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/price-list-items', options)
      .then(({ data }: { data: { data: PriceListItemModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.PRICE_LIST_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createPriceListItem(priceListItem: Partial<PriceListItemModel>, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      ...priceListItem,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/price-list-items', options, config)
      .then(({ data }: { data: PriceListItemModel }) => {
        const normalizedJson = normalize(data, Schemas.PRICE_LIST_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

type PatchParams = Pick<PriceListItemModel, 'descriptionOverride' | 'id' | 'nameOverride' | 'price'>

// Patch only accepts a subset of attributes, any extra will cause a 400 bad request in the backend
export function patchPriceListItem(
  priceListItem: PatchParams,
  options: EntityOptions = {},
) {
  const { descriptionOverride, nameOverride, price } = priceListItem

  const config = {
    method: 'PATCH',
    data: JSON.stringify({
      descriptionOverride,
      nameOverride,
      price,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/price-list-items/${priceListItem.id}`, options, config)
      .then(({ data }: { data: PriceListItemModel }) => {
        const normalizedJson = normalize(data, Schemas.PRICE_LIST_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deletePriceListItem(priceListItem: DeleteParams<PriceListItemModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/price-list-items/${priceListItem.id}`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        const deletedEntity = { id: priceListItem.id }
        const normalizedJson = normalize(deletedEntity, Schemas.PRICE_LIST_ITEM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function removePriceListItem(dispatch: AppDispatch, priceListItem: PriceListItemModel) {
  const normalizedJson = normalize(priceListItem, Schemas.PRICE_LIST_ITEM)
  dispatch(deleteEntity(normalizedJson))
}

// Reducer
export default function reducer(
  state: PriceListItemModuleState = initialState,
  action: Action = { type: '' },
): PriceListItemModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
