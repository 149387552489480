import { formatDate } from '@campaignhub/javascript-utils'

import { faBox, faCalendarAlt, faCog } from '@fortawesome/pro-light-svg-icons'

import { DashboardModule } from '@campaignhub/suit-theme'

import useCampaign, { useRelations as useCampaignRelations } from '@hooks/useCampaign'
import { useRelations as useOrderItemRelations } from '@hooks/useOrderItem'
import { useRelations as usePriceListItemRelations } from '@hooks/usePriceListItem'

import DetailsItem from './DetailsItem'

interface OrderDetailsProps {
  campaignPayload: ReturnType<typeof useCampaign>,
}

const OrderDetails = (props: OrderDetailsProps) => {
  const {
    campaignPayload: {
      campaign,
      loading,
    },
  } = props

  const formattedRequiredAt = formatDate(campaign.requiredAt, 'ISO8601', 'dd/MM/yyyy')

  const { adTemplate, orderItem } = useCampaignRelations(campaign)
  const { priceListItem } = useOrderItemRelations(orderItem)
  const { product } = usePriceListItemRelations(priceListItem)

  const { name: adTemplateName } = adTemplate
  const { name: productName } = product

  return (
    <DashboardModule
      loading={loading}
      title="Order Details"
    >
      {!loading && (
        <>
          <DetailsItem
            boxProps={{
              borderBottom: ['1px solid', 'none'],
              borderColor: 'lineColor',
            }}
            icon={faBox}
            text={productName || 'None'}
            title="Product"
          />

          <DetailsItem
            boxProps={{
              borderBottom: ['1px solid', 'none'],
              borderLeft: ['none', '1px solid'],
              borderColor: ['lineColor', 'lineColor'],
            }}
            icon={faCog}
            text={adTemplateName || 'None'}
            title="Ad Template"
          />

          <DetailsItem
            boxProps={{
              borderLeft: ['none', '1px solid'],
              borderColor: ['lineColor', 'lineColor'],
            }}
            campaignId={campaign.id}
            icon={faCalendarAlt}
            text={formattedRequiredAt}
            title="Schedule"
          />
        </>
      )}
    </DashboardModule>
  )
}

export default OrderDetails
