import { Box, DashboardModuleListItem } from '@campaignhub/suit-theme'

import { generateUrls, useRelations } from '@hooks/useUser'

import { useClientUsers } from '@hooks/useClientUsers'

import type { UserModel } from '@models/types'

interface UserListItemProps {
  user: UserModel,
}

const UserListItem = (props: UserListItemProps) => {
  const {
    user,
    user: {
      email,
      id,
      mobileNumber,
      name,
    },
  } = props

  const { userType } = useRelations(user)

  const clientUsersPayload = useClientUsers({
    filters: {
      userId: id,
    },
  })

  const { filteredClientUsersCount } = clientUsersPayload

  const { editUserUrl } = generateUrls(user)

  return (
    <DashboardModuleListItem
      href={editUserUrl}
      secondaryText={(email && mobileNumber) ? `${email} | ${mobileNumber}` : (email || mobileNumber || '')}
      title={name}
    >
      <Box flexDirection="column">
        <DashboardModuleListItem.TertiaryText>
          {`${userType?.name || ''} `}
        </DashboardModuleListItem.TertiaryText>

        <DashboardModuleListItem.TertiaryText textProps={{ fontSize: 'xsmall', marginTop: "small" }}>
          {
            userType.id === 0
              ? 'Realbase'
              : `${filteredClientUsersCount} Organisations`
          }
        </DashboardModuleListItem.TertiaryText>
      </Box>
    </DashboardModuleListItem>
  )
}

export default UserListItem
