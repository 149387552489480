import { InputSearch, UserProfileImage } from '@campaignhub/suit-theme'

import useAssets from '@hooks/useAssets'
import useUsers from '@hooks/useUsers'

import type { AssetModel, UserModel } from '@models/types'

const mapResults = (images: AssetModel[], users: UserModel[]) => {
  const userGroup = {
    key: 'users',
    title: 'USERS',
    items: users.map((user) => {
      // TODO: check the image find works once assets can be added to users
      const { assetGroupId, firstName, lastName } = user
      const filteredImages = images.filter(image => image.assetGroupId === assetGroupId)
      const coverImage = filteredImages[0] || {}
      const { filePath } = coverImage || {}

      return {
        iconLeft: (
          <UserProfileImage imageUrl={filePath}>
            {firstName[0] + lastName[0]}
          </UserProfileImage>
        ),
        id: user.id,
        key: `User-${user.id}`,
        secondaryText: user.mobileNumber,
        text: `${user.firstName} ${user.lastName}`,
      }
    }),
  }

  return { groups: [userGroup] }
}

interface UserInputSearchProps {
  callbacks: {
    selectItem: (selected: { id: number }) => void,
  },
  clientId: number,
  selectedUserIds: number[],
}

const UserInputSearch = (props: UserInputSearchProps) => {
  const { callbacks, clientId, selectedUserIds } = props

  const assetsPayload = useAssets({
    filters: {
      assetTypeCategory: 'Image',
    },
  })

  const { filteredAssets } = assetsPayload

  const usersPayload = useUsers({
    filters: {
      clientId,
    },
    performHttpRequests: true,
  })

  const { filteredUsers } = usersPayload
  const unselectedUsers = filteredUsers.filter(user => !selectedUserIds.includes(user.id))

  return (
    <InputSearch
      boxProps={{
        marginBottom: 'large',
      }}
      callbacks={callbacks}
      closeOnSelect
      placeholder="Search for Users"
      results={mapResults(filteredAssets, unselectedUsers)}
      showOnFocus
    />
  )
}

export default UserInputSearch
