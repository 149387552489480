import {
  Box, Image, ListItem, LoadingModule, Text,
} from '@campaignhub/suit-theme'

import placeholderImageUrl from '@assets/default_campaign_image_400x400.png'

import useCampaign, { useRelations } from '@hooks/useCampaign'

import { CampaignModel } from '@models/types'

interface CampaignCardProps {
  campaign: Partial<CampaignModel>,
  v1CampaignName?: string,
  v1ReferenceId?: string,
}

const CampaignCard = (props: CampaignCardProps) => {
  const {
    campaign: initCampaign,
    v1CampaignName,
    v1ReferenceId,
  } = props

  if (initCampaign.id == null) {
    return (
      <ListItem disableHover>
        <Box width={110} marginRight="large">
          <Image
            borderRadius={5}
            height={40}
            marginRight="medium"
            placeholderUrl={placeholderImageUrl}
            url={null}
            width={70}
          />
        </Box>

        <Box flexDirection="column" justifyContent="center" minWidth="0">
          <Text fontSize="small">{v1CampaignName}</Text>
  
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
            {`AIM# ${v1ReferenceId} (V1)`}
          </Text>
        </Box>
      </ListItem>
    )
  }

  const campaignPayload = useCampaign(
    initCampaign,
    {
      performHttpRequests: true,
      requestOptions: {
        include: [
          'AssetGroup.Assets',
          'Project.DataStoreItems',
        ],
      },
    },
  )

  const { campaign, loading } = campaignPayload

  const {
    coverImage: {
      filePath,
    },
    project,
  } = useRelations(campaign)

  if (loading) return <LoadingModule boxProps={{ marginBottom: 'none' }} loading={loading} />

  return (
    <ListItem disableHover>
      <Box width={110} marginRight="large">
        <Image
          borderRadius={5}
          height={40}
          marginRight="medium"
          placeholderUrl={placeholderImageUrl}
          url={filePath}
          width={70}
        />
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{project.name || campaign.name}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
          {`AIM# ${campaign.id}`}
        </Text>
      </Box>
    </ListItem>
  )
}

export default CampaignCard
