import api from '@functions/api'

const searchEndpoint = (
  string: string,
  requestOptions: { [key: string]: any },
  requestUrl: string,
) => {
  if (!requestUrl) return Promise.resolve({ success: false })

  const mergedOptions = {
    string,
    ...requestOptions,
  }

  return api(requestUrl, mergedOptions)
    .then(({ data }) => ({ success: true, data: data.data ? data.data : data }))
    .catch(errors => ({ success: false, errors }))
}

export default searchEndpoint
