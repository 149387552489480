import { Box, Image } from '@campaignhub/suit-theme'

import type { AssetModel } from '@models/types'

type SortableImageProps = {
  callbacks: {
    updateSortOrder: () => void,
  },
  image: AssetModel,
  selectionIndex: number,
  sorting: boolean,
}

const SortableImage = (props: SortableImageProps) => {
  const {
    callbacks: {
      updateSortOrder,
    },
    image: {
      filePath,
    },
    selectionIndex,
    sorting,
  } = props

  const isSelected = selectionIndex !== -1

  return (
    <Image
      borderRadius={5}
      forwardProps={{
        onClick: sorting ? () => updateSortOrder() : null,
        style: { cursor: sorting ? 'pointer' : 'auto' },
      }}
      height={110}
      url={filePath}
    >
      <Box
        alignItems="center"
        backgroundColor={isSelected ? 'rgba(0, 0, 0, 0.5)' : null}
        borderRadius={5}
        height="100%"
        justifyContent="center"
        position="relative"
      >
        {isSelected && (
          <Box
            alignItems="center"
            backgroundColor="rgba(0, 0, 0, 0.5)"
            border="2px solid white"
            borderRadius="100%"
            color="white"
            height="40px"
            justifyContent="center"
            width="40px"
          >
            {selectionIndex + 1}
          </Box>
        )}
      </Box>
    </Image>
  )
}

export default SortableImage
