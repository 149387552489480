import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import { DashboardModuleListItem, Link } from '@campaignhub/suit-theme'

import { generateUrls } from '@hooks/usePriceList'

import type { PriceListModel } from '@models/types'

interface PriceListListItemProps {
  checkboxOnClick: () => void,
  isChecked: boolean,
  priceList: PriceListModel,
}

const PriceList = (props: PriceListListItemProps) => {
  const {
    priceList,
    priceList: {
      id,
      name,
    },
    ...restProps
  } = props

  const { editPriceListUrl } = generateUrls(priceList)
  const urlWithRedirect = `${editPriceListUrl}?redirect=${generateRedirectUrl()}`

  return (
    <DashboardModuleListItem
      showCheckbox
      title={(
        <Link
          textProps={{ color: 'bodyFontColor' }}
          href={urlWithRedirect}
        >
          {name}
        </Link>
      )}
      {...restProps}
    >
      <DashboardModuleListItem.TertiaryText>
        {`# ${id}`}
      </DashboardModuleListItem.TertiaryText>

      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon
          href={urlWithRedirect}
          type="link"
        />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default PriceList
