import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import defaultFormState, { requiredFields } from '@models/adTemplate'
import type { AdTemplateProductModel, AdTemplateProductRequestOptions } from '@models/types'

import * as adTemplateProductActions from '@redux/modules/adTemplateProduct'
import type { AppDispatch } from '@redux/store'
import type { DeleteParams, UpdateParams } from '@redux/modules/types'

export type BulkCreateAdTemplateProductsParams = {
  bulkCreateParams: { adTemplateIds?: number[], productIds?: number[] },
  dispatch: AppDispatch,
  requestOptions?: AdTemplateProductRequestOptions,
}

const bulkCreateAdTemplateProducts = (params: BulkCreateAdTemplateProductsParams) => {
  const { bulkCreateParams, dispatch, requestOptions } = params

  const { bulkCreateAdTemplateProducts: bulkCreateFn } = adTemplateProductActions

  return dispatch(bulkCreateFn(bulkCreateParams, requestOptions))
}

type UpdateAdTemplateProductParams = {
  adTemplateProduct: AdTemplateProductModel,
  adTemplateProductParams: Partial<AdTemplateProductModel>,
  dispatch: AppDispatch,
  requestOptions?: AdTemplateProductRequestOptions,
}

const updateAdTemplateProduct = (params: UpdateAdTemplateProductParams) => {
  const {
    dispatch, adTemplateProduct, adTemplateProductParams, requestOptions,
  } = params
  const { updateAdTemplateProduct: updateFn } = adTemplateProductActions

  const updatedParams = {
    id: adTemplateProduct.id,
    ...adTemplateProductParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteAdTemplateProductParams = {
  adTemplateProduct: DeleteParams<AdTemplateProductModel>,
  dispatch: AppDispatch,
}

const deleteAdTemplateProduct = (params: DeleteAdTemplateProductParams) => {
  const { dispatch, adTemplateProduct } = params
  const { deleteAdTemplateProduct: deleteFn } = adTemplateProductActions

  return dispatch(deleteFn(adTemplateProduct))
}

type SetDefaultAdTemplateProductParams = {
  adTemplateProduct: UpdateParams<AdTemplateProductModel>,
  dispatch: AppDispatch,
}

const setDefaultAdTemplateProduct = (params: SetDefaultAdTemplateProductParams) => {
  const { dispatch, adTemplateProduct } = params
  const { setDefaultAdTemplateProduct: setDefaultFn } = adTemplateProductActions

  return dispatch(setDefaultFn(adTemplateProduct.id, true))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useAdTemplateProductForm(
  adTemplateProduct: Partial<AdTemplateProductModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const adTemplateProductForm = useForm(
    defaultFormState,
    { entity: adTemplateProduct, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [adTemplateProduct.id, adTemplateProduct.cacheKey],
  )

  return {
    ...adTemplateProductForm,
  }
}

export const useRelations = (adTemplateProduct: Partial<AdTemplateProductModel> = {}) => {
  const { adTemplateId, productId } = adTemplateProduct

  const { adTemplates, products } = useSelector(reduxState => reduxState.entities)

  const adTemplate = adTemplateId && adTemplates[adTemplateId] ? adTemplates[adTemplateId] : {}

  const product = productId && products[productId] ? products[productId] : {}

  return {
    adTemplate,
    product,
  }
}

function useAdTemplateProduct(initEntity: Partial<AdTemplateProductModel> = {}) {
  const { entity: adTemplateProduct }:
    { entity: AdTemplateProductModel } = useLatestEntity(initEntity, 'adTemplateProducts')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { adTemplate, product } = useRelations(adTemplateProduct)

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.adTemplateProducts)

  return {
    adTemplate,
    adTemplateProduct,
    callbacks: {
      bulkCreateAdTemplateProducts: (
        bulkCreateParams: BulkCreateAdTemplateProductsParams['bulkCreateParams'],
        requestOptions?: AdTemplateProductRequestOptions,
      ) => (
        bulkCreateAdTemplateProducts({ bulkCreateParams, dispatch, requestOptions })
      ),
      deleteAdTemplateProduct: () => deleteAdTemplateProduct({ dispatch, adTemplateProduct }),
      setDefaultAdTemplateProduct: () => setDefaultAdTemplateProduct({ dispatch, adTemplateProduct }),
      launchBulkCreateAdTemplateProductsModal: () => launchModal({
        callbacks,
        modalKey: 'BulkCreateAdTemplateProductsModal',
        payload: { adTemplateProduct },
      }),
      updateAdTemplateProduct: (
        adTemplateProductParams: Partial<AdTemplateProductModel>,
        entityOptions?: AdTemplateProductRequestOptions,
      ) => (
        updateAdTemplateProduct({
          adTemplateProduct, adTemplateProductParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    product,
    updating,
  }
}

export default useAdTemplateProduct
