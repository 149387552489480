import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

const watchEntityKeys = ['comments']

type CommentFilters = {
  conversationId?: number,
}

type UseCommentsOptions = {
  filters?: CommentFilters,
}

function useComments(options: UseCommentsOptions) {
  const { filters = {} } = options
  const {
    conversationId: filterConversationId,
  } = filters

  const {
    updatedEntities: { comments: commentsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { comments } = useSelector(reduxState => reduxState.entities)

  const filteredComments = useMemo(() => {
    // Return empty if no conversationId
    if (!filterConversationId) return []

    const filtered = Object.values(comments).filter((comment) => {
      const { conversationId } = comment

      const matchConversationId = matchFilterNumber(conversationId, filterConversationId)

      return matchConversationId
    })

    return sortArrayBy(filtered, 'asc', 'id')
  }, [commentsUpdatedAt, JSON.stringify(filters)])

  const filteredCommentsCount = filteredComments.length
  const hasFilteredComments = !!filteredCommentsCount

  return {
    filteredComments,
    filteredCommentsCount,
    hasFilteredComments,
  }
}

export default useComments
