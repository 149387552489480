import { useEffect } from 'react'

import { useSetState } from '@campaignhub/react-hooks'

import { modifyGroupedIdsSort, toggleGroupedIdsItem } from '@campaignhub/javascript-utils'

import useCampaignUsers from '@hooks/useCampaignUsers'

import { CampaignUserModel } from '@models/types'

const groupUserIds = (filteredCampaignUsers: CampaignUserModel[]) => (
  filteredCampaignUsers.reduce((acc, value) => {
    if (value.support){
      acc.supportUserIds.push(value.userId)
    } else {
      acc.leadUserIds.push(value.userId)
    }

    return acc
  }, { leadUserIds: [], supportUserIds: [] })
)

export type Payload = {
  sourceDroppableId: string,
  destinationDroppableId: string,
}

type GroupedUserIds = {
  leadUserIds: number[],
  supportUserIds: number[],
}

const modifyUserSort = (
  fromIndex: number,
  toIndex: number,
  payload: Payload,
  groupedUserIds: GroupedUserIds,
  setState: (state: any) => void,
) => {
  const { destinationDroppableId, sourceDroppableId } = payload
  const groupedIds = modifyGroupedIdsSort(fromIndex, toIndex, payload, groupedUserIds)

  setState({
    [destinationDroppableId]: groupedIds[destinationDroppableId],
    [sourceDroppableId]: groupedIds[sourceDroppableId],
  })
}

const toggleUser = (
  userId: number,
  groupedUserIds: GroupedUserIds,
  listName: string,
  setState: (state: any) => void,
) => {
  const groupedIds = toggleGroupedIdsItem(userId, groupedUserIds, listName)

  setState({ [listName]: groupedIds[listName] })
}

const defaultState = {
  leadUserIds: [],
  showUserSearch: false,
  supportUserIds: [],
}

type ManageCampaignUsersParams = {
  filters?: {
    campaignId?: number,
  },
}

const useManageCampaignUsers = (params: ManageCampaignUsersParams) => {
  const { filters } = params

  const campaignUsersPayload = useCampaignUsers({
    filters,
    performHttpRequests: true,
    requestOptions: { include: 'User' },
  })

  const { filteredCampaignUsers } = campaignUsersPayload

  const [state, setState] = useSetState(defaultState)
  const { leadUserIds, supportUserIds } = state

  useEffect(() => {
    const groupedUserIds = groupUserIds(filteredCampaignUsers)
    setState({ ...groupedUserIds })
  }, [])

  const groupedUserIds = {
    leadUserIds,
    supportUserIds,
  }

  return {
    callbacks: {
      modifyUserSort: (fromIndex: number, toIndex: number, payload: Payload) => (
        modifyUserSort(fromIndex, toIndex, payload, groupedUserIds, setState)
      ),
      setState,
      toggleUser: (userId: number, listName: string) => toggleUser(userId, groupedUserIds, listName, setState),
    },
    groupedUserIds,
    state,
  }
}

export default useManageCampaignUsers
