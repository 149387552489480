import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, EntityMultiSelect, Form, Line, ModalContext, SectionDivider, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useBulkCreatePriceListItemOptionValues from './hooks/useBulkCreatePriceListItemOptionValues'

const MODAL_KEY = 'BulkCreatePriceListItemOptionValuesModal'

type ModalCallbacks = {
  bulkCreatePriceListItemOptionValues: (payload: HandleCallbackActionParams) => void,
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const BulkCreatePriceListItemOptionValuesModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { bulkCreatePriceListItemOptionValues, closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const priceListItemOption = digObject(modalPayload, 'priceListItemOption', {})

  const {
    callbacks: {
      bulkCreatePriceListItemOptionValues: bulkCreateFn,
      setSelectedProductOptionValueIds,
      setState,
    },
    creating,
    entityParams,
    filteredProductOptionValueIds,
    filteredProductOptionValueIdsCount,
    name,
    productOption,
    productOptionValues,
    productOptionValuesPayload: {
      callbacks: {
        loadMore,
      },
      canLoadMore,
      loading,
    },
    saveEnabled,
    selectedProductOptionValueIds,
  } = useBulkCreatePriceListItemOptionValues({ priceListItemOption })

  const bulkCreatePriceListItemOptionValuesPayload = {
    callbacks: {
      action: bulkCreateFn,
      afterAction: closeModal,
    },
    entityParams,
    toastText: 'Values Created',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Add" titleSecondLine="Values" />

      <SidebarModal.Content>
        <Box flexDirection="column" justifyContent="center" minWidth="0">
          <Text color="bodyFontLightColor" fontSize="xsmall">Product Option</Text>

          <Text marginTop="large">{priceListItemOption.nameOverride || productOption.name}</Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {productOption.displayType}
          </Text>
        </Box>

        <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />

        <Form.Field label="Search Values">
          <input onChange={e => setState({ name: e.target.value })} value={name} />
        </Form.Field>

        <SectionDivider>Multi Select Values</SectionDivider>

        <EntityMultiSelect
          callbacks={{
            loadMore,
            setSelectedIds: setSelectedProductOptionValueIds,
          }}
          canLoadMore={canLoadMore}
          entities={productOptionValues}
          entityCount={filteredProductOptionValueIdsCount}
          entityTitleKey="name"
          filteredEntityIds={filteredProductOptionValueIds}
          loading={loading}
          selectedIds={selectedProductOptionValueIds}
        />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => bulkCreatePriceListItemOptionValues(bulkCreatePriceListItemOptionValuesPayload)}
          size="large"
        >
          {saveEnabled ? 'Add Values' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <BulkCreatePriceListItemOptionValuesModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
