import { capitalizeString } from '@campaignhub/javascript-utils'

import {
  Box,
  DashboardModule,
  Image,
  Text,
} from '@campaignhub/suit-theme'

import { generateLogoUrl } from '@functions/adAccount'

import type { AdAccountLinkModel } from '@models/types'

import AdAccountListItem from './AdAccountListItem'

type CategoryTitle = 'facebook' | 'google'

interface CustomHeaderProps {
  categoryTitle: CategoryTitle,
}

const CustomHeader = (props: CustomHeaderProps) => {
  const { categoryTitle } = props

  const logoUrl = generateLogoUrl(categoryTitle)

  return (
    <Box borderBottom="1px solid" borderColor="lineColor">
      <Box borderRight="1px solid" borderColor="lineColor" padding="large" width="fit-content">
        <Image backgroundSize="contain" height="32px" width="120px" url={logoUrl} />
      </Box>

      <Box flexDirection="column" justifyContent="center" padding="large">
        <Text fontSize="small">{capitalizeString(categoryTitle)}</Text>
      </Box>
    </Box>
  )
}

interface AdAccountsModuleProps {
  adAccountLinks: AdAccountLinkModel[],
  categoryTitle: CategoryTitle,
}

const AdAccountsModule = (props: AdAccountsModuleProps) => {
  const {
    adAccountLinks,
    categoryTitle,
  } = props

  return (
    <DashboardModule customHeader={<CustomHeader categoryTitle={categoryTitle} />}>
      <Box flexDirection="column">
        {adAccountLinks.map((adAccountLink) => (
          <AdAccountListItem adAccountLink={adAccountLink} key={adAccountLink.id} />
        ))}
      </Box>
    </DashboardModule>
  )
}

export default AdAccountsModule