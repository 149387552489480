import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ArtworkRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['artworks']

type ArtworkFilters = {
  campaignId?: number,
}

type UseArtworksOptions = {
  filters?: ArtworkFilters,
  performHttpRequests?: boolean,
  requestOptions?: ArtworkRequestOptions,
}

function useArtworks(options: UseArtworksOptions) {
  const { filters = {}, requestOptions } = options
  const {
    campaignId: filterCampaignId,
  } = filters

  const {
    updatedEntities: { artworks: artworksUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { artworks } = useSelector(reduxState => reduxState.entities)

  const filteredArtworks = useMemo(() => {
    const filtered = Object.values(artworks).filter((artwork) => {
      const { campaignId } = artwork

      const matchCampaignId = matchFilterNumber(campaignId, filterCampaignId)

      return matchCampaignId
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [artworksUpdatedAt, JSON.stringify(filters)])

  const filteredArtworksCount = filteredArtworks.length
  const hasFilteredArtworks = !!filteredArtworksCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredArtworksCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingArtworks } = useReduxAction(
    'artworks',
    'loadArtworks',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredArtworks,
    filteredArtworksCount,
    hasFilteredArtworks,
    loading: loadingArtworks,
  }
}

export default useArtworks
