import { useContext } from 'react'

import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import { DashboardModuleListItem, Text } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { confirmDelete } from '@functions/entity'

import { ClientUserModel } from '@models/types'

import useClientUser from '@hooks/useClientUser'
import { generateUrls as generateClientUrls } from '@hooks/useClient'
import { useRelations as useClientRelations } from '@hooks/useClient'
import { generateUrls as generateUserUrls, useRelations } from '@hooks/useUser'

interface ClientUserListItemProps {
  clientUser: ClientUserModel,
  isShowingUsers: boolean,
}

const ClientUserListItem = (props: ClientUserListItemProps) => {
  const { clientUser, isShowingUsers } = props

  const clientUserPayload = useClientUser(clientUser)

  const {
    callbacks: {
      deleteClientUser: deleteFn,
    },
    client,
    client: {
      name,
    },
    user,
    user: {
      email,
      firstName,
      lastName,
      mobileNumber,
    },
  } = clientUserPayload

  const { userType } = useRelations(user)

  const { editClientUrl } = generateClientUrls(client)
  const { editUserUrl } = generateUserUrls(user)

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext
  const { deleteClientUser } = callbacks || {}

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deleteClientUser,
      deleteFn,
    },
    strings: {
      entityName: isShowingUsers ? 'User' : 'Organisation',
    },
  }

  const { brand } = useClientRelations(client)

  return (
    <DashboardModuleListItem
      secondaryText={
        isShowingUsers
          ? [email, mobileNumber].filter(Boolean).join(' | ')
          : brand.name
      }
      title={isShowingUsers ? `${firstName} ${lastName}` : name}
    >
      {isShowingUsers && <Text color="bodyFontLightColor">{userType?.name}</Text>}

      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon
          boxProps={{ marginLeft: 'medium' }}
          onClick={() => confirmDelete(confirmDeletePayload)}
          type="delete"
        />

        <DashboardModuleListItem.Icon
          boxProps={{ marginLeft: 'medium' }}
          href={`${isShowingUsers ? editUserUrl : editClientUrl}?redirect=${generateRedirectUrl()}`}
          type="link"
        />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default ClientUserListItem