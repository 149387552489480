import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import type { AssetFilters } from '@hooks/useAssets'
import type { CampaignUserFilters } from '@hooks/useCampaignUsers'

import EditDocumentModal from '@modals/EditDocumentModal'
import CreateOrEditImageModal from '@modals/CreateOrEditImageModal'
import ManageCampaignUsersModal from '@modals/ManageCampaignUsersModal'
import ManageDocumentsModal from '@modals/ManageDocumentsModal'
import ManageMediaModal from '@modals/ManageMediaModal'

import type { AssetModel } from '@models/types'

import PageContent from './components/PageContent'

const defaultState = {
  showEditDocumentModal: false,
  showCreateOrEditImageModal: false,
  showManageCampaignUsersModal: false,
  showManageDocumentsModal: false,
  showManageMediaModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

// eslint-disable-next-line
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    EditDocumentModal: {
      closeModal: () => setState({ showEditDocumentModal: false }),
      deleteDocument: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateDocument: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    CreateOrEditImageModal: {
      closeModal: () => setState({ showCreateOrEditImageModal: false }),
      deleteAsset: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateAsset: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    ManageCampaignUsersModal: {
      bulkCreateCampaignUsers: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showManageCampaignUsersModal: false }),
    },
    ManageDocumentsModal: {
      closeModal: () => setState({ showManageDocumentsModal: false }),
      deleteDocument: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateDocument: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    ManageMediaModal: {
      closeModal: () => setState({ showManageMediaModal: false }),
      saveSortOrder: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateCampaign: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

interface DetailsProps {
  campaignId: number,
}

const Details = (props: DetailsProps) => {
  const { campaignId } = props

  const [state, setState] = useSetState(defaultState)
  const {
    showEditDocumentModal,
    showCreateOrEditImageModal,
    showManageCampaignUsersModal,
    showManageDocumentsModal,
    showManageMediaModal,
  } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showEditDocumentModal: (payload: { asset: AssetModel }) => {
        setModalData('EditDocumentModal', payload)
        setState({ showEditDocumentModal: true })
      },
      showCreateOrEditImageModal: (payload: { asset: AssetModel }) => {
        setModalData('CreateOrEditImageModal', payload)
        setState({ showCreateOrEditImageModal: true })
      },
      showManageCampaignUsersModal: (payload: { filters: CampaignUserFilters }) => {
        setModalData('ManageCampaignUsersModal', payload)
        setState({ showManageCampaignUsersModal: true })
      },
      showManageDocumentsModal: (payload: { filters: AssetFilters }) => {
        setModalData('ManageDocumentsModal', payload)
        setState({ showManageDocumentsModal: true })
      },
      showManageMediaModal: (payload: { filters: AssetFilters }) => {
        setModalData('ManageMediaModal', payload)
        setState({ showManageMediaModal: true })
      },
    },
  }), [modalContext])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent campaignId={campaignId} />

        <EditDocumentModal
          callbacks={callbacks('EditDocumentModal', setState)}
          showModal={showEditDocumentModal}
        />

        <CreateOrEditImageModal
          callbacks={callbacks('CreateOrEditImageModal', setState)}
          showModal={showCreateOrEditImageModal}
        />

        <ManageCampaignUsersModal
          callbacks={callbacks('ManageCampaignUsersModal', setState)}
          showModal={showManageCampaignUsersModal}
        />

        <ManageDocumentsModal
          callbacks={callbacks('ManageDocumentsModal', setState)}
          showModal={showManageDocumentsModal}
        />

        <ManageMediaModal
          callbacks={callbacks('ManageMediaModal', setState)}
          showModal={showManageMediaModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details
