import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import getAssetEntity from '@functions/asset'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { AssetModel } from '@models/types'

import type { EntityOptions, ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['assets']

export type AssetFilters = {
  assetType?: string,
  assetTypeCategory?: string,
  assetableId?: number,
  assetableType?: string,
}

export type useAssetsOptions = {
  filters?: AssetFilters,
  performHttpRequests?: boolean,
  requestOptions?: EntityOptions,
}

const useAssets = (options: useAssetsOptions = {}) => {
  const { filters, requestOptions } = options
  const {
    assetTypeCategory: filterAssetTypeCategory,
    assetableId: filterAssetableId,
    assetableType: filterAssetableType,
  } = filters || {}

  const {
    updatedEntities: { assets: assetsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets } = entities || {}

  const assetableEntity = getAssetEntity(filters, entities)
  const { assetGroupId: filterAssetGroupId } = assetableEntity

  const filteredAssets = useMemo(() => {
    const filtered = Object.values(assets).filter((asset: AssetModel) => {
      const { assetGroupId, category } = asset

      const matchAssetGroup = filterAssetableId && filterAssetableType
        ? assetGroupId === filterAssetGroupId
        : true

      const matchAssetType = matchFilterString(category, filterAssetTypeCategory)

      return matchAssetGroup && matchAssetType
    })

    return sortArrayBy(filtered, 'asc', ({ sort }) => (sort || Infinity))
  }, [assetsUpdatedAt, JSON.stringify(filters)])

  const filteredAssetsCount = filteredAssets.length
  const hasFilteredAssets = !!filteredAssetsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredAssetsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingAssets } = useReduxAction(
    'assets',
    'loadAssets',
    {
      ...filters,
      ...requestOptions,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { loading, errors } = entityReducer
        return performHttpRequests && !loading && !errors?.length
      },
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAssets,
    filteredAssetsCount,
    hasFilteredAssets,
    loading: loadingAssets,
  }
}

export default useAssets
