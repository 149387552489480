import {
  Box, Button, DashboardModule, Form,
} from '@campaignhub/suit-theme'

import handleCallbackAction from '@functions/handleCallbackAction'

import usePriceListItem, { usePriceListItemForm, useRelations } from '@hooks/usePriceListItem'

import type { PriceListItemModel } from '@models/types'

interface PriceListItemDetailsProps {
  priceListItem: PriceListItemModel,
}

const PriceListItemDetails = (props: PriceListItemDetailsProps) => {
  const { priceListItem } = props

  const {
    callbacks: {
      patchPriceListItem: patchFn,
    },
    updating,
  } = usePriceListItem(priceListItem)

  const {
    entityState,
    entityState: {
      descriptionOverride,
      nameOverride,
      price,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = usePriceListItemForm(priceListItem, { validateOn: 'change' })

  const {
    product: {
      basePrice,
      description,
      name,
    },
  } = useRelations(priceListItem)

  const patchPriceListItemPayload = {
    callbacks: {
      action: patchFn,
    },
    entityParams: entityState,
    toastText: 'Product Updated',
  }

  return (
    <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title="Product Details">
      <Box flexDirection="column" padding="large">
        <Form.Row style={{ display: 'flex' }}>
          <Form.Field label="Name" style={{ flex: 2 }}>
            <input
              name="nameOverride"
              type="text"
              value={nameOverride || name}
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Cost Price ($)" style={{ flex: 1 }}>
            <input disabled value={basePrice} />
          </Form.Field>

          <Form.Field errorMessage={errors.price} label="Client Price ($)" style={{ flex: 1 }}>
            <input
              // EntityState set manually to override empty string with null
              onChange={e => setEntityState({ price: Number(e.target.value) || null })}
              type="number"
              value={price}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="Description">
            <textarea
              name="descriptionOverride"
              style={{ height: 120, resize: 'vertical' }}
              value={descriptionOverride || description}
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Box>

      <DashboardModule.Footer>
        <DashboardModule.FooterRight>
          <Button
            buttonStyle="primaryCreate"
            disabled={!saveEnabled}
            loading={updating}
            loadingBubbleColor="white"
            onClick={() => handleCallbackAction(patchPriceListItemPayload)}
            size="small"
          >
            Save Product
          </Button>
        </DashboardModule.FooterRight>
      </DashboardModule.Footer>
    </DashboardModule>
  )
}

export default PriceListItemDetails
