import { Box, Button, DashboardModule } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlus } from '@fortawesome/pro-light-svg-icons'

import useCutterProfileModule from '@hooks/useCutterProfileModule'
import useCutterProfileModules from '@hooks/useCutterProfileModules'

import CutterProfileModuleListItem from './components/CutterProfileModuleListItem'

interface CutterProfileModulesProps {
  cutterProfileId: number,
}

const CutterProfileModules = (props: CutterProfileModulesProps) => {
  const { cutterProfileId } = props

  const initCutterProfileModule = { cutterProfileId }

  const {
    callbacks: {
      createOrEditCutterProfileModule,
    },
  } = useCutterProfileModule(initCutterProfileModule)

  const cutterProfileModulesPayload = useCutterProfileModules({
    filters: {
      cutterProfileId,
    },
    performHttpRequests: true,
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCutterProfileModules,
    filteredCutterProfileModulesCount,
    hasFilteredCutterProfileModules,
    loading,
  } = cutterProfileModulesPayload

  return (
    <DashboardModule title="Modules" loading={loading}>
      <Box flexDirection="column">
        <DashboardModule.ScrollableSection flexDirection="column">
          {!loading && !hasFilteredCutterProfileModules && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>
                No Modules present
              </DashboardModule.BlankState.Title>

              <DashboardModule.BlankState.Paragraph>
                Please add some Artwork Templates
              </DashboardModule.BlankState.Paragraph>
            </DashboardModule.BlankState>
          )}

          {!loading && hasFilteredCutterProfileModules && (
            filteredCutterProfileModules.map(cutterProfileModule => (
              <CutterProfileModuleListItem
                cutterProfileModule={cutterProfileModule}
                key={cutterProfileModule.id}
              />
            ))
          )}
        </DashboardModule.ScrollableSection>

        <DashboardModule.LoadMoreFooter
          boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
          callbacks={{ loadMore }}
          canLoadMore={canLoadMore}
          entityCount={filteredCutterProfileModulesCount}
          loading={loading}
        >
          <DashboardModule.FooterRight>
            <Button
              buttonStyle="secondaryEdit"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => createOrEditCutterProfileModule()}
              size={['medium', 'small']}
              width={['100%', 'fit-content']}
            >
              Add Module
            </Button>
          </DashboardModule.FooterRight>
        </DashboardModule.LoadMoreFooter>
      </Box>
    </DashboardModule>
  )
}

export default CutterProfileModules
