import { useState } from 'react'

import { Button, Form, SidebarModal } from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useClient from '@hooks/useClient'

const MODAL_KEY = 'ImportClientModal'

type ModalCallbacks = {
  closeModal: () => void,
  importClient: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const ImportClientModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, importClient } = callbacks || {}

  const [realbaseId, setRealbaseId] = useState('')

  const {
    callbacks: {
      importClient: importFn,
    },
    creating,
  } = useClient()

  const importClientPayload = {
    callbacks: {
      action: () => importFn(Number(realbaseId)),
      afterAction: closeModal,
    },
    shouldRedirect: true,
    toastText: 'Organisation Imported',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Import" titleSecondLine="Organisation" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* Realbase ID">
            <input
              onChange={e => setRealbaseId(e.target.value)}
              type="number"
              value={realbaseId}
            />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!realbaseId}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => importClient(importClientPayload)}
          size="large"
        >
          {!!realbaseId ? 'Import Organisation' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ImportClientModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
