import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSave, faSignOut, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import {
  Box, Button, Form, Text, ToggleButton, Link,
} from '@campaignhub/suit-theme'

import { confirmAction, confirmDelete } from '@functions/entity'
import handleCallbackAction from '@functions/handleCallbackAction'

import usePriceListItemOptionValue,
{
  usePriceListItemOptionValueForm,
  useRelations as usePriceListItemOptionValueRelations,
} from '@hooks/usePriceListItemOptionValue'
import { useRelations as useProductOptionValueRelations } from '@hooks/useProductOptionValue'
import { generateUrls } from '@hooks/useProductOption'

import type { PriceListItemOptionValueModel } from '@models/types'

interface PriceListItemOptionValueListItemProps {
  isFirst: boolean,
  priceListItemOptionValue: PriceListItemOptionValueModel,
}

const PriceListItemOptionValueListItem = (props: PriceListItemOptionValueListItemProps) => {
  const { isFirst, priceListItemOptionValue } = props

  const priceListItemOptionValueFormPayload = usePriceListItemOptionValueForm(
    priceListItemOptionValue,
    { validateOn: 'change' },
  )

  const {
    entityState,
    entityState: {
      nameOverride,
      price,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = priceListItemOptionValueFormPayload

  const {
    callbacks: {
      deletePriceListItemOptionValue: deleteFn,
      setDefaultPriceListItemOptionValue: setDefaultFn,
      updatePriceListItemOptionValue: updateFn,
    },
    priceListItemOptionValue: {
      isDefault,
    },
  } = usePriceListItemOptionValue(priceListItemOptionValue)

  const {
    productOptionValue,
    productOptionValue: {
      costPrice,
      name,
    },
  } = usePriceListItemOptionValueRelations(priceListItemOptionValue)
  const { productOption } = useProductOptionValueRelations(productOptionValue)

  const { editProductOptionUrl } = generateUrls(productOption)
  const urlWithRedirect = `${editProductOptionUrl}?redirect=${generateRedirectUrl()}`

  const updateProductOptionValuePayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'Value Updated',
  }

  const actionString = isDefault ? 'Unset' : 'Set'
  const setDefaultPriceListItemOptionValuePayload = {
    callbacks: {
      actionFn: setDefaultFn,
    },
    strings: {
      actionString,
      entityName: 'Default Value',
      toastString: actionString,
    },
  }

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: handleCallbackAction,
      deleteFn,
    },
    strings: {
      entityName: 'Value',
    },
  }

  return (
    <Form boxProps={{ paddingY: 'small' }}>
      <Form.Row style={{ display: 'flex', alignItems: 'end' }}>
        <Form.Field label={isFirst ? 'Name' : ''} style={{ flex: 4 }}>
          <input
            name="nameOverride"
            type="text"
            value={nameOverride || name}
            {...handlers}
          />
        </Form.Field>

        <Form.Field label={isFirst ? 'Cost Price ($)' : ''} style={{ flex: 1 }}>
          <input disabled value={costPrice} />
        </Form.Field>

        <Form.Field
          errorMessage={errors.price}
          hideErrorMessage
          label={isFirst ? 'Client Price ($)' : ''}
          style={{ flex: 1 }}
        >
          <input
            onChange={e => setEntityState({ price: Number(e.target.value) || null })}
            type="number"
            value={price}
          />
        </Form.Field>

        <Box
          flexDirection="column"
          style={{ width: '37px' }}
        >
          {isFirst && (
            <Text
              color="bodyFontLightColor"
              fontSize="xsmall"
              letterSpacing="1px"
              marginBottom="small"
            >
              Default
            </Text>
          )}

          <ToggleButton
            boxProps={{
              height: '37px', width: '37px',
            }}
            onClick={() => confirmAction(setDefaultPriceListItemOptionValuePayload)}
            toggleOn={isDefault}
            toggleOffText=""
            toggleOnText=""
          />
        </Box>

        <Button
          buttonStyle="secondaryCreate"
          disabled={!saveEnabled}
          onClick={() => handleCallbackAction(updateProductOptionValuePayload)}
          style={{
            border: '1px solid lightgray', borderRadius: '4px', height: '37px', width: '37px',
          }}
        >
          <FontAwesomeIcon icon={faSave} />
        </Button>

        <Button
          buttonStyle="secondaryDestroy"
          onClick={() => confirmDelete(confirmDeletePayload)}
          style={{
            border: '1px solid lightgray', borderRadius: '4px', height: '37px', width: '37px',
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>

        <Box
          alignItems="center"
          height={37}
          justifyContent="center"
          width={20}
        >
          <Link
            greyLink
            href={urlWithRedirect}
            textProps={{ fontSize: 'medium', height: 'fit-content' }}
          >
            <FontAwesomeIcon icon={faSignOut} />
          </Link>
        </Box>
      </Form.Row>
    </Form>
  )
}

export default PriceListItemOptionValueListItem
