import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'
import type { UseFiltersPayload } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import type { BrandModel } from '@models/types'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    showImportBrandModal: () => void,
  },
}

const defaultState = {
  showImportBrandModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    ImportBrandModal: {
      closeModal: () => setState({ showImportBrandModal: false }),
      importBrand: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const List = () => {
  const [state, setState] = useSetState(defaultState)
  const { showImportBrandModal } = state

  useLocation()

  const { isArchived } = parsePermittedQueryParams(getQueryParamsFromHash(), ['isArchived'])
  const initDefaultFilters = isArchived ? { isArchived } : {}

  const filtersPayload = useFilters({
    defaultFilters: initDefaultFilters,
    persistFilters: true,
  })

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showImportBrandModal: (payload: { brand: BrandModel }) => {
        setModalData('ImportBrandModal', payload)
        setState({ showImportBrandModal: true })
      },
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt]) // modalContext dependency required if layering modals

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default List
