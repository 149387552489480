import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { useFilters } from '@campaignhub/react-hooks'

import {
  Box, Button, DashboardModule,
} from '@campaignhub/suit-theme'

import DashboardModuleFilters from '@components/DashboardModuleFilters'

import useClientPriceList from '@hooks/useClientPriceList'
import useClientPriceLists from '@hooks/useClientPriceLists'

import ClientPriceListListItem from './ClientPriceListListItem'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'priceListName',
      label: 'Filter by Name',
    },
  ]
}

interface ClientPriceListsProps {
  clientId: number,
}

const ClientPriceLists = (props: ClientPriceListsProps) => {
  const { clientId } = props

  const filterPayload = useFilters()

  const {
    pageFilters,
  } = filterPayload

  const options = {
    filters: { clientId, ...pageFilters },
    performHttpRequests: !!clientId,
    requestOptions: {
      include: 'PriceList',
    },
    sortKey: 'priceList',
  }

  const clientPriceListsPayload = useClientPriceLists(options)

  const {
    callbacks: {
      launchBulkCreateClientPriceListsModal,
    },
  } = useClientPriceList({ clientId })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredClientPriceLists,
    filteredClientPriceListsCount,
    hasFilteredClientPriceLists,
    loading,
  } = clientPriceListsPayload

  return (
    <DashboardModule
      boxProps={{ borderBottom: loading ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={<DashboardModule.FilterHeader {...filterPayload} />}
      loading={loading}
      title="Price Lists"
    >
      <DashboardModuleFilters
        filterFields={buildPageFilterFields()}
        {...filterPayload}
      />

      <Box flexDirection="column">
        <DashboardModule.ScrollableSection flexDirection="column">
          {!loading && !hasFilteredClientPriceLists && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>
                No Price Lists present
              </DashboardModule.BlankState.Title>

              <DashboardModule.BlankState.Paragraph>
                Please adjust your filters or link a Price List
              </DashboardModule.BlankState.Paragraph>
            </DashboardModule.BlankState>
          )}

          {!loading && filteredClientPriceLists.map(clientPriceList => (
            <ClientPriceListListItem
              key={clientPriceList.id}
              clientPriceList={clientPriceList}
            />
          ))}
        </DashboardModule.ScrollableSection>

        <DashboardModule.LoadMoreFooter
          boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
          callbacks={{ loadMore }}
          canLoadMore={canLoadMore}
          entityCount={filteredClientPriceListsCount}
          loading={loading}
        >
          <Button
            buttonStyle="secondaryEdit"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => launchBulkCreateClientPriceListsModal()}
            size={['medium', 'small']}
            width={['100%', 'fit-content']}
          >
            Assign Price Lists
          </Button>
        </DashboardModule.LoadMoreFooter>
      </Box>
    </DashboardModule>
  )
}

export default ClientPriceLists
