import {
  DashboardModule, ModuleBlankState,
} from '@campaignhub/suit-theme'

import bullseye from '@assets/bullseye.svg'

import useCampaigns from '@hooks/useCampaigns'
import type { CampaignFilters } from '@hooks/useCampaigns'

import OrderValidationTable from './OrderValidationTable'

interface OrdersProps {
  pageFilters: CampaignFilters,
}

const OrderValidation = (props: OrdersProps) => {
  const { pageFilters } = props

  const campaignsPayload = useCampaigns({
    filters: {
      ...pageFilters,
    },
    hidePassedValidations: true,
    performHttpRequests: true,
    requestOptions: {
      include: [
        'CampaignValidations',
        'CampaignValidationSummary',
      ],
    },
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCampaignsCount,
    hasFilteredCampaigns,
    loading,
  } = campaignsPayload

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Order Validation"
    >
      {!loading && !hasFilteredCampaigns && (
        <ModuleBlankState boxProps={{ height: 200 }} imageUrl={bullseye}>
          <ModuleBlankState.Title>No Validation Issues Found</ModuleBlankState.Title>

          <ModuleBlankState.Paragraph>
            There are currently no orders that have failed validation
          </ModuleBlankState.Paragraph>
        </ModuleBlankState>
      )}

      {!loading && hasFilteredCampaigns && (
        <OrderValidationTable campaignsPayload={campaignsPayload} />
      )}

      <DashboardModule.LoadMoreFooter
        boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
        callbacks={{ loadMore }}
        canLoadMore={canLoadMore}
        entityCount={filteredCampaignsCount}
        loading={loading}
      />
    </DashboardModule>
  )
}

export default OrderValidation
