import { useContext } from 'react'

import {
  Button, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

const MODAL_KEY = 'SelectedPlaybooksModal'

type ModalCallbacks = {
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const SelectedPlaybooksModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const adQueue = digObject(modalPayload, 'adQueue', {})

  const { selectedPlaybooks } = adQueue

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Selected" titleSecondLine="Playbooks" />

      <SidebarModal.Content>
        <Text color="bodyFontLightColor" fontSize="small">Audiences</Text>

        {selectedPlaybooks.map((playbook: string) => (
          <Text key={playbook} marginTop="large">{playbook}</Text>
        ))}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => closeModal()}
          size="large"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <SelectedPlaybooksModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
