import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'
import type { UseFiltersPayload } from '@campaignhub/react-hooks'

import { MainContent, ModalContext, TopBar } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useMainNavigation from '@hooks/useMainNavigation'

import type { AdCampaignModel, AdGroupModel } from '@models/types'

import AdGroupModal from '@modals/AdGroupModal'
import AdSetDetailsModal from '@modals/AdSetDetailsModal'
import LocationTargetingModal from '@modals/LocationTargetingModal'
import ManageAdCampaignModal from '@modals/ManageAdCampaignModal'

import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Admin/components/MainNavigation'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    showAdGroupsModal: (payload: { adCampaign: AdCampaignModel }) => void,
    showAdSetDetailsModal: (payload: { adGroup: AdGroupModel }) => void,
    showLocationTargetingModal: (payload: { adCampaign: AdCampaignModel }) => void,
    showManageAdCampaignModal: (payload: { adCampaign: AdCampaignModel }) => void,
  },
}

const defaultState = {
  showAdGroupModal: false,
  showAdSetDetailsModal: false,
  showLocationTargetingModal: false,
  showManageAdCampaignModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    AdGroupModal: {
      closeModal: () => setState({ showAdGroupModal: false }),
    },
    AdSetDetailsModal: {
      closeModal: () => setState({ showAdSetDetailsModal: false }),
    },
    LocationTargetingModal: {
      closeModal: () => setState({ showLocationTargetingModal: false }),
    },
    ManageAdCampaignModal: {
      closeModal: () => setState({ showManageAdCampaignModal: false }),
      deleteAdCampaign: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      duplicateAdCampaign: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      syncAdCampaign: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateAdCampaign: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateAdCampaignStatus: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const AdLoader = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  const [state, setState] = useSetState(defaultState)
  const {
    showAdGroupModal,
    showAdSetDetailsModal,
    showLocationTargetingModal,
    showManageAdCampaignModal,
  } = state

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  // adType filter in url does not work without this
  useLocation()

  const { adType }: { adType?: string } = parsePermittedQueryParams(getQueryParamsFromHash(), ['adType'])

  const initDefaultFilters = { deploymentStack: 'v2' }
  if (adType) initDefaultFilters.adType = adType

  const filtersPayload = useFilters({
    defaultFilters: initDefaultFilters,
    persistFilters: true,
  })

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showAdGroupModal: (payload: { adCampaign: AdCampaignModel }) => {
        setModalData('AdGroupModal', payload)
        setState({ showAdGroupModal: true })
      },
      showAdSetDetailsModal: (payload: { adGroup: AdGroupModel }) => {
        setModalData('AdSetDetailsModal', payload)
        setState({ showAdSetDetailsModal: true })
      },
      showLocationTargetingModal: (payload: { adCampaign: AdCampaignModel }) => {
        setModalData('LocationTargetingModal', payload)
        setState({ showLocationTargetingModal: true })
      },
      showManageAdCampaignModal: (payload: { adCampaign: AdCampaignModel }) => {
        setModalData('ManageAdCampaignModal', payload)
        setState({ showManageAdCampaignModal: true })
      },
      ...filterCallbacks,
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt, modalContext])

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<TopBar.Search callbacks={{ search: null }} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <PageContext.Provider value={pageContext}>
          <ModalContext.Provider value={modalContext}>
            <PageContent pageFilters={pageFilters} />

            <AdGroupModal
              callbacks={callbacks('AdGroupModal', setState)}
              showModal={showAdGroupModal}
            />

            <AdSetDetailsModal
              callbacks={callbacks('AdSetDetailsModal', setState)}
              showModal={showAdSetDetailsModal}
            />

            <LocationTargetingModal
              callbacks={callbacks('LocationTargetingModal', setState)}
              showModal={showLocationTargetingModal}
            />

            <ManageAdCampaignModal
              callbacks={callbacks('ManageAdCampaignModal', setState)}
              showModal={showManageAdCampaignModal}
            />
          </ModalContext.Provider>
        </PageContext.Provider>
      </MainContent>
    </>
  )
}

export default AdLoader
