import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Button, EntityMultiSelect, Form, ModalContext, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useBulkCreateAdTemplateProducts from './hooks/useBulkCreateAdTemplateProducts'

const MODAL_KEY = 'BulkCreateAdTemplateProductsModal'

type ModalCallbacks = {
  closeModal: () => void,
  bulkCreateAdTemplateProducts: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const BulkCreateAdTemplateProductsModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const {
    bulkCreateAdTemplateProducts,
    closeModal,
  } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const adTemplateProduct = digObject(modalPayload, 'adTemplateProduct', {})

  const {
    adTemplates,
    adTemplatesPayload: {
      callbacks: {
        loadMore,
      },
      canLoadMore,
      loading,
    },
    callbacks: {
      bulkCreateFn,
      setSelectedEntityIds,
      setState,
    },
    creating,
    entityParams,
    filteredAdTemplateIds,
    filteredAdTemplateIdsCount,
    name,
    saveEnabled,
    selectedEntityIds,
  } = useBulkCreateAdTemplateProducts(adTemplateProduct)

  const entityText = adTemplateProduct.adTemplateId ? 'Products' : 'Ad Templates'

  const bulkCreateAdTemplateProductsPayload = {
    callbacks: {
      action: bulkCreateFn,
      afterAction: closeModal,
    },
    entityParams,
    toastText: `${entityText} Assigned`,
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Assign" titleSecondLine={entityText} />

      <SidebarModal.Content>
        <Form.Field label="Search Ad Templates">
          <input onChange={e => setState({ name: e.target.value })} value={name} />
        </Form.Field>

        <SectionDivider>Multi Select Values</SectionDivider>

        <EntityMultiSelect
          boxProps={{ marginTop: 'large' }}
          callbacks={{
            loadMore,
            setSelectedIds: setSelectedEntityIds,
          }}
          canLoadMore={canLoadMore}
          entities={adTemplates}
          entityCount={filteredAdTemplateIdsCount}
          entityTitleKey="name"
          filteredEntityIds={filteredAdTemplateIds}
          loading={loading}
          selectedIds={selectedEntityIds}
        />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => bulkCreateAdTemplateProducts(bulkCreateAdTemplateProductsPayload)}
          size="large"
        >
          {saveEnabled ? `Assign ${entityText}` : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <BulkCreateAdTemplateProductsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
