import { useContext } from 'react'

import { faTachometer } from '@fortawesome/pro-light-svg-icons'

import { Box, Columns } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import type { CampaignFilters } from '@hooks/useCampaigns'

import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import OrderValidation from '../OrderValidation'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }
  const boxProps = { marginBottom: 'large' }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by campaign name or AIM#',
        style,
      },
      key: 'string',
      label: 'Search',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'validationStatus',
      label: 'Status',
      values: [
        // Success intentionally excluded
        { key: 'Warning', label: 'Warning' },
        { key: 'Error', label: 'Error' },
      ],
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'clients',
        entityTitleKey: 'name',
      },
      key: 'clientId',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        style,
      },
      key: 'startDate',
      label: 'Start Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        style,
      },
      key: 'endDate',
      label: 'End Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'Latest First',
        inputComponent: 'select',
        style,
      },
      key: 'sortBy',
      label: 'Sort By',
      values: [
        { key: 'asc', label: 'Oldest First' },
      ],
    },
  ]
}

interface PageContentProps {
  pageFilters: CampaignFilters,
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  return (
    <>
      <PageHeader
        activeTabBarItemKey="order"
        tabBarItems={[
          {
            href: '#/validator',
            icon: faTachometer,
            key: 'order',
            title: 'All Orders',
          },
        ]}
        title="Validator"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <OrderValidation pageFilters={pageFilters} />
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields()}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
