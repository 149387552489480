import { faIdCard, faList, faToggleLargeOn } from '@fortawesome/pro-light-svg-icons'

import { PriceListModel } from '@models/priceList'

import { generateUrls } from '@hooks/usePriceList'

const generateTabBarItems = (priceList: PriceListModel) => {
  const urls = generateUrls(priceList)
  const { editPriceListItemsUrl, editPriceListUrl, editPriceListRelationsUrl } = urls

  return [
    {
      icon: faIdCard,
      href: editPriceListUrl,
      key: 'details',
      title: priceList?.name?.slice(0, 50) || 'Price List Details',
    },
    {
      icon: faList,
      href: editPriceListItemsUrl,
      key: 'items',
      title: 'Price List Items',
    },
    {
      icon: faToggleLargeOn,
      href: editPriceListRelationsUrl,
      key: 'relations',
      title: 'Relations',
    },
  ]
}

export default generateTabBarItems
