import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ProductOptionProductRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['productOptionProducts']

type ProductOptionProductFilters = {
  productId?: number,
  productName?: string,
  productOptionId?: number,
  productOptionName?: string,
}

type UseProductOptionProductsOptions = {
  filters?: ProductOptionProductFilters,
  performHttpRequests?: boolean,
  requestOptions?: ProductOptionProductRequestOptions,
}

function useProductOptionProducts(options: UseProductOptionProductsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    productId: filterProductId,
    productName: filterProductName,
    productOptionId: filtedProductOptionId,
    productOptionName: filterProductOptionName,
  } = filters

  const {
    updatedEntities: { productOptionProducts: productOptionProductsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { products, productOptionProducts, productOptions } = useSelector(reduxState => reduxState.entities)

  const filteredProductOptionProducts = useMemo(() => {
    const filtered = Object.values(productOptionProducts).filter((productOptionProduct) => {
      const { productId, productOptionId } = productOptionProduct

      const productName = products[productId]?.name
      const productOptionName = productOptions[productOptionId]?.name

      const matchProductId = matchFilterNumber(productId, filterProductId)
      const matchProductOptionId = matchFilterNumber(productOptionId, filtedProductOptionId)
      
      const matchProductName = matchFilterString(productName, filterProductName)
      const matchProductOptionName = matchFilterString(productOptionName, filterProductOptionName)

      return matchProductId && matchProductOptionId && matchProductName && matchProductOptionName
    })

    return sortArrayBy(filtered, 'asc', ({ productOptionId }) => productOptions[productOptionId]?.name)
  }, [productOptionProductsUpdatedAt, JSON.stringify(filters)])

  const filteredProductOptionProductsCount = filteredProductOptionProducts.length
  const hasFilteredProductOptionProducts = !!filteredProductOptionProductsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredProductOptionProductsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingProductOptionProducts } = useReduxAction(
    'productOptionProducts',
    'loadProductOptionProducts',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredProductOptionProducts,
    filteredProductOptionProductsCount,
    hasFilteredProductOptionProducts,
    loading: loadingProductOptionProducts,
  }
}

export default useProductOptionProducts
