import { Route, Routes } from 'react-router-dom'

import ProductEdit from './Edit'
import ProductsList from './List'

const ProductsRoutes = () => (
  <Routes>
    <Route path="/" element={<ProductsList />} />
    <Route path="/:productId/edit/*" element={<ProductEdit />} />
  </Routes>
)

export default ProductsRoutes
