import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ArtworkTemplateGroupRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['artworkTemplateGroups']

type ArtworkTemplateGroupFilters = {
  string?: string,
}

type UseArtworkTemplateGroupsOptions = {
  filters?: ArtworkTemplateGroupFilters,
  performHttpRequests?: boolean,
  requestOptions?: ArtworkTemplateGroupRequestOptions,
}

function useArtworkTemplateGroups(options: UseArtworkTemplateGroupsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    string: filterString,
  } = filters

  const {
    updatedEntities: { artworkTemplateGroups: artworkTemplateGroupsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { artworkTemplateGroups } = useSelector(reduxState => reduxState.entities)

  const filteredArtworkTemplateGroups = useMemo(() => {
    const filtered = Object.values(artworkTemplateGroups).filter((artworkTemplateGroup) => {
      const { id, name } = artworkTemplateGroup

      const matchId = id.toString() === filterString
      const matchName = matchFilterString(name, filterString)
      const matchString = matchId || matchName

      return matchString
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [artworkTemplateGroupsUpdatedAt, JSON.stringify(filters)])

  const filteredArtworkTemplateGroupsCount = filteredArtworkTemplateGroups.length
  const hasFilteredArtworkTemplateGroups = !!filteredArtworkTemplateGroupsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredArtworkTemplateGroupsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingArtworkTemplateGroups } = useReduxAction(
    'artworkTemplateGroups',
    'loadArtworkTemplateGroups',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredArtworkTemplateGroups,
    filteredArtworkTemplateGroupsCount,
    hasFilteredArtworkTemplateGroups,
    loading: loadingArtworkTemplateGroups,
  }
}

export default useArtworkTemplateGroups
