import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { AdQueueModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/adQueue/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/adQueue/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/adQueue/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/adQueue/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/adQueue/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/adQueue/CREATE_FAILURE'

const DELETE_REQUEST = 'realbase/adQueue/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/adQueue/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/adQueue/DELETE_FAILURE'

export type AdQueueModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
}

// Initial State
const initialState: AdQueueModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loadedIds: [],
  loading: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadAdQueues(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().adQueues.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/ad-queues', options)
      .then(({ data }: { data: { data: AdQueueModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.AD_QUEUE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadAdQueue(adQueueId: number, options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.adQueues.loadedIds]
    const loadedForKeys = [...state.adQueues.loadedForKeys]

    if (adQueueId && !loadedIds.includes(adQueueId)){
      loadedIds.push(adQueueId)
    }

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/ad-queues/${adQueueId}`, options)
      .then(({ data }: { data: AdQueueModel }) => {
        const normalizedJson = normalize(data, Schemas.AD_QUEUE)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function processAdQueue(adQueue: Partial<AdQueueModel>) {
  const config = {
    method: 'POST',
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api(`/ad-queues/${adQueue.id}/async`, {}, config)
      .then(({ data }: { data: AdQueueModel }) => {
        const normalizedJson = normalize(data, Schemas.AD_QUEUE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function bulkProcessAdQueues(adQueueIds: number[]) {
  const config = {
    method: 'POST',
    data: adQueueIds,
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/ad-queues/async/bulk', {}, config)
      .then(({ data }: { data: AdQueueModel }) => {
        const normalizedJson = normalize(data, Schemas.AD_QUEUE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteAdQueue(adQueue: DeleteParams<AdQueueModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/ad-queues/${adQueue.id}`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        const deletedEntity = { id: adQueue.id }
        const normalizedJson = normalize(deletedEntity, Schemas.AD_QUEUE)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export type BulkDeleteParams = {
  adQueueIds: number[]
}

export function bulkDeleteAdQueues(params:BulkDeleteParams) {
  const config = {
    method: 'DELETE',
    data: JSON.stringify(params),
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api('/ad-queues/bulk', {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

          const { adQueueIds } = params 

        adQueueIds.forEach(adQueueId => {
          const deletedEntity = { id: adQueueId }
          const normalizedJson = normalize(deletedEntity, Schemas.AD_QUEUE)
          dispatch(deleteEntity(normalizedJson))
        })

        dispatch(deleteSuccess())

        return { success: true as const }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function bulkDeleteAndRequeueAdQueues(adQueueIds: number[]) {
  const config = {
    method: 'POST',
    data: adQueueIds
  }
  
  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api(`/ad-queues/requeue`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        adQueueIds.forEach((adQueueId) => {
          const deletedEntity = { id: adQueueId }
          const normalizedJson = normalize(deletedEntity, Schemas.AD_QUEUE)
          dispatch(deleteEntity(normalizedJson))
        })

        dispatch(createSuccess())

        return { success: true as const }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: AdQueueModuleState = initialState,
  action: Action = { type: '' },
): AdQueueModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
