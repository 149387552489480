import {
  DashboardModule, Form,
} from '@campaignhub/suit-theme'

import { useClientForm } from '@hooks/useClient'

interface ClientAddressProps {
  clientFormPayload: ReturnType<typeof useClientForm>,
}

const ClientAddress = (props: ClientAddressProps) => {
  const {
    clientFormPayload: {
      entityState: {
        addressLine1,
        addressLine2,
        postCode,
        region,
        state,
      },
    },
  } = props

  return (
    <DashboardModule title="Address">
      <Form padding="large">
        <Form.Row>
          <Form.Field label="* Address Line 1">
            <input
              disabled
              value={addressLine1}
              type="text"
            />
          </Form.Field>

          <Form.Field label="Address Line 2" marginTop={['large', 0]}>
            <input
              disabled
              type="text"
              value={addressLine2}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="Region">
            <input
              disabled
              type="text"
              value={region}
            />
          </Form.Field>

          <Form.Field label="Post Code" marginTop={['large', 0]}>
            <input
              disabled
              type="text"
              value={postCode}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="State">
            <input
              disabled
              value={state}
              type="text"
            />
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default ClientAddress
