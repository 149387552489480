import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, Line, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useAdCampaign, { useRelations } from '@hooks/useAdCampaign'

import CampaignCard from '@components/CampaignCard'

import AdCampaignDetails from './AdCampaignDetails'
import AdCampaignOptions from './AdCampaignOptions'
import AdCampaignTargeting from './AdCampaignTargeting'
import Reporting from './Reporting'
import SpendDetails from './SpendDetails'

const MODAL_KEY = 'ManageAdCampaignModal'

export interface ManageAdCampaignModalProps {
  callbacks: {
    closeModal: () => void,
    deleteAdCampaign: (payload: HandleCallbackActionParams) => Promise<null>,
    duplicateAdCampaign: (payload: HandleCallbackActionParams) => Promise<object>,
    syncAdCampaign: (payload: HandleCallbackActionParams) => Promise<object>,
    updateAdCampaign: (payload: HandleCallbackActionParams) => Promise<object>,
    updateAdCampaignStatus: (payload: HandleCallbackActionParams) => Promise<object>,
  },
  showModal: boolean,
}

const ManageAdCampaignModal = (props: ManageAdCampaignModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { adCampaign: initAdCampaign } = modalPayload

  const adCampaignPayload = useAdCampaign(
    initAdCampaign,
    {
      performHttpRequests: true,
      requestOptions: {
        include: [
          'Campaign.AdTemplate',
          'Insights',
          'AdQueue',
          'PendingGoogleCampaign.AdQueue'
        ]
      },
    },
  )

  const {
    adCampaign,
    callbacks: {
      viewAdGroups,
      viewLocationTargeting,
    },
  } = adCampaignPayload

  const { adChannelTypeId, referenceId } = adCampaign

  const { campaign } = useRelations(adCampaign)

  // TODO: Audience Generation
  const isGoogleAdCampaign = adChannelTypeId === 'Google'

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Campaign" />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <CampaignCard
            campaign={campaign}
            v1CampaignName={adCampaign.adCampaignName.split('|').at(-1)}
            v1ReferenceId={referenceId}
          />

          <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />

          <AdCampaignDetails adCampaign={adCampaign} />

          <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />

          <SpendDetails adCampaign={adCampaign} callbacks={callbacks} />

          <AdCampaignTargeting
            adCampaign={adCampaign}
            callbacks={{
              viewAdGroups,
              viewLocationTargeting,
            }}
          />

          <Reporting adCampaign={adCampaign} />

          {/* {isGoogleAdCampaign && (
            <>
              <Line border="1px dashed" />

              <AudienceGeneration adCampaign={adCampaign} />
            </>
          )} */}

          <AdCampaignOptions adCampaign={adCampaign} callbacks={callbacks} />
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => closeModal()}
          size="large"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ManageAdCampaignModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageAdCampaignModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
