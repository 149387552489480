import { faComments, faHistory } from '@fortawesome/pro-light-svg-icons'

import { EntityActivityContainer } from '@campaignhub/suit-theme'

import Activity from './Activity'
import ConversationContainer from './ConversationContainer'

interface EntityActivityProps {
  subjectId: number,
  subjectType: string,
}

const EntityActivity = (props: EntityActivityProps) => {
  const { subjectId, subjectType } = props

  return (
    <EntityActivityContainer
      boxProps={{
        height: 500,
        marginBottom: 'large',
      }}
      components={{
        activity: {
          icon: faHistory,
          label: 'Activity',
          render: (params: { subjectId: number, subjectType: string }) => <Activity {...params} />,
        },
        conversation: {
          icon: faComments,
          label: 'Conversation',
          render: (params: { subjectId: number, subjectType: string }) => <ConversationContainer {...params} />,
        },
      }}
      defaultSelectedComponentKey="activity"
      subjectId={subjectId}
      subjectType={subjectType}
    />
  )
}

export default EntityActivity
