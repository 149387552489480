import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlaneTop, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import handleCallbackAction from '@functions/handleCallbackAction'

import {
  Box, Button, Form, Line, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { confirmDelete } from '@functions/entity'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useAdQueue, { useRelations } from '@hooks/useAdQueue'

import CampaignCard from '@components/CampaignCard'

import AdQueueDetails from './AdQueueDetails'
import AdQueueTargeting from './AdQueueTargeting'

const MODAL_KEY = 'ManageAdDeploymentModal'

interface ManageAdDeploymentModalProps {
  callbacks: {
    closeModal: () => void,
    deleteAdQueue: (payload: HandleCallbackActionParams) => Promise<object>,
    processAdQueue: (payload: HandleCallbackActionParams) => Promise<object>,
  },
  showModal: boolean,
}

const ManageAdDeploymentModal = (props: ManageAdDeploymentModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, deleteAdQueue, processAdQueue } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { adQueue } = modalPayload

  const { campaign } = useRelations(adQueue)

  const adQueuePayload = useAdQueue(
    adQueue,
    {
      performHttpRequests: true,
      requestOptions: {
        include: 'AdTemplatePayload.AdTemplate',
      },
    },
  )

  const {
    adQueue: {
      status,
    },
    callbacks: {
      bulkDeleteAndRequeueAdQueues, 
      deleteAdQueue: deleteFn,
      processAdQueue: processFn,
      viewSelectedLocations,
      viewSelectedPlaybooks,
    },
    creating,
    deleting,
  } = adQueuePayload

  const confirmDeletePayload = {
    callbacks: {
      afterAction: closeModal,
      deleteEntity: deleteAdQueue,
      deleteFn,
    },
    strings: {
      entityName: 'Ad Campaign',
    },
  }

  const processAdQueuePayload = {
    callbacks: {
      action: processFn,
    },
    toastText: 'Ad Campaign Queued',
  }

  const deleteAndRequeueAdQueuePayload = {
    callbacks: {
      action: bulkDeleteAndRequeueAdQueues,
      afterAction: closeModal,
    },
    entityParams: [ adQueue.id ],
    toastText: 'Ad Campaign Re-queued',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Campaign" />

      <SidebarModal.Content>
        <Box flexDirection="column">
          {campaign.id && (
            <>
              <CampaignCard campaign={campaign} />

              <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />
            </>
          )}

          <AdQueueDetails adQueue={adQueue} />

          <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />

          <AdQueueTargeting
            adQueue={adQueue}
            callbacks={{
              viewSelectedLocations,
              viewSelectedPlaybooks,
            }}
          />

          <Form.Field label="More Options" marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
              loading={creating}
              onClick={() => processAdQueue(processAdQueuePayload)}
              size="medium"
            >
              {status === 'Failed' ? 'Resubmit Campaign' : 'Submit Campaign'}
            </Button>

            {status === 'Failed' && (
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faPaperPlaneTop} />}
                loading= {creating}
                marginTop="medium"
                onClick={() => handleCallbackAction(deleteAndRequeueAdQueuePayload)}
                size="medium"
              >
                Delete and Requeue Campaign
              </Button>
            )}

            {status === 'Failed' && (
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                loading={deleting}
                marginTop="medium"
                onClick={() => confirmDelete(confirmDeletePayload)}
                size="medium"
              >
                Delete Campaign
              </Button>
            )}
          </Form.Field>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => closeModal()}
          size="large"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ManageAdDeploymentModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageAdDeploymentModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
