import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { CampaignTypeModel } from '@models/types'

import type { AppDispatch } from '@redux/store'
import type { Action, Errors } from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/campaignType/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/campaignType/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/campaignType/FETCH_FAILURE'

export type CampaignTypeModuleState = {
  errors: string[],
  loaded: boolean,
  loading: boolean,
}

// Initial State
const initialState: CampaignTypeModuleState = {
  errors: [],
  loaded: false,
  loading: false,
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadCampaignTypes() {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api('/references/campaign-types')
      .then(({ data }: { data: CampaignTypeModel[] }) => {
        const normalizedJson = normalize(data, Schemas.CAMPAIGN_TYPE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: CampaignTypeModuleState = initialState,
  action: Action = { type: '' },
): CampaignTypeModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
