import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as priceListItemOptionValueActions from '@redux/modules/priceListItemOptionValue'
import type { BulkCreateParams } from '@redux/modules/priceListItemOptionValue'

import defaultFormState, { requiredFields } from '@models/priceListItemOptionValue'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, UpdateParams } from '@redux/modules/types'
import type { PriceListItemOptionValueModel, PriceListItemOptionValueRequestOptions } from '@models/types'

type CreatePriceListItemOptionValueParams = {
  dispatch: AppDispatch,
  priceListItemOptionValueParams: Partial<PriceListItemOptionValueModel>,
  requestOptions?: PriceListItemOptionValueRequestOptions,
}

const createPriceListItemOptionValue = (params: CreatePriceListItemOptionValueParams) => {
  const { dispatch, priceListItemOptionValueParams, requestOptions } = params
  const { createPriceListItemOptionValue: createFn } = priceListItemOptionValueActions

  return dispatch(createFn(priceListItemOptionValueParams, requestOptions))
}

type UpdatePriceListItemOptionValueParams = {
  dispatch: AppDispatch,
  priceListItemOptionValue: PriceListItemOptionValueModel,
  priceListItemOptionValueParams: Partial<PriceListItemOptionValueModel>,
  requestOptions?: PriceListItemOptionValueRequestOptions,
}

const updatePriceListItemOptionValue = (params: UpdatePriceListItemOptionValueParams) => {
  const {
    dispatch, priceListItemOptionValue, priceListItemOptionValueParams, requestOptions,
  } = params

  const { updatePriceListItemOptionValue: updateFn } = priceListItemOptionValueActions

  const updatedParams = {
    id: priceListItemOptionValue.id,
    ...priceListItemOptionValueParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type BulkCreatePriceListItemOptionValueParams = {
  bulkCreateParams: BulkCreateParams,
  dispatch: AppDispatch,
  requestOptions?: PriceListItemOptionValueRequestOptions,
}

const bulkCreatePriceListItemOptionValue = (params: BulkCreatePriceListItemOptionValueParams) => {
  const {
    bulkCreateParams, dispatch, requestOptions,
  } = params

  const { bulkCreatePriceListItemOptionValues: bulkCreateFn } = priceListItemOptionValueActions

  return dispatch(bulkCreateFn(bulkCreateParams, requestOptions))
}

type DeletePriceListItemOptionValueParams = {
  dispatch: AppDispatch,
  priceListItemOptionValue: DeleteParams<PriceListItemOptionValueModel>,
}

const deletePriceListItemOptionValue = (params: DeletePriceListItemOptionValueParams) => {
  const { dispatch, priceListItemOptionValue } = params
  const { deletePriceListItemOptionValue: deleteFn } = priceListItemOptionValueActions

  return dispatch(deleteFn(priceListItemOptionValue))
}

type SetDefaultPriceListItemOptionValueParams = {
  dispatch: AppDispatch,
  priceListItemOptionValue: UpdateParams<PriceListItemOptionValueModel>,
}

const setDefaultPriceListItemOptionValue = (params: SetDefaultPriceListItemOptionValueParams) => {
  const { dispatch, priceListItemOptionValue } = params
  const { setDefaultPriceListItemOptionValue: setDefaultFn } = priceListItemOptionValueActions
  const setToDefault = !priceListItemOptionValue.isDefault

  return dispatch(setDefaultFn(priceListItemOptionValue.id, setToDefault))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function usePriceListItemOptionValueForm(
  priceListItemOptionValue: Partial<PriceListItemOptionValueModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const priceListItemOptionValueForm = useForm(
    defaultFormState,
    { entity: priceListItemOptionValue, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [priceListItemOptionValue.id, priceListItemOptionValue.cacheKey],
  )

  return {
    ...priceListItemOptionValueForm,
  }
}

export const useRelations = (priceListItemOptionValue: Partial<PriceListItemOptionValueModel> = {}) => {
  const { productOptionValueId } = priceListItemOptionValue

  const { productOptionValues } = useSelector(reduxState => reduxState.entities)

  const productOptionValue = productOptionValueId && productOptionValues[productOptionValueId]
    ? productOptionValues[productOptionValueId]
    : {}

  return {
    productOptionValue,
  }
}

function usePriceListItemOptionValue(initEntity: Partial<PriceListItemOptionValueModel> = {}) {
  const { entity: priceListItemOptionValue }: { entity: PriceListItemOptionValueModel } = useLatestEntity(
    initEntity,
    'priceListItemOptionValues',
  )

  const dispatch = useDispatch()

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.priceListItemOptionValues)

  return {
    callbacks: {
      bulkCreatePriceListItemOptionValues: (
        bulkCreateParams: BulkCreateParams,
        entityOptions?: PriceListItemOptionValueRequestOptions,
      ) => (
        bulkCreatePriceListItemOptionValue({ bulkCreateParams, dispatch, requestOptions: entityOptions })
      ),
      createPriceListItemOptionValue: (
        priceListItemOptionValueParams: Partial<PriceListItemOptionValueModel>,
        entityOptions?: PriceListItemOptionValueRequestOptions,
      ) => (
        createPriceListItemOptionValue({ priceListItemOptionValueParams, dispatch, requestOptions: entityOptions })
      ),
      deletePriceListItemOptionValue: () => deletePriceListItemOptionValue({ priceListItemOptionValue, dispatch }),
      setDefaultPriceListItemOptionValue: () => setDefaultPriceListItemOptionValue({ dispatch, priceListItemOptionValue }),
      updatePriceListItemOptionValue: (
        priceListItemOptionValueParams: Partial<PriceListItemOptionValueModel>,
        entityOptions?: PriceListItemOptionValueRequestOptions,
      ) => (
        updatePriceListItemOptionValue({
          priceListItemOptionValue, priceListItemOptionValueParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    priceListItemOptionValue,
    updating,
  }
}

export default usePriceListItemOptionValue
