import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import { generateUrls } from '@hooks/useProduct'

import type { ProductModel } from '@models/types'

interface ProductListItemProps {
  product: ProductModel,
}

const Product = (props: ProductListItemProps) => {
  const { product, product: { id, name } } = props

  const { editProductUrl } = generateUrls(product)

  return (
    <DashboardModuleListItem
      href={editProductUrl}
      title={name}
    >
      <DashboardModuleListItem.TertiaryText>
        {`# ${id}`}
      </DashboardModuleListItem.TertiaryText>
    </DashboardModuleListItem>
  )
}

export default Product
