import { DashboardModule, Line, Link } from '@campaignhub/suit-theme'

import { mapCaptionsToObject } from '@functions/caption'

import useCampaign from '@hooks/useCampaign'
import useCaption from '@hooks/useCaption'
import useCaptions from '@hooks/useCaptions'

import AdContentList from './AdContentList'

interface AdContentProps {
  campaignPayload: ReturnType<typeof useCampaign>,
}

const AdContent = (props: AdContentProps) => {
  const {
    campaignPayload: {
      campaign,
      loading,
    },
  } = props

  const captionsPayload = useCaptions({
    filters: {
      campaignId: campaign.id,
    },
  })

  const { filteredCaptions } = captionsPayload

  const captionsObject = mapCaptionsToObject(filteredCaptions)

  const {
    callToActions,
    descriptions,
    displayUrls,
    headlines,
    texts,
  } = captionsObject

  const {
    callbacks: {
      manageCaptions,
    },
  } = useCaption({ campaignId: campaign.id })

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={(
        <Link
          onClick={() => manageCaptions()}
          textProps={{ fontSize: 'small' }}
        >
          Manage
        </Link>
      )}
      loading={loading}
      title="Ad Content"
    >
      {!loading && (
        <>
          <AdContentList label="Headlines" list={headlines} />

          <Line margin={0} />

          <AdContentList label="Descriptions" list={descriptions} />

          <Line margin={0} />

          <AdContentList label="Text" list={texts} />

          <Line margin={0} />

          <AdContentList label="Call to Action" list={callToActions} />

          <Line margin={0} />

          <AdContentList label="Display URLs" list={displayUrls} />
        </>
      )}
    </DashboardModule>
  )
}

export default AdContent
