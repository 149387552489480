import { Route, Routes } from 'react-router-dom'

import PriceListEdit from './Edit'
import PriceListsList from './List'

const PriceListsRoutes = () => (
  <Routes>
    <Route path="/" element={<PriceListsList />} />
    <Route path="/:priceListId/edit/*" element={<PriceListEdit />} />
  </Routes>
)

export default PriceListsRoutes
