import {
  DashboardModule, Form,
} from '@campaignhub/suit-theme'

import { useClientForm } from '@hooks/useClient'

interface ClientDetailsProps {
  clientFormPayload: ReturnType<typeof useClientForm>,
}

const ClientDetails = (props: ClientDetailsProps) => {
  const {
    clientFormPayload: {
      entityState: {
        additionalNotificationEmail,
        businessName,
        name,
        shortName,
      },
      errors,
      handlers,
    },
  } = props

  return (
    <DashboardModule title="Details">
      <Form padding="large">
        <Form.Row>
          <Form.Field label="* Name">
            <input
              disabled
              value={name}
              type="text"
            />
          </Form.Field>

          <Form.Field label="Business Name" marginTop={['large', 0]}>
            <input
              disabled
              type="text"
              value={businessName}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="Short Name">
            <input
              disabled
              type="text"
              value={shortName}
            />
          </Form.Field>

          <Form.Field errorMessage={errors.additionalNotificationEmail} label="Additional Notification Email" marginTop={['large', 0]}>
            <input
              name="additionalNotificationEmail"
              value={additionalNotificationEmail}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default ClientDetails
