import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as externalPlatformEntityActions from '@redux/modules/externalPlatformEntity'

import defaultFormState, { requiredFields } from '@models/externalPlatformEntity'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { ExternalPlatformEntityModel, ExternalPlatformEntityRequestOptions } from '@models/types'

type CreateExternalPlatformEntityParams = {
  dispatch: AppDispatch,
  externalPlatformEntityParams: Partial<ExternalPlatformEntityModel>,
  requestOptions?: ExternalPlatformEntityRequestOptions,
}

const createExternalPlatformEntity = (params: CreateExternalPlatformEntityParams) => {
  const { dispatch, externalPlatformEntityParams, requestOptions } = params
  const { createExternalPlatformEntity: createFn } = externalPlatformEntityActions

  return dispatch(createFn(externalPlatformEntityParams, requestOptions))
}

type UpdateExternalPlatformEntityParams = {
  dispatch: AppDispatch,
  externalPlatformEntity: ExternalPlatformEntityModel,
  externalPlatformEntityParams: Partial<ExternalPlatformEntityModel>,
  requestOptions?: ExternalPlatformEntityRequestOptions,
}

const updateExternalPlatformEntity = (params: UpdateExternalPlatformEntityParams) => {
  const {
    dispatch, externalPlatformEntity, externalPlatformEntityParams, requestOptions,
  } = params
  const { updateExternalPlatformEntity: updateFn } = externalPlatformEntityActions

  const updatedParams = {
    id: externalPlatformEntity.id,
    ...externalPlatformEntityParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteExternalPlatformEntityParams = {
  dispatch: AppDispatch,
  externalPlatformEntity: DeleteParams<ExternalPlatformEntityModel>,
}

const deleteExternalPlatformEntity = (params: DeleteExternalPlatformEntityParams) => {
  const { dispatch, externalPlatformEntity } = params
  const { deleteExternalPlatformEntity: deleteFn } = externalPlatformEntityActions

  return dispatch(deleteFn(externalPlatformEntity))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useExternalPlatformEntityForm(
  externalPlatformEntity: Partial<ExternalPlatformEntityModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const externalPlatformEntityForm = useForm(
    defaultFormState,
    { entity: externalPlatformEntity, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [externalPlatformEntity.id, externalPlatformEntity.cacheKey],
  )

  return {
    ...externalPlatformEntityForm,
  }
}

export const useRelations = (externalPlatformEntity: Partial<ExternalPlatformEntityModel> = {}) => {
  const { externalPlatformId } = externalPlatformEntity

  const { externalPlatforms } = useSelector(reduxState => reduxState.entities)

  const externalPlatform = externalPlatformId && externalPlatforms[externalPlatformId] ? externalPlatforms[externalPlatformId] : {}

  return {
    externalPlatform,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ExternalPlatformEntityRequestOptions,
}

function useExternalPlatformEntity(initEntity: Partial<ExternalPlatformEntityModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: externalPlatformEntity }: { entity: ExternalPlatformEntityModel } = useLatestEntity(initEntity, 'externalPlatformEntities')
  const { id } = externalPlatformEntity

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'externalPlatformEntities',
    'loadExternalPlatformEntity',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.externalPlatformEntities)

  return {
    callbacks: {
      createExternalPlatformEntity: (externalPlatformEntityParams: Partial<ExternalPlatformEntityModel>, entityOptions?: ExternalPlatformEntityRequestOptions) => (
        createExternalPlatformEntity({ dispatch, externalPlatformEntityParams, requestOptions: entityOptions })
      ),
      createOrEditExternalPlatformEntity: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditExternalPlatformEntityModal',
        payload: { externalPlatformEntity },
      }),
      deleteExternalPlatformEntity: () => deleteExternalPlatformEntity({ externalPlatformEntity, dispatch }),
      updateExternalPlatformEntity: (externalPlatformEntityParams: Partial<ExternalPlatformEntityModel>, entityOptions?: ExternalPlatformEntityRequestOptions) => (
        updateExternalPlatformEntity({
          dispatch, externalPlatformEntity, externalPlatformEntityParams, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    externalPlatformEntity,
    loading,
    updating,
  }
}

export default useExternalPlatformEntity
