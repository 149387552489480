import {
  DashboardModule, Form,
} from '@campaignhub/suit-theme'

import { usePropertyForm } from '@hooks/useProperty'
import useCampaign, { useRelations } from '@hooks/useCampaign'

type PropertyDetailsProps = {
  campaignPayload: ReturnType<typeof useCampaign>,
}

const PropertyDetails = (props: PropertyDetailsProps) => {
  const {
    campaignPayload: {
      campaign,
    },
  } = props

  const { property } = useRelations(campaign)

  const {
    entityState: {
      bathrooms,
      bedrooms,
      carparks,
      landArea,
      landAreaUnits,
      landDetails,
      name,
      propertyType,
      studies,
    },
    errors,
    handlers,
  } = usePropertyForm(property)

  return (
    <DashboardModule title="Property Details">
      <Form padding="large">
        <Form.Field label="Property Title" errorMessage={errors.name}>
          <input
            name="name"
            value={name}
            type="text"
            {...handlers}
          />
        </Form.Field>

        <Form.Row marginTop="large">
          <Form.Field label="Bedrooms">
            <input
              name="bedrooms"
              value={bedrooms}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Bathrooms" marginTop={['large', 0]}>
            <input
              name="bathrooms"
              value={bathrooms}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="Studies">
            <input
              name="studies"
              value={studies}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Carparks" marginTop={['large', 0]}>
            <input
              name="carparks"
              value={carparks}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          {/* TODO: Request property types from backend and generate list from those */}
          <Form.Field label="Property Type">
            <select name="propertyType" value={propertyType} {...handlers}>
              <option value="">Please Select...</option>
              <option value="apartment">Apartment</option>
              <option value="house">House</option>
            </select>
          </Form.Field>

          <Form.Field label="Land Area" marginTop={['large', 0]}>
            <input
              name="landArea"
              value={landArea}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="Land Area Units">
            <input
              name="landAreaUnits"
              value={landAreaUnits}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field label="Land Details" marginTop={['large', 0]}>
            <input
              name="landDetails"
              value={landDetails}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default PropertyDetails
