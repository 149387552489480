import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowFromLeft, faCopy, faPause, faPlayCircle, faSync, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'

import { Button, Form } from '@campaignhub/suit-theme'

import { generateAdsManagerUrl } from '@functions/adCampaign'
import {
  adCampaignCanBeDeleted,
  adCampaignCanBePaused,
  adCampaignCanBeSynced,
  PAUSED_STATUS,
} from '@functions/adCampaignStatus'
import { confirmDelete } from '@functions/entity'

import useAdCampaign from '@hooks/useAdCampaign'

import type { AdCampaignModel } from '@models/types'

import type { ManageAdCampaignModalProps } from '..'

type AdCampaignOptionsProps = {
  adCampaign: AdCampaignModel,
  callbacks: ManageAdCampaignModalProps['callbacks'],
}

const AdCampaignOptions = (props: AdCampaignOptionsProps) => {
  const { adCampaign, callbacks } = props
  const { status } = adCampaign

  const adsManagerUrl = generateAdsManagerUrl(adCampaign)

  const {
    closeModal,
    deleteAdCampaign,
    duplicateAdCampaign,
    syncAdCampaign,
    updateAdCampaignStatus,
  } = callbacks

  const {
    callbacks: {
      deleteAdCampaign: deleteFn,
      duplicateAdCampaign: duplicateFn,
      syncAdCampaign: syncFn,
      updateAdCampaignStatus: updateStatusFn,
    },
    updating,
  } = useAdCampaign(adCampaign)

  const confirmDeletePayload = {
    callbacks: {
      afterAction: closeModal,
      deleteEntity: deleteAdCampaign,
      deleteFn,
    },
    strings: {
      entityName: 'Ad Campaign',
    },
  }

  const duplicateAdCampaignPayload = {
    callbacks: {
      action: duplicateFn,
      afterAction: closeModal,
    },
    toastText: 'Ad Campaign Created',
  }

  const syncAdCampaignPayload = {
    callbacks: {
      action: syncFn,
    },
    toastText: 'Ad Campaign Synced',
  }

  const updateAdCampaignStatusPayload = {
    callbacks: {
      action: () => updateStatusFn(status === 'Paused' ? 'Resume' : 'Pause'),
    },
    toastText: 'Ad Campaign Updated',
  }

  return (
    <Form.Field label="More Options" marginTop="large">
      <Button
        as='a'
        buttonStyle="secondaryUtility"
        href={adsManagerUrl}
        icon={<FontAwesomeIcon icon={faArrowFromLeft} />}
        marginTop="medium"
        size="medium"
        target="_blank"
      >
        Go to Ads Manager
      </Button>

      {adCampaignCanBeSynced(status) && (
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faSync} />}
          marginTop="medium"
          onClick={() => syncAdCampaign(syncAdCampaignPayload)}
          size="medium"
        >
          Sync Campaign
        </Button>
      )}

      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faCopy} />}
        marginTop="medium"
        onClick={() => duplicateAdCampaign(duplicateAdCampaignPayload)}
        size="medium"
      >
        Duplicate Campaign
      </Button>

      {adCampaignCanBePaused(status) && (
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={status === PAUSED_STATUS ? faPlayCircle : faPause} />}
          loading={updating}
          marginTop="medium"
          onClick={() => updateAdCampaignStatus(updateAdCampaignStatusPayload)}
          size="medium"
        >
          {status === PAUSED_STATUS ? 'Resume Campaign' : 'Pause Campaign'}
        </Button>
      )}

      {adCampaignCanBeDeleted(status) && (
        <Button
          buttonStyle="secondaryUtility"
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          marginTop="medium"
          onClick={() => confirmDelete(confirmDeletePayload)}
          size="medium"
        >
          Delete Campaign
        </Button>
      )}
    </Form.Field>
  )
}

export default AdCampaignOptions
