export type CutterProfileModuleModel = {
  adTypeId: string,
  cutterProfileId: number,
  description: string,
  height: number,
  id: number,
  instances: number,
  name: string,
  topLeftXCoordinates: number,
  topLeftYCoordinates: number,
  width: number,
}

export type CutterProfileModuleRequestOptions = {}

const state = {
  cutterProfileId: null,
  description: '',
  height: null,
  id: null,
  name: '',
  topLeftXCoordinates: 0,
  topLeftYCoordinates: 0,
  width: null,
}

export const requiredFields = [
  { key: 'cutterProfileId' },
  { key: 'height' },
  { key: 'name' },
  { key: 'width' },
]

export default state
