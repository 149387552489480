import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/pro-light-svg-icons'

import { Box, DashboardModule, SidebarModal, Text } from '@campaignhub/suit-theme'

import { PriceListItemOptionValueModel } from '@models/types'

import TargetingListItem from '../TargetingListItem'

interface AudienceTargetingProps {
  callbacks: {
    toggleAudienceTargetingId: (id: number) => void,
  },
  loading: boolean,
  priceListItemOptionValues: PriceListItemOptionValueModel[],
  selectedPriceListItemOptionValues: number[],
}

const AudienceTargeting = (props: AudienceTargetingProps) => {
  const {
    callbacks: {
      toggleAudienceTargetingId,
    },
    loading,
    priceListItemOptionValues,
    selectedPriceListItemOptionValues,
  } = props

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Additional Audience Targeting"
    >
      {!loading && (
        <Box flexDirection="column" padding="large">
          <SidebarModal.NoticeBox>
            <Box>
              <FontAwesomeIcon icon={faUsers} style={{ marginBottom: 8, marginRight: 8 }} />

              <SidebarModal.NoticeBox.Title>Choose Your Additional Audiences</SidebarModal.NoticeBox.Title>
            </Box>

            <SidebarModal.NoticeBox.Paragraph boxProps={{ marginTop: 'none', marginBottom: 'medium' }}>
              You can choose to customise your audiences and locations but the product's default settings are designed to maximise your result
            </SidebarModal.NoticeBox.Paragraph>
          </SidebarModal.NoticeBox>

          <Text
            color="bodyFontLightColor"
            fontSize="xsmall"
            letterSpacing="0.5px"
            marginBottom="medium"
          >
            Additional Audience Targeting
          </Text>

          {priceListItemOptionValues.map((priceListItemOptionValue) => (
            <TargetingListItem
              callbacks={{
                selectTargeting: () => toggleAudienceTargetingId(priceListItemOptionValue.id),
              }}
              isSelected={selectedPriceListItemOptionValues.includes(priceListItemOptionValue.id)}
              key={priceListItemOptionValue.id}
              priceListItemOptionValue={priceListItemOptionValue}
            />
          ))}
        </Box>
      )}
    </DashboardModule>
  )
}

export default AudienceTargeting