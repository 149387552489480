import { BlankState } from '@campaignhub/suit-theme'

import blankImageUrl from './assets/filterBlankState.svg'

const ListBlankState = (props: { boxProps?: object }) => {
  const { boxProps } = props

  return (
    <BlankState boxProps={{ marginBottom: ['large', 0], ...boxProps }} imageUrl={blankImageUrl}>
      <BlankState.Title>No Results Available</BlankState.Title>
      <BlankState.Paragraph>No Results Available. Please try adjusting your filters.</BlankState.Paragraph>
    </BlankState>
  )
}

export default ListBlankState
