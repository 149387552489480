export const ARCHIVED_STATUS = 'Archived'
export const CANCELLED_STATUS = 'Cancelled'
export const COMPLETED_STATUS = 'Completed'
export const FAILED_STATUS = 'Failed'
export const LIVE_STATUS = 'Live'
export const PAUSED_STATUS = 'Paused'
export const PENDING_STATUS = 'Pending'
export const REVIEW_STATUS = 'Review'
export const SCHEDULED_STATUS = 'Scheduled'

export const adCampaignCanBePaused = (adCampaignStatus: string) => [
  LIVE_STATUS, PAUSED_STATUS, PENDING_STATUS, REVIEW_STATUS, SCHEDULED_STATUS,
].includes(adCampaignStatus)

export const adCampaignCanBeSynced = (adCampaignStatus: string) => [
  COMPLETED_STATUS, FAILED_STATUS, LIVE_STATUS, PAUSED_STATUS, PENDING_STATUS, REVIEW_STATUS, SCHEDULED_STATUS,
].includes(adCampaignStatus)

export const adCampaignCanBeDeleted = (adCampaignStatus: string) => ![
  COMPLETED_STATUS,
].includes(adCampaignStatus)

export const activeAdCampaignStatuses = [
  CANCELLED_STATUS,
  COMPLETED_STATUS,
  PAUSED_STATUS,
  LIVE_STATUS,
  SCHEDULED_STATUS,
]

export const loadedAdCampaignStatuses = [REVIEW_STATUS, FAILED_STATUS]

const adCampaignStatuses = [
  ARCHIVED_STATUS,
  CANCELLED_STATUS,
  COMPLETED_STATUS,
  FAILED_STATUS,
  LIVE_STATUS,
  PAUSED_STATUS,
  PENDING_STATUS,
  REVIEW_STATUS,
  SCHEDULED_STATUS,
]

export default adCampaignStatuses
