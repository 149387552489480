import { Route, Routes } from 'react-router-dom'

import UserEdit from './Edit'
import UsersList from './List'

const UsersRoutes = () => (
  <Routes>
    <Route path="/" element={<UsersList />} />
    <Route path="/:userId/edit/*" element={<UserEdit />} />
  </Routes>
)

export default UsersRoutes
