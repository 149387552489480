import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useProject, { useProjectForm } from '@hooks/useProject'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'EditProjectModal'

type ModalCallbacks = {
  closeModal: () => void,
  updateProject: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const EditProjectModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, updateProject } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const project = digObject(modalPayload, 'project', {})

  const {
    callbacks: {
      updateProject: updateFn,
    },
    updating,
  } = useProject(project)

  const {
    entityState,
    entityState: {
      dataStoreItems,
      dataStoreItems: {
        PostCode,
        State,
        Street,
        StreetNumber,
        Suburb,
        UnitNumber,
      },
    },
    errors,
    handlers,
    saveEnabled,
  } = useProjectForm(project, { validateOn: 'change' })

  // Only add DSIs to array if there is a value
  // This allows delete of DSIs using Project Update
  const dataStoreItemsArray = Object.entries(dataStoreItems).reduce((acc, dataStoreItem) => {
    const [key, value] = dataStoreItem
    if (value) acc.push({ customFieldId: key, value })
    return acc
  }, [] as { [key: string]: string }[])

  const updateProjectPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    entityParams: { ...entityState, dataStoreItems: dataStoreItemsArray },
    toastText: 'Project Updated',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Property" titleSecondLine="Details" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="Unit Number">
            <input name="dataStoreItems.UnitNumber" type="text" value={UnitNumber} {...handlers} />
          </Form.Field>

          <Form.Field errorMessage={errors['dataStoreItems.StreetNumber']} label="Street Number" marginTop="large">
            <input name="dataStoreItems.StreetNumber" type="text" value={StreetNumber} {...handlers} />
          </Form.Field>

          <Form.Field errorMessage={errors['dataStoreItems.Street']} label="Street" marginTop="large">
            <input name="dataStoreItems.Street" type="text" value={Street} {...handlers} />
          </Form.Field>

          <Form.Field errorMessage={errors['dataStoreItems.Suburb']} label="Suburb" marginTop="large">
            <input name="dataStoreItems.Suburb" type="text" value={Suburb} {...handlers} />
          </Form.Field>

          <Form.Field errorMessage={errors['dataStoreItems.State']} label="State" marginTop="large">
            <input name="dataStoreItems.State" type="text" value={State} {...handlers} />
          </Form.Field>

          <Form.Field errorMessage={errors['dataStoreItems.PostCode']} label="Postcode" marginTop="large">
            <input name="dataStoreItems.PostCode" type="text" value={PostCode} {...handlers} />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => updateProject(updateProjectPayload)}
          size="large"
        >
          {saveEnabled ? 'Update Property' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <EditProjectModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
