import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, DashboardModule, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import getStatusBadgeProps from '@functions/statusBadge'

type OrderDashboardModuleProps = {
  order: {}, // will be OrderModel
}

const OrderModule = (props: OrderDashboardModuleProps) => {
  const { order } = props

  const status = 'Pending'

  const { color } = getStatusBadgeProps(status)

  const customerHeader = (
    <Box
      borderBottom="1px solid"
      borderColor="lineColor"
      padding="large"
      width="auto"
    >
      <Box flexDirection="column">
        <Text letterSpacing="0.5px" fontSize="small" width="auto">Aim Digital Starter</Text>
        <Text
          color="bodyFontLightColor"
          fontSize="xsmall"
          letterSpacing="0.5px"
          marginTop="small"
          width="auto"
        >
          Digital Duo Starter - Agent Advantage
        </Text>
      </Box>

      <Box alignItems="flex-end" flexDirection="column" width="auto">
        <StatusBadge color={color} text={status} />
        <Text color="lightGrey" fontSize="xsmall" marginTop="small" width="max-content">Due in 10 Days</Text>
      </Box>
    </Box>
  )

  const secondaryText = (
    <Box alignItems="center">
      <StatusBadge boxProps={{ marginRight: 'small' }} text="Draft" />
      <Text color="bodyFontLightColor">Facebook &amp; Google Ads</Text>
    </Box>
  )

  return (
    <DashboardModule customHeader={customerHeader}>
      <DashboardModule.ListItem
        secondaryText={secondaryText}
        showCheckbox
        title="Campaign Ads"
      >
        <Button
          buttonStyle="primaryCreate"
          icon={<FontAwesomeIcon icon={faThumbsUp} />}
          iconMargin="medium"
          size="small"
          width={100}
        >
          Approve
        </Button>
      </DashboardModule.ListItem>
    </DashboardModule>
  )
}

export default OrderModule
