import { useNumericParams } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import ClientEditRoutes from './routes'

const ClientEdit = () => {
  const { clientId } = useNumericParams()

  useReduxAction(
    'clients',
    'loadClient',
    {
      entityId: clientId,
    },
    [clientId],
    {
      dispatchAction: (action, requestOptions) => action(clientId, requestOptions),
      shouldPerformFn: ({ loading }) => !!clientId && !loading,
    },
  )

  return (
    <ClientEditRoutes />
  )
}

export default ClientEdit
