import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown, faAngleRight, faCircle, faPlus, faTrashAlt,
} from '@fortawesome/pro-light-svg-icons'
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons'

import {
  Box, Button, Line, Text,
} from '@campaignhub/suit-theme'

import colours from '@functions/colours'
import { confirmDelete } from '@functions/entity'

import type { PriceListItemModel } from '@models/types'
import usePriceListProduct from '../../../../hooks/usePriceListProduct'

import PriceListItemOption from './PriceListItemOption'

interface PriceListItemProps {
  isLast: boolean,
  priceListItem: PriceListItemModel,
}

const PriceListItem = (props: PriceListItemProps) => {
  const {
    isLast,
    priceListItem,
    priceListItem: {
      nameOverride,
    },
  } = props

  const priceListProductPayload = usePriceListProduct(priceListItem)
  const {
    callbacks: {
      deleteFn,
      deletePriceListItem,
      launchBulkCreatePriceListItemOptionsModal,
      selectPriceListItemOptionId,
      toggleOpen,
    },
    filteredPriceListItemOptions,
    filteredPriceListItemOptionsCount,
    hasFilteredPriceListItemOptions,
    isOpen,
    product,
    selectedPriceListItemOption,
  } = priceListProductPayload

  const confirmDeletePayload = {
    callbacks: {
      deleteFn,
      deleteEntity: deletePriceListItem,
    },
    strings: {
      entityName: 'Product',
    },
  }

  return (
    <Box
      borderBottom={isLast ? 'none' : '1px dashed'}
      borderColor="lineColor"
      disableHover
      flexDirection="column"
      fontSize="small"
      padding="large"
    >
      <Box onClick={() => toggleOpen()} style={{ cursor: 'pointer' }}>
        <Box alignItems="center" color={colours.teal} justifyContent="center" width={14}>
          <FontAwesomeIcon icon={isOpen ? faCircleSolid : faCircle} />
        </Box>

        <Box flexDirection="column" marginLeft="large">
          <Text color="bodyFontColor" variant="ellipsis">
            {nameOverride || product.name}
          </Text>

          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {filteredPriceListItemOptionsCount} {filteredPriceListItemOptionsCount === 1 ? 'Option' : 'Options'}
          </Text>
        </Box>

        <Box alignItems="center" marginLeft="auto" width="auto">
          <FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleRight} />
        </Box>
      </Box>

      {isOpen && (
        <>
          {hasFilteredPriceListItemOptions && <Line />}

          <Box flexDirection="column">
            {filteredPriceListItemOptions.map((priceListItemOption, index) => (
              <PriceListItemOption
                callbacks={{
                  selectPriceListItemOption: () => selectPriceListItemOptionId(priceListItemOption.id),
                }}
                isLast={index + 1 === filteredPriceListItemOptionsCount}
                isSelected={selectedPriceListItemOption.id === priceListItemOption.id}
                key={priceListItemOption.id}
                priceListItemOption={priceListItemOption}
              />
            ))}
          </Box>

          <Box flexDirection="column" marginTop="large">
            <Button
              buttonStyle="secondaryEdit"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => launchBulkCreatePriceListItemOptionsModal()}
              size="small"
            >
              Add Options
            </Button>

            <Button
              buttonStyle="secondaryDestroy"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={() => confirmDelete(confirmDeletePayload)}
              marginTop="small"
              size="small"
            >
              Delete Product
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default PriceListItem
