import { useContext } from 'react'

import {
  Button,
  Columns,
  LoadingModule,
  SaveFormModule,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useProduct, { useProductForm } from '@hooks/useProduct'

import ExternalPlatformEntities from '@components/ExternalPlatformEntities'
import ProductImage from '../ProductImage'
import ProductDetails from '../ProductDetails'
import ProductTools from '../../../../components/ProductTools'
import PageLayout from '../../../../components/PageLayout'

interface PageContentProps {
  productId: number,
}

const PageContent = (props: PageContentProps) => {
  const { productId } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      updateProduct,
    },
  } = pageContext

  const productPayload = useProduct({ id: productId })
  const {
    product,
    callbacks: {
      updateProduct: updateFn,
    },
    loading,
    updating,
  } = productPayload

  const productFormPayload = useProductForm(product, { validateOn: 'change' })
  const { entityState, saveEnabled } = productFormPayload

  const updateProductPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'Product Updated',
  }

  return (
    <PageLayout productPayload={productPayload} activeTabBarItemKey="details">
      <Columns.Main>
        <LoadingModule loading={loading} times={3} />

        {!loading && (
          <>
            <ProductDetails productFormPayload={productFormPayload} />

            <SaveFormModule>
              <Button
                buttonStyle="primaryCreate"
                disabled={!saveEnabled}
                loading={updating}
                marginLeft="auto"
                onClick={() => updateProduct(updateProductPayload)}
                size="medium"
              >
                Save Details
              </Button>
            </SaveFormModule>
          </>
        )}
      </Columns.Main>

      <Columns.Sidebar>
        <ProductTools productPayload={productPayload} />

        <ProductImage
          filters={{
            assetTypeCategory: 'Image',
            assetableId: productId,
            assetableType: 'Product',
          }}
        />

        <ExternalPlatformEntities
          filters={{
            internalEntityId: productId,
            internalEntityType: 'Product',
          }}
        />
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent
