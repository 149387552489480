import { useState } from 'react'

import { useSetState } from '@campaignhub/react-hooks'

import useProductOptionValues from '@hooks/useProductOptionValues'
import useProductOptionValuesSubset from '@hooks/useProductOptionValuesSubset'
import useSelector from '@hooks/useSelector'

const defaultState = {
  name: '',
}

type BulkCreateProductOptionValuesParams = {
  assignedProductOptionValueIds: number[],
  productId: number,
  productOptionId: number,
}

const useBulkCreateProductOptionValuesSubsets = (params: BulkCreateProductOptionValuesParams) => {
  const {
    assignedProductOptionValueIds,
    productId,
    productOptionId,
  } = params

  const [selectedProductOptionValueIds, setSelectedProductOptionValueIds] = useState<number[]>(
    assignedProductOptionValueIds,
  )

  const [state, setState] = useSetState(defaultState)
  const { name } = state

  const { productOptionValues } = useSelector(reduxState => reduxState.entities)

  const {
    callbacks: {
      bulkCreateProductOptionValuesSubsets,
    },
    creating,
  } = useProductOptionValuesSubset()

  // No name filter in backend, so we don't want to send more requests when name changes
  const productOptionValuesPayload = useProductOptionValues({
    filters: {
      name,
      productOptionId,
    },
    performHttpRequests: !!productOptionId && !name,
  })

  const { filteredProductOptionValues } = productOptionValuesPayload
  const filteredProductOptionValueIds = filteredProductOptionValues.map(value => value.id)

  const entityParams = {
    productId,
    productOptionId,
    productOptionValueIds: selectedProductOptionValueIds,
  }

  // Save is enabled if the current selection is different from original
  const saveEnabled = JSON.stringify(assignedProductOptionValueIds.sort())
    !== JSON.stringify(selectedProductOptionValueIds.sort())

  return {
    callbacks: {
      bulkCreateProductOptionValuesSubsets,
      setSelectedProductOptionValueIds,
      setState,
    },
    creating,
    entityParams,
    filteredProductOptionValueIds,
    name,
    productOptionValues,
    productOptionValuesPayload,
    saveEnabled,
    selectedProductOptionValueIds,
  }
}

export default useBulkCreateProductOptionValuesSubsets
