import type { DataStoreItemModel } from '@models/types'

const buildDataStoreItemSummary = (dataStoreItems: DataStoreItemModel[]) => {
  const result = {} as { [key: string]: string }

  dataStoreItems.forEach(dataStoreItem => {
    result[dataStoreItem.customFieldId] = dataStoreItem.value
  })

  return result
}

export const generateBedBathCarText = (dataStoreItemSummary: ReturnType<typeof buildDataStoreItemSummary>) => {
  const { Bedrooms, Bathrooms, Carparks } = dataStoreItemSummary

  const resultArray = []

  if (Bedrooms) resultArray.push(`${Bedrooms} Bed`)
  if (Bathrooms) resultArray.push(`${Bathrooms} Bath`)
  if (Carparks) resultArray.push(`${Carparks} Car`)

  return resultArray.join(', ')
}

export const generateAddress = (dataStoreItemSummary: ReturnType<typeof buildDataStoreItemSummary>) => {
  const {
    Street,
    StreetNumber,
    Suburb,
    UnitNumber,
  } = dataStoreItemSummary

  let address = ''
  if (UnitNumber) address += `${UnitNumber}/`
  if (StreetNumber) address += StreetNumber
  if (Street) address += ` ${Street}`
  if (Suburb) address += `, ${Suburb}`

  return address
}

export default buildDataStoreItemSummary