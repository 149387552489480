import { Box, DashboardModuleListItem, ListItem, Text } from '@campaignhub/suit-theme'

import type { CaptionsFormState } from '@hooks/useAdContent'

type HandleDeleteParams = {
  callbacks: {
    setCaptionsFormState: React.Dispatch<React.SetStateAction<CaptionsFormState>>
  },
  captionsFormState: CaptionsFormState,
  formStateKey: string,
  index: number,
  list: string[],
}

const handleDelete = (params: HandleDeleteParams) => {
  const {
    callbacks: {
      setCaptionsFormState,
    },
    captionsFormState,
    formStateKey,
    index,
    list
  } = params

  // Mutate list by deleting the item
  list.splice(index, 1)

  setCaptionsFormState({
    ...captionsFormState,
    [formStateKey]: list,
  })
}

interface AdContentListProps {
  callbacks: {
    setCaptionsFormState: React.Dispatch<React.SetStateAction<CaptionsFormState>>,
  },
  captionsFormState: CaptionsFormState,
  formStateKey: string,
  label: string,
  list: string[],
}

const AdContentList = (props: AdContentListProps) => {
  const {
    callbacks: {
      setCaptionsFormState,
    },
    captionsFormState,
    formStateKey,
    label,
    list,
  } = props
    
  const hasListItems = !!list.length

  return (
    <Box flexDirection="column">
      <Text
        color="bodyFontLightColor"
        display="flex"
        fontSize="xsmall"
        letterSpacing="1px"
      >
        {label}
      </Text>

      {list.map((listItem, index) => (
        <DashboardModuleListItem
          boxProps={{ paddingX: 0 }}
          isSidebar
          key={listItem}
          title={listItem}
        >
          <DashboardModuleListItem.Actions>
            <DashboardModuleListItem.Icon
              onClick={() => handleDelete({
                callbacks: { setCaptionsFormState },
                captionsFormState,
                formStateKey,
                index,
                list,
              })}
              type="delete"
            />
          </DashboardModuleListItem.Actions>
        </DashboardModuleListItem>
      ))}

      {!hasListItems && (
        <ListItem boxProps={{ paddingY: 'large' }} disableHover>
          <Text fontSize="small">None</Text>
        </ListItem>
      )}
    </Box>
  )
}

export default AdContentList