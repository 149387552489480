import { Route, Routes } from 'react-router-dom'

import Details from './screens/Details'
import Relations from './screens/Relations'

const UserEditRoutes = () => (
  <Routes>
    <Route index element={<Details />} />
    <Route path="/relations" element={<Relations />} />
  </Routes>
)

export default UserEditRoutes
