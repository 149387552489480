import {
  faChartBar,
  faHistory,
  faPencil,
  faQuestionCircle,
  faTachometer,
} from '@fortawesome/pro-light-svg-icons'

import { generateUrls } from '@hooks/useCampaign'

const generateNavigationItems = (campaignId: number) => {
  const {
    campaignActivitiesUrl,
    campaignDetailUrl,
    campaignEnquiriesUrl,
    campaignOverviewUrl,
    campaignReportingUrl,
  } = generateUrls({ id: campaignId })

  return {
    sections: [
      {
        key: 'dashboard',
        visible: true,
        items: [
          {
            href: campaignOverviewUrl,
            icon: faTachometer,
            key: 'overview',
            title: 'Overview',
            visible: true,
          },
          {
            href: campaignDetailUrl,
            icon: faPencil,
            key: 'details',
            title: 'Site Details',
            visible: true,
          },
          {
            href: campaignReportingUrl,
            icon: faChartBar,
            key: 'reporting',
            title: 'Reporting',
            visible: true,
          },
          {
            href: campaignEnquiriesUrl,
            icon: faQuestionCircle,
            key: 'enquiries',
            title: 'Enquiries',
            visible: true,
          },
          {
            href: campaignActivitiesUrl,
            icon: faHistory,
            key: 'activities',
            title: 'Activities',
            visible: true,
          },
        ],
      },
    ],
  }
}

export default generateNavigationItems
