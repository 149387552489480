import { Columns } from '@campaignhub/suit-theme'

import usePriceList from '@hooks/usePriceList'

import PageLayout from '../../../../components/PageLayout'

import PriceListTools from '../../../../components/PriceListTools'

import ClientPriceLists from '../ClientPriceLists'

interface PageContentProps {
  priceListId: number,
}

const PageContent = (props: PageContentProps) => {
  const { priceListId } = props

  const priceListPayload = usePriceList({ id: priceListId })

  return (
    <PageLayout priceListPayload={priceListPayload} activeTabBarItemKey="relations">
      <Columns.Main>
        <ClientPriceLists priceListId={priceListId} />
      </Columns.Main>

      <Columns.Sidebar>
        <PriceListTools priceListPayload={priceListPayload} />
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent
