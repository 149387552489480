import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ProductOptionValuesSubsetRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['productOptionValuesSubsets']

type ProductOptionValuesSubsetFilters = {
  productId?: number,
  productOptionId?: number,
}

type UseProductOptionValuesSubsetsOptions = {
  filters?: ProductOptionValuesSubsetFilters,
  performHttpRequests?: boolean,
  requestOptions?: ProductOptionValuesSubsetRequestOptions,
}

function useProductOptionValuesSubsets(options: UseProductOptionValuesSubsetsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    productId: filterProductId,
    productOptionId: filterProductOptionId,
  } = filters

  const {
    updatedEntities: { productOptionValuesSubsets: productOptionValuesSubsetsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { productOptionValuesSubsets } = useSelector(reduxState => reduxState.entities)

  const filteredProductOptionValuesSubsets = useMemo(() => {
    const filtered = Object.values(productOptionValuesSubsets).filter((productOptionValuesSubset) => {
      const { productId, productOptionId } = productOptionValuesSubset

      const matchProductId = matchFilterNumber(productId, filterProductId)
      const matchProductOptionId = matchFilterNumber(productOptionId, filterProductOptionId)

      return matchProductId && matchProductOptionId
    })

    return sortArrayBy(filtered, 'asc', 'id')
  }, [productOptionValuesSubsetsUpdatedAt, JSON.stringify(filters)])

  const filteredProductOptionValuesSubsetsCount = filteredProductOptionValuesSubsets.length
  const hasFilteredProductOptionValuesSubsets = !!filteredProductOptionValuesSubsetsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredProductOptionValuesSubsetsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingProductOptionValuesSubsets } = useReduxAction(
    'productOptionValuesSubsets',
    'loadProductOptionValuesSubsets',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredProductOptionValuesSubsets,
    filteredProductOptionValuesSubsetsCount,
    hasFilteredProductOptionValuesSubsets,
    loading: loadingProductOptionValuesSubsets,
  }
}

export default useProductOptionValuesSubsets
