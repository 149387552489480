import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

const watchEntityKeys = ['dataStoreItems']

type DataStoreItemFilters = {
  projectId?: number,
}

type UseDataStoreItemsOptions = {
  filters?: DataStoreItemFilters,
  performHttpRequests?: boolean,
}

function useDataStoreItems(options: UseDataStoreItemsOptions) {
  const { filters = {} } = options
  const {
    projectId: filterProjectId,
  } = filters

  const {
    updatedEntities: { dataStoreItems: dataStoreItemsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { dataStoreItems } = useSelector(reduxState => reduxState.entities)

  const filteredDataStoreItems = useMemo(() => {
    const filtered = Object.values(dataStoreItems).filter((dataStoreItem) => {
      const { projectId } = dataStoreItem

      const matchProjectId = matchFilterNumber(projectId, filterProjectId)

      return matchProjectId
    })

    return sortArrayBy(filtered, 'asc', 'id')
  }, [dataStoreItemsUpdatedAt, JSON.stringify(filters)])

  const filteredDataStoreItemsCount = filteredDataStoreItems.length
  const hasFilteredDataStoreItems = !!filteredDataStoreItemsCount

  return {
    filteredDataStoreItems,
    filteredDataStoreItemsCount,
    hasFilteredDataStoreItems,
  }
}

export default useDataStoreItems
