import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ProductOptionValuesSubsetModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/productOptionValuesSubset/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/productOptionValuesSubset/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/productOptionValuesSubset/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/productOptionValuesSubset/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/productOptionValuesSubset/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/productOptionValuesSubset/CREATE_FAILURE'

export type ProductOptionValuesSubsetModuleState = {
  creating: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loading: boolean,
}

// Initial State
const initialState: ProductOptionValuesSubsetModuleState = {
  creating: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadProductOptionValuesSubsets(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().productOptionValuesSubsets.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/product-option-values-subsets', options)
      .then(({ data }: { data: { data: ProductOptionValuesSubsetModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.PRODUCT_OPTION_VALUES_SUBSET_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export type BulkCreateParams = {
  productId: number,
  productOptionId: number,
  productOptionValueIds: number[],
}

export function bulkCreateProductOptionValuesSubsets(
  params: BulkCreateParams,
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    data: JSON.stringify(params),
  }

  const { productOptionId } = params

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    dispatch(createRequest())

    const promise = api('/product-option-values-subsets/bulk', options, config)
      .then(({ data }: { data: ProductOptionValuesSubsetModel[] }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_OPTION_VALUES_SUBSET_ARRAY)

        // Remove all existing ProductOptionValuesSubsets related to the ProductOption
        const { productOptionValuesSubsets } = getState().entities
        Object.values(productOptionValuesSubsets).forEach((productOptionValuesSubset) => {
          if (productOptionValuesSubset.productOptionId === productOptionId){
            const normalizedSubset = normalize(productOptionValuesSubset, Schemas.PRODUCT_OPTION_VALUES_SUBSET)
            dispatch(deleteEntity(normalizedSubset))
          }
        })

        // Update the store with the new ProductOptionValuesSubsets
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function removeProductOptionValuesSubset(
  dispatch: AppDispatch,
  productOptionValuesSubset: ProductOptionValuesSubsetModel,
) {
  const normalizedJson = normalize(productOptionValuesSubset, Schemas.PRODUCT_OPTION_VALUES_SUBSET)
  dispatch(deleteEntity(normalizedJson))
}

// Reducer
export default function reducer(
  state: ProductOptionValuesSubsetModuleState = initialState,
  action: Action = { type: '' },
): ProductOptionValuesSubsetModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
