import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import { confirmAction } from '@functions/entity'

import useCampaign from '@hooks/useCampaign'

interface OrderToolsProps {
  campaignPayload: ReturnType<typeof useCampaign>,
}

const OrderTools = (props: OrderToolsProps) => {
  const {
    campaignPayload:{
      callbacks: {
        generateArtworksForCampaign: generateArtworksForCampaignFn,
      },
    },
  } = props

  const generateArtworksForCampaignPayload = {
    callbacks: {
      actionFn: generateArtworksForCampaignFn,
    },
    options: {
      entityParams: { purge: true },
      isBulkAction: true,
    },
    strings: {
      actionString: 'regenerate',
      entityName: 'artworks',
    },
  }

  return (
    <Tools>
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faSync} />}
        marginBottom="medium"
        onClick={() => confirmAction(generateArtworksForCampaignPayload)}
        size="medium"
      >
        Regenerate Artworks
      </Button>
    </Tools>
  )
}

export default OrderTools
