import { useContext } from 'react'

import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { confirmDelete } from '@functions/entity'

import { ArtworkTemplateGroupLinkModel } from '@models/types'
import useArtworkTemplateGroupLink from '@hooks/useArtworkTemplateGroupLink'

interface ArtworkTemplateGroupLinkListItemProps {
  artworkTemplateGroupLink: ArtworkTemplateGroupLinkModel,
}

const ArtworkTemplateGroupLinkListItem = (props: ArtworkTemplateGroupLinkListItemProps) => {
  const { artworkTemplateGroupLink } = props

  const artworkTemplateGroupLinkPayload = useArtworkTemplateGroupLink(artworkTemplateGroupLink)

  const {
    callbacks: {
      deleteArtworkTemplateGroupLink: deleteFn,
    },
    artworkTemplateGroupLink: {
      entityName,
    },
  } = artworkTemplateGroupLinkPayload

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext
  const { deleteArtworkTemplateGroupLinks } = callbacks || {}

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deleteArtworkTemplateGroupLinks,
      deleteFn,
    },
    strings: {
      entityName: 'Artwork Template Group Link',
    },
  }

  return (
    <DashboardModuleListItem
      title={entityName}
    >
      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon
          boxProps={{ marginLeft: 'medium' }}
          onClick={() => confirmDelete(confirmDeletePayload)}
          type="delete"
        />

      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default ArtworkTemplateGroupLinkListItem
