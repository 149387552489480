import { useContext } from 'react'

import {
  Button,
  Columns,
  LoadingModule,
  SaveFormModule,
  SectionDivider,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useCutterProfile, { useCutterProfileForm } from '@hooks/useCutterProfile'

import EntityActivity from '@components/EntityActivity'
import CutterProfileDetails from '../CutterProfileDetails'
import CutterProfileModules from '../CutterProfileModules'
import PageLayout from '../../../../components/PageLayout'

interface PageContentProps {
  cutterProfileId: number,
}

const PageContent = (props: PageContentProps) => {
  const { cutterProfileId } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      updateCutterProfile,
    },
  } = pageContext

  const cutterProfilePayload = useCutterProfile({ id: cutterProfileId })
  const {
    cutterProfile,
    callbacks: {
      updateCutterProfile: updateFn,
    },
    loading,
    updating,
  } = cutterProfilePayload

  const cutterProfileFormPayload = useCutterProfileForm(cutterProfile)
  const { entityState, saveEnabled } = cutterProfileFormPayload

  const updateCutterProfilePayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'Cutter Profile Updated',
  }

  return (
    <PageLayout cutterProfilePayload={cutterProfilePayload} activeTabBarItemKey="details">
      <Columns.Main>
        <LoadingModule loading={loading} times={3} />

        {!loading && (
          <>
            <CutterProfileDetails cutterProfileFormPayload={cutterProfileFormPayload} />

            <SaveFormModule>
              <Button
                buttonStyle="primaryCreate"
                disabled={!saveEnabled}
                loading={updating}
                marginLeft="auto"
                onClick={() => updateCutterProfile(updateCutterProfilePayload)}
                size="medium"
              >
                Save Details
              </Button>
            </SaveFormModule>

            <SectionDivider>Modules</SectionDivider>

            <CutterProfileModules cutterProfileId={cutterProfileId} />

            <EntityActivity subjectId={cutterProfileId} subjectType="CutterProfile" />
          </>
        )}
      </Columns.Main>
    </PageLayout>
  )
}

export default PageContent
