import { Route, Routes } from 'react-router-dom'

import CutterProfileEdit from './Edit'
import CutterProfilesList from './List'

const CutterProfilesRoutes = () => (
  <Routes>
    <Route path="/" element={<CutterProfilesList />} />
    <Route path="/:cutterProfileId/edit/*" element={<CutterProfileEdit />} />
  </Routes>
)

export default CutterProfilesRoutes
