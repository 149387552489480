import { useState } from 'react'

import { useSetState } from '@campaignhub/react-hooks'

import usePriceListItem from '@hooks/usePriceListItem'
import usePriceListItemOption from '@hooks/usePriceListItemOption'
import usePriceListItemOptions from '@hooks/usePriceListItemOptions'
import useProduct from '@hooks/useProduct'
import useProductOptions from '@hooks/useProductOptions'
import useSelector from '@hooks/useSelector'

const defaultState = {
  name: '',
}

type BulkCreatePriceListItemOptionsParams = {
  priceListItemId: number,
}

const useBulkCreatePriceListItemOptions = (params: BulkCreatePriceListItemOptionsParams) => {
  const { priceListItemId } = params

  const [selectedProductOptionIds, setSelectedProductOptionIds] = useState<number[]>([])

  const [state, setState] = useSetState(defaultState)
  const { name } = state

  const { productOptions } = useSelector(reduxState => reduxState.entities)

  const {
    callbacks: {
      bulkCreatePriceListItemOptions,
    },
    creating,
  } = usePriceListItemOption()

  const {
    priceListItem,
    product,
  } = usePriceListItem({ id: priceListItemId })

  // Request all the ProductOptions which may be selected in this modal
  useProduct(product, {
    performHttpRequests: true,
    requestOptions: {
      include: 'ProductOptionProducts.ProductOption',
    },
  })

  const productOptionsPayload = useProductOptions({
    filters: {
      string: name,
      productId: product.id,
    },
  })

  const { filteredProductOptions } = productOptionsPayload

  // Filter out ProductOption Ids which are already assigned
  const priceListItemOptionsPayload = usePriceListItemOptions({
    filters: {
      priceListItemId,
    },
  })

  const { filteredPriceListItemOptions } = priceListItemOptionsPayload
  const assignedProductOptionIds = filteredPriceListItemOptions.map(priceListItemOption => (
    priceListItemOption.productOptionId
  ))

  const filteredProductOptionIds = filteredProductOptions.reduce<number[]>((acc, value) => {
    if (!assignedProductOptionIds?.includes(value.id)){
      acc.push(value.id)
    }

    return acc
  }, [])

  const entityParams = {
    priceListItemId,
    productOptionIds: selectedProductOptionIds,
  }

  return {
    callbacks: {
      bulkCreatePriceListItemOptions,
      setSelectedProductOptionIds,
      setState,
    },
    creating,
    entityParams,
    filteredProductOptionIds,
    filteredProductOptionIdsCount: filteredProductOptionIds.length,
    name,
    priceListItem,
    product,
    productOptions,
    productOptionsPayload,
    saveEnabled: !!selectedProductOptionIds.length,
    selectedProductOptionIds,
  }
}

export default useBulkCreatePriceListItemOptions
