export const FACEBOOK_AD_TYPE = 'Facebook'
export const GOOGLE_DISPLAY_AD_TYPE = 'GoogleDisplay'
export const GOOGLE_SEARCH_AD_TYPE = 'GoogleSearch'

const getObjectivesList = (adType?: string) => {
  const isFacebookAdType = adType === FACEBOOK_AD_TYPE
  const isGoogleAdType = [GOOGLE_DISPLAY_AD_TYPE, GOOGLE_SEARCH_AD_TYPE].includes(adType)

  const facebookObjectives = [
    { key: 'Traffic', label: 'Traffic' },
    { key: 'Awareness', label: 'Awareness' },
    { key: 'Lead', label: 'Lead' },
    { key: 'Conversions', label: 'Conversions' },
  ]

  const googleObjectives = [
    { key: 'None', label: 'None' },
    { key: 'WebsiteVisits', label: 'Website Visits' },
    { key: 'Leads', label: 'Leads' },
    { key: 'BrandAwareness&Reach', label: 'Brand Awareness & Reach' },
  ]

  if (isFacebookAdType) return facebookObjectives
  if (isGoogleAdType) return googleObjectives
  return [...facebookObjectives, ...googleObjectives]
}

export default getObjectivesList
