import { useMemo } from 'react'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['adCampaignStatuses']

type UseAdCampaignStatusesOptions = {
  performHttpRequests?: boolean,
}

function useAdCampaignStatuses(options: UseAdCampaignStatusesOptions) {
  const { performHttpRequests } = options

  const {
    updatedEntities: { adCampaignStatuses: adCampaignStatusesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { adCampaignStatuses: entityAdCampaignStatuses } = useSelector(reduxState => reduxState.entities)

  const adCampaignStatuses = useMemo(() => Object.values(entityAdCampaignStatuses), [adCampaignStatusesUpdatedAt])

  const { loading: loadingAdCampaignStatuses } = useReduxAction(
    'adCampaignStatuses',
    'loadAdCampaignStatuses',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  return {
    adCampaignStatuses,
    loading: loadingAdCampaignStatuses,
  }
}

export default useAdCampaignStatuses
