import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Form, Line, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import AdContentList from './AdContentList'
import useManageCaptionsModal from './hooks/useManageCaptionsModal'

const MODAL_KEY = 'ManageCaptionsModal'

type ModalCallbacks = {
  closeModal: () => void,
  createOrUpdateCaptions: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const ManageCaptionsModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createOrUpdateCaptions } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const captionsParams = digObject(modalPayload, 'captionsParams', {})

  const { campaignId } = captionsParams

  const {
    callbacks: {
      createOrUpdateCaptions: createOrUpdateFn,
      setCaptionsFormState,
      setState,
      validateCaptionLength,
    },
    captionsFormState,
    creating,
    state,
  } = useManageCaptionsModal({ campaignId })

  const {
    callToActions,
    descriptions,
    displayUrls,
    headlines,
    texts,
  } = captionsFormState

  const { captionText, captionType } = state

  const { isValid, error } = validateCaptionLength(captionText, captionType)

  const createOrUpdateCaptionsPayload = {
    callbacks: {
      action: createOrUpdateFn,
      afterAction: closeModal,
    },
    entityParams: captionsFormState,
    toastText: 'Captions Updated',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Ad Content" />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <Form>
            <Form.Field label="Ad Type">
              <select onChange={e => setState({ captionType: e.target.value })} value={captionType}>
                <option value="">Please Select...</option>
                <option value="headlines">Headline</option>
                <option value="descriptions">Description</option>
                <option value="texts">Text</option>
                <option value="callToActions">Call to Action</option>
                <option value="displayUrls">Display URL</option>
              </select>
            </Form.Field>

            <Form.Field label="Content" marginTop="large" errorMessage={error}>
              <textarea
                disabled={!captionType}
                onChange={e => setState({ captionText: e.target.value })}
                style={{ height: 120, resize: 'vertical' }}
                value={captionText}
              />
            </Form.Field>

            <Button
              buttonStyle="secondaryUtility"
              disabled={!captionType || !captionText || !isValid}
              icon={<FontAwesomeIcon icon={faPlus} />}
              marginTop="large"
              onClick={() => {
                setCaptionsFormState({
                ...captionsFormState,
                [captionType]: [...captionsFormState[captionType], captionText],
                })
                setState({ captionText: '' })
              }}
              size="medium"
            >
              Add Content
            </Button>
          </Form>

          <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />

          <AdContentList
            callbacks={{ setCaptionsFormState }}
            captionsFormState={captionsFormState}
            formStateKey="headlines"
            label="Headlines"
            list={headlines}
          />

          <Line style={{ marginLeft: -16, marginTop: 0, width: 'calc(100% + 32px)' }} />

          <AdContentList
            callbacks={{ setCaptionsFormState }}
            captionsFormState={captionsFormState}
            formStateKey="descriptions"
            label="Descriptions"
            list={descriptions}
          />

          <Line style={{ marginLeft: -16, marginTop: 0, width: 'calc(100% + 32px)' }} />

          <AdContentList
            callbacks={{ setCaptionsFormState }}
            captionsFormState={captionsFormState}
            formStateKey="texts"
            label="Text"
            list={texts}
          />

          <Line style={{ marginLeft: -16, marginTop: 0, width: 'calc(100% + 32px)' }} />

          <AdContentList
            callbacks={{ setCaptionsFormState }}
            captionsFormState={captionsFormState}
            formStateKey="callToActions"
            label="Calls to Action"
            list={callToActions}
          />

          <Line style={{ marginLeft: -16, marginTop: 0, width: 'calc(100% + 32px)' }} />

          <AdContentList
            callbacks={{ setCaptionsFormState }}
            captionsFormState={captionsFormState}
            formStateKey="displayUrls"
            label="Display URLs"
            list={displayUrls}
          />
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => createOrUpdateCaptions(createOrUpdateCaptionsPayload)}
          size="large"
        >
          Save Ad Content
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageCaptionsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
