import usePriceList from '@hooks/usePriceList'

import usePriceListManager from './hooks/usePriceListManager'

import Editor from './components/Editor'

import PageLayout from '../../../../components/PageLayout'

interface PriceListManagerProps {
  priceListManagerPayload: ReturnType<typeof usePriceListManager>,
}

const PriceListManager = (props: PriceListManagerProps) => {
  const {
    priceListManagerPayload,
    priceListManagerPayload: {
      priceListPayload: {
        loading,
        priceList,
      },
    },
  } = props

  const priceListPayload = usePriceList(priceList)

  return (
    <PageLayout priceListPayload={priceListPayload} activeTabBarItemKey="items">
      <Editor
        loading={loading}
        priceListManagerPayload={priceListManagerPayload}
      />
    </PageLayout>
  )
}

export default PriceListManager
