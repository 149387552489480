import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { Box, Link } from '@campaignhub/suit-theme'

import useAdCampaign from '@hooks/useAdCampaign'

interface ActionsProps {
  adCampaignId: number,
}

const Actions = (props: ActionsProps) => {
  const { adCampaignId } = props

  const {
    callbacks: {
      manageAdCampaign,
    },
  } = useAdCampaign({ id: adCampaignId })

  return (
    <Box alignItems="center" justifyContent="center">
      <Link
        greyLink
        onClick={() => manageAdCampaign()}
        textProps={{ fontSize: 'medium', height: 'fit-content' }}
      >
        <FontAwesomeIcon icon={faPencil} />
      </Link>
    </Box>
  )
}

export default Actions
