import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, ListItem, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import getStatusBadgeProps from '@functions/statusBadge'

import { useRelations } from '@hooks/useCampaign'

import { CampaignModel } from '@models/types'

interface WebsiteDetailsProps {
  campaign: CampaignModel,
}

const WebsiteDetails = (props: WebsiteDetailsProps) => {
  const { campaign } = props

  const { landingPage } = useRelations(campaign)
  const { isValidated, landingPageTypeId, url } = landingPage

  // Hardcoded Live status ok because we check isValidated before displaying
  const statusBadgeProps = getStatusBadgeProps('Live')

  return (
    <ListItem boxProps={{ borderBottom: '1px solid' }} disableHover>
      {isValidated && !!url && (
        <>
          <Box flexDirection="column">
            <Text color="bodyFontLightColor" fontSize="xsmall" padding="large">Destination</Text>

            <Box paddingX="large" paddingBottom="large">
              {/* <Box width={70} marginRight="large">
                <Image
                  borderRadius={5}
                  height={40}
                  placeholderUrl={placeholderImageUrl}
                  url={null}
                  width={70}
                />
              </Box> */}

              <Box flexDirection="column" justifyContent="center">
                <Box>
                  <Text fontSize="small">
                    {url}

                    <Text as="span" color="bodyFontLightColor" fontSize="small"> {`(${landingPageTypeId})`}</Text>
                  </Text>
                </Box>

                <StatusBadge
                  boxProps={{ borderWidth: 2, marginTop: 'medium' }}
                  ghost
                  {...statusBadgeProps}
                />
              </Box>
            </Box>
          </Box>

          <Box alignItems="center" justifyContent="center" paddingRight="large" width={32}>
            <Link
              greyLink
              href={url}
              target="_blank"
            >
              <FontAwesomeIcon icon={faExternalLink} />
            </Link>
          </Box>
        </>
      )}

      {!url && (
        <Box flexDirection="column">
          <Text color="bodyFontLightColor" fontSize="xsmall" padding="large">Destination</Text>

          <Text fontSize="small" marginBottom="large" marginLeft="large">
            No URL Generated
          </Text>

          <StatusBadge
            boxProps={{
              borderWidth: 2,
              marginBottom: 'large',
              marginLeft: 'large',
            }}
            color="mysteryGrey"
            ghost
            text="Draft"
          />
        </Box>
      )}
    </ListItem>
  )
}

export default WebsiteDetails
