import { Box, DashboardModule, DashboardModuleListItem } from '@campaignhub/suit-theme'
import HexColour from '@components/HexColour'

import useBrand from '@hooks/useBrand'

interface BrandColoursProps {
  brandPayload: ReturnType<typeof useBrand>,
}

const BrandColours = (props: BrandColoursProps) => {
  const {
    brandPayload: {
      brand: {
        colourPrimary,
        colourSecondary,
      },
      loading,
    },
  } = props


  return (
    <DashboardModule loading={loading} title="Brand Colours">
      <Box flexDirection="column">
        <DashboardModuleListItem
          secondaryText="Primary Colour"
          title={colourPrimary || 'Colour Code Not Added'}
        >
          <DashboardModuleListItem.TertiaryText>
            <HexColour colour={`#${colourPrimary}`} />
          </DashboardModuleListItem.TertiaryText>
        </DashboardModuleListItem>

        <DashboardModuleListItem
          secondaryText="Secondary Colour"
          title={colourSecondary || 'Colour Code Not Added'}
        >
          <DashboardModuleListItem.TertiaryText>
            <HexColour colour={`#${colourSecondary}`} />
          </DashboardModuleListItem.TertiaryText>
        </DashboardModuleListItem>
      </Box>
    </DashboardModule>
  )
}

export default BrandColours
