import { useNumericParams } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import BrandEditRoutes from './routes'

const BrandEdit = () => {
  const { brandId } = useNumericParams()

  useReduxAction(
    'brands',
    'loadBrand',
    {
      entityId: brandId,
    },
    [brandId],
    {
      dispatchAction: (action, requestOptions) => action(brandId, requestOptions),
      shouldPerformFn: ({ loading }) => !!brandId && !loading,
    },
  )

  return (
    <BrandEditRoutes />
  )
}

export default BrandEdit
