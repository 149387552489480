import {
  DashboardModule, ModuleBlankState,
} from '@campaignhub/suit-theme'

import filter from '@assets/filter.svg'

import useAdCampaigns from '@hooks/useAdCampaigns'

import type { AdCampaignFilters } from '@hooks/useAdCampaigns'

import ActiveCampaignsTable from './ActiveCampaignsTable'

interface ActiveCampaignsProps {
  pageFilters: AdCampaignFilters,
}

const ActiveCampaigns = (props: ActiveCampaignsProps) => {
  const { pageFilters } = props

  const adCampaignsPayload = useAdCampaigns({
    filters: {
      ...pageFilters,
    },
    showActiveOnly: true,
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdCampaigns,
    filteredAdCampaignsCount,
    hasFilteredAdCampaigns,
    loading,
  } = adCampaignsPayload

  return (
    <DashboardModule
      boxProps={{ borderBottom: loading && !hasFilteredAdCampaigns ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Active Campaigns"
    >
      {!loading && !hasFilteredAdCampaigns && (
        <ModuleBlankState boxProps={{ height: 200 }} imageUrl={filter}>
          <ModuleBlankState.Title>No Results</ModuleBlankState.Title>

          <ModuleBlankState.Paragraph>
            Try changing your filters, or wait for more results
          </ModuleBlankState.Paragraph>
        </ModuleBlankState>
      )}

      {!loading && hasFilteredAdCampaigns && (
        <ActiveCampaignsTable adCampaigns={filteredAdCampaigns} />
      )}

      <DashboardModule.LoadMoreFooter
        boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
        callbacks={{ loadMore }}
        canLoadMore={canLoadMore}
        entityCount={filteredAdCampaignsCount}
        loading={loading}
      />
    </DashboardModule>
  )
}

export default ActiveCampaigns
