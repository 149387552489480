import {
  adminRoles, agencyAdminRoles, agencyRoles, agentRoles, brandRoles,
} from '@functions/userType'

export function isAdmin(roleTitle: string): boolean {
  return adminRoles.includes(roleTitle)
}

export function isAgencyAdmin(roleTitle: string): boolean {
  return agencyAdminRoles.includes(roleTitle)
}

export function isAgent(roleTitle: string): boolean {
  return agentRoles.includes(roleTitle)
}

export function isAgencyUser(roleTitle: string): boolean {
  return agencyRoles.includes(roleTitle)
}

export function isBrandUser(roleTitle: string): boolean {
  return brandRoles.includes(roleTitle)
}
