import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

import { Box, Columns } from '@campaignhub/suit-theme'

import PageHeader from '@components/PageHeader'

import useCampaign from '@hooks/useCampaign'

import generateTabBarItems from '../../utils/generateTabBarItems'

interface PageLayoutProps {
  activeTabBarItemKey?: string,
  campaignPayload: ReturnType<typeof useCampaign>,
  children: ReactNode,
}

const PageLayout = (props: PageLayoutProps) => {
  const {
    activeTabBarItemKey,
    campaignPayload: {
      campaign,
    },
    children,
  } = props

  return (
    <>
      <Helmet>
        <title>Campaign Overview | AIM</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey={activeTabBarItemKey}
        nestedNavigation
        tabBarItems={generateTabBarItems(campaign)}
        title={`Campaign (AIM #${campaign.id})`}
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          {children}
        </Columns>
      </Box>
    </>
  )
}

export default PageLayout
