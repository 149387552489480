import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { AdTemplateProductModel, AdTemplateProductRequestOptions } from '@models/types'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['adTemplateProducts']

type AdTemplateProductFilters = {
  adTemplateId?: number,
  adTemplateName?: string,
  productId?: number,
  productName?: string,
}

type UseAdTemplateProductsOptions = {
  filters?: AdTemplateProductFilters,
  performHttpRequests?: boolean,
  requestOptions?: AdTemplateProductRequestOptions,
  sortKey?: 'adTemplate' | 'product',
}

export function useAdTemplateProducts(options: UseAdTemplateProductsOptions) {
  const { filters = {}, requestOptions, sortKey } = options
  const {
    adTemplateId: filterAdTemplateId,
    adTemplateName: filterAdTemplateName,
    productId: filterProductId,
    productName: filterProductName,
  } = filters

  const {
    updatedEntities: { adTemplateProducts: adTemplateProductsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { adTemplateProducts, adTemplates, products } = useSelector(reduxState => reduxState.entities)

  const filteredAdTemplateProducts = useMemo(() => {
    const filtered = Object.values(adTemplateProducts).filter((adTemplateProduct: AdTemplateProductModel) => {
      const { adTemplateId, productId } = adTemplateProduct

      const adTemplateName = adTemplates[adTemplateId]?.name
      const productName = products[productId]?.name

      const matchAdTemplateId = matchFilterNumber(adTemplateId, filterAdTemplateId)
      const matchAdTemplateName = matchFilterString(adTemplateName, filterAdTemplateName)
      const matchAdTemplateString = matchAdTemplateId && matchAdTemplateName

      const matchProductId = matchFilterNumber(productId, filterProductId)
      const matchProductName = matchFilterString(productName, filterProductName)
      const matchProductString = matchProductId && matchProductName

      return matchAdTemplateString && matchProductString
    })

    const sortedArray = filtered.sort((adTemplateProduct1, adTemplateProduct2) => {
      const comparison1 = Number(adTemplateProduct2.isDefault) - Number(adTemplateProduct1.isDefault)

      if (comparison1 !== 0){
        return comparison1
      }

      const key = sortKey === 'adTemplate' ? 'adTemplateId' : 'productId'
      const targetObject = sortKey === 'adTemplate' ? adTemplates : products
      const name1 = targetObject[adTemplateProduct1[key]]?.name || ''
      const name2 = targetObject[adTemplateProduct2[key]]?.name || ''

      return name1.localeCompare(name2)
    })

    return sortedArray
  }, [adTemplateProductsUpdatedAt, JSON.stringify(filters)])

  const filteredAdTemplateProductsCount = filteredAdTemplateProducts.length
  const hasFilteredAdTemplateProducts = !!filteredAdTemplateProductsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredAdTemplateProductsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingAdTemplateProducts } = useReduxAction(
    'adTemplateProducts',
    'loadAdTemplateProducts',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdTemplateProducts,
    filteredAdTemplateProductsCount,
    hasFilteredAdTemplateProducts,
    loading: loadingAdTemplateProducts,
  }
}

export default useAdTemplateProducts
