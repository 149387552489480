import { useMemo } from 'react'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useComment from '@hooks/useComment'
import useComments from '@hooks/useComments'
import useConversation from '@hooks/useConversation'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import {
  CommentModel, CommentRequestOptions, ConversationModel, ConversationRequestOptions,
} from '@models/types'

const watchEntityKeys = ['conversations']

type ConversationContainerParams = {
  performHttpRequests?: boolean,
  subjectId: number,
  subjectType: string,
}

type FindOrBuildConversationParams = {
  conversations: { [key: number]: ConversationModel },
} & Pick<ConversationContainerParams, 'subjectId' | 'subjectType'>

const findOrBuildConversation = (params: FindOrBuildConversationParams) => {
  const { conversations, subjectId, subjectType } = params

  const conversationsArray = Object.values(conversations)

  const existingConversation = conversationsArray.find(conversation => (
    conversation.subjectId === subjectId && conversation.subjectType === subjectType
  ))

  return existingConversation?.id ? existingConversation : { subjectId, subjectType }
}

type CreateCommentWithConversationParams = {
  commentParams: Partial<CommentModel>,
  conversation: ConversationModel,
  createComment: ReturnType<typeof useComment>['callbacks']['createComment'],
  createConversation: ReturnType<typeof useConversation>['callbacks']['createConversation'],
  requestOptions?: CommentRequestOptions | ConversationRequestOptions,
}

const createCommentWithConversation = (params: CreateCommentWithConversationParams) => {
  const {
    commentParams, conversation, createComment, createConversation,
  } = params

  // If we have a conversation already we can just create a comment
  const updatedComment = {
    conversationId: conversation.id,
    ...commentParams,
  }

  if (conversation.id) return createComment(updatedComment, { include: 'User.AssetGroup.Assets' })

  // If we do not have a conversation, we must create the conversation with the comment
  const updatedConversation = {
    ...conversation,
    comments: [commentParams],
  }

  return createConversation(updatedConversation, { include: 'Comments.User.AssetGroup.Assets' })
}

function useConversationContainer(options: ConversationContainerParams) {
  const {
    performHttpRequests,
    subjectId,
    subjectType,
  } = options

  const { loading } = useReduxAction(
    'conversations',
    'loadConversationForSubject',
    {
      entityKey: subjectType + subjectId,
      include: 'Comments.User.AssetGroup.Assets',
    },
    [subjectId, subjectType],
    {
      dispatchAction: (action, requestOptions) => action({ subjectId, subjectType }, requestOptions),
      shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  const {
    updatedEntities: { conversations: conversationsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const conversations = useSelector(reduxState => reduxState.entities.conversations)

  const initConversation = useMemo(() => (
    findOrBuildConversation({ conversations, subjectId, subjectType })
  ), [conversationsUpdatedAt, subjectId, subjectType])

  const {
    callbacks: { createConversation },
    conversation,
    creating: creatingConversation,
  } = useConversation(initConversation)

  const {
    callbacks: { createComment },
    creating: creatingComment,
  } = useComment()

  const commentsPayload = useComments({
    filters: {
      conversationId: conversation.id,
    },
  })

  const {
    filteredComments,
    filteredCommentsCount,
    hasFilteredComments,
  } = commentsPayload

  return {
    callbacks: {
      createCommentWithConversation: (commentParams: Partial<CommentModel>) => createCommentWithConversation({
        commentParams, conversation, createComment, createConversation,
      }),
    },
    creating: creatingComment || creatingConversation,
    filteredComments,
    filteredCommentsCount,
    hasFilteredComments,
    loading,
  }
}

export default useConversationContainer
