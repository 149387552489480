import { Button, DashboardModule } from '@campaignhub/suit-theme'

import useAsset from '@hooks/useAsset'
import useAssets from '@hooks/useAssets'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import ProductImageListItem from './ProductImageListItem'

interface ProductImageProps {
  filters: {
    assetTypeCategory: string,
    assetableId: number,
    assetableType: string,
  },
}

const ProductImage = (props: ProductImageProps) => {
  const { filters } = props

  const assetsPayload = useAssets({
    filters,
    performHttpRequests: true,
  })

  const {
    filteredAssets,
    hasFilteredAssets,
    loading,
  } = assetsPayload

  const {
    callbacks: {
      createOrEditImage,
    },
  } = useAsset()

  // There should only be 1 image on a Product, so taking [0] should be fine
  const image = filteredAssets[0]

  return (
    <DashboardModule loading={loading} title="Product Image">
      {!loading && (
        <>
          {hasFilteredAssets && (
            <ProductImageListItem image={image} filters={filters} />
          )}

          {!hasFilteredAssets && (
            <DashboardModule.Footer boxProps={{ borderTop: 'none' }}>
              <DashboardModule.FooterRight>
                <Button
                  buttonStyle="secondaryEdit"
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => createOrEditImage(filters)}
                  size={['medium', 'small']}
                  width={['100%', 'fit-content']}
                >
                  Upload Image
                </Button>
              </DashboardModule.FooterRight>
            </DashboardModule.Footer>
          )}
        </>
      )}
    </DashboardModule>
  )
}

export default ProductImage
