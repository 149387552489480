import { useAuth0 } from '@auth0/auth0-react'

type UseAuth0Payload = ReturnType<typeof useAuth0>

let accessToken: null | string = null

export const setAccessToken = async (getAccessTokenSilently: UseAuth0Payload['getAccessTokenSilently']) => {
  try {
    accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
      },
    })
  } catch (error){
    console.log(error)
  }
}

export const getAccessToken = () => accessToken
