import { useContext } from 'react'

import { useLatestEntity } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as adQueueActions from '@redux/modules/adQueue'
import type { BulkDeleteParams } from '@redux/modules/adQueue'

import PageContext from '@contexts/pageContext'

import type {
  AdQueueModel, AdQueueRequestOptions, AdTemplateModel, AdTemplatePayloadModel, CampaignModel,
} from '@models/types'

import type { AppDispatch } from '@redux/store'
import type { ModuleState } from '@redux/modules/types'

type BulkProcessAdQueueParams = {
  adQueueIds: number[],
  dispatch: AppDispatch,
}

const bulkProcessAdQueues = (params: BulkProcessAdQueueParams) => {
  const { adQueueIds, dispatch } = params
  const { bulkProcessAdQueues: bulkProcessFn } = adQueueActions

  return dispatch(bulkProcessFn(adQueueIds))
}

type DeleteAdQueueParams = {
  adQueue: AdQueueModel,
  dispatch: AppDispatch,
}

const deleteAdQueue = (params: DeleteAdQueueParams) => {
  const { adQueue, dispatch } = params
  const { deleteAdQueue: deleteFn } = adQueueActions

  return dispatch(deleteFn(adQueue))
}

type ProcessAdQueueParams = {
  adQueue: AdQueueModel,
  dispatch: AppDispatch,
}

const processAdQueue = (params: ProcessAdQueueParams) => {
  const { adQueue, dispatch } = params
  const { processAdQueue: processFn } = adQueueActions

  return dispatch(processFn(adQueue))
}

type BulkDeleteAdQueuesParams = {
  bulkUpdateParams: BulkDeleteParams,
  dispatch: AppDispatch,
}

const bulkDeleteAdQueues = (params: BulkDeleteAdQueuesParams) => {
  const { bulkUpdateParams, dispatch } = params
  const { bulkDeleteAdQueues: bulkDeleteAdQueuesFn } = adQueueActions

  return dispatch(bulkDeleteAdQueuesFn(bulkUpdateParams))
}

type BulkDeleteAndRequeueAdQueuesParams = {
  adQueueIds: number[],
  dispatch: AppDispatch,
}

const bulkDeleteAndRequeueAdQueues = (params: BulkDeleteAndRequeueAdQueuesParams) => {
  const { adQueueIds, dispatch } = params
  const { bulkDeleteAndRequeueAdQueues: bulkDeleteAndRequeueAdQueuesFn } = adQueueActions

  return dispatch(bulkDeleteAndRequeueAdQueuesFn(adQueueIds))
}

export const useRelations = (adQueue: Partial<AdQueueModel> = {}) => {
  const { adTemplatePayloadId, campaignId } = adQueue

  const entities = useSelector(reduxState => reduxState.entities)
  const { adTemplates, adTemplatePayloads, campaigns } = entities

  const adTemplatePayload: Partial<AdTemplatePayloadModel> = (
    adTemplatePayloadId && adTemplatePayloads[adTemplatePayloadId]
      ? adTemplatePayloads[adTemplatePayloadId]
      : {}
  )

  const { adTemplateId } = adTemplatePayload
  const adTemplate: Partial<AdTemplateModel> = adTemplateId && adTemplates[adTemplateId]
    ? adTemplates[adTemplateId]
    : {}

  const campaign: Partial<CampaignModel> = campaignId && campaigns[campaignId] ? campaigns[campaignId] : {}

  return {
    adTemplate,
    adTemplatePayload,
    campaign,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: AdQueueRequestOptions,
}

function useAdQueue(initEntity: Partial<AdQueueModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: adQueue }: { entity: AdQueueModel} = useLatestEntity(initEntity, 'adQueues')
  const { id } = adQueue

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'adQueues',
    'loadAdQueue',
    {
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const { creating, deleting, loading } = useSelector(reduxState => reduxState.adQueues)

  return {
    adQueue,
    callbacks: {
      bulkDeleteAdQueues: (bulkUpdateParams: BulkDeleteParams) => bulkDeleteAdQueues({ bulkUpdateParams, dispatch }),
      bulkDeleteAndRequeueAdQueues: (adQueueIds: number[]) => bulkDeleteAndRequeueAdQueues({ adQueueIds, dispatch }),
      bulkProcessAdQueues: (adQueueIds: number[]) => bulkProcessAdQueues({ adQueueIds, dispatch }),
      deleteAdQueue: () => deleteAdQueue({ adQueue, dispatch }),
      manageAdDeployment: () => launchModal({
        callbacks,
        modalKey: 'ManageAdDeploymentModal',
        payload: { adQueue },
      }),
      processAdQueue: () => processAdQueue({ adQueue, dispatch }),
      viewSelectedLocations: () => launchModal({
        callbacks,
        modalKey: 'SelectedLocationsModal',
        payload: { adQueue },
      }),
      viewSelectedPlaybooks: () => launchModal({
        callbacks,
        modalKey: 'SelectedPlaybooksModal',
        payload: { adQueue },
      }),
    },
    creating,
    deleting,
    loading,
  }
}

export default useAdQueue
