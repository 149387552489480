import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

import systemColours from '@functions/colours'

import { CampaignValidationModel } from '@models/types'

const getIcon = (status: string | null) => {
  switch (status){
    case 'Success':
      return <FontAwesomeIcon color={systemColours.teal} icon={faCheckCircle} />
    case 'Warning':
      return <FontAwesomeIcon color={systemColours.yellow} icon={faExclamationCircle} />
    case 'Error':
      return <FontAwesomeIcon color={systemColours.red} icon={faTimesCircle} />
    default:
      return <FontAwesomeIcon color={systemColours.grey} icon={faExclamationCircle} />
  }
}

interface ValidationListItemProps {
  validation: CampaignValidationModel,
}

const ValidationListItem = (props: ValidationListItemProps) => {
  const {
    validation: {
      displayName,
      level,
      message,
    },
  } = props

  return (
    <Box alignItems="center" justifyContent="space-between" paddingY="medium">
      <Text color="bodyFontLightColor" fontSize="small" width="100%">{displayName}</Text>

      <Box alignItems="center" justifyContent="flex-end">
        {!!message && <Text fontSize="small" marginRight="medium" variant="ellipsis">{message}</Text>}
        {getIcon(level)}
      </Box>
    </Box>
  )
}

export default ValidationListItem
