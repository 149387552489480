import {
  DashboardModule, ModuleBlankState,
} from '@campaignhub/suit-theme'

import filter from '@assets/filter.svg'

import useAdQueues from '@hooks/useAdQueues'
import type { AdQueueFilters } from '@hooks/useAdQueues'

import DeployingCampaignsTable from './DeployingCampaignsTable'

interface DeployingCampaignsProps {
  pageFilters: AdQueueFilters,
}

const DeployingCampaigns = (props: DeployingCampaignsProps) => {
  const { pageFilters } = props

  const adQueuesPayload = useAdQueues({
    filters: {
      ...pageFilters,
    },
    performHttpRequests: true,
    requestOptions: {
      include: [
        'Campaign.Project.ExternalPlatformEntities.ExternalPlatform',
        'FacebookCampaign',
        'PendingGoogleCampaign'
      ],
    },
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdQueues,
    filteredAdQueuesCount,
    hasFilteredAdQueues,
    loading,
  } = adQueuesPayload

  return (
    <DashboardModule
      boxProps={{ borderBottom: loading && !hasFilteredAdQueues ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Deploying Campaigns"
    >
      {!loading && !hasFilteredAdQueues && (
        <ModuleBlankState boxProps={{ height: 200 }} imageUrl={filter}>
          <ModuleBlankState.Title>No Results</ModuleBlankState.Title>

          <ModuleBlankState.Paragraph>
            Try changing your filters, or wait for more results
          </ModuleBlankState.Paragraph>
        </ModuleBlankState>
      )}

      {!loading && hasFilteredAdQueues && (
        <DeployingCampaignsTable adQueues={filteredAdQueues} />
      )}

      <DashboardModule.LoadMoreFooter
        boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
        callbacks={{ loadMore }}
        canLoadMore={canLoadMore}
        entityCount={filteredAdQueuesCount}
        loading={loading}
      />
    </DashboardModule>
  )
}

export default DeployingCampaigns
