import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

import type { OrderItemOptionValueRequestOptions } from '@models/types'

const watchEntityKeys = ['orderItemOptionValues']

type OrderItemOptionValueFilters = {
  orderItemId?: number,
}

type UseOrderItemOptionValuesOptions = {
  filters?: OrderItemOptionValueFilters,
  performHttpRequests?: boolean,
  requestOptions?: OrderItemOptionValueRequestOptions,
}

function useOrderItemOptionValues(options: UseOrderItemOptionValuesOptions) {
  const { filters = {} } = options
  const {
    orderItemId: filterOrderItemId,
  } = filters

  const {
    updatedEntities: { orderItemOptionValues: orderItemOptionValuesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { orderItemOptionValues } = useSelector(reduxState => reduxState.entities)

  const filteredOrderItemOptionValues = useMemo(() => {
    const filtered = Object.values(orderItemOptionValues).filter((orderItemOptionValue) => {
      const { orderItemId } = orderItemOptionValue

      const matchOrderItemId = matchFilterNumber(orderItemId, filterOrderItemId)

      return matchOrderItemId
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [orderItemOptionValuesUpdatedAt, JSON.stringify(filters)])

  const filteredOrderItemOptionValuesCount = filteredOrderItemOptionValues.length
  const hasFilteredOrderItemOptionValues = !!filteredOrderItemOptionValuesCount

  return {
    filteredOrderItemOptionValues,
    filteredOrderItemOptionValuesCount,
    hasFilteredOrderItemOptionValues,
  }
}

export default useOrderItemOptionValues
