import { useMemo } from 'react'

import { formatDate } from '@campaignhub/javascript-utils'

import {
  StatusBadge, Table, Text,
} from '@campaignhub/suit-theme'

import getStatusBadgeProps from '@functions/statusBadge'

import useCampaigns from '@hooks/useCampaigns'
import useSelector from '@hooks/useSelector'

import { CampaignModel } from '@models/types'

import type { EntitiesState } from '@redux/entities'

import Actions from './Actions'

const buildColumns = (entities: EntitiesState) => {
  const {
    externalPlatformEntities,
    externalPlatforms,
    orderItems,
    orders,
    projects,
  } = entities

  return [
    {
      title: 'Campaign',
      dataKey: 'name',
      cellProps: {
        maxWidth: 250,
        verticalAlign: 'middle',
        width: 250,
      },
      render: (name: string) => (
        <Text variant="ellipsis" width={250}>
          {name}
        </Text>
      ),
    },
    {
      title: 'AIM#',
      dataKey: 'id',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
    },
    {
      title: 'Source',
      dataKey: 'source',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (_:any, campaign: CampaignModel) => {
        const { projectId } = campaign
        const project = projects[projectId]

        if (!project){
          return '-'
        }

        const { externalPlatformEntities: externalPlatformEntityIds = [] } = project
        const sourceStrings = [] as string[]

        externalPlatformEntityIds.forEach((externalPlatformEntityId) => {
          const externalPlatformEntity = externalPlatformEntities[externalPlatformEntityId]
          const { externalPlatformId, value, entityType } = externalPlatformEntity
          if (entityType !== 'Project'){
            return
          }
          const externalPlatform = externalPlatforms[externalPlatformId]
          const { name } = externalPlatform

          sourceStrings.push(`${name} (${value})`)
        })

        return sourceStrings.join(', ') || '-'
      },
    },
    {
      title: 'External Order',
      dataKey: 'externalOrderId',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (_:any, campaign: CampaignModel) => {
        const { orderItemId } = campaign
        const orderItem = orderItemId ? orderItems[orderItemId] : undefined

        if (!orderItem?.orderId){
          return '-'
        }

        const order = orders[orderItem.orderId]

        const { externalPlatformEntities: orderExternalIds = [] } = order
        const externalOrderData = [] as string[]

        orderExternalIds.forEach((externalOrderId) => {
          const { externalPlatformId, value, entityType } = externalPlatformEntities[externalOrderId]
          if (entityType !== 'Order'){
            return
          }
          const externalPlatform = externalPlatforms[externalPlatformId]
          const { name } = externalPlatform

          externalOrderData.push(`${name} (${value})`)
        })

        return externalOrderData.join(', ') || '-'
      },
    },
    {
      title: 'Due',
      dataKey: 'requiredAt',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (dueDate: string) => (
        <StatusBadge
          boxProps={{
            backgroundColor: 'backgroundColor',
            borderColor: 'lineColor',
            color: 'black',
          }}
          text={formatDate(dueDate, 'ISO8601', 'dd/MM/yyyy')}
        />
      ),
    },
    {
      title: 'Status',
      dataKey: 'statusId',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (statusId: string) => {
        const statusBadgeProps = getStatusBadgeProps(statusId)

        return (
          <StatusBadge {...statusBadgeProps} />
        )
      },
    },
    {
      title: 'Actions',
      dataKey: 'id',
      cellProps: {
        key: 'actions',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 20,
      },
      render: (campaignId: number) => <Actions campaignId={campaignId} />,
    },
  ]
}

interface OrdersTableProps {
  campaignsPayload: ReturnType<typeof useCampaigns>,
}

const OrdersTable = (props: OrdersTableProps) => {
  const {
    campaignsPayload: {
      filteredCampaigns,
      filteredCampaignsCount,
    },
  } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const memoColumns = useMemo(() => buildColumns(entities), [filteredCampaignsCount])

  return (
    <Table
      boxProps={{ border: 'none', maxHeight: 1600, overflowY: 'auto' }}
      columns={memoColumns}
      data={filteredCampaigns}
      scroll={{ x: 800 }}
      stickyHeader
    />
  )
}

export default OrdersTable
