import {
  Box, DashboardModule, LoadingModule, Text,
} from '@campaignhub/suit-theme'

import type { ProjectModel } from '@models/types'

interface CampaignDetailsProps {
  loading: boolean,
  project: ProjectModel,
}

const CampaignDetails = (props: CampaignDetailsProps) => {
  const { loading, project } = props

  if (loading) return <LoadingModule loading={loading} />

  return (
    <DashboardModule
      contentBoxProps={{ padding: 'large' }}
      title="Campaign Details"
    >
      <Box flexDirection="column" justifyContent="center">
        <Text fontSize="small" marginBottom="small" letterSpacing="0.3px">{project.name}</Text>
      </Box>
    </DashboardModule>
  )
}

export default CampaignDetails