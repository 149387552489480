import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import { digObject, formatDate } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useBulkUpdatePriceLists from './hooks/useBulkUpdatePriceLists'

const MODAL_KEY = 'BulkUpdatePriceListsModal'

export type ModalCallbacks = {
  bulkUpdatePriceLists: (payload: HandleCallbackActionParams) => void,
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const BulkUpdatePriceListsModal = (props: ModalProps) => {
  const { callbacks, showModal } = props

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const {
    callbacks: {
      deselectAll,
    },
    priceListIds,
  } = digObject(modalData, MODAL_KEY, {})

  const bulkUpdatePriceListsPayload = useBulkUpdatePriceLists({
    callbacks: {
      ...callbacks,
      deselectAll,
    },
  })

  const {
    callbacks: {
      handleSubmit,
    },
    currentDateTime,
    entityState: {
      direction,
      expiresAt,
      percentage,
    },
    errors,
    handlers,
    saveEnabled,
    updating,
  } = bulkUpdatePriceListsPayload

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Bulk Update" titleSecondLine="Price Lists" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="Expiry Date">
            <input name="expiresAt" min={currentDateTime} type="date" value={expiresAt} {...handlers} />
          </Form.Field>

          {expiresAt && (
            <SidebarNoticeBox boxProps={{ marginTop: 'large', marginBottom: 'none' }}>
              <SidebarNoticeBox.Title>
                Selected price lists will expire on {formatDate(expiresAt, 'yyyy-MM-dd', 'dd LLL yyyy')}
              </SidebarNoticeBox.Title>

              <SidebarNoticeBox.Paragraph>
                Price changes will come into effect after the expiry date.
              </SidebarNoticeBox.Paragraph>
            </SidebarNoticeBox>
          )}

          <Form.Field label="* Adjust" marginTop="large">
            <select name="direction" value={direction} {...handlers}>
              <option value="">Please Select...</option>
              <option value="increase">Increase by</option>
              <option value="decrease">Decrease by</option>
              <option value="undoIncrease">Undo Increase of</option>
              <option value="undoDecrease">Undo Decrease of</option>
            </select>
          </Form.Field>

          {direction && (
            <Form.Field errorMessage={errors.percentage} label="* Percentage" marginTop="large">
              <input
                min="0"
                name="percentage"
                step="0.1"
                type="number"
                value={percentage}
                {...handlers}
              />
            </Form.Field>
          )}
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => handleSubmit(priceListIds)}
          size="large"
        >
          {saveEnabled ? 'Update Price Lists' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <BulkUpdatePriceListsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
