import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useProductOption, { useProductOptionForm } from '@hooks/useProductOption'

import useProductOptionKeyTypes from '@hooks/useProductOptionKeyTypes'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'CreateProductOptionModal'

type ModalCallbacks = {
  closeModal: () => void,
  createProductOption: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateProductOptionModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createProductOption } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const productOption = digObject(modalPayload, 'productOption', {})

  const {
    callbacks: {
      createProductOption: createFn,
    },
    creating,
    updating,
  } = useProductOption(productOption)

  const { productOptionKeyTypes } = useProductOptionKeyTypes({
    performHttpRequests: true,
  })

  const {
    entityState,
    entityState: {
      displayType,
      name,
      key,
    },
    errors,
    handlers,
    saveEnabled,
  } = useProductOptionForm(productOption, { validateOn: 'change' })

  // To be used with handleCallbackAction
  const createProductOptionPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
    toastText: 'Product Option Created',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title="Create"
        titleSecondLine="Product Option"
      />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* Name" errorMessage={errors.name}>
            <input name="name" type="text" value={name} {...handlers} />
          </Form.Field>

          <Form.Field label="* Display Type" errorMessage={errors.displayType} marginTop="large">
            <select data-value-type="number" name="displayType" value={displayType} {...handlers}>
              <option value="">Please Select...</option>
              <option value={1}>Multi Select</option>
              <option value={2}>Dropdown</option>
            </select>
          </Form.Field>

          <Form.Field label="* Key" errorMessage={errors.key} marginTop="large">
            <select data-value-type="number" name="key" value={key} {...handlers}>
              <option value="">Please Select...</option>
              {productOptionKeyTypes.map(productOptionKeyType => (
                <option key={productOptionKeyType.id} value={productOptionKeyType.id}>{productOptionKeyType.displayName}</option>
              ))}
            </select>
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          loadingBubbleColor="white"
          onClick={() => createProductOption(createProductOptionPayload)}
          size="large"
        >
          {saveEnabled ? 'Create Product Option' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateProductOptionModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
