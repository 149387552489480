import { useMemo } from 'react'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['campaignStatuses']

type UseCampaignStatusesOptions = {
  performHttpRequests?: boolean,
}

function useCampaignStatuses(options: UseCampaignStatusesOptions) {
  const { performHttpRequests } = options

  const {
    updatedEntities: { campaignStatuses: campaignStatusesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { campaignStatuses: entityCampaignStatuses } = useSelector(reduxState => reduxState.entities)

  const campaignStatuses = useMemo(() => Object.values(entityCampaignStatuses), [campaignStatusesUpdatedAt])

  const { loading: loadingCampaignStatuses } = useReduxAction(
    'campaignStatuses',
    'loadCampaignStatuses',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  return {
    campaignStatuses,
    loading: loadingCampaignStatuses,
  }
}

export default useCampaignStatuses
