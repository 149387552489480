import { Box, SectionDivider } from '@campaignhub/suit-theme'

import groupValidationsByType from '@functions/campaignValidation'

import { CampaignValidationModel } from '@models/types'

import ValidationListItem from './ValidationListItem'

interface ValidationsSummaryProps {
  campaignValidations: CampaignValidationModel[],
}

const ValidationsSummary = (props: ValidationsSummaryProps) => {
  const { campaignValidations } = props

  const groupedValidations = groupValidationsByType(campaignValidations)

  return (
    <Box flexDirection="column">
      <SectionDivider>Project Validations</SectionDivider>

      {groupedValidations.project.map(validation => (
        <ValidationListItem key={validation.id} validation={validation} />
      ))}

      <SectionDivider>Facebook Validations</SectionDivider>

      {groupedValidations.facebook.map(validation => (
        <ValidationListItem key={validation.id} validation={validation} />
      ))}

      <SectionDivider>Google Validations</SectionDivider>

      {groupedValidations.google.map(validation => (
        <ValidationListItem key={validation.id} validation={validation} />
      ))}
    </Box>
  )
}

export default ValidationsSummary
