import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as clientPriceListActions from '@redux/modules/clientPriceList'

import defaultFormState, { requiredFields } from '@models/clientPriceList'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { ClientPriceListModel, ClientPriceListRequestOptions } from '@models/types'

type BulkCreateClientPriceListsParams = {
  bulkCreateParams: { clientIds?: number[], priceListIds?: number[] },
  dispatch: AppDispatch,
  requestOptions?: ClientPriceListRequestOptions,
}

const bulkCreateClientPriceLists = (params: BulkCreateClientPriceListsParams) => {
  const {
    bulkCreateParams,
    dispatch,
    requestOptions,
  } = params
  const { bulkCreateClientPriceLists: bulkCreateFn } = clientPriceListActions

  return dispatch(bulkCreateFn(bulkCreateParams, requestOptions))
}

type DeleteClientPriceListParams = {
  dispatch: AppDispatch,
  clientPriceList: DeleteParams<ClientPriceListModel>,
}

const deleteClientPriceList = (params: DeleteClientPriceListParams) => {
  const { dispatch, clientPriceList } = params
  const { deleteClientPriceList: deleteFn } = clientPriceListActions

  return dispatch(deleteFn(clientPriceList))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useClientPriceListForm(
  clientPriceList: Partial<ClientPriceListModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const clientPriceListForm = useForm(
    defaultFormState,
    { entity: clientPriceList, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [clientPriceList.id, clientPriceList.cacheKey],
  )

  return {
    ...clientPriceListForm,
  }
}

export const useRelations = (clientPriceList: Partial<ClientPriceListModel> = {}) => {
  const { clientId, priceListId } = clientPriceList

  const { clients, priceLists } = useSelector(reduxState => reduxState.entities)

  const client = clientId && clients[clientId] ? clients[clientId] : {}
  const priceList = priceListId && priceLists[priceListId] ? priceLists[priceListId] : {}

  return {
    client,
    priceList,
  }
}

function useClientPriceList(initEntity: Partial<ClientPriceListModel> = {}) {
  const { entity: clientPriceList }:
    { entity: ClientPriceListModel } = useLatestEntity(initEntity, 'clientPriceLists')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { client, priceList } = useRelations(clientPriceList)

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.clientPriceLists)

  return {
    client,
    clientPriceList,
    callbacks: {
      deleteClientPriceList: () => deleteClientPriceList({ dispatch, clientPriceList }),
      launchBulkCreateClientPriceListsModal: () => launchModal({
        callbacks,
        modalKey: 'BulkCreateClientPriceListsModal',
        payload: { clientPriceList },
      }),
      bulkCreateClientPriceLists: (
        bulkCreateParams: BulkCreateClientPriceListsParams['bulkCreateParams'],
        requestOptions?: ClientPriceListRequestOptions,
      ) => (
        bulkCreateClientPriceLists({
          bulkCreateParams,
          dispatch,
          requestOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    priceList,
    updating,
  }
}

export default useClientPriceList
