import { useContext } from 'react'

import PageContext from '@contexts/pageContext'

import usePriceListItem, { useRelations } from '@hooks/usePriceListItem'
import usePriceListItemOption from '@hooks/usePriceListItemOption'
import usePriceListItemOptions from '@hooks/usePriceListItemOptions'

import type { PriceListItemModel } from '@models/types'

const usePriceListProduct = (priceListItem: PriceListItemModel) => {
  const { id } = priceListItem

  const { product } = useRelations(priceListItem)

  const {
    callbacks: {
      deletePriceListItem: deleteFn,
    },
  } = usePriceListItem(priceListItem)

  const {
    callbacks: {
      launchBulkCreatePriceListItemOptionsModal,
    },
  } = usePriceListItemOption({ priceListItemId: priceListItem.id })

  const priceListItemOptionPayload = usePriceListItemOptions({
    filters: {
      priceListItemId: id,
    },
  })

  const {
    filteredPriceListItemOptions,
    filteredPriceListItemOptionsCount,
    hasFilteredPriceListItemOptions,
  } = priceListItemOptionPayload

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      deletePriceListItem,
      selectPriceListItemId,
      selectPriceListItemOptionId,
    },
    selectedPriceListItem,
    selectedPriceListItemOption,
  } = pageContext

  const isOpen = selectedPriceListItem.id === id

  return {
    callbacks: {
      deleteFn,
      deletePriceListItem,
      launchBulkCreatePriceListItemOptionsModal,
      selectPriceListItemOptionId,
      toggleOpen: () => {
        selectPriceListItemId(isOpen ? null : id)
        selectPriceListItemOptionId(null)
      },
    },
    filteredPriceListItemOptions,
    filteredPriceListItemOptionsCount,
    hasFilteredPriceListItemOptions,
    isOpen,
    product,
    selectedPriceListItemOption,
  }
}

export default usePriceListProduct
