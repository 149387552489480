import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'
import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { PackageProductRequestOptions } from '@models/types'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['packageProducts']

type PackageProductFilters = {
  productId?: number,
  packageId?: number,
  string?: string,
}

type UsePackageProductsOptions = {
  filters?: PackageProductFilters,
  performHttpRequests?: boolean,
  requestOptions?: PackageProductRequestOptions,
}

export function usePackageProducts(options: UsePackageProductsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    productId: filterProductId,
    packageId: filterPackageId,
    string: filterString,
  } = filters

  const {
    updatedEntities: { packageProducts: packageProductsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { packageProducts, productPackages } = useSelector(reduxState => reduxState.entities)

  const filteredPackageProducts = useMemo(() => {
    const filtered = Object.values(packageProducts).filter((packageProduct) => {
      const { packageId, productId } = packageProduct

      const matchId = packageId.toString() === filterString
      const matchTitle = matchFilterString(productPackages[packageId]?.name, filterString)
      const stringMatch = matchId || matchTitle

      const matchProductId = matchFilterNumber(productId, filterProductId)
      const matchPackageId = matchFilterNumber(packageId, filterPackageId)

      return stringMatch && matchProductId && matchPackageId
    })

    return sortArrayBy(filtered, 'asc', ({ packageId }) => productPackages[packageId]?.name)
  }, [packageProductsUpdatedAt, JSON.stringify(filters)])

  const filteredPackageProductsCount = filteredPackageProducts.length
  const hasFilteredPackageProducts = !!filteredPackageProductsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredPackageProductsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingPackageProducts } = useReduxAction(
    'packageProducts',
    'loadPackageProducts',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredPackageProducts,
    filteredPackageProductsCount,
    hasFilteredPackageProducts,
    loading: loadingPackageProducts,
  }
}

export default usePackageProducts
