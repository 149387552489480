import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['userTypes']

type UseUserTypesOptions = {
  filters?: {
    string?: string,
  },
  performHttpRequests?: boolean,
}

function useUserTypes(options: UseUserTypesOptions) {
  const {
    filters,
    performHttpRequests = false,
  } = options || {}

  const { string } = filters || {}

  const {
    updatedEntities: { userTypes: userTypesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading: loadingUserTypes } = useReduxAction(
    'userTypes',
    'loadUserTypes',
    {},
    [performHttpRequests, filters],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors?.length
      },
    },
  )

  const userTypes = useSelector(reduxState => reduxState.entities.userTypes)

  const filteredUserTypes = useMemo(() => {
    const filtered = Object.values(userTypes).filter((userType) => {
      const { name } = userType

      const nameMatch = matchFilterString(name, string)

      return nameMatch
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [userTypesUpdatedAt, JSON.stringify(filters)])

  const hasFilteredUserTypes = !!filteredUserTypes.length

  return {
    filteredUserTypes,
    hasFilteredUserTypes,
    loading: loadingUserTypes,
  }
}

export default useUserTypes
