import { useContext } from 'react'

import {
  Button,
  Columns,
  LoadingModule,
  SaveFormModule,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useUser, { useUserForm } from '@hooks/useUser'

import UserDetails from '../UserDetails'
import PageLayout from '../../../../components/PageLayout'

interface PageContentProps {
  userId: number,
}

const PageContent = (props: PageContentProps) => {
  const { userId } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      updateUser,
    },
  } = pageContext

  const userPayload = useUser({ id: userId })
  const {
    user,
    callbacks: {
      updateUser: updateFn,
    },
    loading,
    updating,
  } = userPayload

  const userFormPayload = useUserForm(user)
  const { entityState, saveEnabled } = userFormPayload

  const updateUserPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'User Updated',
  }

  return (
    <PageLayout userPayload={userPayload} activeTabBarItemKey="details">
      <Columns.Main>
        <LoadingModule loading={loading} times={3} />

        {!loading && (
          <>
            <UserDetails userFormPayload={userFormPayload} />

            <SaveFormModule>
              <Button
                buttonStyle="primaryCreate"
                disabled={!saveEnabled}
                loading={updating}
                marginLeft="auto"
                onClick={() => updateUser(updateUserPayload)}
                size="medium"
              >
                Save Details
              </Button>
            </SaveFormModule>
          </>
        )}
      </Columns.Main>
    </PageLayout>
  )
}

export default PageContent
