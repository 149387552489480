import { Box, DashboardModule, Text } from '@campaignhub/suit-theme'

import { useRelations } from '@hooks/usePriceListItemOption'

import type { PriceListItemOptionModel } from '@models/types'

import PriceListItemOptionValueDetails from './PriceListItemOptionValueDetails'

interface PriceListItemOptionDetailsProps {
  priceListItemOption: PriceListItemOptionModel,
}

const PriceListItemOptionDetails = (props: PriceListItemOptionDetailsProps) => {
  const { priceListItemOption } = props

  const {
    productOption: {
      displayType,
      id,
      name,
    },
  } = useRelations(priceListItemOption)

  return (
    <>
      <DashboardModule
        boxProps={{ marginBottom: 'none' }}
        contentBoxProps={{ flexDirection: 'column' }}
        title="Option Details"
      >
        <Box alignItems="center" padding="large">
          <Box flexDirection="column">
            <Text fontSize="small">{name}</Text>

            <Text color="bodyFontLightColor" fontSize="small" marginTop="medium">{displayType}</Text>
          </Box>

          <Text color="bodyFontLightColor" fontSize="small">#{id}</Text>
        </Box>
      </DashboardModule>

      <PriceListItemOptionValueDetails priceListItemOption={priceListItemOption} />
    </>
  )
}

export default PriceListItemOptionDetails
