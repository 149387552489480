import {
  Box, Link, ListItem, LoadingModule, ModuleBlankState, Text,
} from '@campaignhub/suit-theme'

import noArtworks from '@assets/no_artworks.svg'

import useArtworks from '@hooks/useArtworks'
import { generateUrls } from '@hooks/useCampaign'

import { CampaignModel } from '@models/types'

import ArtworkCard from './ArtworkCard'

interface ArtworkDetailsProps {
  campaign: CampaignModel,
}

const ArtworkDetails = (props: ArtworkDetailsProps) => {
  const { campaign } = props

  const { systemAdminOrderCreative } = generateUrls(campaign)

  const artworksPayload = useArtworks({
    filters: {
      campaignId: campaign.id,
    },
    performHttpRequests: true,
  })

  const {
    filteredArtworks,
    hasFilteredArtworks,
    loading,
  } = artworksPayload

  return (
    <ListItem disableHover>
      {loading && <LoadingModule boxProps={{ margin: 'large' }} loading={loading} />}

      {!loading && !hasFilteredArtworks && (
        <ModuleBlankState boxProps={{ height: 200 }} imageUrl={noArtworks}>
          <ModuleBlankState.Title>No Artworks</ModuleBlankState.Title>

          <ModuleBlankState.Paragraph>
            The Order artworks or media haven&apos;t been approved
          </ModuleBlankState.Paragraph>
        </ModuleBlankState>
      )}

      {!loading && hasFilteredArtworks && (
        <Box flexDirection="column">
          <Box
            justifyContent="space-between"
            paddingLeft="large"
            paddingRight="large"
            paddingTop="large"
          >
            <Text
              color="bodyFontLightColor"
              fontSize="xsmall"
            >
              Creative
            </Text>

            <Link
              href={systemAdminOrderCreative}
              textProps={{ fontSize: 'xsmall' }}
            >
              View All
            </Link>
          </Box>

          <Box
            flexDirection={['column', 'row']}
            overflow="hidden"
            flexWrap="wrap"
            height={[null, 280]}
          >
            {filteredArtworks.map((artwork, index) => (
              <ArtworkCard artwork={artwork} isFirst={index === 0} key={artwork.id} />
            ))}
          </Box>
        </Box>
      )}
    </ListItem>
  )
}

export default ArtworkDetails
