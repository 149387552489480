import { faIdCard, faToggleLargeOn } from '@fortawesome/pro-light-svg-icons'

import { ProductOptionModel } from '@models/productOption'

import { generateUrls } from '@hooks/useProductOption'

const generateTabBarItems = (productOption: ProductOptionModel) => {
  const { name } = productOption

  const urls = generateUrls(productOption)
  const { editProductOptionUrl, editProductRelationsUrl } = urls

  return [
    {
      icon: faIdCard,
      href: editProductOptionUrl,
      key: 'details',
      title: name?.slice(0, 30) || 'Product Option Details',
    },
    {
      icon: faToggleLargeOn,
      href: editProductRelationsUrl,
      key: 'relations',
      title: 'Relations',
    },
  ]
}

export default generateTabBarItems
