import {
  DashboardModule, Form,
} from '@campaignhub/suit-theme'

import { useArtworkTemplateGroupForm } from '@hooks/useArtworkTemplateGroup'

interface ArtworkTemplateGroupDetailsProps {
  artworkTemplateGroupFormPayload: ReturnType<typeof useArtworkTemplateGroupForm>,
}

const ArtworkTemplateGroupDetails = (props: ArtworkTemplateGroupDetailsProps) => {
  const {
    artworkTemplateGroupFormPayload: {
      entityState: {
        name,
        description,
      },
      errors,
      handlers,
    },
  } = props

  return (
    <DashboardModule title="Artwork Template Group Details">
      <Form padding="large">
        <Form.Row>
          <Form.Field errorMessage={errors.name} label="* Name">
            <input
              name="name"
              type="text"
              value={name}
              {...handlers}
            />
          </Form.Field>

          <Form.Field errorMessage={errors.description} label="Description">
            <input
              name="description"
              value={description}
              type="text"
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default ArtworkTemplateGroupDetails
