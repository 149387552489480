import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'
import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['assetTypes']

type UseAssetTypesOptions = {
  filters?: {
    category?: string,
  },
  performHttpRequests?: boolean,
}

function useAssetTypes(options: UseAssetTypesOptions) {
  const {
    filters,
    performHttpRequests = false,
  } = options || {}

  const {
    category: filterCategory,
  } = filters || {}

  const {
    updatedEntities: { assetTypes: assetTypesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading: loadingAssetTypes } = useReduxAction(
    'assetTypes',
    'loadAssetTypes',
    {},
    [performHttpRequests, filters],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors?.length
      },
    },
  )

  const assetTypes = useSelector(reduxState => reduxState.entities.assetTypes)

  const filteredAssetTypes = useMemo(() => {
    const filtered = Object.values(assetTypes).filter((assetType) => {
      const { category } = assetType

      const categoryMatch = matchFilterString(category, filterCategory)

      return categoryMatch
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [assetTypesUpdatedAt, JSON.stringify(filters)])

  const hasFilteredAssetTypes = !!filteredAssetTypes.length

  return {
    filteredAssetTypes,
    hasFilteredAssetTypes,
    loading: loadingAssetTypes,
  }
}

export default useAssetTypes
