import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons'

import { Box, Link, Text } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { confirmDelete } from '@functions/entity'

import usePriceListItemOption, { useRelations } from '@hooks/usePriceListItemOption'

import type { PriceListItemOptionModel } from '@models/types'

interface PriceListItemOptionProps {
  callbacks: {
    selectPriceListItemOption: () => void,
  },
  isLast: boolean,
  isSelected: boolean,
  priceListItemOption: PriceListItemOptionModel,
}

const PriceListItemOption = (props: PriceListItemOptionProps) => {
  const {
    callbacks: {
      selectPriceListItemOption,
    },
    isLast,
    isSelected,
    priceListItemOption,
    priceListItemOption: {
      nameOverride,
    },
  } = props

  const {
    callbacks: {
      deletePriceListItemOption: deleteFn,
    },
  } = usePriceListItemOption(priceListItemOption)

  const { productOption } = useRelations(priceListItemOption)

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      deletePriceListItemOption,
    },
  } = pageContext

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deletePriceListItemOption,
      deleteFn,
    },
    strings: {
      entityName: 'Option',
    },
  }

  return (
    <Box borderBottom={isLast ? 'none' : '1px dashed'} borderColor="lineColor" paddingY="medium">
      <Box
        color="lightGrey"
        onClick={() => selectPriceListItemOption()}
        width="auto"
      >
        <FontAwesomeIcon icon={isSelected ? faCircleSolid : faCircle} />
      </Box>

      <Text color="bodyFontColor" marginLeft="large">{nameOverride || productOption.name}</Text>

      <Box marginLeft="auto" width="fit-content">
        <Link as="a" greyLink onClick={() => selectPriceListItemOption()} textProps={{ marginRight: 'medium' }}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </Link>

        <Link
          as="a"
          greyLink
          onClick={() => confirmDelete(confirmDeletePayload)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Link>
      </Box>
    </Box>
  )
}

export default PriceListItemOption
