import { Text } from '@campaignhub/suit-theme'

import { AudiencesModel } from '@models/adCampaign'

interface AudienceProps {
  audiences: AudiencesModel[],
}

const Audiences = (props: AudienceProps) => {
  const { audiences } = props

  return (
    <>
      <Text color="bodyFontLightColor" fontSize="xsmall">Audiences</Text>

      {audiences.map(({ name }) => <Text fontSize="small" paddingY="medium" key={name}>{name}</Text>)}
    </>
  )
}

export default Audiences
