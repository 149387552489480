import { useNumericParams } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import PriceListEditRoutes from './routes'

const PriceListEdit = () => {
  const { priceListId } = useNumericParams()

  useReduxAction(
    'priceLists',
    'loadPriceList',
    {
      entityId: priceListId,
      include: [
        'PriceListItems.PriceListItemOptions.PriceListItemOptionValues.ProductOptionValue',
        'PriceListItems.PriceListItemOptions.ProductOption.ProductOptionValues',
        'PriceListItems.Product',
      ],
    },
    [priceListId],
    {
      dispatchAction: (action, requestOptions) => action(priceListId, requestOptions),
      shouldPerformFn: ({ loading }) => !!priceListId && !loading,
    },
  )

  return (
    <PriceListEditRoutes />
  )
}

export default PriceListEdit
