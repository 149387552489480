import { DashboardModule, StatusBadge } from '@campaignhub/suit-theme'

import getStatusBadgeProps from '@functions/statusBadge'

import useCampaigns from '@hooks/useCampaigns'

import type { CampaignModel } from '@models/types'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import CampaignListItem from './CampaignListItem'

type CampaignProps = {
  status: 'Pending' | 'Live' | 'Completed',
}

const Campaigns = (props: CampaignProps) => {
  const { status } = props

  const useCampaignsPayload = useCampaigns({
    filters: {
      status,
    },
    performHttpRequests: true,
    requestOptions: {
      ...defaultRequestOptions.campaign,
    },
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCampaigns,
    filteredCampaignsCount,
    hasFilteredCampaigns,
    loading,
  } = useCampaignsPayload

  const { color } = getStatusBadgeProps(status)

  return (
    <DashboardModule
      boxProps={{ borderBottom: loading ? 'none' : '1px solid lineColour' }}
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={<StatusBadge color={color} text={status} />}
      loading={loading}
      title={`${status} Campaigns`}
    >
      {!loading && hasFilteredCampaigns && (
        <DashboardModule.ScrollableSection>
          {filteredCampaigns.map((campaign: CampaignModel) => (
            <CampaignListItem campaign={campaign} key={campaign.id} />
          ))}
        </DashboardModule.ScrollableSection>
      )}

      {!loading && !hasFilteredCampaigns && (
        <DashboardModule.BlankState>
          <DashboardModule.BlankState.Title>
            No {status} Campaigns
          </DashboardModule.BlankState.Title>

          <DashboardModule.BlankState.Paragraph>
            Complete required tasks to progress your campaign
          </DashboardModule.BlankState.Paragraph>
        </DashboardModule.BlankState>
      )}

      <DashboardModule.LoadMoreFooter
        callbacks={{ loadMore }}
        canLoadMore={canLoadMore}
        entityCount={filteredCampaignsCount}
        loading={loading}
        showBorderTop={!loading}
      />
    </DashboardModule>
  )
}

export default Campaigns
