import { isAdmin } from '@functions/user'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'
import { useRelations } from '@hooks/useUser'

import type { ModuleState } from '@redux/modules/types'

type UseCurrentUserOptions = {
  performHttpRequests?: boolean,
}

const useCurrentUser = (options: UseCurrentUserOptions) => {
  const { performHttpRequests = false } = options || {}

  useReduxAction(
    'currentUser',
    'loadCurrentUser',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors?.length
      },
    },
  )

  const entityReducer = useSelector(reduxState => reduxState.currentUser)
  const { loaded, loading, result: currentUser } = entityReducer

  const currentUserRelations = useRelations(currentUser)
  const { userType } = currentUserRelations

  return {
    currentUser,
    isAdmin: isAdmin(userType.name),
    loaded,
    loading,
  }
}

export default useCurrentUser
