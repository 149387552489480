import { useContext } from 'react'

import {
  Box,
  Checkbox,
  DashboardModuleListItem,
  ListItem,
  Text,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { confirmDelete } from '@functions/entity'

import useAdAccountLink from '@hooks/useAdAccountLink'

import type { AdAccountLinkModel } from '@models/types'

const generateAdAccountTypeTitle = (adAccountType: string) => {
  const adAccountTypeMap = {
    FacebookAdAccount: 'Ad Account',
    FacebookPage: 'Page',
    FacebookPixel: 'Pixel',
    FacebookUser: 'User',
    GoogleAdAccount: 'Google MCC',
  } as { [key: string]: string }

  return adAccountTypeMap[adAccountType]
}

interface AdAccountListItemProps {
  adAccountLink: AdAccountLinkModel,
}

const AdAccountListItem = (props: AdAccountListItemProps) => {
  const { adAccountLink } = props

  const {
    adAccount: {
      accountReferenceId,
      adAccountType,
      name,
    },
    callbacks: {
      deleteAdAccountLink: deleteFn,
    },
  } = useAdAccountLink(adAccountLink)

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      deleteAdAccountLink,
    },
  } = pageContext

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deleteAdAccountLink,
      deleteFn,
    },
    strings: {
      entityName: 'Ad Account Link',
    },
  }

  const adAccountTypeTitle = generateAdAccountTypeTitle(adAccountType)

  return (
    <ListItem.WithCheckbox
      boxProps={{ paddingX: 'large', paddingY: 'small' }}
      checkbox={<Checkbox checked fontSize="small" />}
      disableHover
    >
      <Box alignItems="center">
        <Box flexDirection="column">
          <Text color="bodyFontLightColor" fontSize="xsmall">Linked {adAccountTypeTitle}</Text>

          <Text fontSize="small">{name}</Text>
        </Box>

        <Text color="bodyFontLightColor" fontSize="small">{accountReferenceId}</Text>
      </Box>

      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon
          onClick={() => confirmDelete(confirmDeletePayload)}
          type="delete"
        />
      </DashboardModuleListItem.Actions>
    </ListItem.WithCheckbox>
  )
}

export default AdAccountListItem