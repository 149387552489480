import { useMemo } from 'react'

import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import CreateOrEditArtworkTemplateModal from '@modals/CreateOrEditArtworkTemplateModal'

import { ArtworkTemplateModel } from '@models/artworkTemplate'
import PageContent from './components/PageContent'

const defaultState = {
  showCreateOrEditArtworkTemplateModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateOrEditArtworkTemplateModal: {
      closeModal: () => setState({ showCreateOrEditArtworkTemplateModal: false }),
      createArtworkTemplate: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteArtworkTemplate: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateArtworkTemplate: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Details = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditArtworkTemplateModal,
  } = state

  const { artworkTemplateGroupId } = useNumericParams()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showCreateOrEditArtworkTemplateModal: (payload: { artworkTemplate: ArtworkTemplateModel }) => {
        setModalData('CreateOrEditArtworkTemplateModal', payload)
        setState({ showCreateOrEditArtworkTemplateModal: true })
      },
      updateArtworkTemplateGroup: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent artworkTemplateGroupId={artworkTemplateGroupId} />

        <CreateOrEditArtworkTemplateModal
          callbacks={callbacks('CreateOrEditArtworkTemplateModal', setState)}
          showModal={showCreateOrEditArtworkTemplateModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details
