import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['externalPlatforms']

type UseExternalPlatformsOptions = {
  performHttpRequests?: boolean,
}

function useExternalPlatforms(options: UseExternalPlatformsOptions) {
  const { performHttpRequests = false } = options

  const {
    updatedEntities: { externalPlatforms: externalPlatformsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entityExternalPlatforms = useSelector(reduxState => reduxState.entities.externalPlatforms)

  const externalPlatforms = useMemo(() => {
    const filtered = Object.values(entityExternalPlatforms)

    return sortArrayBy(filtered, 'asc', 'name')
  }, [externalPlatformsUpdatedAt])

  const externalPlatformsCount = externalPlatforms.length
  const hasExternalPlatforms = !!externalPlatformsCount

  const { loading: loadingExternalPlatforms } = useReduxAction(
    'externalPlatforms',
    'loadExternalPlatforms',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    externalPlatforms,
    externalPlatformsCount,
    hasExternalPlatforms,
    loading: loadingExternalPlatforms,
  }
}

export default useExternalPlatforms
