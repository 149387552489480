import { Box, Image, Text } from '@campaignhub/suit-theme'

interface BlankStateProps {
  imageUrl: string,
  subtitle: string,
  title: string,
}

const BlankState = (props: BlankStateProps) => {
  const { imageUrl, subtitle, title } = props

  return (
    <Box alignItems="center" flexDirection="column" justifyContent="center" marginY="large">
      <Image height="50px" width="50px" url={imageUrl} />

      <Text color="bodyFontLightColor" fontSize="medium" marginTop="large">
        {title}
      </Text>

      <Text color="lightGrey" fontSize="small" lineHeight="1.3" marginTop="large" textAlign="center">
        {subtitle}
      </Text>
    </Box>
  )
}

export default BlankState
