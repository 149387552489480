export type UserModel = {
  id: number,
  assetGroupId?: number,
  clientId?: number,
  countryCallingCode?: string,
  email: string,
  firstName: string,
  isArchived: boolean,
  lastName: string,
  mobileNumber: string,
  name: string,
  realbaseId?: number,
  timeZone: string,
  userTypeId: number,
  username: string,
  cacheKey: number,
}

export type UserRequestOptions = {}

const state = {
  id: null,
  assetGroupId: null,
  clientId: null,
  countryCallingCode: '',
  email: '',
  firstName: '',
  isArchived: false,
  lastName: '',
  mobileNumber: '',
  name: '',
  realbaseId: null,
  timeZone: '',
  userTypeId: null,
  username: '',
  cacheKey: null,
}

export const requiredFields = []

export default state