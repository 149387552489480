import { toast } from 'react-toastify'

import { handleCallbackAction as handleCallbackActionBase } from '@campaignhub/javascript-utils'
import type { HandleCallbackActionParams as HandleCallbackActionBaseParams } from '@campaignhub/javascript-utils'

const toastError = (params: { message: string }) => {
  const { message } = params
  toast.warn(message)
}

export type HandleCallbackActionParams = HandleCallbackActionBaseParams & {
  toastText: string,
}

const handleCallbackAction = (params: HandleCallbackActionParams) => {
  const {
    callbacks,
    toastText,
  } = params

  const updatedCallbacks = {
    ...callbacks,
    alertError: toastError,
    alertSuccess: toastText ? () => toast(`${toastText} Successfully`) : undefined,
  }

  const updatedParams = {
    ...params,
    callbacks: updatedCallbacks,
  }

  return handleCallbackActionBase(updatedParams)
}

export default handleCallbackAction
