import {
  Box, Button, DashboardModule,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlus } from '@fortawesome/pro-light-svg-icons'

import useArtworkTemplate from '@hooks/useArtworkTemplate'
import useArtworkTemplates from '@hooks/useArtworkTemplates'

import ArtworkTemplateListItem from './ArtworkTemplateListItem'

interface ArtworkTemplatesProps {
  artworkTemplateGroupId: number,
}

const ArtworkTemplates = (props: ArtworkTemplatesProps) => {
  const { artworkTemplateGroupId } = props

  const initArtworkTemplate = { artworkTemplateGroupId }

  const {
    callbacks: {
      createOrEditArtworkTemplate,
    },
  } = useArtworkTemplate(initArtworkTemplate)

  const artworkTemplatesPayload = useArtworkTemplates({
    filters: {
      artworkTemplateGroupId,
    },
    performHttpRequests: true,
  })

  const {
    filteredArtworkTemplates,
    hasFilteredArtworkTemplates,
    loading,
  } = artworkTemplatesPayload

  return (
    <DashboardModule title="Template Pages" loading={loading}>
      <Box flexDirection="column">
        <DashboardModule.ScrollableSection flexDirection="column">
          {!loading && !hasFilteredArtworkTemplates && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>
                No Artwork Templates present
              </DashboardModule.BlankState.Title>

              <DashboardModule.BlankState.Paragraph>
                Please add some Artwork Templates
              </DashboardModule.BlankState.Paragraph>
            </DashboardModule.BlankState>
          )}
        </DashboardModule.ScrollableSection>

        {!loading && hasFilteredArtworkTemplates && (
          filteredArtworkTemplates.map((artworkTemplate, index, artworkTemplates) => (
            <ArtworkTemplateListItem
              artworkTemplate={artworkTemplate}
              key={artworkTemplate.id}
              showBorderBottom={index + 1 !== artworkTemplates.length}
            />
          ))
        )}

        <DashboardModule.Footer boxProps={{ borderTop: loading ? 'none' : '1px solid' }}>
          <DashboardModule.FooterRight>
            <Button
              buttonStyle="secondaryEdit"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => createOrEditArtworkTemplate()}
              size={['medium', 'small']}
              width={['100%', 'fit-content']}
            >
              Add Template Page
            </Button>
          </DashboardModule.FooterRight>
        </DashboardModule.Footer>
      </Box>
    </DashboardModule>
  )
}

export default ArtworkTemplates
