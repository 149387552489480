import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import { confirmDelete } from '@functions/entity'

import useExternalPlatformEntity, { useExternalPlatformEntityForm } from '@hooks/useExternalPlatformEntity'
import useExternalPlatforms from '@hooks/useExternalPlatforms'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'CreateOrEditExternalPlatformEntityModal'

type ModalCallbacks = {
  closeModal: () => void,
  createExternalPlatformEntity: (payload: HandleCallbackActionParams) => void,
  deleteExternalPlatformEntity: (payload: HandleCallbackActionParams) => void,
  updateExternalPlatformEntity: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateOrEditExternalPlatformEntityModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const {
    closeModal,
    createExternalPlatformEntity,
    deleteExternalPlatformEntity,
    updateExternalPlatformEntity,
  } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const externalPlatformEntity = digObject(modalPayload, 'externalPlatformEntity', {})

  const newRecord = !externalPlatformEntity.id

  const {
    callbacks: {
      createExternalPlatformEntity: createFn,
      deleteExternalPlatformEntity: deleteFn,
      updateExternalPlatformEntity: updateFn,
    },
    creating,
    deleting,
    updating,
  } = useExternalPlatformEntity(externalPlatformEntity)

  const {
    entityState,
    entityState: {
      externalPlatformId,
      value,
    },
    errors,
    handlers,
    saveEnabled,
  } = useExternalPlatformEntityForm(externalPlatformEntity, { validateOn: 'change' })

  const { externalPlatforms } = useExternalPlatforms({ performHttpRequests: true })

  const createOrUpdateExternalPlatformEntityPayload = {
    callbacks: {
      action: newRecord ? createFn : updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: `External Entity ${newRecord ? 'Created' : 'Updated'}`,
  }

  const confirmDeletePayload = {
    callbacks: {
      afterAction: closeModal,
      deleteEntity: deleteExternalPlatformEntity,
      deleteFn,
    },
    strings: {
      entityName: 'External Entity',
    },
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title={newRecord ? 'Add' : 'Edit'} titleSecondLine="External Entity" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* External Platform" errorMessage={errors.externalPlatformId}>
            <select data-value-type="number" name="externalPlatformId" value={externalPlatformId} {...handlers}>
              <option value="">Please Select...</option>
              {externalPlatforms.map(externalPlatform => (
                <option key={externalPlatform.id} value={externalPlatform.id}>{externalPlatform.name}</option>
              ))}
            </select>
          </Form.Field>

          <Form.Field label="* External ID" errorMessage={errors.value} marginTop="large">
            <input name="value" value={value} {...handlers} />
          </Form.Field>
        </Form>

        {!newRecord && (
          <Form.Field label="More Options" marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              loading={deleting}
              onClick={() => confirmDelete(confirmDeletePayload)}
              size="medium"
            >
              Delete External Entity
            </Button>
          </Form.Field>
        )}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          loadingBubbleColor="white"
          onClick={
            newRecord
              ? () => createExternalPlatformEntity(createOrUpdateExternalPlatformEntityPayload)
              : () => updateExternalPlatformEntity(createOrUpdateExternalPlatformEntityPayload)
          }
          size="large"
        >
          {saveEnabled ? `${newRecord ? 'Create' : 'Update'} External Entity` : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditExternalPlatformEntityModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
