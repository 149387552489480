import { useState } from 'react'

import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import useClientPriceList from '@hooks/useClientPriceList'
import useClientPriceLists from '@hooks/useClientPriceLists'
import usePriceLists from '@hooks/usePriceLists'
import useSelector from '@hooks/useSelector'

import { ClientPriceListModel } from '@models/types'

const defaultState = {
  name: '',
}

const useBulkCreateClientPriceLists = (clientPriceList: Partial<ClientPriceListModel> = {}) => {
  const { clientId, priceListId } = clientPriceList

  const [selectedEntityIds, setSelectedEntityIds] = useState<number[]>([])

  const [state, setState] = useSetState(defaultState)
  const { name } = state

  const {
    callbacks: {
      bulkCreateClientPriceLists: bulkCreateFn,
    },
    creating,
  } = useClientPriceList(clientPriceList)

  const { priceLists } = useSelector(reduxState => reduxState.entities)

  const debouncedString = useDebounce(name, 200)

  const priceListsPayload = usePriceLists({
    filters: {
      isArchived: false,
      string: debouncedString,
    },
    performHttpRequests: true,
  })

  const { filteredPriceLists } = priceListsPayload

  // Filter out Price List Ids which are already assigned
  const { filteredClientPriceLists } = useClientPriceLists({
    filters: {
      clientId,
    },
  })

  const assignedPriceListIds = filteredClientPriceLists.map(p => p.priceListId)

  const filteredClientPriceListIds = filteredPriceLists?.reduce<number[]>((acc, priceList) => {
    if (!assignedPriceListIds.includes(priceList.id)) {
      acc.push(priceList.id)
    }
    return acc
  }, [])

  const entityParams = priceListId
    ? { priceListIds: [priceListId], clientIds: selectedEntityIds }
    : { priceListIds: selectedEntityIds, clientIds: [clientId] }

  return {
    callbacks: {
      bulkCreateFn,
      setSelectedEntityIds,
      setState,
    },
    creating,
    entityParams,
    filteredClientPriceListIds,
    filteredClientPriceListIdsCount: filteredClientPriceListIds?.length,
    name,
    priceLists,
    priceListsPayload,
    saveEnabled: (priceListId || clientId) && !!selectedEntityIds.length,
    selectedEntityIds,
  }
}

export default useBulkCreateClientPriceLists
