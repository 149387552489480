import { useState } from 'react'

import { useSetState } from '@campaignhub/react-hooks'

import usePriceListItemOptionValue from '@hooks/usePriceListItemOptionValue'
import usePriceListItemOptionValues from '@hooks/usePriceListItemOptionValues'
import useProductOptionValues from '@hooks/useProductOptionValues'
import useProductOptionValuesSubsets from '@hooks/useProductOptionValuesSubsets'
import useSelector from '@hooks/useSelector'
import { useRelations } from '@hooks/usePriceListItemOption'

import type { PriceListItemOptionModel } from '@models/types'

const defaultState = {
  name: '',
}

type BulkCreatePriceListItemOptionValuesParams = {
  priceListItemOption: PriceListItemOptionModel,
}

const useBulkCreatePriceListItemOptionValues = (params: BulkCreatePriceListItemOptionValuesParams) => {
  const { priceListItemOption } = params

  const [selectedProductOptionValueIds, setSelectedProductOptionValueIds] = useState<number[]>([])

  const [state, setState] = useSetState(defaultState)
  const { name } = state

  const { productOptionValues } = useSelector(reduxState => reduxState.entities)

  const {
    callbacks: {
      bulkCreatePriceListItemOptionValues,
    },
    creating,
  } = usePriceListItemOptionValue()

  const { priceListItem, productOption } = useRelations(priceListItemOption)
  const { productId } = priceListItem

  // Only show ProductOptionValues which:
  // 1. Are available via ProductOptionValuesSubsets
  // 2. Are not already selected
  // 3. Match the name filter
  const { filteredProductOptionValuesSubsets } = useProductOptionValuesSubsets({
    filters: {
      productId,
      productOptionId: productOption.id,
    },
    performHttpRequests: !!productId && !!productOption.id,
    requestOptions: {
      include: 'ProductOptionValue',
    },
  })

  const subsetProductOptionValueIds = filteredProductOptionValuesSubsets.map(subset => subset.productOptionValueId)

  const productOptionValuesPayload = useProductOptionValues({
    filters: {
      name,
      productOptionId: productOption.id,
    },
  })

  const { filteredProductOptionValues } = productOptionValuesPayload

  const { filteredPriceListItemOptionValues } = usePriceListItemOptionValues({
    filters: {
      priceListItemOptionId: priceListItemOption.id,
    },
  })

  const assignedProductOptionValueIds = filteredPriceListItemOptionValues.map(value => value.productOptionValueId)

  const validProductOptionValueIds = subsetProductOptionValueIds.filter(id => (
    !assignedProductOptionValueIds.includes(id)
  ))

  const filteredProductOptionValueIds = filteredProductOptionValues.reduce<number[]>((acc, value) => {
    if (validProductOptionValueIds.includes(value.id)){
      acc.push(value.id)
    }

    return acc
  }, [])

  const entityParams = {
    priceListItemOptionId: priceListItemOption.id,
    productOptionValueIds: selectedProductOptionValueIds,
  }

  return {
    callbacks: {
      bulkCreatePriceListItemOptionValues,
      setSelectedProductOptionValueIds,
      setState,
    },
    creating,
    entityParams,
    filteredProductOptionValueIds,
    filteredProductOptionValueIdsCount: filteredProductOptionValueIds.length,
    name,
    productOption,
    productOptionValues,
    productOptionValuesPayload,
    saveEnabled: !!selectedProductOptionValueIds.length,
    selectedProductOptionValueIds,
  }
}

export default useBulkCreatePriceListItemOptionValues
