import { EntitySelector as EntitySelectorBase } from '@campaignhub/suit-theme'
import type { EntitySelectorProps as EntitySelectorBaseProps, FormFieldProps } from '@campaignhub/suit-theme'

import searchEndpoint from '@functions/search'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import entitySettings from './entitySettings'

type EntitySelectorProps = Omit<EntitySelectorBaseProps, 'entities' | 'entitySettings' | 'useReduxAction'>
  & Partial<FormFieldProps>

const EntitySelector = (props: EntitySelectorProps) => {
  const { callbacks, ...restProps } = props

  const entities = useSelector(reduxState => reduxState.entities)

  return (
    <EntitySelectorBase
      callbacks={{ ...callbacks, search: searchEndpoint }}
      entities={entities}
      entitySettings={entitySettings}
      useReduxAction={useReduxAction}
      {...restProps}
    />
  )
}

export default EntitySelector
