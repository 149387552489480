import { useContext, useState } from 'react'

import { toggleArray } from '@campaignhub/javascript-utils'

import {
  Button, DashboardModule, ModuleBlankState,
} from '@campaignhub/suit-theme'

import filter from '@assets/filter.svg'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import { confirmDelete } from '@functions/entity'

import useAdQueue from '@hooks/useAdQueue'
import useAdQueues from '@hooks/useAdQueues'
import type { AdQueueFilters } from '@hooks/useAdQueues'

import FailedCampaignsTable from './FailedCampaignsTable'

type ToggleSelectedAdQueueIdsParams = {
  adQueueId: number,
  selectedAdQueueIds: number[],
  setSelectedAdQueueIds: React.Dispatch<React.SetStateAction<number[]>>,
}

const toggleSelectedAdQueueId = (params: ToggleSelectedAdQueueIdsParams) => {
  const { adQueueId, selectedAdQueueIds, setSelectedAdQueueIds } = params

  const updatedIds: number[] = toggleArray(selectedAdQueueIds, adQueueId)

  setSelectedAdQueueIds(updatedIds)

  return updatedIds
}

interface FailedCampaignsProps {
  pageFilters: AdQueueFilters,
}

const FailedCampaigns = (props: FailedCampaignsProps) => {
  const { pageFilters } = props

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext
  const { bulkDeleteAdQueues, bulkDeleteAndRequeueAdQueues } = callbacks || {}

  const [selectedAdQueueIds, setSelectedAdQueueIds] = useState<number[]>([])

  const hasSelectedAdQueues = !!selectedAdQueueIds.length

  const {
    callbacks: {
      bulkDeleteAdQueues: bulkDeleteFn,
      bulkDeleteAndRequeueAdQueues: bulkDeleteAndRequeueFn,
      bulkProcessAdQueues,
    },
    creating,
    deleting,
  } = useAdQueue()

  const adQueuesPayload = useAdQueues({
    filters: {
      ...pageFilters,
    },
    performHttpRequests: true,
    requestOptions: {
      include: [
        'Campaign',
        'FacebookCampaign',
        'PendingGoogleCampaign'
      ]
    },
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdQueues,
    filteredAdQueuesCount,
    hasFilteredAdQueues,
    loading,
  } = adQueuesPayload

  const filteredAdQueueIds = filteredAdQueues.map(adQueue => adQueue.id)

  const hasSelectedAllAdQueues = selectedAdQueueIds.length >= filteredAdQueuesCount

  const bulkProcessAdQueuesPayload = {
    callbacks: {
      action: bulkProcessAdQueues,
      afterAction: () => setSelectedAdQueueIds([]),
    },
    entityParams: selectedAdQueueIds,
    toastText: 'Ad Campaigns Queued',
  }

  const confirmBulkDeletePayload = {
    callbacks: {
      afterAction: () => setSelectedAdQueueIds([]),
      deleteEntity: bulkDeleteAdQueues,
      deleteFn: bulkDeleteFn,
    },
    options: {
      entityParams: { adQueueIds: selectedAdQueueIds },
      isBulkDelete: true,
    },
    strings: {
      entityName: 'Ad Campaigns',
    },
  }

  const confirmBulkDeleteAndRequeuePayload = {
    callbacks: {
      afterAction: () => setSelectedAdQueueIds([]),
      deleteEntity: bulkDeleteAndRequeueAdQueues,
      deleteFn: bulkDeleteAndRequeueFn,
    },
    options: {
      entityParams: selectedAdQueueIds,
      isBulkDelete: true,
    },
    strings: {
      deleteString: 'Delete and Re-queue',
      entityName: 'Ad Campaigns',
      toastString: 'Deleted and Re-queued',
    },
  }

  return (
    <DashboardModule
      boxProps={{ borderBottom: loading && !hasFilteredAdQueues ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Deploying Campaigns"
    >
      {!loading && !hasFilteredAdQueues && (
        <ModuleBlankState boxProps={{ height: 200 }} imageUrl={filter}>
          <ModuleBlankState.Title>No Failed Campaigns</ModuleBlankState.Title>

          <ModuleBlankState.Paragraph>
            Try changing your filters, or wait for more results
          </ModuleBlankState.Paragraph>
        </ModuleBlankState>
      )}

      {!loading && hasFilteredAdQueues && (
        <FailedCampaignsTable
          adQueues={filteredAdQueues}
          callbacks={{
            toggleAllAdQueues: () => setSelectedAdQueueIds(hasSelectedAllAdQueues ? [] : filteredAdQueueIds),
            toggleSelectedAdQueueId: (adQueueId: number) => toggleSelectedAdQueueId(
              { adQueueId, selectedAdQueueIds, setSelectedAdQueueIds },
            ),
          }}
          hasSelectedAllAdQueues={hasSelectedAllAdQueues}
          selectedAdQueueIds={selectedAdQueueIds}
        />
      )}

      <DashboardModule.LoadMoreFooter
        boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
        callbacks={{ loadMore }}
        canLoadMore={canLoadMore}
        entityCount={filteredAdQueuesCount}
        loading={loading}
      >
        <div>
          <Button
            buttonStyle="secondaryCreate"
            disabled={!hasSelectedAdQueues}
            loading={creating}
            onClick={() => handleCallbackAction(bulkProcessAdQueuesPayload)}
            size={['medium', 'small']}
          >
            Resubmit
          </Button>
        </div>

        <div style={{ marginLeft: 8 }}>
          <Button
            buttonStyle="secondaryCreate"
            disabled={!hasSelectedAdQueues}
            loading={deleting}
            onClick={() => confirmDelete(confirmBulkDeletePayload)}
            size={['medium', 'small']}
          >
            Delete
          </Button>
        </div>

        <div style={{ marginLeft: 8 }}>
          <Button
            buttonStyle="secondaryCreate"
            disabled={!hasSelectedAdQueues}
            loading={creating}
            onClick={() => confirmDelete(confirmBulkDeleteAndRequeuePayload)}
            size={['medium', 'small']}
          >
            Delete and Requeue
          </Button>
        </div>
      </DashboardModule.LoadMoreFooter>
    </DashboardModule>
  )
}

export default FailedCampaigns
