import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ClientUserRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['clientUsers']

type ClientUserFilters = {
  clientId?: number,
  clientName?: string,
  userId?: number,
  userName?: string,
}

type UseClientUsersOptions = {
  filters?: ClientUserFilters,
  performHttpRequests?: boolean,
  requestOptions?: ClientUserRequestOptions,
  sortKey?: 'client' | 'user',
}

export function useClientUsers(options: UseClientUsersOptions) {
  const { filters = {}, requestOptions, sortKey } = options

  const {
    clientId: filterClientId,
    clientName: filterClientName,
    userId: filterUserId,
    userName: filterUserName,
  } = filters

  const {
    updatedEntities: { clientUsers: clientUsersUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { clients, clientUsers, users } = useSelector(reduxState => reduxState.entities)

  const filteredClientUsers = useMemo(() => {
    const filtered = Object.values(clientUsers).filter((clientUser) => {
      const { clientId, userId } = clientUser

      const clientName = clients[clientId]?.name
      const userName = users[userId]?.name

      const matchClientId = matchFilterNumber(clientId, filterClientId)
      const matchClientName = matchFilterString(clientName, filterClientName)
      const matchClientString = matchClientId && matchClientName

      const matchUserId = matchFilterNumber(userId, filterUserId)
      const matchUserName = matchFilterString(userName, filterUserName)
      const matchUserString = matchUserId && matchUserName

      return matchClientString && matchUserString
    })

    return sortArrayBy(
      filtered,
      'asc',
      ({ clientId, userId }) => (
        sortKey === 'client'
          ? clients[clientId]?.name
          : users[userId]?.name
      ),
    )
  }, [clientUsersUpdatedAt, JSON.stringify(filters)])

  const filteredClientUsersCount = filteredClientUsers.length
  const hasFilteredClientUsers = !!filteredClientUsersCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredClientUsersCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingClientUsers } = useReduxAction(
    'clientUsers',
    'loadClientUsers',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredClientUsers,
    filteredClientUsersCount,
    hasFilteredClientUsers,
    loading: loadingClientUsers,
  }
}

export default useClientUsers
