import {
  faChartLine, faImages, faServer, faTachometer,
} from '@fortawesome/pro-light-svg-icons'

import { generateUrls } from '@hooks/useCampaign'

import { CampaignModel } from '@models/types'

const generateNavigationItems = (campaign: Partial<CampaignModel>) => {
  const {
    systemAdminOrderCampaigns,
    systemAdminOrderCreative,
    systemAdminOrderOverview,
    systemAdminOrderReporting,
  } = generateUrls(campaign)

  return {
    sections: [
      {
        key: 'dashboard',
        visible: true,
        items: [
          {
            href: systemAdminOrderOverview,
            icon: faTachometer,
            key: 'overview',
            title: 'Overview',
            visible: true,
          },
          {
            href: systemAdminOrderCreative,
            icon: faImages,
            key: 'creative',
            title: 'Ad Creative',
            visible: true,
          },
          {
            href: systemAdminOrderCampaigns,
            icon: faServer,
            key: 'campaigns',
            title: 'Campaigns',
            visible: true,
          },
          {
            href: systemAdminOrderReporting,
            icon: faChartLine,
            key: 'reporting',
            title: 'Reporting',
            visible: true,
          },
        ],
      },
    ],
  }
}

export default generateNavigationItems
