import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

import type { CaptionRequestOptions } from '@models/types'

const watchEntityKeys = ['captions']

type CaptionFilters = {
  campaignId?: number,
}

type UseCaptionsOptions = {
  filters?: CaptionFilters,
  requestOptions?: CaptionRequestOptions,
}

function useCaptions(options: UseCaptionsOptions) {
  const { filters = {} } = options
  const {
    campaignId: filterCampaignId,
  } = filters

  const {
    updatedEntities: { captions: captionsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { captions } = useSelector(reduxState => reduxState.entities)

  const filteredCaptions = useMemo(() => {
    const filtered = Object.values(captions).filter((caption) => {
      const { campaignId } = caption

      const matchCampaignId = matchFilterNumber(campaignId, filterCampaignId)

      return matchCampaignId
    })

    return sortArrayBy(filtered, 'asc', 'id')
  }, [captionsUpdatedAt, JSON.stringify(filters)])

  const filteredCaptionsCount = filteredCaptions.length
  const hasFilteredCaptions = !!filteredCaptionsCount

  return {
    filteredCaptions,
    filteredCaptionsCount,
    hasFilteredCaptions,
  }
}

export default useCaptions
