import useSelector from '@hooks/useSelector'

import type { OrderModel } from '@models/types'

export const useRelations = (order: Partial<OrderModel> = {}) => {
  const { projectId } = order

  const { projects } = useSelector(reduxState => reduxState.entities)

  const project = projectId && projects[projectId] ? projects[projectId] : {}

  return {
    project,
  }
}
