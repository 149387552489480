import { DynamicInput, DashboardModule } from '@campaignhub/suit-theme'
import type { FiltersProps } from '@campaignhub/suit-theme'

import useSelector from '@hooks/useSelector'

import EntitySelector from '@components/EntitySelector'

const supportedComponents = {
  DynamicInput,
  EntitySelector,
}

type DashboardModuleFiltersProps = Omit<FiltersProps, 'entities' | 'supportedComponents'>

const DashboardModuleFilters = (props: DashboardModuleFiltersProps) => {
  const { callbacks, ...restProps } = props

  const entities = useSelector(reduxState => reduxState.entities)

  return (
    <DashboardModule.Filters
      callbacks={{ ...callbacks }}
      entities={entities}
      supportedComponents={supportedComponents}
      {...restProps}
    />
  )
}

export default DashboardModuleFilters
