import { useContext } from 'react'

import { launchModal } from '@campaignhub/javascript-utils'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import defaultFormState, { requiredFields } from '@models/priceList'
import type { PriceListModel, PriceListRequestOptions } from '@models/types'

import * as priceListActions from '@redux/modules/priceList'
import type { BulkUpdatePriceListsParams } from '@redux/modules/priceList'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'

export const generateUrls = (priceList?: Partial<PriceListModel>) => {
  const { id } = priceList || {}

  return {
    archivedPriceListsIndexUrl: '#/priceLists?isArchived=true',
    editPriceListItemsUrl: `#/priceLists/${id}/edit/items`,
    editPriceListUrl: `#/priceLists/${id}/edit/`,
    editPriceListRelationsUrl: `#/priceLists/${id}/edit/relations`,
    priceListsIndexUrl: '#/priceLists',
  }
}

type ArchivePriceListParams = {
  dispatch: AppDispatch,
  nextPriceListId: number,
  priceList: DeleteParams<PriceListModel>,
}

const archivePriceList = (params: ArchivePriceListParams) => {
  const { dispatch, nextPriceListId, priceList } = params
  const { archivePriceList: archiveFn } = priceListActions

  const updatedParams = {
    ...priceList,
    nextPriceListId,
  }

  return dispatch(archiveFn(updatedParams))
}

type CreatePriceListParams = {
  dispatch: AppDispatch,
  priceListParams: Partial<PriceListModel>,
  requestOptions?: PriceListRequestOptions,
}

const createPriceList = (params: CreatePriceListParams) => {
  const { dispatch, priceListParams, requestOptions } = params
  const { createPriceList: createFn } = priceListActions

  return dispatch(createFn(priceListParams, requestOptions)).then((response) => {
    const { success } = response

    if (success) {
      const { data } = response

      const { editPriceListUrl } = generateUrls(data)

      return {
        success,
        data,
        redirectUrl: editPriceListUrl,
      }
    }

    return response
  })
}

type UpdatePriceListParams = {
  dispatch: AppDispatch,
  priceList: PriceListModel,
  priceListParams: Partial<PriceListModel>,
  requestOptions?: PriceListRequestOptions,
}

const updatePriceList = (params: UpdatePriceListParams) => {
  const {
    dispatch, priceList, priceListParams, requestOptions,
  } = params
  const { updatePriceList: updateFn } = priceListActions

  const updatedParams = {
    id: priceList.id,
    ...priceListParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeletePriceListParams = {
  dispatch: AppDispatch,
  priceList: DeleteParams<PriceListModel>,
}

const deletePriceList = (params: DeletePriceListParams) => {
  const { dispatch, priceList } = params
  const { deletePriceList: deleteFn } = priceListActions

  return dispatch(deleteFn(priceList))
}

type DuplicatePriceListParams = {
  dispatch: AppDispatch,
  priceListParams: Partial<PriceListModel>,
  requestOptions?: PriceListRequestOptions,
}

const duplicatePriceList = (params: DuplicatePriceListParams) => {
  const { dispatch, priceListParams, requestOptions } = params
  const { duplicatePriceList: duplicateFn } = priceListActions

  return dispatch(duplicateFn(priceListParams, requestOptions)).then((response) => {
    const { success } = response

    if (success) {
      const { data } = response

      const { editPriceListUrl } = generateUrls(data)

      return {
        success,
        data,
        redirectUrl: editPriceListUrl,
      }
    }

    return response
  })
}

type BulkUpdatePriceListParams = {
  bulkUpdateParams: BulkUpdatePriceListsParams,
  dispatch: AppDispatch,
}

const bulkUpdatePriceLists = (params: BulkUpdatePriceListParams) => {
  const { bulkUpdateParams, dispatch } = params
  const { bulkUpdatePriceLists: bulkUpdateFn } = priceListActions

  return dispatch(bulkUpdateFn(bulkUpdateParams))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function usePriceListForm(
  priceList: Partial<PriceListModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const priceListForm = useForm(
    defaultFormState,
    { entity: priceList, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [priceList.id, priceList.cacheKey],
  )

  return {
    ...priceListForm,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: PriceListRequestOptions,
}

type BulkUpdatePriceListCustomPayload = {
  callbacks: {
    deselectAll: () => void,
  },
  priceListIds: number[],
}

function usePriceList(initEntity: Partial<PriceListModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: priceList }: { entity: PriceListModel } = useLatestEntity(initEntity, 'priceLists')
  const { id } = priceList

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'priceLists',
    'loadPriceList',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.priceLists)

  return {
    callbacks: {
      archivePriceList: (nextPriceListId: number) => archivePriceList({ priceList, nextPriceListId, dispatch }),
      bulkUpdatePriceLists: (bulkUpdateParams: BulkUpdatePriceListsParams) => bulkUpdatePriceLists({
        bulkUpdateParams, dispatch,
      }),
      createPriceList: (priceListParams: Partial<PriceListModel>, entityOptions?: PriceListRequestOptions) => (
        createPriceList({ priceListParams, dispatch, requestOptions: entityOptions })
      ),
      deletePriceList: () => deletePriceList({ priceList, dispatch }),
      duplicatePriceList: (priceListParams: Partial<PriceListModel>, entityOptions?: PriceListRequestOptions) => (
        duplicatePriceList({ priceListParams, dispatch, requestOptions: entityOptions })
      ),
      launchArchivePriceListModal: () => launchModal({
        callbacks,
        modalKey: 'ArchivePriceListModal',
        payload: { priceList },
      }),
      launchBulkUpdatePriceListsModal: (customPayload: BulkUpdatePriceListCustomPayload) => launchModal({
        callbacks,
        modalKey: 'BulkUpdatePriceListsModal',
        payload: customPayload,
      }),
      launchDuplicatePriceListModal: () => launchModal({
        callbacks,
        modalKey: 'DuplicatePriceListModal',
        payload: { priceList },
      }),
      updatePriceList: (priceListParams: Partial<PriceListModel>, entityOptions?: PriceListRequestOptions) => (
        updatePriceList({
          priceList, priceListParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    priceList,
    updating,
    urls: generateUrls(priceList),
  }
}

export default usePriceList
