import { useNumericParams } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import ProductOptionEditRoutes from './routes'

const ProductOptionEdit = () => {
  const { productOptionId } = useNumericParams()

  useReduxAction(
    'productOptions',
    'loadProductOption',
    {
      entityId: productOptionId,
    },
    [productOptionId],
    {
      dispatchAction: (action, requestOptions) => action(productOptionId, requestOptions),
      shouldPerformFn: ({ loading }) => !!productOptionId && !loading,
    },
  )

  return (
    <ProductOptionEditRoutes />
  )
}

export default ProductOptionEdit
