import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import type { ProductOptionModel } from '@models/types'

import { generateUrls } from '@hooks/useProductOption'

interface ProductOptionListItemProps {
  productOption: ProductOptionModel,
}

const ProductOption = (props: ProductOptionListItemProps) => {
  const {
    productOption,
    productOption: {
      displayType,
      id,
      name,
    },
  } = props

  const { editProductOptionUrl } = generateUrls(productOption)

  return (
    <DashboardModuleListItem
      href={editProductOptionUrl}
      title={name}
      secondaryText={displayType}
    >
      <DashboardModuleListItem.TertiaryText>
        {`# ${id}`}
      </DashboardModuleListItem.TertiaryText>
    </DashboardModuleListItem>
  )
}

export default ProductOption
