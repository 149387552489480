import { useMemo } from 'react'

import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import CreateOrEditCutterProfileModuleModal from '@modals/CreateOrEditCutterProfileModuleModal'

import PageContent from './components/PageContent'
import { CutterProfileModuleModel } from '@models/cutterProfileModule'

const defaultState = {
  showCreateOrEditCutterProfileModuleModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateOrEditCutterProfileModuleModal: {
      closeModal: () => setState({ showCreateOrEditCutterProfileModuleModal: false }),
      createCutterProfileModule: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateCutterProfileModule: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Details = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditCutterProfileModuleModal,
  } = state

  const { cutterProfileId } = useNumericParams()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showCreateOrEditCutterProfileModuleModal: (payload: { cutterProfileModule: CutterProfileModuleModel }) => {
        setModalData('CreateOrEditCutterProfileModuleModal', payload)
        setState({ showCreateOrEditCutterProfileModuleModal: true })
      },
      updateCutterProfile: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent cutterProfileId={cutterProfileId} />

        <CreateOrEditCutterProfileModuleModal
          callbacks={callbacks('CreateOrEditCutterProfileModuleModal', setState)}
          showModal={showCreateOrEditCutterProfileModuleModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details
