import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/pro-light-svg-icons'

import {
  Button, Tools as ToolsBase,
} from '@campaignhub/suit-theme'

import useCampaign from '@hooks/useCampaign'

type ToolsProps = {
  campaignPayload: ReturnType<typeof useCampaign>,
}

const Tools = (props: ToolsProps) => {
  const { campaignPayload } = props

  // get function to open display settings modal
  // const {
  //   callbacks: {
  //     manageDisplaySettings,
  //   },
  // } = campaignPayload

  return (
    <ToolsBase title="Tools">
      <Button
        buttonStyle="secondaryUtility"
        icon={<FontAwesomeIcon icon={faCog} />}
        // onClick={() => manageDisplaySettings()}
        size="medium"
      >
        Display Settings
      </Button>
    </ToolsBase>
  )
}

export default Tools
