import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ProductOptionValueModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

import { removePriceListItemOptionValue } from './priceListItemOptionValue'
import { removeProductOptionValuesSubset } from './productOptionValuesSubset'

const FETCH_REQUEST = 'realbase/productOptionValue/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/productOptionValue/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/productOptionValue/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/productOptionValue/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/productOptionValue/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/productOptionValue/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/productOptionValue/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/productOptionValue/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/productOptionValue/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/productOptionValue/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/productOptionValue/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/productOptionValue/DELETE_FAILURE'

export type ProductOptionValueModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: ProductOptionValueModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadProductOptionValues(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().productOptionValues.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/product-option-values', options)
      .then(({ data }: { data: { data: ProductOptionValueModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.PRODUCT_OPTION_VALUE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createProductOptionValue(
  productOptionValue: Partial<ProductOptionValueModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      ...productOptionValue,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/product-option-values', options, config)
      .then(({ data }: { data: ProductOptionValueModel }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_OPTION_VALUE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateProductOptionValue(
  productOptionValue: UpdateParams<ProductOptionValueModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      ...productOptionValue,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/product-option-values/${productOptionValue.id}`, options, config)
      .then(({ data }: { data: ProductOptionValueModel }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_OPTION_VALUE)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function duplicateProductOptionValue(
  productOptionValue: Partial<ProductOptionValueModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api(`/product-option-values/${productOptionValue.id}/duplicate`, options, config)
      .then(({ data }: { data: ProductOptionValueModel }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_OPTION_VALUE)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteProductOptionValue(productOptionValue: DeleteParams<ProductOptionValueModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    dispatch(deleteRequest())

    const promise = api(`/product-option-values/${productOptionValue.id}`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        const deletedEntity = { id: productOptionValue.id }
        const normalizedJson = normalize(deletedEntity, Schemas.PRODUCT_OPTION_VALUE)
        dispatch(deleteEntity(normalizedJson))

        const { priceListItemOptionValues, productOptionValuesSubsets } = getState().entities

        // Remove any PriceListItemOptionValues related to the ProductOptionValue
        Object.values(priceListItemOptionValues).forEach((priceListItemOptionValue) => {
          if (priceListItemOptionValue.productOptionValueId === productOptionValue.id){
            removePriceListItemOptionValue(dispatch, priceListItemOptionValue)
          }
        })

        // Remove any ProductOptionValuesSubsets related to the ProductOptionValue
        Object.values(productOptionValuesSubsets).forEach((productOptionValuesSubset) => {
          if (productOptionValuesSubset.productOptionValueId === productOptionValue.id){
            removeProductOptionValuesSubset(dispatch, productOptionValuesSubset)
          }
        })

        dispatch(deleteSuccess())

        return { success: true as const, data: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

type SortProductOptionValuesParams = {
  productOptionId: number,
  sortedIds: number[],
}

export function sortProductOptionValues(params: SortProductOptionValuesParams) {
  const { productOptionId, sortedIds } = params

  const config = {
    method: 'PATCH',
    data: JSON.stringify({ productOptionId, productOptionValueIds: sortedIds }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api('/product-option-values/sorting', {}, config)
      .then(({ data }: { data: { data: ProductOptionValueModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.PRODUCT_OPTION_VALUE_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })
    return promise
  }
}

// Reducer
export default function reducer(
  state: ProductOptionValueModuleState = initialState,
  action: Action = { type: '' },
): ProductOptionValueModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
