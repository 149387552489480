import { faIdCard } from '@fortawesome/pro-light-svg-icons'

import { BrandModel } from '@models/brand'
import { generateUrls } from '@hooks/useBrand'

const generateTabBarItems = (brand: BrandModel) => {
  const urls = generateUrls(brand)
  const { editBrandUrl } = urls

  return [
    {
      icon: faIdCard,
      href: editBrandUrl,
      key: 'details',
      title: brand?.name || 'Brand Details',
    },
  ]
}

export default generateTabBarItems
