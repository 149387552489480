import {
  Box,
  Columns,
  DashboardModule,
  LoadingModule,
} from '@campaignhub/suit-theme'

import useBrand, { useBrandForm } from '@hooks/useBrand'
import useClients from '@hooks/useClients'

import ClientListItem from '@components/ClientListItem'
import BrandDetails from '../BrandDetails'
import PageLayout from '../../../../components/PageLayout'
import BrandColours from '../BrandColours'

interface PageContentProps {
  brandId: number,
}

const PageContent = (props: PageContentProps) => {
  const { brandId } = props

  const brandPayload = useBrand({ id: brandId })

  const {
    brand,
    loading,
  } = brandPayload

  const useClientsPayload = useClients({ filters: { brandId }, performHttpRequests: true })
  const { filteredClients, hasFilteredClients } = useClientsPayload

  const brandFormPayload = useBrandForm(brand, { validateOn: 'change' })

  return (
    <PageLayout brandPayload={brandPayload} activeTabBarItemKey="details">
      <Columns.Main>
        <LoadingModule loading={loading} times={3} />

        {!loading && (
          <BrandDetails brandFormPayload={brandFormPayload} />
        )}

        {!loading && hasFilteredClients && (
          <DashboardModule title="Organisations">
            <Box flexDirection="column" overflow="auto">
              {filteredClients.map(client => (
                <ClientListItem key={client.id} client={client} />
              ))}
            </Box>
          </DashboardModule>
        )}
      </Columns.Main>

      <Columns.Sidebar>
        <BrandColours brandPayload={brandPayload} />
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent
