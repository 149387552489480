import { useNumericParams } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import ArtworkTemplateGroupEditRoutes from './routes'

const ArtworkTemplateGroupEdit = () => {
  const { artworkTemplateGroupId } = useNumericParams()

  useReduxAction(
    'artworkTemplateGroups',
    'loadArtworkTemplateGroup',
    {
      entityId: artworkTemplateGroupId,
      include: [
        'ArtworkTemplates.CutterProfile.CutterProfileModules',
      ],
    },
    [artworkTemplateGroupId],
    {
      dispatchAction: (action, requestOptions) => action(artworkTemplateGroupId, requestOptions),
      shouldPerformFn: ({ loading }) => !!artworkTemplateGroupId && !loading,
    },
  )

  return (
    <ArtworkTemplateGroupEditRoutes />
  )
}

export default ArtworkTemplateGroupEdit
