import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { AdTemplateRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['adTemplates']

type AdTemplateFilters = {
  name?: string,
}

type UseAdTemplatesOptions = {
  filters?: AdTemplateFilters,
  performHttpRequests?: boolean,
  requestOptions?: AdTemplateRequestOptions,
}

function useAdTemplates(options: UseAdTemplatesOptions) {
  const { filters = {}, requestOptions } = options
  const {
    name: filterString,
  } = filters

  const {
    updatedEntities: { adTemplates: adTemplatesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { adTemplates } = useSelector(reduxState => reduxState.entities)

  const filteredAdTemplates = useMemo(() => {
    const filtered = Object.values(adTemplates).filter((adTemplate) => {
      const { id, name } = adTemplate

      const matchId = id.toString() === filterString
      const matchTitle = matchFilterString(name, filterString)
      const stringMatch = matchId || matchTitle

      return stringMatch
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [adTemplatesUpdatedAt, JSON.stringify(filters)])

  const filteredAdTemplatesCount = filteredAdTemplates.length
  const hasFilteredAdTemplates = !!filteredAdTemplatesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredAdTemplatesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingAdTemplates } = useReduxAction(
    'adTemplates',
    'loadAdTemplates',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdTemplates,
    filteredAdTemplatesCount,
    hasFilteredAdTemplates,
    loading: loadingAdTemplates,
  }
}

export default useAdTemplates
