import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal, SidebarNoticeBox,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import usePriceList, { usePriceListForm } from '@hooks/usePriceList'

import { confirmAction } from '@functions/entity'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import EntitySelector from '@components/EntitySelector'

type ModalCallbacks = {
  archivePriceList: (payload: HandleCallbackActionParams) => void,
  closeModal: () => void,
}

const MODAL_KEY = 'ArchivePriceListModal'

const customRequiredFields = [
  { key: 'nextPriceListId' },
]

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const ArchivePriceListModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const priceList = digObject(modalPayload, 'priceList', {})

  const {
    callbacks: {
      archivePriceList: archiveFn,
    },
    updating,
  } = usePriceList(priceList)

  const {
    entityState: {
      nextPriceListId,
    },
    errors,
    saveEnabled,
    setEntityState,
  } = usePriceListForm(priceList, { customRequiredFields, validateOn: 'change' })

  const archivePriceListPayload = {
    callbacks: {
      actionFn: archiveFn,
      afterAction: closeModal,
    },
    options: {
      confirmButtonColor: '#DD6B55',
      entityParams: nextPriceListId,
      icon: 'warning',
    },
    strings: {
      actionString: 'archive',
      entityName: 'Price List',
    },
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Archive" titleSecondLine="Price List" />

      <SidebarModal.Content>
        <Form>
          <SidebarNoticeBox>
            <SidebarNoticeBox.Title>Reassign Organisations and Archive</SidebarNoticeBox.Title>

            <SidebarNoticeBox.Paragraph>
              All organisations currently assigned to this price list will
              be reassigned to the price list selected below.
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>

          <EntitySelector
            callbacks={{
              selectItem: priceListEntity => setEntityState({
                nextPriceListId: priceListEntity ? priceListEntity.id : null,
              }),
            }}
            entityKey="priceLists"
            entityTitleKey="name"
            errorMessage={errors.nextPriceListId}
            searchRequestOptions={{ isArchived: false }}
            selectedIds={[priceList.id]}
            selectedItemId={nextPriceListId}
          />
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          loadingBubbleColor="white"
          onClick={() => confirmAction(archivePriceListPayload)}
          size="large"
        >
          {saveEnabled ? 'Archive Price List' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ArchivePriceListModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
