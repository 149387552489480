import {
  Box, Button, DashboardModule,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { useFilters } from '@campaignhub/react-hooks'

import DashboardModuleFilters from '@components/DashboardModuleFilters'

import useProductOptionProduct from '@hooks/useProductOptionProduct'
import useProductOptionProducts from '@hooks/useProductOptionProducts'

import ProductOptionProductListItem from './ProductOptionProductListItem'

const buildPageFilterFields = (isShowingProductOptions: boolean) => {
  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: isShowingProductOptions ? 'productOptionName' : 'productName',
      label: 'Filter by Name',
    },
  ]
}

type ProductOptionProductsProps = { productId: number, productOptionId?: number } | { productId?: number, productOptionId: number }

const ProductOptionProducts = (props: ProductOptionProductsProps) => {
  const { productId, productOptionId } = props

  const isShowingProductOptions = !!productId

  const filterPayload = useFilters()

  const {
    pageFilters,
  } = filterPayload

  const options = {
    filters: {
      productId,
      productOptionId,
      ...pageFilters,
    },
    performHttpRequests: !!productId || !!productOptionId,
    requestOptions: {
      include: isShowingProductOptions ? 'ProductOption.ProductOptionValuesSubsets' : 'Product.ProductOptionValuesSubsets',
    },
  }

  const productOptionProductsPayload = useProductOptionProducts(options)

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredProductOptionProducts,
    filteredProductOptionProductsCount,
    hasFilteredProductOptionProducts,
    loading,
  } = productOptionProductsPayload

  const {
    callbacks: {
      launchBulkCreateProductOptionProductsModal,
    },
  } = useProductOptionProduct({ productId, productOptionId })

  return (
    <DashboardModule
      boxProps={{ borderTop: loading ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={<DashboardModule.FilterHeader {...filterPayload} />}
      loading={loading}
      title={isShowingProductOptions ? "Product Options" : "Products"}
    >
      <DashboardModuleFilters
        filterFields={buildPageFilterFields(isShowingProductOptions)}
        {...filterPayload}
      />

      <Box flexDirection="column">
        <DashboardModule.ScrollableSection flexDirection="column">
          {!loading && !hasFilteredProductOptionProducts && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>
                No {isShowingProductOptions ? 'Product Options' : 'Products'} Linked
              </DashboardModule.BlankState.Title>

              <DashboardModule.BlankState.Paragraph>
                Please adjust your filters or link a {isShowingProductOptions ? 'Product Option' : 'Product'}
              </DashboardModule.BlankState.Paragraph>
            </DashboardModule.BlankState>
          )}

          {!loading && filteredProductOptionProducts.map(productOptionProduct => (
            <ProductOptionProductListItem
              isShowingProductOptions={isShowingProductOptions}
              key={productOptionProduct.id}
              productOptionProduct={productOptionProduct}
            />
          ))}
        </DashboardModule.ScrollableSection>

        <DashboardModule.LoadMoreFooter
          boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
          callbacks={{ loadMore }}
          canLoadMore={canLoadMore}
          entityCount={filteredProductOptionProductsCount}
          loading={loading}
        >
          <Button
            buttonStyle="secondaryEdit"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => launchBulkCreateProductOptionProductsModal()}
            size={['medium', 'small']}
            width={['100%', 'fit-content']}
          >
            {isShowingProductOptions ? 'Assign Product Options' : 'Assign Products'}
          </Button>
        </DashboardModule.LoadMoreFooter>
      </Box>
    </DashboardModule>
  )
}

export default ProductOptionProducts
