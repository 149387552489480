import { faHome } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, Columns } from '@campaignhub/suit-theme'

import { generateUrls } from '@hooks/useCampaign'

import PageHeader from '@components/PageHeader'

import OrderModule from '../OrderModule'

type PageContentProps = {
  campaignId: number,
}

const PageContent = (props: PageContentProps) => {
  const { campaignId } = props

  const { campaignOverviewUrl } = generateUrls({ id: campaignId })

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey="overview"
        nestedNavigation
        tabBarItems={[
          {
            href: campaignOverviewUrl,
            icon: faHome,
            key: 'overview',
            title: 'Overview',
          },
        ]}
        title="Overview"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <OrderModule />
          </Columns.Main>

          <Columns.Sidebar />
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
