import { formatMoney } from 'accounting'

import { Box, DashboardModule, LoadingModule, Text } from '@campaignhub/suit-theme'

import { useRelations } from '@hooks/useOrderItem'

import type { OrderItemModel } from '@models/types'

interface OrderDetailsProps {
  loading: boolean,
  orderItem: OrderItemModel,
}

const OrderDetails = (props: OrderDetailsProps) => {
  const { loading, orderItem } = props

  const { order, priceListItem } = useRelations(orderItem)

  if (loading) return <LoadingModule loading={loading} />

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      title="Order Details"
    >
      <Box alignItems="center" borderBottom="1px solid" borderColor="lineColor" justifyContent="space-between" padding="large">
        <Text letterSpacing="0.3px" lineHeight="24px">{orderItem.name}</Text>

        <Text>{formatMoney(priceListItem.price, '$', 2)}</Text>
      </Box>

      <Box flexDirection="column" padding="large">
        <Text color="bodyFontLightColor" fontSize="xsmall" letterSpacing="1px" lineHeight="1.33">Order Number</Text>

        <Text fontSize="small" letterSpacing="0.3px" lineHeight="1.43" marginBottom="medium">{order.id}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" letterSpacing="1px" lineHeight="1.33">Provider</Text>

        <Text fontSize="small" letterSpacing="0.3px" lineHeight="1.43">CampaignTrack AIM</Text>
      </Box>
    </DashboardModule>
  )
}

export default OrderDetails