import {
  faChartLine, faImages, faServer, faTachometer,
} from '@fortawesome/pro-light-svg-icons'

import { generateUrls } from '@hooks/useCampaign'

import type { CampaignModel } from '@models/types'

const generateTabBarItems = (campaign: Partial<CampaignModel>) => {
  const {
    systemAdminOrderCampaigns,
    systemAdminOrderCreative,
    systemAdminOrderOverview,
    systemAdminOrderReporting,
  } = generateUrls(campaign)

  return [
    {
      icon: faTachometer,
      href: systemAdminOrderOverview,
      key: 'overview',
      title: 'Overview',
    },
    {
      icon: faImages,
      href: systemAdminOrderCreative,
      key: 'creative',
      title: 'Creative',
    },
    {
      icon: faServer,
      href: systemAdminOrderCampaigns,
      key: 'campaigns',
      title: 'Campaigns',
    },
    {
      icon: faChartLine,
      href: systemAdminOrderReporting,
      key: 'reporting',
      title: 'Reporting',
    },
  ]
}

export default generateTabBarItems
