import { useNumericParams } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import UserEditRoutes from './routes'

const UserEdit = () => {
  const { userId } = useNumericParams()

  useReduxAction(
    'users',
    'loadUser',
    {
      entityId: userId,
    },
    [userId],
    {
      dispatchAction: (action, requestOptions) => action(userId, requestOptions),
      shouldPerformFn: ({ loading }) => !!userId && !loading,
    },
  )

  return (
    <UserEditRoutes />
  )
}

export default UserEdit
