import { useContext } from 'react'

import { useLatestEntity } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as adAccountLinkActions from '@redux/modules/adAccountLink'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { BulkCreateParams } from '@redux/modules/adAccountLink'
import type { DeleteParams } from '@redux/modules/types'

import type { AdAccountLinkModel, AdAccountLinkRequestOptions } from '@models/types'

type BulkCreateAdAccountLinksParams = {
  adAccountLinkParams: BulkCreateParams,
  dispatch: AppDispatch,
  requestOptions?: AdAccountLinkRequestOptions,
}

const bulkCreateAdAccountLinks = (params: BulkCreateAdAccountLinksParams) => {
  const { dispatch, adAccountLinkParams, requestOptions } = params
  const { bulkCreateAdAccountLinks: bulkCreateFn } = adAccountLinkActions

  return dispatch(bulkCreateFn(adAccountLinkParams, requestOptions))
}

type DeleteAdAccountLinkParams = {
  adAccountLink: DeleteParams<AdAccountLinkModel>,
  dispatch: AppDispatch,
}

const deleteAdAccountLink = (params: DeleteAdAccountLinkParams) => {
  const { adAccountLink, dispatch } = params
  const { deleteAdAccountLink: deleteFn } = adAccountLinkActions

  return dispatch(deleteFn(adAccountLink))
}

export const useRelations = (adAccountLink: Partial<AdAccountLinkModel> = {}) => {
  const { adAccountId } = adAccountLink

  const { adAccounts } = useSelector(reduxState => reduxState.entities)

  const adAccount = adAccountId && adAccounts[adAccountId] ? adAccounts[adAccountId] : {}

  return {
    adAccount,
  }
}

function useAdAccountLink(initEntity: Partial<AdAccountLinkModel> = {}) {
  const { entity: adAccountLink }: { entity: AdAccountLinkModel } = useLatestEntity(initEntity, 'adAccountLinks')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { adAccount } = useRelations(adAccountLink)

  const { creating, loading } = useSelector(reduxState => reduxState.adAccountLinks)

  return {
    adAccount,
    adAccountLink,
    callbacks: {
      bulkCreateAdAccountLinks: (adAccountLinkParams: BulkCreateParams, entityOptions?: AdAccountLinkRequestOptions) => (
        bulkCreateAdAccountLinks({ adAccountLinkParams, dispatch, requestOptions: entityOptions })
      ),
      createOrEditAdAccountLink: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditAdAccountLinkModal',
        payload: { adAccountLink },
      }),
      deleteAdAccountLink: () => deleteAdAccountLink({ adAccountLink, dispatch }),
    },
    creating,
    loading,
  }
}

export default useAdAccountLink
