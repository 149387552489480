import { useNumericParams } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

import ProductEditRoutes from './routes'

const ProductEdit = () => {
  const { productId } = useNumericParams()

  useReduxAction(
    'products',
    'loadProduct',
    {
      entityId: productId,
    },
    [productId],
    {
      dispatchAction: (action, requestOptions) => action(productId, requestOptions),
      shouldPerformFn: ({ loading }) => !!productId && !loading,
    },
  )

  return (
    <ProductEditRoutes />
  )
}

export default ProductEdit
