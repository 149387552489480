import { Columns } from '@campaignhub/suit-theme'

import useProductOption from '@hooks/useProductOption'

import ProductOptionProducts from '@components/ProductOptionProducts'

import PageLayout from '../../../../components/PageLayout'
import ProductOptionTools from '../../../../components/ProductOptionTools'

interface PageContentProps {
  productOptionId: number,
}

const PageContent = (props: PageContentProps) => {
  const { productOptionId } = props

  const productOptionPayload = useProductOption({ id: productOptionId })

  return (
    <PageLayout productOptionPayload={productOptionPayload} activeTabBarItemKey="relations">
      <Columns.Main>
        <ProductOptionProducts productOptionId={productOptionId} />
      </Columns.Main>

      <Columns.Sidebar>
        <ProductOptionTools productOptionPayload={productOptionPayload} />
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent
