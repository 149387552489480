import { Box } from '@campaignhub/suit-theme'

interface HexColourProps {
  colour: string,
}

const HexColour = (props: HexColourProps) => {
  const { colour } = props

  return (
    <Box
      backgroundColor={colour}
      borderColor="lineColor"
      borderRadius="50%"
      flexShrink={0}
      height={24}
      width={24}
    />
  )
}

export default HexColour
