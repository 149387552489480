import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Link, ListItem, Text, UserProfileImage,
} from '@campaignhub/suit-theme'

import { useRelations } from '@hooks/useUser'

import { UserModel } from '@models/types'

interface DraggableUserProps {
  callbacks: {
    removeUser: (userId: number) => void,
  },
  user: UserModel,
}

const DraggableUser = React.forwardRef((props: DraggableUserProps, ref) => {
  const {
    callbacks,
    dragHandleProps,
    dragSnapshot,
    draggableProps,
    user,
    user: {
      id,
      firstName,
      lastName,
      mobileNumber,
    },
  } = props

  const { removeUser } = callbacks || {}

  const { isDragging } = dragSnapshot || {}

  const {
    coverImage: {
      filePath,
    },
  } = useRelations(user)

  const initials = firstName[0] + lastName[0]

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottom: '1px dashed',
        paddingY: 'large',
      }}
      disableHover
      forwardProps={{
        ...draggableProps,
        ...dragHandleProps,
      }}
      isDragging={isDragging}
      ref={ref}
    >
      <Box marginRight="large" width="auto">
        <UserProfileImage imageUrl={filePath} width={40}>
          {initials}
        </UserProfileImage>
      </Box>

      <Box flexDirection="column" justifyContent="center">
        <Text fontSize="small">{firstName} {lastName}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">{mobileNumber}</Text>
      </Box>

      <Link
        greyLink
        onClick={() => removeUser(id)}
        textProps={{ fontSize: 'small' }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </Link>
    </ListItem>
  )
})

export default DraggableUser
