import { useState } from 'react'

import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import useClientUser from '@hooks/useClientUser'
import useClientUsers from '@hooks/useClientUsers'
import useClients from '@hooks/useClients'
import useSelector from '@hooks/useSelector'
import useUsers from '@hooks/useUsers'

import { ClientUserModel } from '@models/types'

const defaultState = {
  entityName: '',
}

type UseBulkCreateClientUsersParams = {
  clientUser: Partial<ClientUserModel>,
}

const useBulkCreateClientUsers = (params: UseBulkCreateClientUsersParams) => {
  const {
    clientUser,
    clientUser: {
      clientId,
      userId,
    },
  } = params

  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const [state, setState] = useSetState(defaultState)
  const { entityName } = state

  const debouncedString = useDebounce(entityName, 200)

  const isShowingUsers = !!clientId

  const clientsPayload = useClients({
    filters: {
      string: debouncedString,
    },
    performHttpRequests: !!userId,
  })

  const {
    callbacks: {
      loadMore: loadMoreClients,
    },
    canLoadMore: canLoadMoreClients,
    filteredClients,
    loading: loadingClients,
  } = clientsPayload

  // Exclude Admin Users because Admins cannot be linked to Clients
  const usersPayload = useUsers({
    excludeAdminUsers: true,
    filters: {
      string: debouncedString,
    },
    performHttpRequests: !!clientId,
  })

  const {
    callbacks: {
      loadMore: loadMoreUsers,
    },
    canLoadMore: canLoadMoreUsers,
    filteredUsers,
    loading: loadingUsers,
  } = usersPayload

  const { clients, users } = useSelector(reduxState => reduxState.entities)

  const clientUsersPayload = useClientUsers({
    filters: {
      clientId,
      userId,
    },
  })

  const { filteredClientUsers } = clientUsersPayload

  const assignedEntityIds = filteredClientUsers.map(assignedClientUser => (
    isShowingUsers ? assignedClientUser.userId : assignedClientUser.clientId
  ))

  // Filter out Clients or Users that are already assigned
  const filteredEntities = isShowingUsers ? filteredUsers : filteredClients
  const filteredEntityIds = filteredEntities.reduce<number[]>((acc, value) => {
    if (!assignedEntityIds.includes(value.id)){
      acc.push(value.id)
    }

    return acc
  }, [])

  const {
    callbacks: {
      bulkCreateClientUsers,
    },
    creating,
  } = useClientUser(clientUser)

  const entityParams = {
    clientIds: isShowingUsers ? [clientId] : selectedIds,
    userIds: isShowingUsers ? selectedIds : [userId],
  }

  return {
    callbacks: {
      bulkCreateClientUsers,
      loadMore: isShowingUsers ? loadMoreUsers : loadMoreClients,
      setSelectedIds,
      setState,
    },
    creating,
    canLoadMore: isShowingUsers ? canLoadMoreUsers : canLoadMoreClients,
    entities: isShowingUsers ? users : clients,
    entityName,
    entityParams,
    filteredEntityIds,
    filteredEntityIdsCount: filteredEntityIds.length,
    loading: isShowingUsers ? loadingUsers : loadingClients,
    saveEnabled: !!selectedIds.length,
    selectedIds,
  }
}

export default useBulkCreateClientUsers