export type ArtworkTemplateModel = {
  artworkTemplateGroupId: number,
  cacheKey: number,
  createdAt: string,
  cutterProfileId?: number,
  description: string,
  height: number,
  id: number,
  name: string,
  previewImageUrl: string,
  realhubId?: number,
  width: number,
}

export type ArtworkTemplateRequestOptions = {}

const state = {
  artworkTemplateGroupId: null,
  cutterProfileId: null,
  id: null,
  realhubId: null,
}

export const requiredFields = [
  { key: 'artworkTemplateGroupId' },
  { key: 'realhubId' },
  { key: 'cutterProfileId' },
]

export default state
