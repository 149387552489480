import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { faBan, faCheckCircle } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'
import type { DefaultContext } from '@contexts/pageContext'

import getActiveTabBar from '@functions/tabBar'

import useArtworkTemplateGroups from '@hooks/useArtworkTemplateGroups'
import { generateUrls } from '@hooks/useArtworkTemplateGroup'

import ListBlankState from '@components/ListBlankState'
import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import ArtworkTemplateGroupListItem from '../ArtworkTemplateGroupListItem'

import type { PageContextPayload } from '../../index'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by artwork template group name or ID',
        style,
      },
      key: 'string',
      label: 'Name or ID',
    },
  ]
}

interface PageContentProps {
  pageFilters: {},
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props
  const { isArchived: archived } = pageFilters

  const isArchived = archived === 'true'

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const artworkTemplateGroupsPayload = useArtworkTemplateGroups({
    filters: {
      ...pageFilters,
      isArchived,
    },
    performHttpRequests: true,
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredArtworkTemplateGroups,
    filteredArtworkTemplateGroupsCount,
    hasFilteredArtworkTemplateGroups,
    loading,
  } = artworkTemplateGroupsPayload

  const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  const {
    callbacks: {
      resetFilters,
      showCreateOrEditArtworkTemplateGroupModal,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  const { artworkTemplateGroupsIndexUrl } = generateUrls()

  return (
    <>
      <Helmet>
        <title>Artwork Template Groups | AIM Digital</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showCreateOrEditArtworkTemplateGroupModal()} size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey={getActiveTabBar({ isArchived })}
        tabBarItems={[
          {
            href: artworkTemplateGroupsIndexUrl,
            icon: faCheckCircle,
            key: 'active',
            title: 'Active',
          },
        ]}
        title="Artwork Template Groups"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFilteredArtworkTemplateGroups && <ListBlankState />}

            {!loading && hasFilteredArtworkTemplateGroups && (
              <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title="Artwork Template Groups">
                <Box flexDirection="column">
                  {filteredArtworkTemplateGroups.map(artworkTemplateGroup => (
                    <ArtworkTemplateGroupListItem
                      key={artworkTemplateGroup.id}
                      artworkTemplateGroup={artworkTemplateGroup}
                    />
                  ))}
                </Box>

                <DashboardModule.LoadMoreFooter
                  callbacks={{ loadMore }}
                  canLoadMore={canLoadMore}
                  entityCount={filteredArtworkTemplateGroupsCount}
                  loading={loading}
                />
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields()}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
