import { useContext } from 'react'

import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import { DashboardModuleListItem, Text } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { confirmDelete } from '@functions/entity'

import useProduct from '@hooks/useProduct'
import useProductOption from '@hooks/useProductOption'
import useProductOptionProduct from '@hooks/useProductOptionProduct'
import useProductOptionValuesSubset from '@hooks/useProductOptionValuesSubset'
import useProductOptionValuesSubsets from '@hooks/useProductOptionValuesSubsets'

import { ProductOptionProductModel } from '@models/types'

interface ProductOptionProductListItemProps {
  isShowingProductOptions: boolean
  productOptionProduct: ProductOptionProductModel,
}

const ProductOptionProductListItem = (props: ProductOptionProductListItemProps) => {
  const { isShowingProductOptions, productOptionProduct } = props

  const { productId, productOptionId } = productOptionProduct

  const productOptionProductPayload = useProductOptionProduct(productOptionProduct)

  const {
    callbacks: {
      deleteProductOptionProduct: deleteFn,
    },
    product,
    product: {
      name: productName,
    },
    productOption,
    productOption: {
      name: productOptionName,
    },
  } = productOptionProductPayload

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext
  const { deleteProductOptionProduct } = callbacks || {}

  const {
    callbacks: {
      launchBulkCreateProductOptionValuesSubsetsModal,
    },
  } = useProductOptionValuesSubset({ productId, productOptionId })

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deleteProductOptionProduct,
      deleteFn,
    },
    strings: {
      entityName: isShowingProductOptions ? 'Product Option': 'Product',
    },
  }

  const {
    urls: { editProductUrl },
  } = useProduct(product)

  const {
    urls: { editProductOptionUrl },
  } = useProductOption(productOption)

  // Get subsets which are selected for this Product and under the selected ProductOption
  const productOptionValuesSubsetsPayload = useProductOptionValuesSubsets({
    filters: {
      productId,
      productOptionId,
    },
  })

  const {
    filteredProductOptionValuesSubsets,
    filteredProductOptionValuesSubsetsCount,
  } = productOptionValuesSubsetsPayload

  const assignedProductOptionValueIds = filteredProductOptionValuesSubsets.map(
    valuesSubset => valuesSubset.productOptionValueId,
  )

  return (
    <DashboardModuleListItem title={isShowingProductOptions ? productOptionName : productName}>
      <Text color="bodyFontLightColor">{filteredProductOptionValuesSubsetsCount} Values</Text>

      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon
          onClick={() => launchBulkCreateProductOptionValuesSubsetsModal({
            assignedProductOptionValueIds,
          })}
          type="edit"
        />

        <DashboardModuleListItem.Icon
          boxProps={{ marginLeft: 'medium' }}
          onClick={() => confirmDelete(confirmDeletePayload)}
          type="delete"
        />

        <DashboardModuleListItem.Icon
          boxProps={{ marginLeft: 'medium' }}
          href={`${isShowingProductOptions ? editProductOptionUrl : editProductUrl}?redirect=${generateRedirectUrl()}`}
          type="link"
        />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default ProductOptionProductListItem
