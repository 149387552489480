import useSelector from '@hooks/useSelector'
import useOrderItem from '@hooks/useOrderItem'

import type { OrderItemModel } from '@models/types'

import useAudienceTargeting from './useAudienceTargeting'
import useGeoTargeting from './useGeoTargeting'

const useTargeting = (orderItem: OrderItemModel) => {
  const {
    priceListItemOptionValues,
    priceListItemOptions,
    productOptions,
  } = useSelector(reduxState => reduxState.entities)

  const {
    audiencePatchParams,
    audiencePriceListItemOptionValues,
    callbacks: {
      toggleAudienceTargetingId,
    },
    selectedAudienceTargetingIds,
  } = useAudienceTargeting({ orderItem, priceListItemOptionValues, priceListItemOptions, productOptions })

  const {
    callbacks: {
      toggleGeoTargetingId,
    },
    geoPatchParams,
    geoPriceListItemOptionValues,
    selectedGeoTargetingIds,
  } = useGeoTargeting({ orderItem, priceListItemOptionValues, priceListItemOptions, productOptions })

  const patchParams = { priceListItemOptionValues: [] }

  if (audiencePatchParams) patchParams.priceListItemOptionValues.push(audiencePatchParams)
  if (geoPatchParams) patchParams.priceListItemOptionValues.push(geoPatchParams)

  const {
    callbacks: {
      patchOrderItem,
    },
    updating,
  } = useOrderItem(orderItem)

  return {
    audiencePriceListItemOptionValues,
    callbacks: {
      patchOrderItem: () => patchOrderItem(patchParams),
      toggleAudienceTargetingId,
      toggleGeoTargetingId,
    },
    geoPriceListItemOptionValues,
    selectedAudienceTargetingIds,
    selectedGeoTargetingIds,
    updating,
  }
}

export default useTargeting