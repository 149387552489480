import { useContext } from 'react'

import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { confirmDelete } from '@functions/entity'

import useExternalPlatformEntity, { useRelations } from '@hooks/useExternalPlatformEntity'

import type { ExternalPlatformEntityModel } from '@models/types'

interface ExternalPlatformEntityListItemProps {
  externalPlatformEntity: ExternalPlatformEntityModel,
}

const ExternalPlatformEntityListItem = (props: ExternalPlatformEntityListItemProps) => {
  const { externalPlatformEntity } = props

  const { externalPlatform } = useRelations(externalPlatformEntity)

  const {
    callbacks: {
      createOrEditExternalPlatformEntity,
      deleteExternalPlatformEntity: deleteFn,
    },
  } = useExternalPlatformEntity(externalPlatformEntity)

  const pageContext = useContext(PageContext)
  const { callbacks } = pageContext
  const { deleteExternalPlatformEntity } = callbacks || {}

  const confirmDeletePayload = {
    callbacks: {
      deleteEntity: deleteExternalPlatformEntity,
      deleteFn,
    },
    strings: {
      entityName: 'External Entity',
    },
  }

  return (
    <DashboardModuleListItem title={externalPlatform.name}>
      <DashboardModuleListItem.TertiaryText>
        {externalPlatformEntity.value}
      </DashboardModuleListItem.TertiaryText>

      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon
          marginRight="medium"
          onClick={() => createOrEditExternalPlatformEntity()}
          type="edit"
        />

        <DashboardModuleListItem.Icon
          onClick={() => confirmDelete(confirmDeletePayload)}
          type="delete"
        />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>

  )
}

export default ExternalPlatformEntityListItem