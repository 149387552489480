import {
  Box, DashboardModule,
} from '@campaignhub/suit-theme'

import { useFilters } from '@campaignhub/react-hooks'

import DashboardModuleFilters from '@components/DashboardModuleFilters'

import usePackageProducts from '@hooks/usePackageProducts'

import PackageListItem from './PackageListItem'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'string',
      label: 'Filter by Name',
    },
  ]
}

interface PackageProductsProps {
  productId: number,
}

const PackageProducts = (props: PackageProductsProps) => {
  const { productId } = props

  const filterPayload = useFilters()

  const {
    pageFilters,
  } = filterPayload

  const options = {
    filters: { productId, ...pageFilters },
    performHttpRequests: !!productId,
    requestOptions: {
      include: 'Package.Products',
    },
  }

  const packageProductsPayload = usePackageProducts(options)

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredPackageProducts,
    filteredPackageProductsCount,
    hasFilteredPackageProducts,
    loading,
  } = packageProductsPayload

  return (
    <DashboardModule
      boxProps={{ borderBottom: loading && !hasFilteredPackageProducts ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={<DashboardModule.FilterHeader {...filterPayload} />}
      loading={loading}
      title="Packages"
    >
      <DashboardModuleFilters
        filterFields={buildPageFilterFields()}
        {...filterPayload}
      />

      <Box flexDirection="column">
        <DashboardModule.ScrollableSection flexDirection="column">
          {!loading && !hasFilteredPackageProducts && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>
                Please adjust your filters or link a Package
              </DashboardModule.BlankState.Title>
            </DashboardModule.BlankState>
          )}

          {!loading && filteredPackageProducts.map(packageProduct => (
            <PackageListItem
              key={packageProduct.id}
              packageProduct={packageProduct}
            />
          ))}
        </DashboardModule.ScrollableSection>

        <DashboardModule.LoadMoreFooter
          boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
          callbacks={{ loadMore }}
          canLoadMore={canLoadMore}
          entityCount={filteredPackageProductsCount}
          loading={loading}
        />
      </Box>
    </DashboardModule>
  )
}

export default PackageProducts
