import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import { useRelations } from '@hooks/usePriceListItemOptionValue'

import type { PriceListItemOptionValueModel } from '@models/types'

interface TargetingListItemProps {
  callbacks: {
    selectTargeting: (id: number) => void,
  },
  isSelected: boolean,
  priceListItemOptionValue: PriceListItemOptionValueModel,
}

const TargetingListItem = (props: TargetingListItemProps) => {
  const {
    callbacks: {
      selectTargeting,
    },
    isSelected,
    priceListItemOptionValue,
  } = props

  const { id } = priceListItemOptionValue

  const { productOptionValue } = useRelations(priceListItemOptionValue)

  return (
    <DashboardModuleListItem
      boxProps={{ borderBottom: 'none', padding: 'medium' }}
      checkboxOnClick={() => selectTargeting(id)}
      isChecked={isSelected}
      showCheckbox
      title={productOptionValue.name}
    />
  )
}

export default TargetingListItem
