import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ArtworkTemplateGroupModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/artworkTemplateGroup/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/artworkTemplateGroup/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/artworkTemplateGroup/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/artworkTemplateGroup/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/artworkTemplateGroup/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/artworkTemplateGroup/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/artworkTemplateGroup/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/artworkTemplateGroup/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/artworkTemplateGroup/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/artworkTemplateGroup/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/artworkTemplateGroup/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/artworkTemplateGroup/DELETE_FAILURE'

export type ArtworkTemplateGroupModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: ArtworkTemplateGroupModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadArtworkTemplateGroups(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().artworkTemplateGroups.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/artwork-template-groups', options)
      .then(({ data }: { data: { data: ArtworkTemplateGroupModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.ARTWORK_TEMPLATE_GROUP_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadArtworkTemplateGroup(artworkTemplateGroupId: number, options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.artworkTemplateGroups.loadedIds]
    const loadedForKeys = [...state.artworkTemplateGroups.loadedForKeys]

    if (artworkTemplateGroupId && !loadedIds.includes(artworkTemplateGroupId)){
      loadedIds.push(artworkTemplateGroupId)
    }

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/artwork-template-groups/${artworkTemplateGroupId}`, options)
      .then(({ data }: { data: ArtworkTemplateGroupModel }) => {
        const normalizedJson = normalize(data, Schemas.ARTWORK_TEMPLATE_GROUP)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createArtworkTemplateGroup(artworkTemplateGroup: Partial<ArtworkTemplateGroupModel>, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      ...artworkTemplateGroup,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/artwork-template-groups', options, config)
      .then(({ data }: { data: ArtworkTemplateGroupModel }) => {
        const normalizedJson = normalize(data, Schemas.ARTWORK_TEMPLATE_GROUP)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateArtworkTemplateGroup(
  artworkTemplateGroup: UpdateParams<ArtworkTemplateGroupModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      ...artworkTemplateGroup,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/artwork-template-groups/${artworkTemplateGroup.id}`, options, config)
      .then(({ data }: { data: ArtworkTemplateGroupModel }) => {
        const normalizedJson = normalize(data, Schemas.ARTWORK_TEMPLATE_GROUP)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteArtworkTemplateGroup(artworkTemplateGroup: DeleteParams<ArtworkTemplateGroupModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/artwork-template-groups/${artworkTemplateGroup.id}`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        const deletedEntity = { id: artworkTemplateGroup.id }
        const normalizedJson = normalize(deletedEntity, Schemas.ARTWORK_TEMPLATE_GROUP)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ArtworkTemplateGroupModuleState = initialState,
  action: Action = { type: '' },
): ArtworkTemplateGroupModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
