import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as productOptionValueActions from '@redux/modules/productOptionValue'

import defaultFormState, { requiredFields } from '@models/productOptionValue'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { ProductOptionValueModel, ProductOptionValueRequestOptions } from '@models/types'

type CreateProductOptionValueParams = {
  dispatch: AppDispatch,
  productOptionValueParams: Partial<ProductOptionValueModel>,
  requestOptions?: ProductOptionValueRequestOptions,
}

const createProductOptionValue = (params: CreateProductOptionValueParams) => {
  const { dispatch, productOptionValueParams, requestOptions } = params
  const { createProductOptionValue: createFn } = productOptionValueActions

  return dispatch(createFn(productOptionValueParams, requestOptions))
}

type UpdateProductOptionValueParams = {
  dispatch: AppDispatch,
  productOptionValue: ProductOptionValueModel,
  productOptionValueParams: Partial<ProductOptionValueModel>,
  requestOptions?: ProductOptionValueRequestOptions,
}

const updateProductOptionValue = (params: UpdateProductOptionValueParams) => {
  const {
    dispatch, productOptionValue, productOptionValueParams, requestOptions,
  } = params
  const { updateProductOptionValue: updateFn } = productOptionValueActions

  const updatedParams = {
    id: productOptionValue.id,
    ...productOptionValueParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DuplicateProductOptionValueParams = {
  dispatch: AppDispatch,
  productOptionValueParams: Partial<ProductOptionValueModel>,
  requestOptions?: ProductOptionValueRequestOptions,
}

const duplicateProductOptionValue = (params: DuplicateProductOptionValueParams) => {
  const { dispatch, productOptionValueParams, requestOptions } = params
  const { duplicateProductOptionValue: duplicateFn } = productOptionValueActions

  return dispatch(duplicateFn(productOptionValueParams, requestOptions))
}

type DeleteProductOptionValueParams = {
  dispatch: AppDispatch,
  productOptionValue: DeleteParams<ProductOptionValueModel>,
}

const deleteProductOptionValue = (params: DeleteProductOptionValueParams) => {
  const { dispatch, productOptionValue } = params
  const { deleteProductOptionValue: deleteFn } = productOptionValueActions

  return dispatch(deleteFn(productOptionValue))
}

type SortProductOptionValuesParams = {
  dispatch: AppDispatch,
  productOptionId: number,
  sortedIds: number[],
}

const sortProductOptionValues = (params: SortProductOptionValuesParams) => {
  const { dispatch, productOptionId, sortedIds } = params
  const { sortProductOptionValues: sortFn } = productOptionValueActions

  return dispatch(sortFn({ productOptionId, sortedIds }))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useProductOptionValueForm(
  productOptionValue: Partial<ProductOptionValueModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const productOptionValueForm = useForm(
    defaultFormState,
    { entity: productOptionValue, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [productOptionValue.id, productOptionValue.cacheKey],
  )

  return {
    ...productOptionValueForm,
  }
}

export const useRelations = (productOptionValue: Partial<ProductOptionValueModel> = {}) => {
  const { productOptionId } = productOptionValue

  const { productOptions } = useSelector(reduxState => reduxState.entities)

  const productOption = productOptionId && productOptions[productOptionId]
    ? productOptions[productOptionId]
    : {}

  return {
    productOption,
  }
}

function useProductOptionValue(initEntity: Partial<ProductOptionValueModel> = {}) {
  const latestEntityPayload = useLatestEntity(initEntity, 'productOptionValues')
  const { entity: productOptionValue }: { entity: ProductOptionValueModel } = latestEntityPayload

  const dispatch = useDispatch()

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.productOptionValues)

  return {
    callbacks: {
      createProductOptionValue: (
        productOptionValueParams: Partial<ProductOptionValueModel>,
        entityOptions?: ProductOptionValueRequestOptions,
      ) => (
        createProductOptionValue({ productOptionValueParams, dispatch, requestOptions: entityOptions })
      ),
      deleteProductOptionValue: () => deleteProductOptionValue({ dispatch, productOptionValue }),
      duplicateProductOptionValue: (
        productOptionValueParams: Partial<ProductOptionValueModel>,
        entityOptions?: ProductOptionValueRequestOptions,
      ) => (
        duplicateProductOptionValue({ productOptionValueParams, dispatch, requestOptions: entityOptions })
      ),
      sortProductOptionValues: (productOptionId: number, sortedIds: number[]) => (
        sortProductOptionValues({ dispatch, productOptionId, sortedIds })
      ),
      updateProductOptionValue: (
        productOptionValueParams: Partial<ProductOptionValueModel>,
        entityOptions?: ProductOptionValueRequestOptions,
      ) => (
        updateProductOptionValue({
          productOptionValue, productOptionValueParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    productOptionValue,
    updating,
  }
}

export default useProductOptionValue
