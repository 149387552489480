import { ActivityItem as ActivityItemBase } from '@campaignhub/suit-theme'
import { formatDate } from '@campaignhub/javascript-utils'

import useActivityLog from '@hooks/useActivityLog'
import { useRelations as useUserRelations } from '@hooks/useUser'

import { ActivityLogModel } from '@models/types'

interface ActivityItemProps {
  activityLog: ActivityLogModel,
  boxProps?: object,
  isLast: boolean,
}

const ActivityItem = (props: ActivityItemProps) => {
  const {
    activityLog,
    activityLog: {
      createdAt,
      description,
    },
    boxProps,
    isLast,
  } = props

  const {
    // callbacks: { manageActivity },
    isDestructive,
    user,
  } = useActivityLog(activityLog)

  const { coverImage, userType } = useUserRelations(user)

  const createdAtString = formatDate(createdAt, 'ISO8601', "ccc, dd LLL yyyy 'at' h:mm a")

  return (
    <ActivityItemBase
      actionText={description}
      actionType={isDestructive ? 'destructive' : 'default'}
      boxProps={{
        paddingTop: 'medium',
        paddingBottom: isLast ? 0 : 'medium',
        ...boxProps,
      }}
      dateTimeText={createdAtString}
      imageUrl={coverImage.filePath}
      // onClick={() => manageActivity()}
      roleName={userType.name}
      title={user.name}
    />
  )
}

export default ActivityItem
