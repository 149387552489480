import { useContext } from 'react'

import {
  Button,
  Columns,
  LoadingModule,
  SaveFormModule,
  SectionDivider,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useArtworkTemplateGroup, { useArtworkTemplateGroupForm } from '@hooks/useArtworkTemplateGroup'

import EntityActivity from '@components/EntityActivity'
import ArtworkTemplateGroupDetails from '../ArtworkTemplateGroupDetails'
// import ArtworkTemplateGroupTools from '../../../../components/ArtworkTemplateGroupTools'
import PageLayout from '../../../../components/PageLayout'
import ArtworkTemplates from '../ArtworkTemplates'

interface PageContentProps {
  artworkTemplateGroupId: number,
}

const PageContent = (props: PageContentProps) => {
  const { artworkTemplateGroupId } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      updateArtworkTemplateGroup,
    },
  } = pageContext

  const artworkTemplateGroupPayload = useArtworkTemplateGroup({ id: artworkTemplateGroupId })
  const {
    artworkTemplateGroup,
    callbacks: {
      updateArtworkTemplateGroup: updateFn,
    },
    loading,
    updating,
  } = artworkTemplateGroupPayload

  const artworkTemplateGroupFormPayload = useArtworkTemplateGroupForm(artworkTemplateGroup)
  const { entityState, saveEnabled } = artworkTemplateGroupFormPayload

  const updateArtworkTemplateGroupPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'Artwork Template Group Updated',
  }

  return (
    <PageLayout artworkTemplateGroupPayload={artworkTemplateGroupPayload} activeTabBarItemKey="details">
      <Columns.Main>
        <LoadingModule loading={loading} times={3} />

        {!loading && (
          <>
            <ArtworkTemplateGroupDetails artworkTemplateGroupFormPayload={artworkTemplateGroupFormPayload} />

            <SaveFormModule boxProps={{ marginBottom: 'none' }}>
              <Button
                buttonStyle="primaryCreate"
                disabled={!saveEnabled}
                loading={updating}
                marginLeft="auto"
                onClick={() => updateArtworkTemplateGroup(updateArtworkTemplateGroupPayload)}
                size="medium"
              >
                Save Details
              </Button>
            </SaveFormModule>

            <SectionDivider>Template Pages</SectionDivider>

            <ArtworkTemplates artworkTemplateGroupId={artworkTemplateGroupId} />

            <EntityActivity subjectId={artworkTemplateGroupId} subjectType="ArtworkTemplateGroup" />
          </>
        )}
      </Columns.Main>

      {/* <Columns.Sidebar>
        <ArtworkTemplateGroupTools artworkTemplateGroupPayload={artworkTemplateGroupPayload} />
      </Columns.Sidebar> */}
    </PageLayout>
  )
}

export default PageContent
