import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, DashboardModule } from '@campaignhub/suit-theme'

import useExternalPlatformEntities from '@hooks/useExternalPlatformEntities'
import useExternalPlatformEntity from '@hooks/useExternalPlatformEntity'
import useExternalPlatforms from '@hooks/useExternalPlatforms'

import ExternalPlatformEntityListItem from './ExternalPlatformEntityListItem'

interface ExternalPlatformEntitiesProps {
  filters?: {
    externalEntityId?: string,
    externalPlatformId?: number,
    internalEntityId?: number,
    internalEntityType?: string,
  }
}

const ExternalPlatformEntities = (props: ExternalPlatformEntitiesProps) => {
  const { filters } = props
  const { internalEntityId, internalEntityType } = filters  || {}

  useExternalPlatforms({ performHttpRequests: true })

  const initExternalPlatformEntity = { entityId: internalEntityId, entityType: internalEntityType }

  const {
    callbacks:  {
      createOrEditExternalPlatformEntity,
    },
  } = useExternalPlatformEntity(initExternalPlatformEntity)

  const externalPlatformEntitiesPayload = useExternalPlatformEntities({
    filters,
    performHttpRequests: true,
  })

  const {
    filteredExternalPlatformEntities,
    hasFilteredExternalPlatformEntities,
    loading,
  } = externalPlatformEntitiesPayload

  return (
    <DashboardModule contentBoxProps={{ flexDirection: 'column' }} loading={loading} title="External Entities">
      <Box flexDirection="column">
        {filteredExternalPlatformEntities.map((externalPlatformEntity) => (
          <ExternalPlatformEntityListItem externalPlatformEntity={externalPlatformEntity} key={externalPlatformEntity.id} />
        ))}
      </Box>

      <DashboardModule.Footer boxProps={{ borderTop: hasFilteredExternalPlatformEntities ? '1px solid' : 'none' }}>
        <DashboardModule.FooterRight>
          <Button
            buttonStyle="secondaryEdit"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => createOrEditExternalPlatformEntity()}
            size={['medium', 'small']}
            width={['100%', 'fit-content']}
          >
            Add External Entity
          </Button>
        </DashboardModule.FooterRight>
      </DashboardModule.Footer>
    </DashboardModule>
  )
}

export default ExternalPlatformEntities