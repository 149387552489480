import {
  DashboardModule, Form,
} from '@campaignhub/suit-theme'

import { useCutterProfileForm } from '@hooks/useCutterProfile'

interface CutterProfileDetailsProps {
  cutterProfileFormPayload: ReturnType<typeof useCutterProfileForm>,
}

const CutterProfileDetails = (props: CutterProfileDetailsProps) => {
  const {
    cutterProfileFormPayload: {
      entityState: {
        name,
      },
      errors,
      handlers,
    },
  } = props

  return (
    <DashboardModule title="Cutter Profile Details">
      <Form padding="large">
        <Form.Row>
          <Form.Field errorMessage={errors.name} label="* Name">
            <input
              name="name"
              type="text"
              value={name}
              {...handlers}
            />
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default CutterProfileDetails
