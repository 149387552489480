import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useProduct, { useProductForm } from '@hooks/useProduct'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'CreateProductModal'

type ModalCallbacks = {
  closeModal: () => void,
  createProduct: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateProductModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createProduct } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const product = digObject(modalPayload, 'product', {})

  const {
    callbacks: {
      createProduct: createFn,
    },
    creating,
  } = useProduct(product)

  const {
    entityState,
    entityState: {
      description,
      name,
    },
    errors,
    handlers,
    saveEnabled,
  } = useProductForm(product, { validateOn: 'change' })

  // To be used with handleCallbackAction
  const createProductPayload = {
    callbacks: {
      action: createFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    shouldRedirect: true,
    toastText: 'Product Created',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Create" titleSecondLine="Product" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* Name" errorMessage={errors.name}>
            <input name="name" type="text" value={name} {...handlers} />
          </Form.Field>

          <Form.Field label="* Description" errorMessage={errors.description} marginTop="large">
            <textarea
              name="description"
              value={description}
              style={{ height: 120, resize: 'vertical' }}
              {...handlers}
            />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => createProduct(createProductPayload)}
          size="large"
        >
          {saveEnabled ? 'Create Product' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateProductModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
