import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity } from '@redux/entities'

import type { DataStoreItemModel } from '@models/types'
import type { AppDispatch } from '@redux/store'

export function removeDataStoreItem(dispatch: AppDispatch, dataStoreItem: DataStoreItemModel) {
  const normalizedJson = normalize(dataStoreItem, Schemas.DATA_STORE_ITEM)
  dispatch(deleteEntity(normalizedJson))
}