import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'
import type { UseFiltersPayload } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import CreateOrEditCutterProfileModal from '@modals/CreateOrEditCutterProfileModal'

import type { CutterProfileModel } from '@models/types'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    showCreateOrEditCutterProfileModal: () => void,
  },
}

const defaultState = {
  showCreateOrEditCutterProfileModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateOrEditCutterProfileModal: {
      closeModal: () => setState({ showCreateOrEditCutterProfileModal: false }),
      createCutterProfile: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateCutterProfile: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const List = () => {
  const [state, setState] = useSetState(defaultState)
  const { showCreateOrEditCutterProfileModal } = state

  useLocation()
  const filtersPayload = useFilters({ persistFilters: true })

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showCreateOrEditCutterProfileModal: (payload: { cutterProfile: CutterProfileModel }) => {
        setModalData('CreateOrEditCutterProfileModal', payload)
        setState({ showCreateOrEditCutterProfileModal: true })
      },
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt]) // modalContext dependency required if layering modals

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <CreateOrEditCutterProfileModal
          callbacks={callbacks('CreateOrEditCutterProfileModal', setState)}
          showModal={showCreateOrEditCutterProfileModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default List
