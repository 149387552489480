import { useMemo } from 'react'

import { matchFilterNumber, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { PriceListItemOptionValueRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['priceListItemOptionValues']

type PriceListItemOptionValueFilters = {
  priceListItemOptionId?: number,
}

type UsePriceListItemOptionValuesOptions = {
  filters?: PriceListItemOptionValueFilters,
  performHttpRequests?: boolean,
  requestOptions?: PriceListItemOptionValueRequestOptions,
}

function usePriceListItemOptionValues(options: UsePriceListItemOptionValuesOptions) {
  const { filters = {}, requestOptions } = options
  const {
    priceListItemOptionId: filterPriceListItemOptionId,
  } = filters

  const {
    updatedEntities: { priceListItemOptionValues: priceListItemOptionValuesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { priceListItemOptionValues, productOptionValues } = useSelector(reduxState => reduxState.entities)

  const filteredPriceListItemOptionValues = useMemo(() => {
    const filtered = Object.values(priceListItemOptionValues).filter((priceListItemOptionValue) => {
      const { priceListItemOptionId } = priceListItemOptionValue

      const matchPricelistItemOptionId = matchFilterNumber(priceListItemOptionId, filterPriceListItemOptionId)

      return matchPricelistItemOptionId
    })

    return sortArrayBy(
      filtered,
      'asc',
      ({ productOptionValueId }) => productOptionValues[productOptionValueId]?.sort || Infinity,
    )
  }, [priceListItemOptionValuesUpdatedAt, JSON.stringify(filters)])

  const filteredPriceListItemOptionValuesCount = filteredPriceListItemOptionValues.length
  const hasFilteredPriceListItemOptionValues = !!filteredPriceListItemOptionValuesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredPriceListItemOptionValuesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingPriceListItemOptionValues } = useReduxAction(
    'priceListItemOptionValues',
    'loadPriceListItemOptionValues',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredPriceListItemOptionValues,
    filteredPriceListItemOptionValuesCount,
    hasFilteredPriceListItemOptionValues,
    loading: loadingPriceListItemOptionValues,
  }
}

export default usePriceListItemOptionValues
