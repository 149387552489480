import { useContext } from 'react'

import { useLatestEntity } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as productOptionValuesSubsetActions from '@redux/modules/productOptionValuesSubset'
import type { BulkCreateParams } from '@redux/modules/productOptionValuesSubset'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { ModuleState } from '@redux/modules/types'
import type { ProductOptionValuesSubsetModel, ProductOptionValuesSubsetRequestOptions } from '@models/types'

type BulkCreateProductOptionValuesSubsetParams = {
  bulkCreateParams: BulkCreateParams,
  dispatch: AppDispatch,
  requestOptions?: ProductOptionValuesSubsetRequestOptions,
}

const bulkCreateProductOptionValuesSubsets = (params: BulkCreateProductOptionValuesSubsetParams) => {
  const { bulkCreateParams, dispatch, requestOptions } = params
  const { bulkCreateProductOptionValuesSubsets: bulkCreateFn } = productOptionValuesSubsetActions

  return dispatch(bulkCreateFn(bulkCreateParams, requestOptions))
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ProductOptionValuesSubsetRequestOptions,
}

function useProductOptionValuesSubset(initEntity: Partial<ProductOptionValuesSubsetModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: productOptionValuesSubset }: { entity: ProductOptionValuesSubsetModel } = useLatestEntity(
    initEntity,
    'productOptionValuesSubsets',
  )
  const { id } = productOptionValuesSubset

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'productOptionValuesSubsets',
    'loadProductOptionValuesSubset',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const { creating, loading } = useSelector(reduxState => reduxState.productOptionValuesSubsets)

  return {
    callbacks: {
      bulkCreateProductOptionValuesSubsets: (
        bulkCreateParams: BulkCreateParams,
        entityOptions?: ProductOptionValuesSubsetRequestOptions,
      ) => (
        bulkCreateProductOptionValuesSubsets({ bulkCreateParams, dispatch, requestOptions: entityOptions })
      ),
      launchBulkCreateProductOptionValuesSubsetsModal: (
        customPayload: { assignedProductOptionValueIds: number[] },
      ) => launchModal({
        callbacks,
        modalKey: 'BulkCreateProductOptionValuesSubsetsModal',
        payload: { ...customPayload, ...productOptionValuesSubset },
      }),
    },
    creating,
    loading,
    productOptionValuesSubset,
  }
}

export default useProductOptionValuesSubset
