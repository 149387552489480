import { useContext } from 'react'

import {
  Box, Button, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import { LocationsModel } from '@models/types'

const renderLocations = (locations: LocationsModel[]) => locations.map((location) => {
  const { name, radius } = location

  return (
    <Box alignItems="center" justifyContent="space-between" key={name} marginBottom="large">
      <Text fontSize="small">{name}</Text>

      {radius && <Text color="bodyFontLightColor" fontSize="small">{radius} km</Text>}
    </Box>
  )
})

const MODAL_KEY = 'LocationTargetingModal'

type ModalCallbacks = {
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const LocationTargetingModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const adCampaign = digObject(modalPayload, 'adCampaign', {})

  const { locations } = adCampaign

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Location" titleSecondLine="Targeting" />

      <SidebarModal.Content>
        {renderLocations(locations)}
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => closeModal()}
          size="large"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <LocationTargetingModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
