import { Box, DashboardModule, SidebarModal, Text } from '@campaignhub/suit-theme'

const BlankState = () => (
  <DashboardModule
    contentBoxProps={{ flexDirection: 'column' }}
    title="Additional Targeting"
  >
    <Box padding="large">
      <SidebarModal.NoticeBox>
        <Box style={{ marginBottom: 8 }}>
          <SidebarModal.NoticeBox.Title>No Additional Targeting Available</SidebarModal.NoticeBox.Title>
        </Box>

        <SidebarModal.NoticeBox.Paragraph boxProps={{ marginTop: 'none', marginBottom: 'medium' }}>
          There is no additional targeting available on the Product you have selected
        </SidebarModal.NoticeBox.Paragraph>
      </SidebarModal.NoticeBox>
    </Box>
  </DashboardModule>
)

export default BlankState