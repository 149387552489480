import { useContext } from 'react'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCloudUpload, faSave, faSortAmountDown, faTimes,
} from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Button, FileUploadZone, Form, Grid, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useAssetTypes from '@hooks/useAssetTypes'
import useAssets from '@hooks/useAssets'
import useUppyAssetUpload from '@hooks/useUppyAssetUpload'
import useCampaign, { useCampaignForm } from '@hooks/useCampaign'

import SortableImage from './SortableImage'
import useManageMediaModal from './hooks/useManageMediaModal'

const MODAL_KEY = 'ManageMediaModal'

interface ManageMediaModalProps {
  callbacks: {
    closeModal: () => void,
    saveSortOrder: (payload: HandleCallbackActionParams) => Promise<object>,
    updateCampaign: (payload: HandleCallbackActionParams) => Promise<object>,
  },
  showModal: boolean,
}

const ManageMediaModal = (props: ManageMediaModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, saveSortOrder, updateCampaign } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const {
    filters,
    filters: {
      assetableId,
      assetableType,
    },
  } = modalPayload

  const {
    filteredAssets,
    hasFilteredAssets,
  } = useAssets({ filters })

  const {
    callbacks: {
      saveSortOrder: saveSortOrderFn,
      setState,
      updateSortOrder,
    },
    campaign,
    sortOrder,
    sorting,
    updating,
    uploading,
  } = useManageMediaModal(filters)

  const {
    entityState,
    entityState: {
      videoUrl,
    },
    handlers,
  } = useCampaignForm(campaign)

  const {
    callbacks: {
      updateCampaign: updateFn,
    },
  } = useCampaign(campaign)

  const assetTypesPayload = useAssetTypes({
    filters: {
      category: 'Image',
    },
    performHttpRequests: true,
  })

  const { filteredAssetTypes } = assetTypesPayload

  // Default assetType to the first in Image category
  const assetType = filteredAssetTypes[0]?.name

  const uppy = useUppyAssetUpload({
    assetTypeId: assetType,
    assetableId,
    assetableType,
    callbacks: {
      afterAction: () => toast('Asset Created Successfully'),
    },
  }, [JSON.stringify(filters), assetType])

  const saveSortOrderParams = {
    callbacks: {
      action: saveSortOrderFn,
      afterAction: () => setState({ sortOrder: [], sorting: false }),
    },
    toastText: 'Assets Sorted',
  }

  const updateCampaignPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Campaign Updated',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Media" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="Campaign Video URL">
            <input
              name="videoUrl"
              type="text"
              placeholder="Enter a valid youtube URL"
              value={videoUrl}
              {...handlers}
            />
          </Form.Field>

          {uploading && (
            <>
              <Form.Field label="Upload Image" marginTop="large">
                <FileUploadZone uppy={uppy} />
              </Form.Field>

              <Form.Field label="More Options" marginTop="large">
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faTimes} />}
                  loading={updating}
                  onClick={() => setState({ uploading: false })}
                  size="medium"
                >
                  Cancel
                </Button>
              </Form.Field>
            </>
          )}

          {!uploading && hasFilteredAssets && (
            <Form.Field label="Images" marginTop="large">
              <Grid
                gridAutoRows="1fr"
                gridColumnGap="medium"
                gridRowGap="medium"
                gridTemplateColumns="repeat(auto-fill, minmax(160px, 1fr))"
                gridTemplateRows="repeat(auto-fill, minmax(110px, 1fr))"
              >
                {filteredAssets.map((image) => {
                  const selectionIndex = sortOrder.findIndex(id => id === image.id)

                  return (
                    <SortableImage
                      callbacks={{
                        updateSortOrder: () => updateSortOrder(image.id),
                      }}
                      image={image}
                      key={image.id}
                      selectionIndex={selectionIndex}
                      sorting={sorting}
                    />
                  )
                })}
              </Grid>
            </Form.Field>
          )}

          {!sorting && !uploading && (
            <Form.Field label="More Options" marginTop="large">
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faSortAmountDown} />}
                loading={updating}
                onClick={() => setState({ sorting: true })}
                size="medium"
              >
                Select Sort Order
              </Button>

              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faCloudUpload} />}
                loading={updating}
                marginTop="medium"
                onClick={() => setState({ uploading: true })}
                size="medium"
              >
                Upload Images
              </Button>
            </Form.Field>
          )}

          {sorting && (
            <Form.Field label="More Options" marginTop="large">
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTimes} />}
                loading={updating}
                onClick={() => setState({ sortOrder: [], sorting: false })}
                size="medium"
              >
                Cancel
              </Button>

              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faSave} />}
                loading={updating}
                marginTop="medium"
                onClick={() => saveSortOrder(saveSortOrderParams)}
                size="medium"
              >
                Save Sort Order
              </Button>
            </Form.Field>
          )}
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => updateCampaign(updateCampaignPayload)}
          size="large"
        >
          Save
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ManageMediaModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageMediaModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
