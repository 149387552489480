export type PriceListModel = {
  cacheKey: number,
  expiresAt?: string,
  id: number,
  isArchived: boolean,
  name: string,
  nextPriceListId?: number,
}

export type PriceListRequestOptions = {}

const state = {
  id: null,
  expiresAt: '',
  isArchived: false,
  name: '',
  nextPriceListId: undefined,
}

export const requiredFields = [
  {
    key: 'name',
    validation: (value: string) => value && value.length <= 250,
    invalidMessage: 'Must be 250 chars or less',
  },
]

export default state
