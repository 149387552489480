import { useEffect, useState } from 'react'

import { toggleArray } from '@campaignhub/javascript-utils'

import useOrderItemOptionValues from '@hooks/useOrderItemOptionValues'
import usePriceListItemOptionValues from '@hooks/usePriceListItemOptionValues'

import type { EntitiesState } from '@redux/entities'

import type { OrderItemModel } from '@models/types'

type ToggleParams = {
  id: number,
  selectedAudienceTargetingIds: number[],
  setSelectedAudienceTargetingIds: React.Dispatch<React.SetStateAction<number[]>>,
}

const toggleAudienceTargetingId = (params: ToggleParams) => {
  const { id, selectedAudienceTargetingIds, setSelectedAudienceTargetingIds } = params

  const updatedAudienceTargetingIds = toggleArray(selectedAudienceTargetingIds, id)
  setSelectedAudienceTargetingIds(updatedAudienceTargetingIds)
}

const AUDIENCE_TARGETING_KEY = 'AudienceTargeting'

type UseAudienceTargetingParams = {
  orderItem: OrderItemModel,
  priceListItemOptionValues: EntitiesState['priceListItemOptionValues'],
  priceListItemOptions: EntitiesState['priceListItemOptions'],
  productOptions: EntitiesState['productOptions'],
}

const useAudienceTargeting = (params: UseAudienceTargetingParams) => {
  const {
    orderItem,
    priceListItemOptionValues,
    priceListItemOptions,
    productOptions,
  } = params

  // Get ProductOption for Audience Targeting
  const audienceTargetingProductOption = Object.values(productOptions).find(productOption => (
    productOption.key === AUDIENCE_TARGETING_KEY
  ))

  // Get corresponding PriceListItemOption
  const audienceTargetingPriceListItemOption = Object.values(priceListItemOptions).find(priceListItemOptions => (
    priceListItemOptions.productOptionId === audienceTargetingProductOption?.id
  ))

  // Get corresponding PriceListItemOptionValues
  // If there is no audienceTargetingPriceListItemOption.id, pass -1 to the filter to return no results
  // We must run the hook due to rules of hooks
  const audiencePriceListItemOptionValuesPayload = usePriceListItemOptionValues({
    filters: {
      priceListItemOptionId: audienceTargetingPriceListItemOption?.id || -1,
    }
  })

  const {
    filteredPriceListItemOptionValues: audiencePriceListItemOptionValues,
  } = audiencePriceListItemOptionValuesPayload

  // Initial PriceListItemOptionValue selection is based on existence of OrderItemOptionValues
  const { filteredOrderItemOptionValues } = useOrderItemOptionValues({
    filters: {
      orderItemId: orderItem.id,
    },
  })

  const defaultPriceListItemOptionValueIds = filteredOrderItemOptionValues.map(orderItemOptionValue => (
    orderItemOptionValue.priceListItemOptionValueId
  ))

  const audiencePriceListItemOptionValueIds = defaultPriceListItemOptionValueIds.filter((id) => (
    priceListItemOptionValues[id].priceListItemOptionId === audienceTargetingPriceListItemOption?.id
  ))

  // Set up a state for Audience Targeting
  const [
    selectedAudienceTargetingIds,
    setSelectedAudienceTargetingIds,
  ] = useState<number[]>(audiencePriceListItemOptionValueIds)

  // Value may initialise as empty array due to load time
  useEffect(() => {
    setSelectedAudienceTargetingIds(audiencePriceListItemOptionValueIds)
  }, [audiencePriceListItemOptionValueIds.length])

  // Default to null if there is no audience targeting so we don't send anything on request
  const audiencePatchParams = audienceTargetingPriceListItemOption
    ? {
        priceListItemOptionId: audienceTargetingPriceListItemOption.id,
        priceListItemOptionValueIds: selectedAudienceTargetingIds
      }
    : null

  return {
    audiencePatchParams,
    audiencePriceListItemOptionValues,
    callbacks: {
      toggleAudienceTargetingId: (id: number) => (
        toggleAudienceTargetingId({ id, selectedAudienceTargetingIds, setSelectedAudienceTargetingIds })
      )
    },
    selectedAudienceTargetingIds,
  }
}

export default useAudienceTargeting