import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import { Button, ModalContext, SidebarModal } from '@campaignhub/suit-theme'

import AdChannelCard from './AdChannelCard'

const MODAL_KEY = 'SelectAdChannelModal'

type ModalCallbacks = {
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const SelectAdChannelModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const linkableEntity = digObject(modalPayload, 'linkableEntity', {})
  const linkableType = digObject(modalPayload, 'linkableType', '')

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Select" titleSecondLine="Ad Account Type" />

      <SidebarModal.Content>
        <AdChannelCard
          adChannel="facebook"
          adAccountType="FacebookAdAccount"
          linkableEntity={linkableEntity}
          linkableType={linkableType}
        />

        <AdChannelCard
          adChannel="facebook"
          adAccountType="FacebookPage"
          linkableEntity={linkableEntity}
          linkableType={linkableType}
          marginTop='large'
        />

        <AdChannelCard
          adChannel="google"
          adAccountType="GoogleAdAccount"
          linkableEntity={linkableEntity}
          linkableType={linkableType}
          marginTop='large'
        />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          loadingBubbleColor="white"
          onClick={() => closeModal()}
          size="large"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <SelectAdChannelModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
