import { useMemo } from 'react'

import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import type { LinkAdAccountsModalPayload, SelectAdChannelModalPayload } from '@hooks/useAdAccount'

import CreateOrEditExternalPlatformEntityModal from '@modals/CreateOrEditExternalPlatformEntityModal'
import LinkAdAccountsModal from '@modals/LinkAdAccountsModal'
import SelectAdChannelModal from '@modals/SelectAdChannelModal'

import type { ExternalPlatformEntityModel } from '@models/types'

import PageContent from './components/PageContent'

export type PageContextPayload = {
  callbacks: {
    patchClient: (payload: HandleCallbackActionParams) => Promise<object>,
    showCreateOrEditExternalPlatformEntityModal: (payload: { externalPlatformEntity: ExternalPlatformEntityModel }) => void,
    showLinkAdAccountsModal: () => void,
    showSelectAdChannelModal: () => void,
  },
}

const defaultState = {
  showCreateOrEditClientModal: false,
  showCreateOrEditExternalPlatformEntityModal: false,
  showLinkAdAccountsModal: false,
  showSelectAdChannelModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateOrEditClientModal: {
      closeModal: () => setState({ showCreateOrEditClientModal: false }),
      updateClient: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    CreateOrEditExternalPlatformEntityModal: {
      closeModal: () => setState({ showCreateOrEditExternalPlatformEntityModal: false }),
      createExternalPlatformEntity: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteExternalPlatformEntity: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateExternalPlatformEntity: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    LinkAdAccountsModal: {
      closeModal: () => setState({ showLinkAdAccountsModal: false }),
      createAndLinkAdAccounts: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    SelectAdChannelModal: {
      closeModal: () => setState({ showSelectAdChannelModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const Details = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showCreateOrEditExternalPlatformEntityModal,
    showLinkAdAccountsModal,
    showSelectAdChannelModal,
  } = state

  const { clientId } = useNumericParams()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      deleteAdAccountLink: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      patchClient: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      showCreateOrEditExternalPlatformEntityModal: (payload: { externalPlatformEntity: ExternalPlatformEntityModel }) => {
        setModalData('CreateOrEditExternalPlatformEntityModal', payload)
        setState({ showCreateOrEditExternalPlatformEntityModal: true })
      },
      showLinkAdAccountsModal: (payload: LinkAdAccountsModalPayload) => {
        setModalData('LinkAdAccountsModal', payload)
        setState({ showLinkAdAccountsModal: true })
      },
      showSelectAdChannelModal: (payload: SelectAdChannelModalPayload) => {
        setModalData('SelectAdChannelModal', payload)
        setState({ showSelectAdChannelModal: true })
      },
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent clientId={clientId} />

        <LinkAdAccountsModal
          callbacks={callbacks('LinkAdAccountsModal', setState)}
          showModal={showLinkAdAccountsModal}
        />

        <SelectAdChannelModal
          callbacks={callbacks('SelectAdChannelModal', setState)}
          showModal={showSelectAdChannelModal}
        />

        <CreateOrEditExternalPlatformEntityModal
          callbacks={callbacks('CreateOrEditExternalPlatformEntityModal', setState)}
          showModal={showCreateOrEditExternalPlatformEntityModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details
