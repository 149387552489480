import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ProductOptionProductModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/productOptionProduct/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/productOptionProduct/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/productOptionProduct/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/productOptionProduct/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/productOptionProduct/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/productOptionProduct/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/productOptionProduct/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/productOptionProduct/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/productOptionProduct/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/productOptionProduct/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/productOptionProduct/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/productOptionProduct/DELETE_FAILURE'

export type ProductOptionProductModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: ProductOptionProductModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadProductOptionProducts(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().productOptionProducts.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/product-option-products', options)
      .then(({ data }: { data: { data: ProductOptionProductModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.PRODUCT_OPTION_PRODUCT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export type BulkCreateParams = {
  productIds: number[],
  productOptionIds: number[],
}

export function bulkCreateProductOptionProduct(
  params: BulkCreateParams,
  options: EntityOptions = {},
) {
  const config = {
    method: 'POST',
    data: JSON.stringify(params),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/product-option-products/bulk', options, config)
      .then(({ data }: { data: ProductOptionProductModel[] }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_OPTION_PRODUCT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateProductOptionProduct(
  productOptionProduct: UpdateParams<ProductOptionProductModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      ...productOptionProduct,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/product-option-products/${productOptionProduct.id}`, options, config)
      .then(({ data }: { data: ProductOptionProductModel }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_OPTION_PRODUCT)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteProductOptionProduct(productOptionProduct: DeleteParams<ProductOptionProductModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/product-option-products/${productOptionProduct.id}`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        const deletedEntity = { id: productOptionProduct.id }
        const normalizedJson = normalize(deletedEntity, Schemas.PRODUCT_OPTION_PRODUCT)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function removeProductOptionProduct(dispatch: AppDispatch, productOptionProduct: ProductOptionProductModel) {
  const normalizedJson = normalize(productOptionProduct, Schemas.PRODUCT_OPTION_PRODUCT)
  dispatch(deleteEntity(normalizedJson))
}

// Reducer
export default function reducer(
  state: ProductOptionProductModuleState = initialState,
  action: Action = { type: '' },
): ProductOptionProductModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
