import { formatMoney } from 'accounting'

import { generateRedirectUrl } from '@campaignhub/javascript-utils'

import { Box, DashboardModuleListItem } from '@campaignhub/suit-theme'

import useProductPackage, { generateUrls } from '@hooks/useProductPackage'

import { PackageProductModel } from '@models/types'

interface PackageListItemProps {
  packageProduct: PackageProductModel,
}

const PackageListItem = (props: PackageListItemProps) => {
  const { packageProduct } = props
  const {
    packageId,
  } = packageProduct

  const productPackagePayload = useProductPackage({ id: packageId })

  const {
    productPackage,
  } = productPackagePayload

  const {
    basePrice,
    description,
    name,
    productCount,
  } = productPackage

  const { editProductPackageUrl } = generateUrls(productPackage)

  return (
    <DashboardModuleListItem
      secondaryText={description}
      title={name}
    >
      <Box flexDirection="column">
        <DashboardModuleListItem.TertiaryText>
          {formatMoney(basePrice, '$', 2)}
        </DashboardModuleListItem.TertiaryText>

        <DashboardModuleListItem.TertiaryText>
          {productCount > 1
            ? `${productCount} Products`
            : `${productCount} Product`}
        </DashboardModuleListItem.TertiaryText>
      </Box>

      <DashboardModuleListItem.Actions>
        <DashboardModuleListItem.Icon
          href={`${editProductPackageUrl}?redirect=${generateRedirectUrl()}`}
          type="link"
        />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default PackageListItem
