import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Button, Form, ModalContext, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import { confirmDelete } from '@functions/entity'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useAsset, { useAssetForm } from '@hooks/useAsset'
import useAssetTypes from '@hooks/useAssetTypes'

const MODAL_KEY = 'EditDocumentModal'

interface EditDocumentModalProps {
  callbacks: {
    closeModal: () => void,
    deleteDocument: (payload: HandleCallbackActionParams) => Promise<object>,
    updateDocument: (payload: HandleCallbackActionParams) => Promise<object>,
  },
  showModal: boolean,
}

const EditDocumentModal = (props: EditDocumentModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, deleteDocument, updateDocument } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { asset } = modalPayload

  const {
    callbacks: {
      deleteAsset: deleteFn,
      downloadAsset,
      updateAsset: updateFn,
    },
    deleting,
  } = useAsset(asset)

  const {
    entityState,
    entityState: {
      assetTypeId,
      displayName,
      fileName,
    },
    handlers,
  } = useAssetForm(asset)

  const { filteredAssetTypes } = useAssetTypes({
    filters: {
      category: 'Document',
    },
    performHttpRequests: true,
  })

  const updateAssetPayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Document Updated',
  }

  const confirmDeletePayload = {
    callbacks: {
      afterAction: closeModal,
      deleteEntity: deleteDocument,
      deleteFn,
    },
    strings: {
      entityName: 'Document',
    },
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Document" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="Document Title">
            <input name="displayName" type="text" value={displayName} {...handlers} />
          </Form.Field>

          <Form.Field label="File Name" marginTop="large">
            <input disabled value={fileName} />
          </Form.Field>

          <SectionDivider marginTop="large">Campaign Document Options</SectionDivider>

          <Form.Field label="Document Type" marginTop="large">
            <select data-value-type="number" name="assetTypeId" value={assetTypeId} {...handlers}>
              <option value="">Please Select...</option>
              {filteredAssetTypes.map(assetType => (
                <option value={assetType.id}>{assetType.displayName}</option>
              ))}
            </select>
          </Form.Field>

          <Form.Field label="More Options" marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              loading={deleting}
              onClick={() => confirmDelete(confirmDeletePayload)}
              size="medium"
            >
              Delete Document
            </Button>

            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faDownload} />}
              marginTop="medium"
              onClick={() => downloadAsset()}
              size="medium"
            >
              Download Document
            </Button>
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => updateDocument(updateAssetPayload)}
          size="large"
        >
          Save Document
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: EditDocumentModalProps) => (
  <SidebarModal.RenderController {...props}>
    <EditDocumentModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
