import { camelCaseToTitleCase, capitalizeString } from '@campaignhub/javascript-utils'

import { Box, DashboardModuleListItem, Image } from '@campaignhub/suit-theme'

import { generateLogoUrl } from '@functions/adAccount'

import useAdAccount from '@hooks/useAdAccount'

import type { ClientModel, UserModel } from '@models/types'

interface AdChannelCardProps {
  adAccountType: 'FacebookAdAccount' | 'FacebookPage' | 'GoogleAdAccount',
  adChannel: 'facebook' | 'google',
  linkableEntity: ClientModel | UserModel,
  linkableType: 'Client' | 'User',
  marginTop?: string,
}

const AdChannelCard = (props: AdChannelCardProps) => {
  const { adAccountType, adChannel, linkableEntity, linkableType, marginTop } = props

  const url = generateLogoUrl(adChannel)

  const {
    callbacks: {
      launchLinkAdAccountsModal,
    },
  } = useAdAccount()

  return (
    <Box
      border="1px solid"
      borderColor="lineColor"
      borderRadius={5}
      flexDirection="column"
      marginTop={marginTop}
    >
      <Box
        borderBottom="1px solid"
        borderColor="lineColor"
      >
        <Image backgroundSize="contain" height="112px" url={url} />
      </Box>

      <DashboardModuleListItem
        onClick={() => launchLinkAdAccountsModal({ adAccountType, adChannel, linkableEntity, linkableType })}
        secondaryText={camelCaseToTitleCase(adAccountType)}
        title={capitalizeString(adChannel)}
      >
      </DashboardModuleListItem>
    </Box>
  )
}

export default AdChannelCard