import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ClientRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['clients']

type ClientFilters = {
  brandId?: number,
  isArchived?: boolean,
  string?: string,
}

type UseClientsOptions = {
  filters?: ClientFilters,
  performHttpRequests?: boolean,
  requestOptions?: ClientRequestOptions,
}

function useClients(options: UseClientsOptions) {
  const { filters = {}, requestOptions } = options
  const {
    brandId: filterBrandId,
    isArchived: filterIsArchived,
    string: filterString,
  } = filters

  const {
    updatedEntities: { clients: clientsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { clients } = useSelector(reduxState => reduxState.entities)

  const filteredClients = useMemo(() => {
    const filtered = Object.values(clients).filter((client) => {
      const {
        brandId, id, isArchived, name,
      } = client

      const matchBrand = matchFilterNumber(brandId, filterBrandId)

      const matchIsArchived = filterIsArchived !== undefined ? isArchived === filterIsArchived : true

      const matchId = id.toString() === filterString
      const matchTitle = matchFilterString(name, filterString)
      const stringMatch = matchId || matchTitle

      return matchBrand && matchIsArchived && stringMatch
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [clientsUpdatedAt, JSON.stringify(filters)])

  const filteredClientsCount = filteredClients.length
  const hasFilteredClients = !!filteredClientsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredClientsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingClients } = useReduxAction(
    'clients',
    'loadClients',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredClients,
    filteredClientsCount,
    hasFilteredClients,
    loading: loadingClients,
  }
}

export default useClients
