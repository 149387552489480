import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as orderItemActions from '@redux/modules/orderItem'

import defaultFormState, { requiredFields } from '@models/orderItem'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { OrderItemModel, OrderItemRequestOptions } from '@models/types'
import type { PatchParams } from '@redux/modules/orderItem'

type CreateOrderItemParams = {
  dispatch: AppDispatch,
  orderItemParams: Partial<OrderItemModel>,
  requestOptions?: OrderItemRequestOptions,
}

const createOrderItem = (params: CreateOrderItemParams) => {
  const { dispatch, orderItemParams, requestOptions } = params
  const { createOrderItem: createFn } = orderItemActions

  return dispatch(createFn(orderItemParams, requestOptions))
}

type UpdateOrderItemParams = {
  dispatch: AppDispatch,
  orderItem: OrderItemModel,
  orderItemParams: Partial<OrderItemModel>,
  requestOptions?: OrderItemRequestOptions,
}

const updateOrderItem = (params: UpdateOrderItemParams) => {
  const {
    dispatch, orderItem, orderItemParams, requestOptions,
  } = params
  const { updateOrderItem: updateFn } = orderItemActions

  const updatedParams = {
    id: orderItem.id,
    ...orderItemParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteOrderItemParams = {
  dispatch: AppDispatch,
  orderItem: DeleteParams<OrderItemModel>,
}

const deleteOrderItem = (params: DeleteOrderItemParams) => {
  const { dispatch, orderItem } = params
  const { deleteOrderItem: deleteFn } = orderItemActions

  return dispatch(deleteFn(orderItem))
}

type PatchOrderItemParams = {
  dispatch: AppDispatch,
  orderItemId: number,
  priceListItemOptionValues: PatchParams['priceListItemOptionValues'],
}

const patchOrderItem = (params: PatchOrderItemParams) => {
  const { dispatch, orderItemId, priceListItemOptionValues } = params
  const { patchOrderItem } = orderItemActions

  const patchOrderItemParams = {
    orderItemId,
    priceListItemOptionValues,
  }

  return dispatch(patchOrderItem(patchOrderItemParams))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useOrderItemForm(
  orderItem: Partial<OrderItemModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const orderItemForm = useForm(
    defaultFormState,
    { entity: orderItem, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [orderItem.id, orderItem.cacheKey],
  )

  return {
    ...orderItemForm,
  }
}

export const useRelations = (orderItem: Partial<OrderItemModel> = {}) => {
  const { orderableItemId, orderableItemTypeId, orderId, priceListItemId } = orderItem

  const {
    orders,
    priceListItems,
    productPackages,
    products,
  } = useSelector(reduxState => reduxState.entities)

  // OrderableItemType can be Product or Package
  const orderableItem = orderableItemTypeId === 'Product'
    ? orderableItemId && products[orderableItemId] ? products[orderableItemId] : {}
    : orderableItemId && productPackages[orderableItemId] ? productPackages[orderableItemId] : {}

  const order = orderId && orders[orderId] ? orders[orderId] : {}
  const priceListItem = priceListItemId && priceListItems[priceListItemId] ? priceListItems[priceListItemId] : {}

  return {
    order,
    orderableItem,
    priceListItem,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: OrderItemRequestOptions,
}

function useOrderItem(initEntity: Partial<OrderItemModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: orderItem }: { entity: OrderItemModel } = useLatestEntity(initEntity, 'orderItems')
  const { id } = orderItem

  const dispatch = useDispatch()

  const { priceListItem } = useRelations(orderItem)

  useReduxAction(
    'orderItems',
    'loadOrderItem',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.orderItems)

  return {
    callbacks: {
      createOrderItem: (orderItemParams: Partial<OrderItemModel>, entityOptions?: OrderItemRequestOptions) => (
        createOrderItem({ orderItemParams, dispatch, requestOptions: entityOptions })
      ),
      deleteOrderItem: () => deleteOrderItem({ orderItem, dispatch }),
      patchOrderItem: (priceListItemOptionValues: PatchParams['priceListItemOptionValues']) => (
        patchOrderItem({ dispatch, orderItemId: orderItem.id, priceListItemOptionValues })
      ),
      updateOrderItem: (orderItemParams: Partial<OrderItemModel>, entityOptions?: OrderItemRequestOptions) => (
        updateOrderItem({
          orderItem, orderItemParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    orderItem,
    priceListItem,
    updating,
  }
}

export default useOrderItem
