import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import placeholderImageUrl from '@assets/default_campaign_image_400x400.png'

import type { Property } from '..'

interface CampaignListItemProps {
  property: Property,
}

const CampaignListItem = (props: CampaignListItemProps) => {
  const {
    property: {
      postCode,
      state,
      streetName,
      streetNumber,
      suburb,
    },
  } = props

  // TODO: get property image

  return (
    <ListItem
      boxProps={{ as: 'a', borderBottom: '1px solid', padding: 'large' }}
      showAngleIcon // TODO: launch update property address modal
    >
      <Box width={110} marginRight="large">
        <Image
          borderRadius={5}
          height={40}
          marginRight="medium"
          placeholderUrl={placeholderImageUrl}
          // url={filePath}
          width={70}
        />
      </Box>

      <Box flexDirection="column" justifyContent="center" minWidth="0">
        <Text fontSize="small">{streetNumber} {streetName}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">
          {suburb}, {state} - {postCode}
        </Text>
      </Box>
    </ListItem>
  )
}

export default CampaignListItem
