import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as priceListItemActions from '@redux/modules/priceListItem'

import defaultFormState, { requiredFields } from '@models/priceListItem'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams } from '@redux/modules/types'
import type { PriceListItemModel, PriceListItemRequestOptions } from '@models/types'

type CreatePriceListItemParams = {
  dispatch: AppDispatch,
  priceListItemParams: Partial<PriceListItemModel>,
  requestOptions?: PriceListItemRequestOptions,
}

const createPriceListItem = (params: CreatePriceListItemParams) => {
  const { dispatch, priceListItemParams, requestOptions } = params
  const { createPriceListItem: createFn } = priceListItemActions

  return dispatch(createFn(priceListItemParams, requestOptions))
}

type PatchPriceListItemModel = Pick<PriceListItemModel, 'descriptionOverride' | 'nameOverride' | 'price'>

type PatchPriceListItemParams = {
  dispatch: AppDispatch,
  priceListItem: PriceListItemModel,
  priceListItemParams: PatchPriceListItemModel,
  requestOptions?: PriceListItemRequestOptions,
}

const patchPriceListItem = (params: PatchPriceListItemParams) => {
  const {
    dispatch, priceListItem, priceListItemParams, requestOptions,
  } = params
  const { patchPriceListItem: patchFn } = priceListItemActions

  const updatedParams = {
    id: priceListItem.id,
    ...priceListItemParams,
  }

  return dispatch(patchFn(updatedParams, requestOptions))
}

type DeletePriceListItemParams = {
  dispatch: AppDispatch,
  priceListItem: DeleteParams<PriceListItemModel>,
}

const deletePriceListItem = (params: DeletePriceListItemParams) => {
  const { dispatch, priceListItem } = params
  const { deletePriceListItem: deleteFn } = priceListItemActions

  return dispatch(deleteFn(priceListItem))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function usePriceListItemForm(
  priceListItem: Partial<PriceListItemModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const priceListItemForm = useForm(
    defaultFormState,
    { entity: priceListItem, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [priceListItem.id, priceListItem.cacheKey],
  )

  return {
    ...priceListItemForm,
  }
}

export const useRelations = (priceListItem: Partial<PriceListItemModel> = {}) => {
  const { priceListId, productId } = priceListItem

  const { priceLists, products } = useSelector(reduxState => reduxState.entities)

  const product = productId && products[productId] ? products[productId] : {}
  const priceList = priceListId && priceLists[priceListId] ? priceLists[priceListId] : {}

  return {
    priceList,
    product,
  }
}

function usePriceListItem(initEntity: Partial<PriceListItemModel> = {}) {
  const { entity: priceListItem }: { entity: PriceListItemModel } = useLatestEntity(initEntity, 'priceListItems')

  const dispatch = useDispatch()

  const { priceList, product } = useRelations(priceListItem)

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.priceListItems)

  return {
    callbacks: {
      createPriceListItem: (
        priceListItemParams: Partial<PriceListItemModel>,
        entityOptions?: PriceListItemRequestOptions,
      ) => (
        createPriceListItem({ priceListItemParams, dispatch, requestOptions: entityOptions })
      ),
      deletePriceListItem: () => deletePriceListItem({ priceListItem, dispatch }),
      patchPriceListItem: (
        priceListItemParams: PatchPriceListItemModel,
        entityOptions?: PriceListItemRequestOptions,
      ) => (
        patchPriceListItem({
          priceListItem, priceListItemParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    priceList,
    priceListItem,
    product,
    updating,
  }
}

export default usePriceListItem
