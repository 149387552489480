import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, Line, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import Audiences from './Audiences'
import Demographics from './Demographics'
import Locations from './Locations'

interface AdSetDetailsModalProps {
  callbacks: {
    closeModal: () => void,
  },
  showModal: boolean,
}

const MODAL_KEY = 'AdSetDetailsModal'

const AdSetDetailsModal = (props: AdSetDetailsModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { adCampaign, adGroup } = modalPayload

  const { adChannelTypeId } = adCampaign

  const adCollectionName = adChannelTypeId === 'Facebook' ? 'Ad Set' : 'Ad Group'

  const {
    audiences,
    demographics,
    locations,
    name,
  } = adGroup

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title={adCollectionName}
        titleSecondLine="Details"
      />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <Text color="bodyFontLightColor" fontSize="xsmall">{adCollectionName}</Text>

          <Text fontSize="small" lineHeight={1.3} marginTop="small">{name}</Text>

          <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />

          {!!audiences && (
            <>
              <Audiences audiences={audiences} />

              <Line border="1px dashed" />
            </>
          )}

          {!!locations && (
            <>
              <Locations locations={locations} />

              <Line border="1px dashed" />
            </>
          )}

          {!!demographics && <Demographics demographics={demographics} />}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => closeModal()}
          size="large"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: AdSetDetailsModalProps) => (
  <SidebarModal.RenderController {...props}>
    <AdSetDetailsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
