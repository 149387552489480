import type { CaptionModel } from '@models/types'

export type CaptionsObject = {
  callToActions: string[],
  descriptions: string[],
  displayUrls: string[],
  headlines: string[],
  texts: string[],
}

const captionsTypeMapping = {
  CallToAction: 'callToActions',
  Description: 'descriptions',
  DisplayUrl: 'displayUrls',
  Headline: 'headlines',
  Text: 'texts',
}

const initState = {
  callToActions: [],
  descriptions: [],
  displayUrls: [],
  headlines: [],
  texts: [],
}

export const mapCaptionsToObject = (captions: CaptionModel[]) => {
  const captionsObject = captions.reduce<CaptionsObject>((acc, caption) => {
    const { type, values } = caption
    const key = captionsTypeMapping[type]

    acc[key] = values

    return acc
  }, initState)

  return captionsObject
}