import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { faBan, faCheckCircle } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'
import type { DefaultContext } from '@contexts/pageContext'

import getActiveTabBar from '@functions/tabBar'

import usePriceLists from '@hooks/usePriceLists'
import useSelectEntities from '@hooks/useSelectEntities'
import { generateUrls } from '@hooks/usePriceList'

import ListBlankState from '@components/ListBlankState'
import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import PriceListListItem from '../PriceListListItem'
import PriceListTools from '../PriceListTools'

import type { PageContextPayload } from '../../index'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }
  const boxProps = { marginBottom: 'large' }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by pricelist name or ID',
        style,
      },
      key: 'string',
      label: 'Name or ID',
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'clients',
        entityTitleKey: 'name',
      },
      key: 'clientId',
    },
  ]
}

interface PageContentProps {
  pageFilters: {},
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const {
    isArchived: archived,
  } = parsePermittedQueryParams(getQueryParamsFromHash(), ['isArchived'])

  const isArchived = archived === 'true'

  const priceListsPayload = usePriceLists({
    filters: {
      ...pageFilters,
      isArchived,
    },
    performHttpRequests: true,
    requestOptions: {
      include: 'ClientPriceLists',
    },
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredPriceLists,
    filteredPriceListsCount,
    hasFilteredPriceLists,
    loading,
  } = priceListsPayload

  const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  const {
    callbacks: {
      resetFilters,
      showCreatePriceListModal,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  const {
    archivedPriceListsIndexUrl,
    priceListsIndexUrl,
  } = generateUrls()

  const selectEntitiesPayload = useSelectEntities()
  const {
    callbacks: {
      getSelectableListItemProps,
    },
  } = selectEntitiesPayload

  return (
    <>
      <Helmet>
        <title>Price Lists | AIM Digital</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showCreatePriceListModal()} size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey={getActiveTabBar({ isArchived })}
        tabBarItems={[
          {
            href: priceListsIndexUrl,
            icon: faCheckCircle,
            key: 'active',
            title: 'Active',
          },
          {
            href: archivedPriceListsIndexUrl,
            icon: faBan,
            key: 'archived',
            title: 'Archived',
          },
        ]}
        title="Price Lists"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFilteredPriceLists && <ListBlankState />}

            {!loading && hasFilteredPriceLists && (
              <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title="Price Lists">
                <Box flexDirection="column">
                  {filteredPriceLists.map(priceList => (
                    <PriceListListItem
                      key={priceList.id}
                      priceList={priceList}
                      {...getSelectableListItemProps(priceList.id)}
                    />
                  ))}
                </Box>

                <DashboardModule.LoadMoreFooter
                  callbacks={{ loadMore }}
                  canLoadMore={canLoadMore}
                  entityCount={filteredPriceListsCount}
                  loading={loading}
                />
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields()}
            />

            <PriceListTools selectEntitiesPayload={selectEntitiesPayload} />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
