import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as conversationActions from '@redux/modules/conversation'

import defaultFormState, { requiredFields } from '@models/conversation'

import type { AppDispatch } from '@redux/store'
import type { ConversationModel, ConversationRequestOptions } from '@models/types'

type CreateConversationParams = {
  conversationParams: Partial<ConversationModel>,
  dispatch: AppDispatch,
  requestOptions?: ConversationRequestOptions,
}

const createConversation = (params: CreateConversationParams) => {
  const { dispatch, conversationParams, requestOptions } = params
  const { createConversation: createFn } = conversationActions

  return dispatch(createFn(conversationParams, requestOptions))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useConversationForm(
  conversation: Partial<ConversationModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const conversationForm = useForm(
    defaultFormState,
    { entity: conversation, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [conversation.id, conversation.cacheKey],
  )

  return {
    ...conversationForm,
  }
}

function useConversation(initEntity: Partial<ConversationModel> = {}) {
  const { entity: conversation }: { entity: ConversationModel} = useLatestEntity(initEntity, 'conversations')

  const dispatch = useDispatch()

  const { creating } = useSelector(reduxState => reduxState.conversations)

  return {
    callbacks: {
      createConversation: (
        conversationParams: Partial<ConversationModel>,
        entityOptions?: ConversationRequestOptions,
      ) => (
        createConversation({ conversationParams, dispatch, requestOptions: entityOptions })
      ),
    },
    conversation,
    creating,
  }
}

export default useConversation
