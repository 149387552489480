import {
  faBuilding,
  faHome,
  faLifeRing,
  faStore,
  faTachometer,
  faUser,
} from '@fortawesome/pro-light-svg-icons'

const generateMainNavigationItems = () => ({
  sections: [
    {
      key: 'dashboard',
      visible: true,
      items: [
        {
          href: '#/',
          icon: faTachometer,
          key: 'dashboard',
          title: 'Dashboard',
          visible: true,
        },
      ],
    },
    {
      key: 'marketing',
      title: 'Marketing',
      visible: true,
      items: [
        {
          href: '#/campaigns',
          icon: faHome,
          key: 'property-campaign',
          title: 'Property Campaigns',
          visible: true,
        },
        {
          href: '#/myMarketing',
          icon: faUser,
          key: 'my-marketing',
          title: 'My Marketing',
          visible: true,
        },
        {
          href: '#/officeMarketing',
          icon: faBuilding,
          key: 'office-marketing',
          title: 'Office Marketing',
          visible: true,
        },
      ],
    },
    {
      key: 'admin',
      title: 'Admin',
      visible: true,
      items: [
        {
          href: '#/admin',
          icon: faStore,
          key: 'admin',
          title: 'Account Admin',
          visible: true,
        },
      ],
    },
    {
      key: 'support',
      title: 'Support',
      visible: true,
      items: [
        {
          href: '#/training',
          icon: faLifeRing,
          key: 'training',
          title: 'Training',
          visible: true,
        },
      ],
    },
  ],
})

export default generateMainNavigationItems
