import api from '@functions/api'

import type { UserModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, Errors, FetchRequestPayload,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/currentUser/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/currentUser/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/currentUser/FETCH_FAILURE'

export type CurrentUserModuleState = {
  errors: string[],
  loaded: boolean,
  loading: boolean,
  result: UserModel,
}

// Initial State
const initialState: CurrentUserModuleState = {
  errors: [],
  loaded: false,
  loading: false,
  result: {},
}

// Actions
export function fetchRequest(): Action {
  return {
    type: FETCH_REQUEST,
  }
}

type FetchSuccessPayload = FetchRequestPayload & { result: UserModel }

type CurrentUserAction = Action & { result: UserModel }

export function fetchSuccess(payload: FetchSuccessPayload): CurrentUserAction {
  return {
    type: FETCH_SUCCESS,
    result: payload.result,
  }
}

export function fetchFailure(errors: Errors = []): Action {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadCurrentUser() {
  return (dispatch: AppDispatch) => {
    dispatch(fetchRequest())

    const promise = api('/users/me')
      .then(({ data }: { data: UserModel }) => {
        dispatch(fetchSuccess({ result: data }))

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: CurrentUserModuleState = initialState,
  action: CurrentUserAction = { type: '' },
) {
  switch (action.type){
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        ...state,
        errors: [],
        loaded: true,
        loading: false,
        result: action.result,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        errors: action.errors,
        loaded: false,
        loading: false,
      }
    default:
      return state
  }
}
