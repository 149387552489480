export type CutterProfileModel = {
  id: number,
  name: string,
}

export type CutterProfileRequestOptions = {}

const state = {
  id: null,
  name: '',
}

export const requiredFields = [
  { key: 'name' },
]

export default state
