import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as artworkTemplateGroupActions from '@redux/modules/artworkTemplateGroup'

import defaultFormState, { requiredFields } from '@models/artworkTemplateGroup'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { ArtworkTemplateGroupModel, ArtworkTemplateGroupRequestOptions } from '@models/types'

export const generateUrls = (artworkTemplateGroup?: Partial<ArtworkTemplateGroupModel>) => {
  const { id } = artworkTemplateGroup || {}

  return {
    artworkTemplateGroupsIndexUrl: '#/artworkTemplateGroups',
    editArtworkTemplateGroupUrl: `#/artworkTemplateGroups/${id}/edit/`,
    editArtworkTemplateGroupRelationsUrl: `#/artworkTemplateGroups/${id}/edit/relations/`,
  }
}

type CreateArtworkTemplateGroupParams = {
  artworkTemplateGroupParams: Partial<ArtworkTemplateGroupModel>,
  dispatch: AppDispatch,
  requestOptions?: ArtworkTemplateGroupRequestOptions,
}

const createArtworkTemplateGroup = (params: CreateArtworkTemplateGroupParams) => {
  const { dispatch, artworkTemplateGroupParams, requestOptions } = params
  const { createArtworkTemplateGroup: createFn } = artworkTemplateGroupActions

  return dispatch(createFn(artworkTemplateGroupParams, requestOptions))
}

type UpdateArtworkTemplateGroupParams = {

  artworkTemplateGroup: ArtworkTemplateGroupModel,
  artworkTemplateGroupParams: Partial<ArtworkTemplateGroupModel>,
  dispatch: AppDispatch,
  requestOptions?: ArtworkTemplateGroupRequestOptions,

}

const updateArtworkTemplateGroup = (params: UpdateArtworkTemplateGroupParams) => {
  const {
    dispatch, artworkTemplateGroup, artworkTemplateGroupParams, requestOptions,
  } = params
  const { updateArtworkTemplateGroup: updateFn } = artworkTemplateGroupActions

  const updatedParams = {
    id: artworkTemplateGroup.id,
    ...artworkTemplateGroupParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteArtworkTemplateGroupParams = {

  artworkTemplateGroup: DeleteParams<ArtworkTemplateGroupModel>,
  dispatch: AppDispatch,

}

const deleteArtworkTemplateGroup = (params: DeleteArtworkTemplateGroupParams) => {
  const { dispatch, artworkTemplateGroup } = params
  const { deleteArtworkTemplateGroup: deleteFn } = artworkTemplateGroupActions

  return dispatch(deleteFn(artworkTemplateGroup))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useArtworkTemplateGroupForm(
  artworkTemplateGroup: Partial<ArtworkTemplateGroupModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const artworkTemplateGroupForm = useForm(
    defaultFormState,
    { entity: artworkTemplateGroup, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [artworkTemplateGroup.id, artworkTemplateGroup.cacheKey],
  )

  return {
    ...artworkTemplateGroupForm,
  }
}

export const useRelations = (artworkTemplateGroup: Partial<ArtworkTemplateGroupModel> = {}) => {
  const { related_entity_id } = artworkTemplateGroup

  const { relatedEntities } = useSelector(reduxState => reduxState.entities)

  const relatedEntity = related_entity_id && relatedEntities[related_entity_id] ? relatedEntities[related_entity_id] : {}

  return {
    relatedEntity,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ArtworkTemplateGroupRequestOptions,
}

function useArtworkTemplateGroup(initEntity: Partial<ArtworkTemplateGroupModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: artworkTemplateGroup }: { entity: ArtworkTemplateGroupModel } = useLatestEntity(initEntity, 'artworkTemplateGroups')
  const { id } = artworkTemplateGroup

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { relatedEntity } = useRelations(artworkTemplateGroup)

  useReduxAction(
    'artworkTemplateGroups',
    'loadArtworkTemplateGroup',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.artworkTemplateGroups)

  return {
    callbacks: {
      createArtworkTemplateGroup: (artworkTemplateGroupParams: Partial<ArtworkTemplateGroupModel>, entityOptions?: ArtworkTemplateGroupRequestOptions) => (
        createArtworkTemplateGroup({ artworkTemplateGroupParams, dispatch, requestOptions: entityOptions })
      ),
      createOrEditArtworkTemplateGroup: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditArtworkTemplateGroupModal',
        payload: { artworkTemplateGroup },
      }),
      deleteArtworkTemplateGroup: () => deleteArtworkTemplateGroup({ artworkTemplateGroup, dispatch }),
      updateArtworkTemplateGroup: (artworkTemplateGroupParams: Partial<ArtworkTemplateGroupModel>, entityOptions?: ArtworkTemplateGroupRequestOptions) => (
        updateArtworkTemplateGroup({
          artworkTemplateGroup, artworkTemplateGroupParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    artworkTemplateGroup,
    creating,
    deleting,
    loading,
    relatedEntity,
    updating,
    urls: generateUrls(artworkTemplateGroup),
  }
}

export default useArtworkTemplateGroup
