import { useAuth0 } from '@auth0/auth0-react'

import { faShieldAlt, faUserCog } from '@fortawesome/pro-light-svg-icons'

import { TopBar } from '@campaignhub/suit-theme'

const LoggedInUser = () => {
  const { logout, user } = useAuth0()
  const { 'https://www.realhubapp.com/role': roleName, name, picture } = user || {}

  return (
    <TopBar.LoggedInUser
      callbacks={{ logout: () => logout({ returnTo: window.location.origin }) }}
      imageUrl={picture}
      subtitle={roleName}
      title={name}
    >
      <TopBar.DropdownLink href="#" icon={faUserCog} text="Admin Settings" />
      <TopBar.DropdownLink href="#" icon={faShieldAlt} text="Account Settings" />
    </TopBar.LoggedInUser>
  )
}

export default LoggedInUser
