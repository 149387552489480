import { Box, Image, Text } from '@campaignhub/suit-theme'

import useAsset from '@hooks/useAsset'

import type { AssetModel } from '@models/asset'

type ProductImageListItemProps = {
  image: AssetModel,
  filters: {
    assetTypeCategory: string,
    assetableId: number,
    assetableType: string,
  },
}

const ProductImageListItem = (props: ProductImageListItemProps) => {
  const { image, filters } = props
  const { fileName, filePath } = image

  const {
    callbacks: {
      createOrEditImage,
    },
  } = useAsset(image)

  return (
    <Box alignItems="center" padding="large">
      <Image
        borderRadius={5}
        forwardProps={{
          onClick: () => createOrEditImage(filters),
          style: { cursor: 'pointer' },
        }}
        height={60}
        url={filePath}
        width={120}
      />

      <Text fontSize="small" marginLeft="medium" variant="ellipsis">{fileName}</Text>
    </Box>
  )
}

export default ProductImageListItem
