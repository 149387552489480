export type PriceListItemModel = {
  cacheKey: number,
  descriptionOverride: string,
  id: number,
  nameOverride: string,
  price: number,
  priceListId: number,
  productId: number,
}

export type PriceListItemRequestOptions = {}

const state = {
  descriptionOverride: '',
  id: null,
  nameOverride: '',
  price: undefined,
  priceListId: undefined,
  productId: undefined,
}

export const requiredFields = [
  { key: 'priceListId' },
  { key: 'productId' },
  {
    key: 'price',
    validation: (price: number) => price === undefined || price > 0,
    invalidMessage: 'Invalid Price',
  },
]

export default state
