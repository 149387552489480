import { useContext } from 'react'

import {
  Button,
  Columns,
  LoadingModule,
  SaveFormModule,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useClient, { useClientForm } from '@hooks/useClient'

import AdAccounts from '../AdAccounts'
import ClientAddress from '../ClientAddress'
import ClientDetails from '../ClientDetails'
import ClientTools from '../../../../components/ClientTools'
import ExternalPlatformEntities from '@components/ExternalPlatformEntities'
import PageLayout from '../../../../components/PageLayout'

interface PageContentProps {
  clientId: number,
}

const PageContent = (props: PageContentProps) => {
  const { clientId } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      patchClient,
    },
  } = pageContext

  const clientPayload = useClient({ id: clientId })

  const {
    client,
    callbacks: {
      patchClient: patchFn,
    },
    loading,
    updating,
  } = clientPayload

  const clientFormPayload = useClientForm(client, { validateOn: 'change' })
  const {
    entityState: {
      additionalNotificationEmail
    },
    saveEnabled,
  } = clientFormPayload

  const updateClientPayload = {
    callbacks: {
      action: patchFn,
    },
    entityParams: { additionalNotificationEmail },
    toastText: 'Organisation Updated',
  }

  return (
    <PageLayout clientPayload={clientPayload} activeTabBarItemKey="details">
      <Columns.Main>
        <LoadingModule loading={loading} times={3} />

        {!loading && (
          <>
            <ClientDetails clientFormPayload={clientFormPayload} />

            <ClientAddress clientFormPayload={clientFormPayload} />

            <SaveFormModule boxProps={{ marginBottom: 'none' }}>
              <Button
                buttonStyle="primaryCreate"
                disabled={!saveEnabled}
                loading={updating}
                marginLeft="auto"
                onClick={() => patchClient(updateClientPayload)}
                size="medium"
              >
                Save Details
              </Button>
            </SaveFormModule>

            <AdAccounts client={client} />
          </>
        )}
      </Columns.Main>

      <Columns.Sidebar>
        {/* <ClientTools clientPayload={clientPayload} /> */}

        <ExternalPlatformEntities
          filters={{
            internalEntityId: clientId,
            internalEntityType: 'Client',
          }}
        />
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent