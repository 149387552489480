import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import type { ClientModel } from '@models/types'

import { generateUrls, useRelations } from '@hooks/useClient'

interface ClientListItemProps {
  client: ClientModel,
}

const ClientListItem = (props: ClientListItemProps) => {
  const {
    client,
    client: {
      id,
      name,
    },
  } = props

  const { editClientUrl } = generateUrls(client)

  const { brand } = useRelations(client)

  return (
    <DashboardModuleListItem
      href={editClientUrl}
      secondaryText={brand?.name}
      title={name}
    >
      <DashboardModuleListItem.TertiaryText>
        {`# ${id}`}
      </DashboardModuleListItem.TertiaryText>
    </DashboardModuleListItem>
  )
}

export default ClientListItem
