import { faIdCard, faToggleLargeOn } from '@fortawesome/pro-light-svg-icons'

import { ArtworkTemplateGroupModel } from '@models/artworkTemplateGroup'

import { generateUrls } from '@hooks/useArtworkTemplateGroup'

const generateTabBarItems = (artworkTemplateGroup: ArtworkTemplateGroupModel) => {
  const urls = generateUrls(artworkTemplateGroup)
  const {
    editArtworkTemplateGroupUrl,
    editArtworkTemplateGroupRelationsUrl,
  } = urls

  return [
    {
      icon: faIdCard,
      href: editArtworkTemplateGroupUrl,
      key: 'details',
      title: artworkTemplateGroup?.name || 'Artwork Template Group Details',
    },
    {
      icon: faToggleLargeOn,
      href: editArtworkTemplateGroupRelationsUrl,
      key: 'relations',
      title: 'Relations',
    },
  ]
}

export default generateTabBarItems
