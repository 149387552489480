import { useContext } from 'react'

import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, FileUploadZone, Form, Image, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { confirmDelete } from '@functions/entity'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useAsset, { useAssetForm } from '@hooks/useAsset'
import useAssetTypes from '@hooks/useAssetTypes'
import useUppyAssetUpload from '@hooks/useUppyAssetUpload'

const MODAL_KEY = 'CreateOrEditImageModal'

interface CreateOrEditImageModalProps {
  callbacks: {
    closeModal: () => void,
    deleteAsset: (payload: HandleCallbackActionParams) => Promise<object>,
    updateAsset: (payload: HandleCallbackActionParams) => Promise<object>,
  },
  showModal: boolean,
}

const CreateOrEditImageModal = (props: CreateOrEditImageModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, deleteAsset, updateAsset } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const asset = digObject(modalPayload, 'asset', {})

  const { filters } = modalPayload
  const {
    assetableId,
    assetableType,
  } = filters || {}

  const newRecord = !asset.id

  const {
    filePath,
  } = asset

  const {
    callbacks: {
      deleteAsset: deleteFn,
      downloadAsset,
      updateAsset: updateFn,
    },
    deleting,
    updating,
  } = useAsset(asset)

  const confirmDeletePayload = {
    callbacks: {
      afterAction: closeModal,
      deleteEntity: deleteAsset,
      deleteFn,
    },
    strings: {
      entityName: 'Asset',
    },
  }

  const {
    entityState,
    entityState: {
      assetTypeId,
    },
    handlers,
    saveEnabled,
  } = useAssetForm(asset)

  // To be used with handleCallbackAction
  const updateImagePayload = {
    callbacks: {
      action: updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: 'Image Updated',
  }

  const { filteredAssetTypes } = useAssetTypes({
    filters: {
      category: 'Image',
    },
    performHttpRequests: true,
  })

  const uppy = useUppyAssetUpload({
    assetTypeId,
    assetableId,
    assetableType,
    callbacks: {
      afterAction: () => {
        toast('Asset Created Successfully')
        closeModal()
      },
    },
  }, [JSON.stringify(filters), assetTypeId])

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title={newRecord ? 'Create' : 'Edit'} titleSecondLine="Image" />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <Form.Field label="Media Type">
            <select name="assetTypeId" value={assetTypeId} {...handlers}>
              <option value="">Please Select...</option>
              {filteredAssetTypes.map(assetType => (
                <option key={assetType.id} value={assetType.name}>{assetType.displayName}</option>
              ))}
            </select>
          </Form.Field>

          {!newRecord && (
            <Form.Field label="Image" marginTop="large">
              <Image
                borderRadius={5}
                height={230}
                url={filePath}
              />
            </Form.Field>
          )}

          {newRecord && !!assetTypeId && (
            <Form.Field label="Upload Image" marginTop="large">
              <FileUploadZone uppy={uppy} />
            </Form.Field>
          )}

          {!newRecord && (
            <Form.Field label="More Options" marginTop="large">
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                loading={deleting}
                onClick={() => confirmDelete(confirmDeletePayload)}
                size="medium"
              >
                Delete Image
              </Button>

              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faDownload} />}
                marginTop="medium"
                onClick={() => downloadAsset()}
                size="medium"
              >
                Download Image
              </Button>
            </Form.Field>
          )}
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          loadingBubbleColor="white"
          onClick={
            newRecord
              ? () => closeModal()
              : () => updateAsset(updateImagePayload)
          }
          size="large"
        >
          {saveEnabled ? `${newRecord ? 'Close' : 'Update Image'} ` : 'Complete Fields'}
        </Button>

      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: CreateOrEditImageModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditImageModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
