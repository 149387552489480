import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useSelector from '@hooks/useSelector'

import defaultFormState, { requiredFields } from '@models/autoOrderQueue'

import type { AutoOrderQueueModel } from '@models/types'

export const generateUrls = () => {
  return {
    autoOrderQueuesIndexUrl: '#/autoOrderQueues',
  }
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useAutoOrderQueueForm(
  autoOrderQueue: Partial<AutoOrderQueueModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const autoOrderQueueForm = useForm(
    defaultFormState,
    { entity: autoOrderQueue, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [autoOrderQueue.id, autoOrderQueue.cacheKey],
  )

  return {
    ...autoOrderQueueForm,
  }
}

function useAutoOrderQueue(initEntity: Partial<AutoOrderQueueModel> = {}) {
  const { entity: autoOrderQueue }: { entity: AutoOrderQueueModel } = useLatestEntity(initEntity, 'autoOrderQueues')

  const {
    loading,
  } = useSelector(reduxState => reduxState.autoOrderQueues)

  return {
    callbacks: {},
    autoOrderQueue,
    loading,
  }
}

export default useAutoOrderQueue
