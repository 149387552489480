import { DashboardModuleListItem } from '@campaignhub/suit-theme'

import type { ArtworkTemplateGroupModel } from '@models/types'

import { generateUrls } from '@hooks/useArtworkTemplateGroup'

interface ArtworkTemplateGroupListItemProps {
  artworkTemplateGroup: ArtworkTemplateGroupModel,
}

const ArtworkTemplateGroupListItem = (props: ArtworkTemplateGroupListItemProps) => {
  const { artworkTemplateGroup, artworkTemplateGroup: { id, name } } = props

  const { editArtworkTemplateGroupUrl } = generateUrls(artworkTemplateGroup)

  return (
    <DashboardModuleListItem
      href={editArtworkTemplateGroupUrl}
      title={name}
    >
      <DashboardModuleListItem.TertiaryText>
        {`# ${id}`}
      </DashboardModuleListItem.TertiaryText>
    </DashboardModuleListItem>
  )
}

export default ArtworkTemplateGroupListItem
