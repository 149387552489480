import {
  DashboardModule, Form,
} from '@campaignhub/suit-theme'

import { useProductOptionForm } from '@hooks/useProductOption'

import useProductOptionKeyTypes from '@hooks/useProductOptionKeyTypes'

interface ProductOptionDetailsProps {
  productOptionFormPayload: ReturnType<typeof useProductOptionForm>,
}

const ProductOptionDetails = (props: ProductOptionDetailsProps) => {
  const {
    productOptionFormPayload: {
      entityState: {
        description,
        displayType,
        isArchived,
        isSelectable,
        key,
        name,
      },
      errors,
      handlers,
    },
  } = props

  const { productOptionKeyTypes } = useProductOptionKeyTypes({
    performHttpRequests: true,
  })

  return (
    <DashboardModule title="Details">
      <Form padding="large">
        <Form.Row>
          <Form.Field errorMessage={errors.name} label="* Name">
            <input
              name="name"
              value={name}
              type="text"
              {...handlers}
            />
          </Form.Field>

          <Form.Field errorMessage={errors.displayType} label="* Display Type" marginTop={['large', 0]}>
            <select name="displayType" value={displayType} {...handlers}>
              <option value="Dropdown">Dropdown</option>
              <option value="MultiSelect">Multi Select</option>
            </select>
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field label="Archived">
            <input
              disabled
              value={isArchived ? 'Yes' : 'No'}
              type="text"
            />
          </Form.Field>

          <Form.Field label="Selectable" marginTop={['large', 0]}>
            <select data-value-type="boolean" name="isSelectable" value={isSelectable} {...handlers}>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </Form.Field>
        </Form.Row>

        <Form.Row marginTop="large">
          <Form.Field errorMessage={errors.description} label="Description">
            <textarea
              name="description"
              style={{ height: 120, resize: 'vertical' }}
              value={description}
              {...handlers}
            />
          </Form.Field>

          <Form.Field errorMessage={errors.key} label="* Key" marginTop={['large', 0]}>
            <select name="key" value={key} {...handlers}>
              <option value="">Please Select...</option>
              {productOptionKeyTypes.map(productOptionKeyType => (
                <option key={productOptionKeyType.id} value={productOptionKeyType.name}>{productOptionKeyType.displayName}</option>
              ))}
            </select>
          </Form.Field>
        </Form.Row>
      </Form>
    </DashboardModule>
  )
}

export default ProductOptionDetails
