import { Route, Routes } from 'react-router-dom'

import Overview from './screens/Overview'

const OrderEditRoutes = () => (
  <Routes>
    <Route path="/overview" element={<Overview />} />
    {/* <Route path="/creative" element={<Creative />} /> */}
    {/* <Route path="/campaigns" element={<Campaigns />} /> */}
    {/* <Route path="/reporting" element={<Reporting />} /> */}
  </Routes>
)

export default OrderEditRoutes
