import { useMemo } from 'react'

import type { UseFiltersPayload } from '@campaignhub/react-hooks'
import { useNumericParams, useModals, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import BulkCreateAdTemplateProductsModal from '@modals/BulkCreateAdTemplateProductsModal'
import BulkCreateProductOptionProductsModal from '@modals/BulkCreateProductOptionProductsModal'
import BulkCreateProductOptionValuesSubsetsModal from '@modals/BulkCreateProductOptionValuesSubsetsModal'
import DuplicateProductModal from '@modals/DuplicateProductModal'

import {
  AdTemplateProductModel, ProductModel, ProductOptionProductModel, ProductOptionValuesSubsetModel,
} from '@models/types'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    deleteAdTemplateProduct: (payload: HandleCallbackActionParams) => Promise<object>,
    deletePriceListItem: (payload: HandleCallbackActionParams) => Promise<object>,
    deleteProduct: (payload: HandleCallbackActionParams) => Promise<object>,
    deleteProductOptionProduct: (payload: HandleCallbackActionParams) => Promise<object>,
    showBulkCreateAdTemplateProductsModal: (payload: { adTemplateProduct: AdTemplateProductModel }) => void,
    showBulkCreateProductOptionProductsModal: (payload: { productOptionProduct: ProductOptionProductModel }) => void,
    showBulkCreateProductOptionValuesSubsetsModal: (payload: {
      productOptionValuesSubset: ProductOptionValuesSubsetModel,
    }) => void,
    showDuplicateProductModal: (payload: { product: ProductModel }) => void,
  },
}

const defaultState = {
  showBulkCreateAdTemplateProductsModal: false,
  showBulkCreateProductOptionProductsModal: false,
  showBulkCreateProductOptionValuesSubsetsModal: false,
  showDuplicateProductModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    BulkCreateAdTemplateProductsModal: {
      bulkCreateAdTemplateProducts: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showBulkCreateAdTemplateProductsModal: false }),
    },
    BulkCreateProductOptionProductsModal: {
      bulkCreateProductOptionProducts: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showBulkCreateProductOptionProductsModal: false }),
    },
    BulkCreateProductOptionValuesSubsetsModal: {
      bulkCreateProductOptionValuesSubsets: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showBulkCreateProductOptionValuesSubsetsModal: false }),
    },
    DuplicateProductModal: {
      closeModal: () => setState({ showDuplicateProductModal: false }),
      duplicateProduct: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Relations = () => {
  const { productId } = useNumericParams()

  const [state, setState] = useSetState(defaultState)
  const {
    showBulkCreateAdTemplateProductsModal,
    showBulkCreateProductOptionProductsModal,
    showBulkCreateProductOptionValuesSubsetsModal,
    showDuplicateProductModal,
  } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      deleteAdTemplateProduct: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deletePriceListItem: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteProduct: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteProductOptionProduct: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      setDefaultAdTemplateProduct: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      showBulkCreateAdTemplateProductsModal: (payload: { adTemplateProduct: AdTemplateProductModel }) => {
        setModalData('BulkCreateAdTemplateProductsModal', payload)
        setState({ showBulkCreateAdTemplateProductsModal: true })
      },
      showBulkCreateProductOptionProductsModal: (payload: { productOptionProduct: ProductOptionProductModel }) => {
        setModalData('BulkCreateProductOptionProductsModal', payload)
        setState({ showBulkCreateProductOptionProductsModal: true })
      },
      showBulkCreateProductOptionValuesSubsetsModal: (payload: {
        productOptionValuesSubset: ProductOptionValuesSubsetModel,
      }) => {
        setModalData('BulkCreateProductOptionValuesSubsetsModal', payload)
        setState({ showBulkCreateProductOptionValuesSubsetsModal: true })
      },
      showDuplicateProductModal: (payload: { product: ProductModel }) => {
        setModalData('DuplicateProductModal', payload)
        setState({ showDuplicateProductModal: true })
      },
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent productId={productId} />

        <BulkCreateAdTemplateProductsModal
          callbacks={callbacks('BulkCreateAdTemplateProductsModal', setState)}
          showModal={showBulkCreateAdTemplateProductsModal}
        />

        <BulkCreateProductOptionProductsModal
          callbacks={callbacks('BulkCreateProductOptionProductsModal', setState)}
          showModal={showBulkCreateProductOptionProductsModal}
        />

        <BulkCreateProductOptionValuesSubsetsModal
          callbacks={callbacks('BulkCreateProductOptionValuesSubsetsModal', setState)}
          showModal={showBulkCreateProductOptionValuesSubsetsModal}
        />

        <DuplicateProductModal
          callbacks={callbacks('DuplicateProductModal', setState)}
          showModal={showDuplicateProductModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Relations
