import { useMemo } from 'react'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['productOptionKeyTypes']

type UseProductOptionKeyTypesOptions = {
  performHttpRequests?: boolean,
}

function useProductOptionKeyTypes(options: UseProductOptionKeyTypesOptions) {
  const {
    performHttpRequests = false,
  } = options || {}

  const {
    updatedEntities: { productOptionKeyTypes: productOptionKeyTypesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading: loadingProductOptionKeyTypes } = useReduxAction(
    'productOptionKeyTypes',
    'loadProductOptionKeyTypes',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors?.length
      },
    },
  )

  const entityProductOptionKeyTypes = useSelector(reduxState => reduxState.entities.productOptionKeyTypes)

  const productOptionKeyTypes = useMemo(() => Object.values(entityProductOptionKeyTypes), [productOptionKeyTypesUpdatedAt]);

  return {
    loading: loadingProductOptionKeyTypes,
    productOptionKeyTypes,
  }
}

export default useProductOptionKeyTypes
