import { useForm } from '@campaignhub/react-hooks'
import type { UseFormOptions, UseFormPayload } from '@campaignhub/react-hooks'

import defaultFormState, { requiredFields } from '@models/property'
import type { PropertyModel } from '@models/types'

// eslint-disable-next-line
export const usePropertyForm = (property: Partial<PropertyModel>, options?: UseFormOptions): UseFormPayload => {
  const { validateOn } = options || {}

  const propertyForm = useForm(
    defaultFormState,
    { entity: property, requiredFields: [...requiredFields], validateOn },
    [property.id, property.cacheKey],
  )

  return {
    ...propertyForm,
  }
}
