import { useMemo } from 'react'

import { useModals, useNumericParams } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import PageContent from './components/PageContent'

const Details = () => {
  const { userId } = useNumericParams()

  const modalContext = useModals()
  const {
    callbacks: { },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      updateUser: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent userId={userId} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details
