import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ArtworkTemplateGroupLinkRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['artworkTemplateGroupLinks']

type ArtworkTemplateGroupLinkFilters = {
  string?: string,
  artworkTemplateGroupId?: number,
  entityType?: string,
}

type UseArtworkTemplateGroupLinksOptions = {
  filters?: ArtworkTemplateGroupLinkFilters,
  performHttpRequests?: boolean,
  requestOptions?: ArtworkTemplateGroupLinkRequestOptions,
}

function useArtworkTemplateGroupLinks(options: UseArtworkTemplateGroupLinksOptions) {
  const { filters = {}, requestOptions } = options
  const {
    entityType: filterEntityType,
  } = filters

  const {
    updatedEntities: { artworkTemplateGroupLinks: artworkTemplateGroupLinksUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { artworkTemplateGroupLinks } = useSelector(reduxState => reduxState.entities)

  const filteredArtworkTemplateGroupLinks = useMemo(() => {
    const filtered = Object.values(artworkTemplateGroupLinks).filter((artworkTemplateGroupLink) => {
      const { entityType } = artworkTemplateGroupLink

      const matchEntityType = entityType === filterEntityType
      const matchString = matchEntityType

      return matchString
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [artworkTemplateGroupLinksUpdatedAt, JSON.stringify(filters)])

  const filteredArtworkTemplateGroupLinksCount = filteredArtworkTemplateGroupLinks.length
  const hasFilteredArtworkTemplateGroupLinks = !!filteredArtworkTemplateGroupLinksCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredArtworkTemplateGroupLinksCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingArtworkTemplateGroupLinks } = useReduxAction(
    'artworkTemplateGroupLinks',
    'loadArtworkTemplateGroupLinks',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredArtworkTemplateGroupLinks,
    filteredArtworkTemplateGroupLinksCount,
    hasFilteredArtworkTemplateGroupLinks,
    loading: loadingArtworkTemplateGroupLinks,
  }
}

export default useArtworkTemplateGroupLinks
