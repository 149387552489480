import { Columns } from '@campaignhub/suit-theme'

import useClient from '@hooks/useClient'

import ClientUsers from '@components/ClientUsers'

import ClientPriceLists from '../ClientPriceLists'
import ClientTools from '../../../../components/ClientTools'
import PageLayout from '../../../../components/PageLayout'

interface PageContentProps {
  clientId: number,
}

const PageContent = (props: PageContentProps) => {
  const { clientId } = props

  const clientPayload = useClient({ id: clientId })

  return (
    <PageLayout clientPayload={clientPayload} activeTabBarItemKey="relations">
      <Columns.Main>
        <ClientPriceLists clientId={clientId} />

        <ClientUsers clientId={clientId} />
      </Columns.Main>

      <Columns.Sidebar>
        {/* <ClientTools clientPayload={clientPayload} /> */}
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent

