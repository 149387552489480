import {
  DashboardModule, Grid, Link,
} from '@campaignhub/suit-theme'

import useAsset from '@hooks/useAsset'
import useAssets from '@hooks/useAssets'

import MediaImage from './MediaImage'

interface MediaProps {
  filters: {
    assetTypeCategory: string,
    assetableId: number,
    assetableType: string,
  },
}

const Media = (props: MediaProps) => {
  const { filters } = props

  const assetsPayload = useAssets({
    filters,
    performHttpRequests: true,
  })

  const {
    filteredAssets,
    hasFilteredAssets,
    loading,
  } = assetsPayload

  const {
    callbacks: {
      manageMedia,
    },
  } = useAsset()

  return (
    <DashboardModule
      headerRight={(
        <Link
          onClick={() => manageMedia(filters)}
          textProps={{ fontSize: 'small' }}
        >
          Manage
        </Link>
      )}
      loading={loading}
      title="Media"
    >
      {!loading && (
        <>
          {!hasFilteredAssets && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>
                No Media present
              </DashboardModule.BlankState.Title>

              <DashboardModule.BlankState.Paragraph>
                Please click Manage to create some
              </DashboardModule.BlankState.Paragraph>
            </DashboardModule.BlankState>
          )}

          {hasFilteredAssets && (
            <DashboardModule.ScrollableSection padding="large">
              <Grid
                gridAutoRows="1fr"
                gridColumnGap="medium"
                gridRowGap="medium"
                gridTemplateColumns="repeat(auto-fill, minmax(120px, 1fr))"
                gridTemplateRows="repeat(auto-fill, minmax(80px, 1fr))"
              >
                {filteredAssets.map(image => (
                  <MediaImage image={image} key={image.id} />
                ))}
              </Grid>
            </DashboardModule.ScrollableSection>
          )}
        </>
      )}

    </DashboardModule>
  )
}

export default Media
