export type PriceListItemOptionValueModel = {
  cacheKey: number,
  id: number,
  isDefault: boolean,
  nameOverride?: string,
  price?: number,
  priceListItemOptionId: number,
  productOptionValueId: number,
  sort?: number,
}

export type PriceListItemOptionValueRequestOptions = {}

const state = {
  descriptionOverride: '',
  id: null,
  isDefault: false,
  nameOverride: '',
  price: undefined,
  priceListItemOptionId: undefined,
  productOptionValueId: undefined,
  sort: undefined,
}

export const requiredFields = [
  {
    key: 'price',
    validation: (price: number) => price === undefined || price > 0,
    invalidMessage: 'Invalid Price',
  },
  { key: 'priceListItemOptionId' },
  { key: 'productOptionValueId' },
]

export default state
