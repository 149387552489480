import { Route, Routes } from 'react-router-dom'

import ArtworkTemplateGroupEdit from './Edit'
import ArtworkTemplateGroupsList from './List'

const ArtworkTemplateGroupsRoutes = () => (
  <Routes>
    <Route path="/" element={<ArtworkTemplateGroupsList />} />
    <Route path="/:artworkTemplateGroupId/edit/*" element={<ArtworkTemplateGroupEdit />} />
  </Routes>
)

export default ArtworkTemplateGroupsRoutes
