export type ExternalPlatformEntityModel = {
  cacheKey: number,
  id: number,
  externalPlatformId: number,
  entityType: string,
  entityId: number,
  value: string,
}

export type ExternalPlatformEntityRequestOptions = {}

const state = {
  entityId: '',
  entityType: '',
  externalPlatformId: '',
  id: null,
  value: '',
}

export const requiredFields = [
  { key: 'entityId' },
  { key: 'entityType' },
  { key: 'externalPlatformId' },
  { key: 'value' },
]

export default state
