import { useNumericParams } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import BulkCreatePriceListItemOptionValuesModal from '@modals/BulkCreatePriceListItemOptionValuesModal'
import BulkCreatePriceListItemOptionsModal from '@modals/BulkCreatePriceListItemOptionsModal'

import usePriceListManager from './components/PriceListManager/hooks/usePriceListManager'

import PriceListManager from './components/PriceListManager'

const Items = () => {
  const { priceListId } = useNumericParams()

  const priceListManagerPayload = usePriceListManager(priceListId)
  const {
    callbacks: {
      modalCallbacks,
    },
    modalContext,
    state: {
      showBulkCreatePriceListItemOptionValuesModal,
      showBulkCreatePriceListItemOptionsModal,
    },
  } = priceListManagerPayload

  return (
    <PageContext.Provider value={priceListManagerPayload}>
      <ModalContext.Provider value={modalContext}>
        <PriceListManager priceListManagerPayload={priceListManagerPayload} />

        <BulkCreatePriceListItemOptionsModal
          callbacks={modalCallbacks('BulkCreatePriceListItemOptionsModal')}
          showModal={showBulkCreatePriceListItemOptionsModal}
        />

        <BulkCreatePriceListItemOptionValuesModal
          callbacks={modalCallbacks('BulkCreatePriceListItemOptionValuesModal')}
          showModal={showBulkCreatePriceListItemOptionValuesModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Items
