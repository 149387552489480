import { useContext } from 'react'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Button, EntityMultiSelect, Form, ModalContext, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useBulkCreateClientPriceLists from './hooks/useBulkCreateClientPriceLists'

const MODAL_KEY = 'BulkCreateClientPriceListsModal'

type ModalCallbacks = {
  bulkCreateClientPriceLists: (payload: HandleCallbackActionParams) => void,
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const BulkCreateClientPriceListsModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const {
    bulkCreateClientPriceLists,
    closeModal,
  } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const clientPriceList = digObject(modalPayload, 'clientPriceList', {})

  const {
    callbacks: {
      bulkCreateFn,
      setSelectedEntityIds,
      setState,
    },
    creating,
    entityParams,
    filteredClientPriceListIds,
    filteredClientPriceListIdsCount,
    name,
    priceLists,
    priceListsPayload: {
      callbacks: {
        loadMore,
      },
      canLoadMore,
      loading,
    },
    saveEnabled,
    selectedEntityIds,
  } = useBulkCreateClientPriceLists(clientPriceList)

  const entityText = clientPriceList.priceListId ? 'Clients' : 'Price Lists'

  const bulkCreateClientPriceListsPayload = {
    callbacks: {
      action: bulkCreateFn,
      afterAction: closeModal,
    },
    entityParams,
    toastText: `${entityText} Assigned`,
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Assign" titleSecondLine={entityText} />

      <SidebarModal.Content>
        <Form.Field label="Search Price Lists">
          <input onChange={e => setState({ name: e.target.value })} value={name} />
        </Form.Field>

        <SectionDivider>Multi Select Values</SectionDivider>

        <EntityMultiSelect
          boxProps={{ marginTop: 'large' }}
          callbacks={{
            loadMore,
            setSelectedIds: setSelectedEntityIds,
          }}
          canLoadMore={canLoadMore}
          entities={priceLists}
          entityCount={filteredClientPriceListIdsCount}
          entityTitleKey="name"
          filteredEntityIds={filteredClientPriceListIds}
          loading={loading}
          selectedIds={selectedEntityIds}
        />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => bulkCreateClientPriceLists(bulkCreateClientPriceListsPayload)}
          size="large"
        >
          {saveEnabled ? `Assign ${entityText}` : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <BulkCreateClientPriceListsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
