import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as adAccountActions from '@redux/modules/adAccount'
import type {
  CreateAndLinkAdAccountsParams as CreateAndLinkParams,
  LinkAdAccountParams as LinkParams,
} from '@redux/modules/adAccount'

import defaultFormState, { requiredFields } from '@models/adAccount'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { AdAccountModel, ClientModel, UserModel } from '@models/types'

type CreateAndLinkAdAccountParams = {
  dispatch: AppDispatch,
  createAndLinkParams: CreateAndLinkParams,
}

const createAndLinkAdAccounts = (params: CreateAndLinkAdAccountParams) => {
  const { dispatch, createAndLinkParams } = params
  const { createAndLinkAdAccounts: createAndLinkFn } = adAccountActions

  return dispatch(createAndLinkFn(createAndLinkParams))
}

type LinkAdAccountParams = {
  dispatch: AppDispatch,
  linkParams: LinkParams,
}

const linkAdAccount = (params: LinkAdAccountParams) => {
  const { dispatch, linkParams } = params
  const { linkAdAccount: linkFn } = adAccountActions

  return dispatch(linkFn(linkParams))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useAdAccountForm(
  adAccount: Partial<AdAccountModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const adAccountForm = useForm(
    defaultFormState,
    { entity: adAccount, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [adAccount.id, adAccount.cacheKey],
  )

  return {
    ...adAccountForm,
  }
}

export type LinkAdAccountsModalPayload = {
  adAccountType: 'FacebookAdAccount' | 'FacebookPage' | 'GoogleAdAccount',
  adChannel: 'facebook' | 'google',
  linkableEntity: ClientModel | UserModel,
  linkableType: 'Client' | 'User',
}

export type SelectAdChannelModalPayload = {
  linkableEntity: ClientModel | UserModel,
  linkableType: 'Client' | 'User',
}

function useAdAccount(initEntity: Partial<AdAccountModel> = {}) {
  const { entity: adAccount }: { entity: AdAccountModel } = useLatestEntity(initEntity, 'adAccounts')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { creating, loading } = useSelector(reduxState => reduxState.adAccounts)

  return {
    adAccount,
    callbacks: {
      createAndLinkAdAccounts: (createAndLinkParams: CreateAndLinkParams) => createAndLinkAdAccounts({ dispatch, createAndLinkParams }),
      launchLinkAdAccountsModal: (customPayload: LinkAdAccountsModalPayload) => launchModal({
        callbacks,
        modalKey: 'LinkAdAccountsModal',
        payload: customPayload,
      }),
      launchSelectAdChannelModal: (customPayload: SelectAdChannelModalPayload) => launchModal({
        callbacks,
        modalKey: 'SelectAdChannelModal',
        payload: customPayload,
      }),
      linkAdAccount: (linkParams: LinkParams) => linkAdAccount({ dispatch, linkParams }),
    },
    creating,
    loading,
  }
}

export default useAdAccount
