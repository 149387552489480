import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import realhubUrl from '@functions/realhubUrl'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as artworkTemplateActions from '@redux/modules/artworkTemplate'

import defaultFormState, { requiredFields } from '@models/artworkTemplate'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { ArtworkTemplateModel, ArtworkTemplateRequestOptions } from '@models/types'

export const generateUrls = (artworkTemplate?: Partial<ArtworkTemplateModel>) => {
  const { id, realhubId } = artworkTemplate || {}

  return {
    editArtworkTemplateUrl: `#/artworkTemplates/${id}/edit/`,
    artworkTemplatesIndexUrl: '#/artworkTemplates',
    manageArtworkTemplateUrl: `${realhubUrl}/admin/templates/manage#/edit-page/${realhubId}`,
  }
}

type CreateArtworkTemplateParams = {
  artworkTemplateParams: Partial<ArtworkTemplateModel>,
  dispatch: AppDispatch,
  requestOptions?: ArtworkTemplateRequestOptions,
}

const createArtworkTemplate = (params: CreateArtworkTemplateParams) => {
  const { dispatch, artworkTemplateParams, requestOptions } = params
  const { createArtworkTemplate: createFn } = artworkTemplateActions

  return dispatch(createFn(artworkTemplateParams, requestOptions))
}

type UpdateArtworkTemplateParams = {
  artworkTemplate: ArtworkTemplateModel,
  artworkTemplateParams: Partial<ArtworkTemplateModel>,
  dispatch: AppDispatch,
  requestOptions?: ArtworkTemplateRequestOptions,

}

const updateArtworkTemplate = (params: UpdateArtworkTemplateParams) => {
  const {
    dispatch, artworkTemplate, artworkTemplateParams, requestOptions,
  } = params
  const { updateArtworkTemplate: updateFn } = artworkTemplateActions

  const updatedParams = {
    id: artworkTemplate.id,
    ...artworkTemplateParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteArtworkTemplateParams = {

  artworkTemplate: DeleteParams<ArtworkTemplateModel>,
  dispatch: AppDispatch,

}

const deleteArtworkTemplate = (params: DeleteArtworkTemplateParams) => {
  const { dispatch, artworkTemplate } = params
  const { deleteArtworkTemplate: deleteFn } = artworkTemplateActions

  return dispatch(deleteFn(artworkTemplate))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useArtworkTemplateForm(
  artworkTemplate: Partial<ArtworkTemplateModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}
  const artworkTemplateForm = useForm(
    defaultFormState,
    { entity: artworkTemplate, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [artworkTemplate.id, artworkTemplate.cacheKey],
  )

  return {
    ...artworkTemplateForm,
  }
}

export const useRelations = (artworkTemplate: Partial<ArtworkTemplateModel> = {}) => {
  const { related_entity_id } = artworkTemplate

  const { relatedEntities } = useSelector(reduxState => reduxState.entities)

  const relatedEntity = related_entity_id && relatedEntities[related_entity_id] ? relatedEntities[related_entity_id] : {}

  return {
    relatedEntity,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ArtworkTemplateRequestOptions,
}

function useArtworkTemplate(initEntity: Partial<ArtworkTemplateModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: artworkTemplate }: { entity: ArtworkTemplateModel } = useLatestEntity(initEntity, 'artworkTemplates')
  const { id } = artworkTemplate

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  // const { relatedEntity } = useRelations(artworkTemplate)

  useReduxAction(
    'artworkTemplates',
    'loadArtworkTemplate',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.artworkTemplates)

  return {
    callbacks: {
      createArtworkTemplate: (artworkTemplateParams: Partial<ArtworkTemplateModel>, entityOptions?: ArtworkTemplateRequestOptions) => (
        createArtworkTemplate({ artworkTemplateParams, dispatch, requestOptions: entityOptions })
      ),
      createOrEditArtworkTemplate: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditArtworkTemplateModal',
        payload: { artworkTemplate },
      }),
      deleteArtworkTemplate: () => deleteArtworkTemplate({ artworkTemplate, dispatch }),
      updateArtworkTemplate: (artworkTemplateParams: Partial<ArtworkTemplateModel>, entityOptions?: ArtworkTemplateRequestOptions) => (
        updateArtworkTemplate({
          artworkTemplate, artworkTemplateParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    artworkTemplate,
    creating,
    deleting,
    loading,
    updating,
    urls: generateUrls(artworkTemplate),

  }
}

export default useArtworkTemplate
