import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { OrderItemModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

import { removeOrderItemOptionValue } from './orderItemOptionValue'

const FETCH_REQUEST = 'realbase/orderItem/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/orderItem/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/orderItem/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/orderItem/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/orderItem/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/orderItem/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/orderItem/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/orderItem/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/orderItem/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/orderItem/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/orderItem/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/orderItem/DELETE_FAILURE'

export type OrderItemModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: OrderItemModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadOrderItems(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().orderItems.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/order-items', options)
      .then(({ data }: { data: { data: OrderItemModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.ORDER_ITEM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadOrderItem(orderItemId: number, options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.orderItems.loadedIds]
    const loadedForKeys = [...state.orderItems.loadedForKeys]

    if (orderItemId && !loadedIds.includes(orderItemId)){
      loadedIds.push(orderItemId)
    }

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/order-items/${orderItemId}`, options)
      .then(({ data }: { data: OrderItemModel }) => {
        const normalizedJson = normalize(data, Schemas.ORDER_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createOrderItem(orderItem: Partial<OrderItemModel>, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      ...orderItem,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/order-items', options, config)
      .then(({ data }: { data: OrderItemModel }) => {
        const normalizedJson = normalize(data, Schemas.ORDER_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateOrderItem(
  orderItem: UpdateParams<OrderItemModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      ...orderItem,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/order-items/${orderItem.id}`, options, config)
      .then(({ data }: { data: OrderItemModel }) => {
        const normalizedJson = normalize(data, Schemas.ORDER_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteOrderItem(orderItem: DeleteParams<OrderItemModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/order-items/${orderItem.id}`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        const deletedEntity = { id: orderItem.id }
        const normalizedJson = normalize(deletedEntity, Schemas.ORDER_ITEM)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true as const, data: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export type PatchParams = {
  orderItemId: number,
  priceListItemOptionValues: [
    {
      priceListItemOptionId: number,
      priceListItemOptionValueIds: number[],
    },
  ]
}

export function patchOrderItem(params: PatchParams) {
  const { orderItemId, priceListItemOptionValues } = params

  const config = {
    method: 'PATCH',
    data: JSON.stringify(priceListItemOptionValues)
  }

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    dispatch(updateRequest())

    const promise = api(`/order-items/${orderItemId}/price-list-item-option-values`, {}, config)
      .then(({ data }: { data: OrderItemModel }) => {
        // Delete all OrderItemOptionValues because some will be deleted on backend
        // New OrderItemOptionValues will be returned on the request
        const { orderItemOptionValues } = getState().entities

        Object.values(orderItemOptionValues).forEach(orderItemOptionValue => {
          removeOrderItemOptionValue(dispatch, orderItemOptionValue)
        })

        const normalizedJson = normalize(data, Schemas.ORDER_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export type FindOrImportParams = {
  externalCampaignId: number,
  externalOrderId: number,
  externalOrderItemId: number,
  externalPlatformTypeId: number,
  externalUserId: number,
  orderType: string,
}

export function findOrImportOrderItem(params: FindOrImportParams) {
  const config = {
    method: 'POST',
    data: JSON.stringify(params),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/realbase/order-items/import', {}, config)
      .then(({ data }: { data: OrderItemModel }) => {
        const normalizedJson = normalize(data, Schemas.ORDER_ITEM)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: OrderItemModuleState = initialState,
  action: Action = { type: '' },
): OrderItemModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
