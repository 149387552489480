import { faPencil } from '@fortawesome/pro-light-svg-icons'

import { Box, Button, Columns } from '@campaignhub/suit-theme'

import useCampaign, { generateUrls } from '@hooks/useCampaign'

import Documents from '@components/Documents'
import Media from '@components/Media'
import PageHeader from '@components/PageHeader'

import Agents from '../Agents'
import PropertyAddress from '../PropertyAddress'
import PropertyDetails from '../PropertyDetails'
import Tools from '../Tools'

interface PageContentProps {
  campaignId: number,
}

const PageContent = (props: PageContentProps) => {
  const { campaignId } = props

  const { campaignDetailUrl } = generateUrls({ id: campaignId })

  const campaignPayload = useCampaign({ id: campaignId })

  return (
    <>
      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" size="medium">
            Publish Site
          </Button>
        )}
        activeTabBarItemKey="details"
        nestedNavigation
        tabBarItems={[
          {
            href: campaignDetailUrl,
            icon: faPencil,
            key: 'details',
            title: 'Site Details',
          },
        ]}
        title="Site Details"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <PropertyDetails campaignPayload={campaignPayload} />

            <Media
              filters={{
                assetTypeCategory: 'Image',
                assetableId: campaignId,
                assetableType: 'Campaign',
              }}
            />
          </Columns.Main>

          <Columns.Sidebar>
            <Tools campaignPayload={campaignPayload} />

            <PropertyAddress /> {/* TODO: pass property entity */}

            <Agents campaignPayload={campaignPayload} />

            <Documents
              filters={{
                assetTypeCategory: 'Document',
                assetableId: campaignId,
                assetableType: 'Campaign',
              }}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
