import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { Box, Button, Columns } from '@campaignhub/suit-theme'

import usePriceList from '@hooks/usePriceList'

import PageHeader from '@components/PageHeader'

import generateTabBarItems from '../../utils/generateTabBarItems'

interface PageLayoutProps {
  activeTabBarItemKey?: string,
  children: ReactNode,
  priceListPayload: ReturnType<typeof usePriceList>,
}

const PageLayout = (props: PageLayoutProps) => {
  const {
    activeTabBarItemKey,
    children,
    priceListPayload: {
      priceList,
      urls: { priceListsIndexUrl },
    },
  } = props

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  return (
    <>
      <Helmet>
        <title>Edit Price List | AIM Digital</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button
            as="a"
            buttonStyle="secondaryUtility"
            href={redirect || priceListsIndexUrl}
            size="medium"
            width="auto"
          >
            Back
          </Button>
        )}
        activeTabBarItemKey={activeTabBarItemKey}
        tabBarItems={generateTabBarItems(priceList)}
        title="Edit Price List"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          {children}
        </Columns>
      </Box>
    </>
  )
}

export default PageLayout
