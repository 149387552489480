import { AdAccountModel, AdAccountLinkModel } from '@models/types'

type CategoriseAdAccountLinksParams = {
  adAccounts: { [key: number]: AdAccountModel },
  adAccountLinks: AdAccountLinkModel[],
}

type Categorised = {
  facebook: AdAccountLinkModel[],
  google: AdAccountLinkModel[],
}

export const categoriseAdAccountLinks = (params: CategoriseAdAccountLinksParams) => {
  const { adAccounts, adAccountLinks } = params

  const categorised = adAccountLinks.reduce<Categorised>((acc, adAccountLink) => {
    const { adAccountId } = adAccountLink
    const adAccount = adAccounts[adAccountId]
    const { adAccountType } = adAccount

    if (adAccountType.includes('Facebook')){
      acc.facebook.push(adAccountLink)
    }

    if (adAccountType.includes('Google')){
      acc.google.push(adAccountLink)
    }


    return acc
  }, { facebook: [], google: [] })

  return categorised
}