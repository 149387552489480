import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as productOptionProductActions from '@redux/modules/productOptionProduct'
import type { BulkCreateParams } from '@redux/modules/productOptionProduct'

import defaultFormState, { requiredFields } from '@models/productOptionProduct'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { ProductOptionProductModel, ProductOptionProductRequestOptions } from '@models/types'

type BulkCreateProductOptionProductParams = {
  bulkCreateParams: BulkCreateParams,
  dispatch: AppDispatch,
  requestOptions?: ProductOptionProductRequestOptions,
}

const bulkCreateProductOptionProduct = (params: BulkCreateProductOptionProductParams) => {
  const { bulkCreateParams, dispatch, requestOptions } = params
  const { bulkCreateProductOptionProduct: bulkCreateFn } = productOptionProductActions

  return dispatch(bulkCreateFn(bulkCreateParams, requestOptions))
}

type UpdateProductOptionProductParams = {
  dispatch: AppDispatch,
  productOptionProduct: ProductOptionProductModel,
  productOptionProductParams: Partial<ProductOptionProductModel>,
  requestOptions?: ProductOptionProductRequestOptions,
}

const updateProductOptionProduct = (params: UpdateProductOptionProductParams) => {
  const {
    dispatch, productOptionProduct, productOptionProductParams, requestOptions,
  } = params
  const { updateProductOptionProduct: updateFn } = productOptionProductActions

  const updatedParams = {
    id: productOptionProduct.id,
    ...productOptionProductParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteProductOptionProductParams = {
  dispatch: AppDispatch,
  productOptionProduct: DeleteParams<ProductOptionProductModel>,
}

const deleteProductOptionProduct = (params: DeleteProductOptionProductParams) => {
  const { dispatch, productOptionProduct } = params
  const { deleteProductOptionProduct: deleteFn } = productOptionProductActions

  return dispatch(deleteFn(productOptionProduct))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useProductOptionProductForm(
  productOptionProduct: Partial<ProductOptionProductModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const productOptionProductForm = useForm(
    defaultFormState,
    { entity: productOptionProduct, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [productOptionProduct.id, productOptionProduct.cacheKey],
  )

  return {
    ...productOptionProductForm,
  }
}

export const useRelations = (productOptionProduct: Partial<ProductOptionProductModel> = {}) => {
  const { productId, productOptionId } = productOptionProduct

  const { products, productOptions } = useSelector(reduxState => reduxState.entities)

  const product = productId && products[productId] ? products[productId] : {}
  const productOption = productOptionId && productOptions[productOptionId] ? productOptions[productOptionId] : {}

  return {
    product,
    productOption,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ProductOptionProductRequestOptions,
}

function useProductOptionProduct(initEntity: Partial<ProductOptionProductModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: productOptionProduct }: { entity: ProductOptionProductModel } = useLatestEntity(
    initEntity,
    'productOptionProducts',
  )
  const { id } = productOptionProduct

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { product, productOption } = useRelations(productOptionProduct)

  useReduxAction(
    'productOptionProducts',
    'loadProductOptionProduct',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.productOptionProducts)

  return {
    callbacks: {
      bulkCreateProductOptionProducts: (
        bulkCreateParams: BulkCreateParams,
        entityOptions?: ProductOptionProductRequestOptions,
      ) => (
        bulkCreateProductOptionProduct({ bulkCreateParams, dispatch, requestOptions: entityOptions })
      ),
      deleteProductOptionProduct: () => deleteProductOptionProduct({ productOptionProduct, dispatch }),
      launchBulkCreateProductOptionProductsModal: () => launchModal({
        callbacks,
        modalKey: 'BulkCreateProductOptionProductsModal',
        payload: { productOptionProduct },
      }),
      updateProductOptionProduct: (
        productOptionProductParams: Partial<ProductOptionProductModel>,
        entityOptions?: ProductOptionProductRequestOptions,
      ) => (
        updateProductOptionProduct({
          productOptionProduct, productOptionProductParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    product,
    productOption,
    productOptionProduct,
    updating,
  }
}

export default useProductOptionProduct
