import { useContext } from 'react'

import {
  Button, EntityMultiSelect, Form, ModalContext, SidebarModal, SectionDivider,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useBulkCreateProductOptionProductsModal from './hooks/useBulkCreateProductOptionProductsModal'

const MODAL_KEY = 'BulkCreateProductOptionProductsModal'

type ModalCallbacks = {
  bulkCreateProductOptionProducts: (payload: HandleCallbackActionParams) => void,
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const BulkCreateProductOptionProductsModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { bulkCreateProductOptionProducts, closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const productOptionProduct = digObject(modalPayload, 'productOptionProduct', {})

  const { productId } = productOptionProduct
  const isShowingProductOptions = !!productId

  const {
    callbacks: {
      bulkCreateProductOptionProducts: bulkCreateFn,
      setSelectedIds,
      setState,
    },
    creating,
    entityParams,
    filteredEntityIds,
    filteredEntityIdsCount,
    entityName,
    entities,
    productOptionsPayload: {
      callbacks: {
        loadMore,
      },
      canLoadMore,
      loading,
    },
    saveEnabled,
    selectedIds,
  } = useBulkCreateProductOptionProductsModal({ productOptionProduct })

  const bulkCreateProductOptionProductPayload = {
    callbacks: {
      action: bulkCreateFn,
      afterAction: closeModal,
    },
    entityParams,
    toastText: isShowingProductOptions ? 'Added Product Options' : 'Added Products',
  }

  const buttonText = isShowingProductOptions ? 'Add Product Options' : 'Add Products'

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title="Assign"
        titleSecondLine={isShowingProductOptions ? 'Product Options' : 'Products'}
      />

      <SidebarModal.Content>
        <Form.Field label={isShowingProductOptions ? 'Search Product Options' : 'Search Products'}>
          <input onChange={e => setState({ entityName: e.target.value })} value={entityName} />
        </Form.Field>

        <SectionDivider>Multi Select Values</SectionDivider>

        <EntityMultiSelect
          callbacks={{
            loadMore,
            setSelectedIds,
          }}
          canLoadMore={canLoadMore}
          entities={entities}
          entityCount={filteredEntityIdsCount}
          entityTitleKey="name"
          filteredEntityIds={filteredEntityIds}
          loading={loading}
          selectedIds={selectedIds}
        />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => bulkCreateProductOptionProducts(bulkCreateProductOptionProductPayload)}
          size="large"
        >
          {saveEnabled ? buttonText : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <BulkCreateProductOptionProductsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
