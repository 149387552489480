import { useContext } from 'react'

import {
  Button,
  Form,
  ModalContext,
  SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useArtworkTemplate, { useArtworkTemplateForm } from '@hooks/useArtworkTemplate'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import EntitySelector from '@components/EntitySelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowFromLeft, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { confirmDelete } from '@functions/entity'

const MODAL_KEY = 'CreateOrEditArtworkTemplateModal'

type ModalCallbacks = {
  closeModal: () => void,
  createArtworkTemplate: (payload: HandleCallbackActionParams) => void,
  updateArtworkTemplate: (payload: HandleCallbackActionParams) => void,
  selectItem: (selected: { id: number }) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateOrEditArtworkTemplateModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const {
    closeModal,
    createArtworkTemplate,
    updateArtworkTemplate,
  } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const artworkTemplate = digObject(modalPayload, 'artworkTemplate', {})
  const newRecord = !artworkTemplate.id

  const {
    callbacks: {
      createArtworkTemplate: createFn,
      updateArtworkTemplate: updateFn,
      deleteArtworkTemplate: deleteFn,
    },
    creating,
    updating,
    urls: {
      manageArtworkTemplateUrl,
    },
  } = useArtworkTemplate(artworkTemplate)

  const {
    entityState,
    entityState: {
      realhubId,
      cutterProfileId,
    },
    handlers,
    saveEnabled,
    setEntityState,
  } = useArtworkTemplateForm(artworkTemplate, { validateOn: 'change' })

  const createOrUpdateArtworkTemplatePayload = {
    callbacks: {
      action: newRecord ? createFn : updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: `Artwork Template ${newRecord ? 'Created' : 'Updated'}`,
  }
  const confirmDeletePayload = {
    callbacks: {
      deleteFn,
      deleteEntity: deleteFn,
    },
    strings: {
      entityName: 'Artwork Template',
    },
  }


  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title={newRecord ? 'Create' : 'Edit'} titleSecondLine="Artwork Template" />

      <SidebarModal.Content>
        <Form>
          {newRecord && (
            <EntitySelector
              callbacks={{
                selectItem: (template) => setEntityState({
                  realhubId: template ? template.id : null,
                }),
              }}
              entityKey="realhubArtworkTemplates"
              entityTitleKey="name"
              selectedIds={[realhubId]}
              selectedItemId={realhubId}
              {...handlers}
            />
          )}

          <EntitySelector
            marginTop="large"
            callbacks={{
              selectItem: profile => setEntityState({
                cutterProfileId: profile ? profile.id : null,
              }),
            }}
            entityKey="cutterProfiles"
            entityTitleKey="name"
            selectedIds={[cutterProfileId]}
            selectedItemId={cutterProfileId}
            {...handlers}
          />

          {!newRecord && (
            <Form.Field label="More Options" marginTop="large">
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faArrowFromLeft} />}
                loading={creating}
                as="a"
                href={manageArtworkTemplateUrl}
                size="medium"
              >
                Go to Template Manager
              </Button>
              <Button
                buttonStyle="secondaryDestroy"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                loading={creating}
                onClick={() => confirmDelete(confirmDeletePayload)}
                size="medium"
                marginTop="medium"
              >
                Remove Artwork Template
              </Button>
            </Form.Field>
          )}
        </Form>

      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          loadingBubbleColor="white"
          onClick={
            newRecord
              ? () => createArtworkTemplate(createOrUpdateArtworkTemplatePayload)
              : () => updateArtworkTemplate(createOrUpdateArtworkTemplatePayload)
          }
          size="large"
        >
          {saveEnabled ? `${newRecord ? 'Create' : 'Update'} Artwork Template` : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditArtworkTemplateModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
