import { Columns, LoadingModule } from '@campaignhub/suit-theme'

import usePriceListItems from '@hooks/usePriceListItems'
import usePriceListManager from '../../hooks/usePriceListManager'

import AddProduct from './AddProduct'
import PriceListItemDetails from './PriceListItemDetails'
import PriceListItemList from './PriceListItemList'
import PriceListItemOptionDetails from './PriceListItemOptionDetails'

interface EditorProps {
  loading: boolean,
  priceListManagerPayload: ReturnType<typeof usePriceListManager>,
}

const Editor = (props: EditorProps) => {
  const {
    loading,
    priceListManagerPayload: {
      priceListPayload: {
        priceList,
      },
      selectedPriceListItem,
      selectedPriceListItemOption,
    },
  } = props

  const priceListItemsPayload = usePriceListItems({ filters: { priceListId: priceList.id } })

  return (
    <>
      <Columns.Sidebar>
        <LoadingModule loading={loading} times={3} />

        <PriceListItemList priceListItemsPayload={priceListItemsPayload} />

        <AddProduct priceList={priceList} priceListItemsPayload={priceListItemsPayload} />
      </Columns.Sidebar>

      <Columns.Main
        boxProps={{
          marginLeft: [0, 16],
          marginRight: 0,
          width: ['100%', 'calc(100% - 375px)'],
        }}
      >
        {!!selectedPriceListItem.id && (
          <PriceListItemDetails priceListItem={selectedPriceListItem} />
        )}

        {!!selectedPriceListItemOption.id && (
          <PriceListItemOptionDetails priceListItemOption={selectedPriceListItemOption} />
        )}
      </Columns.Main>
    </>
  )
}

export default Editor
