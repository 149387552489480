import { useMemo } from 'react'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { CampaignUserRequestOptions } from '@models/types'

const watchEntityKeys = ['campaignUsers']

export type CampaignUserFilters = {
  campaignId?: number,
}

type UseCampaignUsersOptions = {
  filters?: CampaignUserFilters,
  performHttpRequests?: boolean,
  requestOptions?: CampaignUserRequestOptions,
}

function useCampaignUsers(options: UseCampaignUsersOptions) {
  const { filters = {}, requestOptions } = options
  const {
    campaignId: filterCampaignId,
  } = filters

  const {
    updatedEntities: { campaignUsers: campaignUsersUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { campaignUsers } = useSelector(reduxState => reduxState.entities)

  const filteredCampaignUsers = useMemo(() => {
    const filtered = Object.values(campaignUsers).filter((campaignUser) => {
      const { campaignId } = campaignUser

      const matchCampaignId = campaignId === filterCampaignId

      return matchCampaignId
    })

    return sortArrayBy(filtered, 'asc', ({ sort }) => sort || Infinity)
  }, [campaignUsersUpdatedAt, JSON.stringify(filters)])

  const leadCampaignUsers = filteredCampaignUsers.filter(campaignUser => !campaignUser.support)
  const supportCampaignUsers = filteredCampaignUsers.filter(campaignUser => campaignUser.support)

  const filteredCampaignUsersCount = filteredCampaignUsers.length
  const hasFilteredCampaignUsers = !!filteredCampaignUsersCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredCampaignUsersCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingCampaignUsers } = useReduxAction(
    'campaignUsers',
    'loadCampaignUsers',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCampaignUsers,
    filteredCampaignUsersCount,
    hasFilteredCampaignUsers,
    leadCampaignUsers,
    loading: loadingCampaignUsers,
    supportCampaignUsers,
  }
}

export default useCampaignUsers
