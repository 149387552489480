import { useContext } from 'react'

import { launchModal } from '@campaignhub/javascript-utils'

import { useLatestEntity } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'
import type { CampaignUserFilters } from '@hooks/useCampaignUsers'

import type { CampaignUserModel, CampaignUserRequestOptions } from '@models/types'

import * as campaignUserActions from '@redux/modules/campaignUser'

import type { AppDispatch } from '@redux/store'

type BulkCreatePayload = {
  campaignId: number,
  leadUserIds: number[],
  supportUserIds: number[],
}

type BulkCreateCampaignUsersParams = {
  dispatch: AppDispatch,
  payload: BulkCreatePayload,
  requestOptions: CampaignUserRequestOptions,
}

const bulkCreateCampaignUsers = (params: BulkCreateCampaignUsersParams) => {
  const { dispatch, payload, requestOptions } = params
  const { bulkCreateCampaignUsers: bulkCreateFn } = campaignUserActions

  return dispatch(bulkCreateFn(payload, requestOptions))
}

export const useRelations = (entity: CampaignUserModel) => {
  const { userId } = entity

  const users = useSelector(reduxState => reduxState.entities.users)

  const user = users[userId] || {}

  return {
    user,
  }
}

function useCampaignUser(initEntity: Partial<CampaignUserModel> = {}) {
  const { entity: campaignUser }: { entity: CampaignUserModel} = useLatestEntity(initEntity, 'campaignUsers')

  const dispatch = useDispatch()

  const { loading } = useSelector(reduxState => reduxState.campaignUsers)

  const { callbacks } = useContext(PageContext)

  return {
    callbacks: {
      bulkCreateCampaignUsers: (payload: BulkCreatePayload, requestOptions: CampaignUserRequestOptions) => (
        bulkCreateCampaignUsers({ payload, dispatch, requestOptions })
      ),
      manageCampaignUsers: (filters?: CampaignUserFilters) => launchModal({
        callbacks,
        modalKey: 'ManageCampaignUsersModal',
        payload: { filters },
      }),
    },
    campaignUser,
    loading,
  }
}

export default useCampaignUser
