const CHANGE_PARAM = 'realbase/navigation/CHANGE_PARAM'

type ReducerAction = {
  name: string,
  type: string,
  value?: any,
}

type InitialReducerState = {
  showNavigation: boolean,
}

const initialState = {
  showNavigation: false,
}

// Actions
export function changeParam(name: string, value: any) {
  return {
    type: CHANGE_PARAM,
    name,
    value,
  }
}

// Reducer
export default function reducer(
  state: InitialReducerState = initialState,
  action: ReducerAction = { name: '', type: '' },
) {
  const { name, type, value } = action

  switch (type){
    case CHANGE_PARAM:
      return {
        ...state,
        [name]: value,
      }
    default:
      return state
  }
}
