import { useMemo } from 'react'

import type { UseFiltersPayload } from '@campaignhub/react-hooks'
import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import BulkCreateArtworkTemplateGroupLinkModal from '@modals/BulkCreateArtworkTemplateGroupLinkModal'

import { ArtworkTemplateGroupLinkModel, ClientPriceListModel } from '@models/types'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    deleteArtworkTemplateGroupLink: (payload: HandleCallbackActionParams) => Promise<object>,
    showBulkCreateArtworkTemplateGroupLinksModal: () => void,
  },
}

const defaultState = {
  showBulkCreateArtworkTemplateGroupLinksModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    BulkCreateArtworkTemplateGroupLinksModal: {
      bulkCreateArtworkTemplateGroupLinks: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showBulkCreateArtworkTemplateGroupLinksModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const Relations = () => {
  const { artworkTemplateGroupId } = useNumericParams()

  const [state, setState] = useSetState(defaultState)
  const {
    showBulkCreateArtworkTemplateGroupLinksModal,
  } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      deleteArtworkTemplateGroupLink: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      showBulkCreateArtworkTemplateGroupLinksModal: (payload: { artworkTemplateGroupLink: ArtworkTemplateGroupLinkModel }) => {
        setModalData('BulkCreateArtworkTemplateGroupLinksModal', payload)
        setState({ showBulkCreateArtworkTemplateGroupLinksModal: true })
      },
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent artworkTemplateGroupId={artworkTemplateGroupId} />

        <BulkCreateArtworkTemplateGroupLinkModal
          callbacks={callbacks('BulkCreateArtworkTemplateGroupLinksModal', setState)}
          showModal={showBulkCreateArtworkTemplateGroupLinksModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Relations
