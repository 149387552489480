export type PropertyModel = {
  id: number,
  bathrooms?: number,
  bedrooms?: number,
  cacheKey: number,
  campaignId: number,
  carparks?: number,
  city?: string,
  clientId: number,
  country?: string,
  name: string,
  postcode: number,
  propertyType?: number,
  state: string,
  street?: string,
  streetNumber: number,
  suburb: string,
  unitNumber?: number,
  userId: number,
}

const state = {
  id: null,
  bathrooms: '',
  bedrooms: '',
  campaignId: '',
  carparks: '',
  city: '',
  clientId: '',
  country: '',
  name: '',
  postcode: '',
  propertyType: '',
  state: '',
  street: '',
  streetNumber: '',
  suburb: '',
  unitNumber: '',
  userId: '',
}

export const requiredFields = [
  { key: 'campaign_id' },
  { key: 'city' },
  { key: 'name' },
  { key: 'postcode' },
  { key: 'state' },
  { key: 'street' },
  { key: 'street_number' },
  { key: 'suburb' },
]

export default state
