import { Conversation as ConversationBase } from '@campaignhub/suit-theme'

import ConversationImageUrl from '@assets/no_conversations.svg'

import useConversationContainer from '@hooks/useConversationContainer'

import BlankState from '../BlankState'
import Comment from './Comment'

interface ConversationContainerProps {
  subjectId: number,
  subjectType: string,
}

const ConversationContainer = (props: ConversationContainerProps) => {
  const { subjectId, subjectType } = props

  const conversationContainerPayload = useConversationContainer({
    performHttpRequests: !!subjectId && !!subjectType,
    subjectId,
    subjectType,
  })

  const {
    callbacks: { createCommentWithConversation },
    creating,
    filteredComments,
    filteredCommentsCount,
    hasFilteredComments,
    loading,
  } = conversationContainerPayload

  const hasComments = !loading && hasFilteredComments

  return (
    <ConversationBase
      callbacks={{ createCommentWithConversation }}
      commentsBoxProps={{
        alignItems: !hasComments ? 'center' : undefined,
        height: !hasComments ? 270 : undefined,
        justifyContent: !hasComments ? 'center' : undefined,
      }}
      commentCount={filteredCommentsCount}
      creating={creating}
      loading={loading}
    >
      {!hasComments && (
        <BlankState
          imageUrl={ConversationImageUrl}
          subtitle="Keep a detailed history of work, decisions, and requests in the conversation."
          title="No Comments"
        />
      )}

      {filteredComments.map((comment, index) => (
        <Comment
          comment={comment}
          isLast={index + 1 === filteredCommentsCount}
          key={comment.id}
        />
      ))}
    </ConversationBase>
  )
}

export default ConversationContainer
