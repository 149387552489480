// CampaignSummary is an object which can be requested with a campaign
// it represents the checks performed on the property campaign itself or ad campaigns
// to determine if the ad campaign can be deployed
// it is not a database nor redux entity

import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faMinusCircle } from '@fortawesome/pro-light-svg-icons'
import {
  faBan,
  faCheckCircle,
  faClipboardCheck,
  faClock,
  faExclamationCircle,
  faPauseCircle,
  faPlayCircle,
  faThumbsUp,
  faTimesCircle,
  faWarehouse,
} from '@fortawesome/pro-solid-svg-icons'

import { sortArrayBy } from '@campaignhub/javascript-utils'

import { Box, HoverHintWrapper, Text } from '@campaignhub/suit-theme'

import systemColours from '@functions/colours'
import adCampaignStatuses from '@functions/adCampaignStatus'

import { CampaignModel, Issue } from '@models/types'

export const getIssuesFromCampaignSummary = (campaignSummary: CampaignModel['campaignSummary']) => {
  if (!campaignSummary) return []

  const issues = Object.values(campaignSummary).reduce<Issue[]>((acc, value) => {
    if (value.issues){
      return [...acc, ...value.issues]
    }

    return acc
  }, [])

  return sortArrayBy(issues, 'desc', 'isCritical')
}

type Summary = {
  status?: string,
  [key: string]: any,
}

const generateHoverableHintText = (summary: Summary = {}) => {
  const statuses = adCampaignStatuses.map(status => status.toLowerCase())

  const text = statuses.reduce<React.ReactElement[]>((acc, status) => {
    const summaryValue = summary[status]

    if (summaryValue){
      acc.push(
        <Text color="white" key={status}>
          {summary[status]} {status}
        </Text>,
      )
    }

    return acc
  }, [])

  return text
}

export const getColour = (summary: Summary) => {
  const { status } = summary || {}

  const colours = {
    Error: systemColours.red,
    Failed: systemColours.red,
    Success: systemColours.teal,
    Warning: systemColours.yellow,
  }

  return colours[status] || systemColours.grey
}

export const getIcon = (summary: Summary) => {
  const { status } = summary || {}

  const statusIcons = {
    // Ad Campaign Statuses
    Archived: faWarehouse,
    Cancelled: faBan,
    Completed: faClipboardCheck,
    Failed: faTimesCircle,
    Live: faPlayCircle,
    Paused: faPauseCircle,
    Pending: faMinusCircle,
    Review: faThumbsUp,
    Scheduled: faClock,

    // Validation Statuses
    Error: faTimesCircle,
    Success: faCheckCircle,
    Warning: faExclamationCircle,
  }

  return statusIcons[status] || faCircle
}

const generateIconComponent = (summary: Summary) => {
  const hoverableHintText = generateHoverableHintText(summary)

  // If there is no text to display, don't display the Hint
  const hintComponent = !!hoverableHintText.length && (
    <HoverHintWrapper.Hint
      boxProps={{
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      {hoverableHintText}
    </HoverHintWrapper.Hint>
  )

  return (
    <Box justifyContent="center">
      <HoverHintWrapper hintComponent={hintComponent}>
        <FontAwesomeIcon
          color={getColour(summary)}
          icon={getIcon(summary)}
          size="lg"
        />
      </HoverHintWrapper>
    </Box>
  )
}

type Statuses = 'Error' | 'Warning' | 'Success'

export const findLeastProgressedStatus = (statuses: Statuses[]) => {
  if (statuses.includes('Error')) return 'Error'
  if (statuses.includes('Warning')) return 'Warning'
  return 'Success'
}

export default generateIconComponent
