import {
  Box, Button, DashboardModule,
} from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { useFilters } from '@campaignhub/react-hooks'

import DashboardModuleFilters from '@components/DashboardModuleFilters'

import useAdTemplateProduct from '@hooks/useAdTemplateProduct'

import useAdTemplateProducts from '@hooks/useAdTemplateProducts'

import AdTemplateProductListItem from './AdTemplateProductListItem'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        style,
      },
      key: 'adTemplateName',
      label: 'Filter by Name',
    },
  ]
}

interface AdTemplateProductsProps {
  productId: number,
}

const AdTemplateProducts = (props: AdTemplateProductsProps) => {
  const { productId } = props

  const filterPayload = useFilters()

  const {
    pageFilters,
  } = filterPayload

  const options = {
    filters: { productId, ...pageFilters },
    performHttpRequests: !!productId,
    requestOptions: {
      include: 'adTemplate',
    },
    sortKey: 'adTemplate' as 'adTemplate',
  }

  const adTemplateProductsPayload = useAdTemplateProducts(options)

  const {
    callbacks: {
      launchBulkCreateAdTemplateProductsModal,
    },
  } = useAdTemplateProduct({ productId })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdTemplateProducts,
    filteredAdTemplateProductsCount,
    hasFilteredAdTemplateProducts,
    loading,
  } = adTemplateProductsPayload

  return (
    <DashboardModule
      boxProps={{ borderTop: loading ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={<DashboardModule.FilterHeader {...filterPayload} />}
      loading={loading}
      title="Ad Templates"
    >
      <DashboardModuleFilters
        filterFields={buildPageFilterFields()}
        {...filterPayload}
      />

      <Box flexDirection="column">
        <DashboardModule.ScrollableSection flexDirection="column">
          {!loading && !hasFilteredAdTemplateProducts && (
            <DashboardModule.BlankState>
              <DashboardModule.BlankState.Title>
                No Ad Templates present
              </DashboardModule.BlankState.Title>

              <DashboardModule.BlankState.Paragraph>
                Please adjust your filters or link an Ad Template
              </DashboardModule.BlankState.Paragraph>
            </DashboardModule.BlankState>
          )}

          {!loading && filteredAdTemplateProducts.map(adTemplateProduct => (
            <AdTemplateProductListItem
              adTemplateProduct={adTemplateProduct}
              key={adTemplateProduct.id}
            />
          ))}
        </DashboardModule.ScrollableSection>

        <DashboardModule.LoadMoreFooter
          boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
          callbacks={{ loadMore }}
          canLoadMore={canLoadMore}
          entityCount={filteredAdTemplateProductsCount}
          loading={loading}
        >
          <Button
            buttonStyle="secondaryEdit"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => launchBulkCreateAdTemplateProductsModal()}
            size={['medium', 'small']}
            width={['100%', 'fit-content']}
          >
            Assign Ad Templates
          </Button>
        </DashboardModule.LoadMoreFooter>
      </Box>
    </DashboardModule>
  )
}

export default AdTemplateProducts
