import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { getAccessToken } from '@functions/accessToken'

import useCurrentUser from '@hooks/useCurrentUser'
import useUserTypes from '@hooks/useUserTypes'

import AdminRoutes from '@sections/Admin/routes'
import ClientRoutes from '@sections/Client/routes'
import Login from '@sections/Auth/Login'

const adminRoles = ['Admin', 'Support']

// When logging in the token is not instantly available
// We wait and check 0.1 second later for it to be available

const waitForAccessToken = (setAccessToken) => {
  const intervalId = setInterval(() => {
    const token = getAccessToken()
    if (token){
      clearInterval(intervalId)
      setAccessToken(token)
    }
  }, 100)
}

const AuthenticationController = () => {
  const [accessToken, setAccessToken] = useState(null)

  const auth0Payload = useAuth0()
  const { isAuthenticated, user } = auth0Payload

  useEffect(() => {
    waitForAccessToken(setAccessToken)
  }, [])

  const { 'https://www.realhubapp.com/role': userRole } = user || {}

  const performHttpRequests = accessToken && isAuthenticated

  useCurrentUser({ performHttpRequests })
  useUserTypes({ performHttpRequests })

  if (!accessToken || !isAuthenticated){
    return <Login />
  }

  if (adminRoles.includes(userRole)){
    return <AdminRoutes />
  }

  return <ClientRoutes />
}

export default AuthenticationController
