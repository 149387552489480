import { useContext } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowFromLeft, faSync } from '@fortawesome/pro-solid-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import {
  Box, Button, Form, Line, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useCampaign from '@hooks/useCampaign'
import useCampaignValidations from '@hooks/useCampaignValidations'

import CampaignCard from '@components/CampaignCard'

import ValidationsSummary from './ValidationsSummary'

const MODAL_KEY = 'ManageCampaignValidationsModal'

interface ManageCampaignValidationsModalProps {
  callbacks: {
    closeModal: () => void,
    validateCampaign: (payload: HandleCallbackActionParams) => Promise<object>,
  },
  showModal: boolean,
}

const ManageCampaignValidationsModal = (props: ManageCampaignValidationsModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, validateCampaign } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { campaign } = modalPayload

  const {
    callbacks: {
      validateCampaign: validateFn,
    },
    urls: {
      systemAdminOrderOverview,
    },
  } = useCampaign(campaign)

  const campaignValidationsPayload = useCampaignValidations({
    filters: {
      campaignId: campaign.id,
    },
    performHttpRequests: true,
  })

  const {
    filteredCampaignValidations,
    hasFilteredCampaignValidations,
    loading,
  } = campaignValidationsPayload

  const validateCampaignPayload = {
    callbacks: {
      action: validateFn,
    },
    entityParams: campaign,
    toastText: 'Campaign Validated',
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Campaign" />

      <SidebarModal.Content>
        <Box flexDirection="column">
          <CampaignCard campaign={campaign} />

          <Line style={{ marginLeft: -16, width: 'calc(100% + 32px)' }} />

          {!loading && !hasFilteredCampaignValidations && (
            <SidebarModal.NoticeBox boxProps={{ marginBottom: 'none' }}>
              <SidebarModal.NoticeBox.Title>No Validations</SidebarModal.NoticeBox.Title>

              <SidebarModal.NoticeBox.Paragraph>
                There are no validations available for that Campaign
              </SidebarModal.NoticeBox.Paragraph>
            </SidebarModal.NoticeBox>
          )}

          {!loading && hasFilteredCampaignValidations && (
            <ValidationsSummary campaignValidations={filteredCampaignValidations} />
          )}

          <Form.Field label="More Options" marginTop="large">
            <Button
              buttonStyle="secondaryUtility"
              icon={<FontAwesomeIcon icon={faSync} />}
              onClick={() => validateCampaign(validateCampaignPayload)}
              size="medium"
            >
              Retry Validating
            </Button>

            <Button
              as="a"
              buttonStyle="secondaryUtility"
              href={systemAdminOrderOverview}
              icon={<FontAwesomeIcon icon={faArrowFromLeft} />}
              marginTop="medium"
              size="medium"
              target="_blank"
            >
              Go to Order Overview
            </Button>
          </Form.Field>
        </Box>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => closeModal()}
          size="large"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ManageCampaignValidationsModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageCampaignValidationsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
