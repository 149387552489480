import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ArtworkTemplateRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['artworkTemplates']

type ArtworkTemplateFilters = {
  string?: string,
  artworkTemplateGroupId?: number,
}

type UseArtworkTemplatesOptions = {
  filters?: ArtworkTemplateFilters,
  performHttpRequests?: boolean,
  requestOptions?: ArtworkTemplateRequestOptions,
}

function useArtworkTemplates(options: UseArtworkTemplatesOptions) {
  const { filters = {}, requestOptions } = options
  const {
    string: filterString,
    artworkTemplateGroupId: filterArtworkTemplateGroupId,
  } = filters

  const {
    updatedEntities: { artworkTemplates: artworkTemplatesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { artworkTemplates } = useSelector(reduxState => reduxState.entities)

  const filteredArtworkTemplates = useMemo(() => {
    const filtered = Object.values(artworkTemplates).filter((artworkTemplate) => {
      const { name, artworkTemplateGroupId } = artworkTemplate

      const matchName = matchFilterString(name, filterString)
      const matchArtworkTemplateGroupId = matchFilterNumber(artworkTemplateGroupId, filterArtworkTemplateGroupId)

      return matchName && matchArtworkTemplateGroupId
    })

    return sortArrayBy(filtered, 'asc', 'title')
  }, [artworkTemplatesUpdatedAt, JSON.stringify(filters)])

  const filteredArtworkTemplatesCount = filteredArtworkTemplates.length
  const hasFilteredArtworkTemplates = !!filteredArtworkTemplatesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredArtworkTemplatesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingArtworkTemplates } = useReduxAction(
    'artworkTemplates',
    'loadArtworkTemplates',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredArtworkTemplates,
    filteredArtworkTemplatesCount,
    hasFilteredArtworkTemplates,
    loading: loadingArtworkTemplates,
  }
}

export default useArtworkTemplates
