import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/pro-light-svg-icons'

import { DashboardModuleListItem, Link } from '@campaignhub/suit-theme'

import useAsset from '@hooks/useAsset'

import type { AssetModel } from '@models/types'

interface DocumentListItemProps {
  asset: AssetModel,
}

const DocumentListItem = (props: DocumentListItemProps) => {
  const {
    asset,
    asset: {
      assetTypeId,
      displayName,
      fileName,
      id,
    },
  } = props

  const title = displayName || `${assetTypeId} ${id}`

  const {
    callbacks: {
      downloadAsset,
      editDocument,
    },
  } = useAsset(asset)

  return (
    <DashboardModuleListItem
      isSidebar
      secondaryText={fileName}
      title={title}
    >
      <DashboardModuleListItem.Actions>
        <Link
          greyLink
          onClick={() => downloadAsset()}
          textProps={{ marginRight: 'medium' }}
        >
          <FontAwesomeIcon icon={faFileDownload} />
        </Link>

        <DashboardModuleListItem.Icon onClick={() => editDocument()} type="edit" />
      </DashboardModuleListItem.Actions>
    </DashboardModuleListItem>
  )
}

export default DocumentListItem
