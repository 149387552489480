import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ProductOptionValueRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['productOptionValues']

type ProductOptionValueFilters = {
  name?: string,
  productOptionId?: number,
}

type UseProductOptionValuesOptions = {
  filters?: ProductOptionValueFilters,
  performHttpRequests?: boolean,
  requestOptions?: ProductOptionValueRequestOptions,
}

function useProductOptionValues(options: UseProductOptionValuesOptions) {
  const { filters = {}, requestOptions } = options
  const {
    name: filterName,
    productOptionId: filterProductOptionId,
  } = filters

  const {
    updatedEntities: { productOptionValues: productOptionValuesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { productOptionValues } = useSelector(reduxState => reduxState.entities)

  const filteredProductOptionValues = useMemo(() => {
    const filtered = Object.values(productOptionValues).filter((productOptionValue) => {
      const { name, productOptionId } = productOptionValue

      const matchName = matchFilterString(name, filterName)
      const matchProductOptionId = matchFilterNumber(productOptionId, filterProductOptionId)

      return matchName && matchProductOptionId
    })

    return sortArrayBy(filtered, 'asc', ({ sort }) => (sort || Infinity))
  }, [productOptionValuesUpdatedAt, JSON.stringify(filters)])

  const filteredProductOptionValuesCount = filteredProductOptionValues.length
  const hasFilteredProductOptionValues = !!filteredProductOptionValuesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredProductOptionValuesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingProductOptionValues } = useReduxAction(
    'productOptionValues',
    'loadProductOptionValues',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredProductOptionValues,
    filteredProductOptionValuesCount,
    hasFilteredProductOptionValues,
    loading: loadingProductOptionValues,
  }
}

export default useProductOptionValues
