import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ExternalPlatformModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, EntityOptions, Errors, FetchRequestPayload, RootReducerState,
} from '@redux/modules/types'

import { handleError } from '../utils'

const FETCH_REQUEST = 'realbase/externalPlatform/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/externalPlatform/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/externalPlatform/FETCH_FAILURE'

export type ExternalPlatformModuleState = {
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loading: boolean,
}

// Initial State
const initialState: ExternalPlatformModuleState = {
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadExternalPlatforms(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().externalPlatforms.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/references/external-platform-types', options)
      .then(({ data }: { data: { data: ExternalPlatformModel[] } }) => {
        const normalizedJson = normalize(data, Schemas.EXTERNAL_PLATFORM_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data: data.data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ExternalPlatformModuleState = initialState,
  action: Action = { type: '' },
): ExternalPlatformModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
