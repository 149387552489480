import { useMemo } from 'react'

import { matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { CutterProfileRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['cutterProfiles']

type CutterProfileFilters = {
  string?: string,
}

type UseCutterProfilesOptions = {
  filters?: CutterProfileFilters,
  performHttpRequests?: boolean,
  requestOptions?: CutterProfileRequestOptions,
}

function useCutterProfiles(options: UseCutterProfilesOptions) {
  const { filters = {}, requestOptions } = options
  const {
    string: filterString,
  } = filters

  const {
    updatedEntities: { cutterProfiles: cutterProfilesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { cutterProfiles } = useSelector(reduxState => reduxState.entities)

  const filteredCutterProfiles = useMemo(() => {
    const filtered = Object.values(cutterProfiles).filter((cutterProfile) => {
      const { id, name } = cutterProfile

      const matchId = id.toString() === filterString
      const matchName = matchFilterString(name, filterString)
      const matchString = matchId || matchName

      return matchString
    })

    return sortArrayBy(filtered, 'asc', 'name')
  }, [cutterProfilesUpdatedAt, JSON.stringify(filters)])

  const filteredCutterProfilesCount = filteredCutterProfiles.length
  const hasFilteredCutterProfiles = !!filteredCutterProfilesCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredCutterProfilesCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingCutterProfiles } = useReduxAction(
    'cutterProfiles',
    'loadCutterProfiles',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCutterProfiles,
    filteredCutterProfilesCount,
    hasFilteredCutterProfiles,
    loading: loadingCutterProfiles,
  }
}

export default useCutterProfiles
