import { Box, ListItem, Text } from '@campaignhub/suit-theme'

import type { AdCampaignModel } from '@models/types'

interface AdCampaignTargetingProps {
  adCampaign: AdCampaignModel,
  callbacks: {
    viewAdGroups: () => void,
    viewLocationTargeting: () => void,
  },
}

const AdCampaignTargeting = (props: AdCampaignTargetingProps) => {
  const {
    adCampaign: {
      adChannelTypeId,
      adGroupCount,
      locations,
    },
    callbacks: {
      viewAdGroups,
      viewLocationTargeting,
    },
  } = props

  const isGoogleAdCampaign = adChannelTypeId === 'Google'
  const hasAdGroups = !!adGroupCount
  const hasLocations = !!locations?.length

  return (
    <>
      <Text color="bodyFontLightColor" fontSize="small" marginTop="large">Targeting</Text>

      <Box marginTop="medium">
        {isGoogleAdCampaign && (
          <ListItem
            boxProps={{
              border: '1px solid',
              borderRadius: 5,
              marginRight: 'medium',
            }}
            disableHover={!hasLocations}
            forceBottomBorder
            onClick={hasLocations ? () => viewLocationTargeting() : null}
            showAngleIcon
          >
            <Box flexDirection="column" padding="large">
              <Text color="bodyFontLightColor" fontSize="xsmall">Location</Text>
              <Text fontSize="small" marginTop="small">{hasLocations ? locations?.length : 0}</Text>
            </Box>
          </ListItem>
        )}

        <ListItem
          boxProps={{
            border: '1px solid',
            borderRadius: 5,
          }}
          disableHover={!hasAdGroups}
          forceBottomBorder
          onClick={hasAdGroups ? () => viewAdGroups() : null}
          showAngleIcon
        >
          <Box flexDirection="column" padding="large">
            <Text color="bodyFontLightColor" fontSize="xsmall">{isGoogleAdCampaign ? 'Ad Groups' : 'Ad Sets'}</Text>
            <Text fontSize="small" marginTop="small">{adGroupCount}</Text>
          </Box>
        </ListItem>
      </Box>
    </>
  )
}

export default AdCampaignTargeting
