import { useContext } from 'react'
import { toast } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUpload, faTimes } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'

import { useSetState } from '@campaignhub/react-hooks'

import {
  Button, FileUploadZone, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useAssetTypes from '@hooks/useAssetTypes'
import useAssets from '@hooks/useAssets'
import useUppyAssetUpload from '@hooks/useUppyAssetUpload'

import DocumentListItem from './DocumentListItem'

const MODAL_KEY = 'ManageDocumentsModal'

const defaultState = {
  uploading: false,
}

interface ManageDocumentsModalProps {
  callbacks: {
    closeModal: () => void,
    deleteDocument: (payload: HandleCallbackActionParams) => Promise<object>,
  },
  showModal: boolean,
}

const ManageDocumentsModal = (props: ManageDocumentsModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, deleteDocument } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { uploading } = state

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const { filters } = modalPayload
  const {
    assetableId,
    assetableType,
  } = filters || {}

  const {
    filteredAssets,
    hasFilteredAssets,
  } = useAssets({ filters })

  const assetTypesPayload = useAssetTypes({
    filters: {
      category: 'Document',
    },
    performHttpRequests: true,
  })

  const { filteredAssetTypes } = assetTypesPayload

  // Default assetType to the first in Document category
  const assetType = filteredAssetTypes[0]?.name

  const uppy = useUppyAssetUpload({
    allowedFileTypes: 'application/pdf',
    assetTypeId: assetType,
    assetableId,
    assetableType,
    callbacks: {
      afterAction: () => toast('Asset Created Successfully'),
    },
  }, [JSON.stringify(filters), assetType])

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Manage" titleSecondLine="Documents" />

      <SidebarModal.Content>
        <Form>
          {!uploading && hasFilteredAssets && (
            <Form.Field label="Documents">
              {filteredAssets.map(asset => (
                <DocumentListItem
                  asset={asset}
                  callbacks={{ deleteDocument }}
                  key={asset.id}
                />
              ))}
            </Form.Field>
          )}

          {!uploading && (
            <Form.Field label="More Options" marginTop={hasFilteredAssets ? 'large' : 'none'}>
              <Button
                buttonStyle="secondaryUtility"
                icon={<FontAwesomeIcon icon={faCloudUpload} />}
                marginTop="medium"
                onClick={() => setState({ uploading: true })}
                size="medium"
              >
                Upload Documents
              </Button>
            </Form.Field>
          )}

          {uploading && (
            <>
              <SidebarModal.NoticeBox>
                <SidebarModal.NoticeBox.Title>Upload Documents</SidebarModal.NoticeBox.Title>
                <SidebarModal.NoticeBox.Paragraph>
                  Documents uploaded here will be stored on your project for use across digital pages
                </SidebarModal.NoticeBox.Paragraph>
              </SidebarModal.NoticeBox>

              <Form.Field label="Upload Documents" marginTop="large">
                <FileUploadZone uppy={uppy} />
              </Form.Field>

              <Form.Field label="More Options" marginTop="large">
                <Button
                  buttonStyle="secondaryUtility"
                  icon={<FontAwesomeIcon icon={faTimes} />}
                  onClick={() => setState({ uploading: false })}
                  size="medium"
                >
                  Cancel
                </Button>
              </Form.Field>
            </>
          )}

        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          onClick={() => closeModal()}
          size="large"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ManageDocumentsModalProps) => (
  <SidebarModal.RenderController {...props}>
    <ManageDocumentsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
