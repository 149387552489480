import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsH } from '@fortawesome/pro-light-svg-icons'

import { Button, Tools } from '@campaignhub/suit-theme'

import usePriceList from '@hooks/usePriceList'
import useSelectEntities from '@hooks/useSelectEntities'

interface PriceListToolsProps {
  selectEntitiesPayload: ReturnType<typeof useSelectEntities>,
}

const PriceListTools = (props: PriceListToolsProps) => {
  const {
    selectEntitiesPayload: {
      callbacks: {
        deselectAll,
      },
      hasMinimumSelected,
      selectedEntityIds,
    },
  } = props

  const {
    callbacks: {
      launchBulkUpdatePriceListsModal,
    },
  } = usePriceList()

  return (
    <Tools>
      <Button
        buttonStyle="secondaryUtility"
        disabled={!hasMinimumSelected}
        icon={<FontAwesomeIcon icon={faArrowsH} />}
        marginBottom="medium"
        onClick={() => launchBulkUpdatePriceListsModal({
          callbacks: { deselectAll },
          priceListIds: selectedEntityIds,
        })}
        size="medium"
      >
        Bulk Update
      </Button>
    </Tools>
  )
}

export default PriceListTools
