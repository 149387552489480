import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useArtworkTemplateGroup, { useArtworkTemplateGroupForm } from '@hooks/useArtworkTemplateGroup'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'CreateOrEditArtworkTemplateGroupModal'

type ModalCallbacks = {
  closeModal: () => void,
  createArtworkTemplateGroup: (payload: HandleCallbackActionParams) => void,
  updateArtworkTemplateGroup: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateOrEditArtworkTemplateGroupModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createArtworkTemplateGroup, updateArtworkTemplateGroup } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const artworkTemplateGroup = digObject(modalPayload, 'artworkTemplateGroup', {})

  const newRecord = !artworkTemplateGroup.id

  const {
    callbacks: {
      createArtworkTemplateGroup: createFn,
      updateArtworkTemplateGroup: updateFn,
    },
    creating,
    updating,
  } = useArtworkTemplateGroup(artworkTemplateGroup)

  const {
    entityState,
    entityState: {
      name,
      description,
    },
    errors,
    handlers,
    saveEnabled,
  } = useArtworkTemplateGroupForm(artworkTemplateGroup, { validateOn: 'change' })

  const createOrUpdateArtworkTemplateGroupPayload = {
    callbacks: {
      action: newRecord ? createFn : updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: `Artwork Template Group ${newRecord ? 'Created' : 'Updated'}`,
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title={newRecord ? 'Create' : 'Edit'} titleSecondLine="Artwork Template Group" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* Name" errorMessage={errors.name}>
            <input name="name" type="text" value={name} {...handlers} />
          </Form.Field>

          <Form.Field label="* Description" errorMessage={errors.description} marginTop="large">
            <textarea
              name="description"
              value={description}
              style={{ height: 120, resize: 'vertical' }}
              {...handlers}
            />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          loadingBubbleColor="white"
          onClick={
            newRecord
              ? () => createArtworkTemplateGroup(createOrUpdateArtworkTemplateGroupPayload)
              : () => updateArtworkTemplateGroup(createOrUpdateArtworkTemplateGroupPayload)
          }
          size="large"
        >
          {saveEnabled ? `${newRecord ? 'Create' : 'Update'} Artwork Template Group` : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditArtworkTemplateGroupModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
