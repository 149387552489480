import { useMemo } from 'react'

import { useModals } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'

type OverviewProps = {
  campaignId: number,
}

const Overview = (props: OverviewProps) => {
  const { campaignId } = props

  const pageContext = useMemo(() => ({
    callbacks: {},
  }), [])

  const modalContext = useModals()

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent campaignId={campaignId} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Overview
