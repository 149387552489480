import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'

const MapMarker = () => (
  <FontAwesomeIcon
    color="red"
    icon={faMapMarkerAlt}
    size="2x"
  />
)

export default MapMarker
