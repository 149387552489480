import { useContext } from 'react'

import {
  Button, Form, ModalContext, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import useCutterProfile, { useCutterProfileForm } from '@hooks/useCutterProfile'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

const MODAL_KEY = 'CreateOrEditCutterProfileModal'

type ModalCallbacks = {
  closeModal: () => void,
  createCutterProfile: (payload: HandleCallbackActionParams) => void,
  updateCutterProfile: (payload: HandleCallbackActionParams) => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const CreateOrEditCutterProfileModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { closeModal, createCutterProfile, updateCutterProfile } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const cutterProfile = digObject(modalPayload, 'cutterProfile', {})

  const newRecord = !cutterProfile.id

  const {
    callbacks: {
      createCutterProfile: createFn,
      updateCutterProfile: updateFn,
    },
    creating,
    updating,
  } = useCutterProfile(cutterProfile)

  const {
    entityState,
    entityState: {
      name,
    },
    errors,
    handlers,
    saveEnabled,
  } = useCutterProfileForm(cutterProfile, { validateOn: 'change' })

  const createOrUpdateCutterProfilePayload = {
    callbacks: {
      action: newRecord ? createFn : updateFn,
      afterAction: closeModal,
    },
    entityParams: entityState,
    toastText: `Cutter Profile ${newRecord ? 'Created' : 'Updated'}`,
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title={newRecord ? 'Create' : 'Edit'} titleSecondLine="Cutter Profile" />

      <SidebarModal.Content>
        <Form>
          <Form.Field label="* Name" errorMessage={errors.name}>
            <input name="name" type="text" value={name} {...handlers} />
          </Form.Field>
        </Form>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating || updating}
          loadingBubbleColor="white"
          onClick={
            newRecord
              ? () => createCutterProfile(createOrUpdateCutterProfilePayload)
              : () => updateCutterProfile(createOrUpdateCutterProfilePayload)
          }
          size="large"
        >
          {saveEnabled ? `${newRecord ? 'Create' : 'Update'} Cutter Profile` : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <CreateOrEditCutterProfileModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
