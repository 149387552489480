import { useEffect, useState } from 'react'

import { toggleArray } from '@campaignhub/javascript-utils'

import useOrderItemOptionValues from '@hooks/useOrderItemOptionValues'
import usePriceListItemOptionValues from '@hooks/usePriceListItemOptionValues'

import type { EntitiesState } from '@redux/entities'

import type { OrderItemModel } from '@models/types'

type ToggleParams = {
  id: number,
  selectedGeoTargetingIds: number[],
  setSelectedGeoTargetingIds: React.Dispatch<React.SetStateAction<number[]>>,
}

const toggleGeoTargetingId = (params: ToggleParams) => {
  const { id, selectedGeoTargetingIds, setSelectedGeoTargetingIds } = params

  const updatedGeoTargetingIds = toggleArray(selectedGeoTargetingIds, id)
  setSelectedGeoTargetingIds(updatedGeoTargetingIds)
}

const GEO_TARGETING_KEY = 'CampaignGeoTargeting'

type UseGeoTargetingParams = {
  orderItem: OrderItemModel,
  priceListItemOptionValues: EntitiesState['priceListItemOptionValues'],
  priceListItemOptions: EntitiesState['priceListItemOptions'],
  productOptions: EntitiesState['productOptions'],
}

const useGeoTargeting = (params: UseGeoTargetingParams) => {
  const {
    orderItem,
    priceListItemOptions,
    priceListItemOptionValues,
    productOptions,
  } = params

  // Get PriceListItemOptionValues corresponding to Geo Targeting
  const geoTargetingProductOption = Object.values(productOptions).find(productOption => (
    productOption.key === GEO_TARGETING_KEY
  ))

  // Get corresponding PriceListItemOption
  const geoTargetingPriceListItemOption = Object.values(priceListItemOptions).find(priceListItemOptions => (
    priceListItemOptions.productOptionId === geoTargetingProductOption?.id
  ))

  // Get corresponding PriceListItemOptionValues
  // If there is no geoTargetingPriceListItemOption.id, pass -1 to the filter to return no results
  // We must run the hook due to rules of hooks
  const geoPriceListItemOptionValuesPayload = usePriceListItemOptionValues({
    filters: {
      priceListItemOptionId: geoTargetingPriceListItemOption?.id || -1,
    }
  })

  const {
    filteredPriceListItemOptionValues: geoPriceListItemOptionValues,
  } = geoPriceListItemOptionValuesPayload

  // Initial PriceListItemOptionValue selection is based on existence of OrderItemOptionValues
  const { filteredOrderItemOptionValues } = useOrderItemOptionValues({
    filters: {
      orderItemId: orderItem.id,
    },
  })

  const defaultPriceListItemOptionValueIds = filteredOrderItemOptionValues.map(orderItemOptionValue => (
    orderItemOptionValue.priceListItemOptionValueId
  ))

  const geoPriceListItemOptionValueIds = defaultPriceListItemOptionValueIds.filter((id) => (
    priceListItemOptionValues[id].priceListItemOptionId === geoTargetingPriceListItemOption?.id
  ))

  // Set up a state for Geo Targeting
  const [
    selectedGeoTargetingIds,
    setSelectedGeoTargetingIds,
  ] = useState<number[]>(geoPriceListItemOptionValueIds)

  // Value may initialise as empty array due to load time
  useEffect(() => {
    setSelectedGeoTargetingIds(geoPriceListItemOptionValueIds)
  }, [geoPriceListItemOptionValueIds.length])

  // Default to null if there is no geo targeting so we don't send anything on request
  const geoPatchParams = geoTargetingPriceListItemOption
    ? {
        priceListItemOptionId: geoTargetingPriceListItemOption.id,
        priceListItemOptionValueIds: selectedGeoTargetingIds,
      }
    : null

  return {
    callbacks: {
      toggleGeoTargetingId: (id: number) => (
        toggleGeoTargetingId({ id, selectedGeoTargetingIds, setSelectedGeoTargetingIds })
      )
    },
    geoPatchParams,
    geoPriceListItemOptionValues,
    selectedGeoTargetingIds,
  }
}

export default useGeoTargeting