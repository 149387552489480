import { Columns } from '@campaignhub/suit-theme'

import useUser from '@hooks/useUser'

import ClientUsers from '@components/ClientUsers'

import PageLayout from '../../../../components/PageLayout'

interface PageContentProps {
  userId: number,
}

const PageContent = (props: PageContentProps) => {
  const { userId } = props

  const userPayload = useUser({ id: userId })

  return (
    <PageLayout userPayload={userPayload} activeTabBarItemKey="relations">
      <Columns.Main>
        <ClientUsers userId={userId} />
      </Columns.Main>
    </PageLayout>
  )
}

export default PageContent

