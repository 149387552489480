import { useContext } from 'react'

import { launchModal } from '@campaignhub/javascript-utils'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as productOptionActions from '@redux/modules/productOption'

import defaultFormState, { requiredFields } from '@models/productOption'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { ProductOptionModel, ProductOptionRequestOptions } from '@models/types'

export const generateUrls = (productOption?: Partial<ProductOptionModel>) => {
  const { id } = productOption || {}

  return {
    archivedProductOptionsIndexUrl: '#/productOptions?isArchived=true',
    editProductOptionUrl: `#/productOptions/${id}/edit/`,
    editProductRelationsUrl: `#/productOptions/${id}/edit/relations`,
    productOptionsIndexUrl: '#/productOptions',
  }
}

type CreateProductOptionParams = {
  dispatch: AppDispatch,
  productOptionParams: Partial<ProductOptionModel>,
  requestOptions?: ProductOptionRequestOptions,
}

const createProductOption = (params: CreateProductOptionParams) => {
  const { dispatch, productOptionParams, requestOptions } = params
  const { createProductOption: createFn } = productOptionActions

  return dispatch(createFn(productOptionParams, requestOptions)).then((response) => {
    const { success } = response

    if (success) {
      const { data } = response

      const { editProductOptionUrl } = generateUrls(data)

      return {
        success,
        data,
        redirectUrl: editProductOptionUrl,
      }
    }

    return response
  })
}

type UpdateProductOptionParams = {
  dispatch: AppDispatch,
  productOption: ProductOptionModel,
  productOptionParams: Partial<ProductOptionModel>,
  requestOptions?: ProductOptionRequestOptions,
}

const updateProductOption = (params: UpdateProductOptionParams) => {
  const {
    dispatch, productOption, productOptionParams, requestOptions,
  } = params
  const { updateProductOption: updateFn } = productOptionActions

  const updatedParams = {
    id: productOption.id,
    ...productOptionParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DuplicateProductOptionParams = {
  dispatch: AppDispatch,
  productOptionParams: Partial<ProductOptionModel>,
  requestOptions?: ProductOptionRequestOptions,
}

const duplicateProductOption = (params: DuplicateProductOptionParams) => {
  const { dispatch, productOptionParams, requestOptions } = params
  const { duplicateProductOption: duplicateFn } = productOptionActions

  return dispatch(duplicateFn(productOptionParams, requestOptions)).then((response) => {
    const { success } = response

    if (success) {
      const { data } = response

      const { editProductOptionUrl } = generateUrls(data)

      return {
        success,
        data,
        redirectUrl: editProductOptionUrl,
      }
    }

    return response
  })
}

type DeleteProductOptionParams = {
  dispatch: AppDispatch,
  productOption: DeleteParams<ProductOptionModel>,
}

const deleteProductOption = (params: DeleteProductOptionParams) => {
  const { dispatch, productOption } = params
  const { deleteProductOption: deleteFn } = productOptionActions

  return dispatch(deleteFn(productOption)).then((response) => {
    const { success } = response

    if (success) {
      const { data } = response

      const { productOptionsIndexUrl } = generateUrls(data)

      return {
        success,
        data,
        redirectUrl: productOptionsIndexUrl,
      }
    }

    return response
  })
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useProductOptionForm(
  productOption: Partial<ProductOptionModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const productOptionForm = useForm(
    defaultFormState,
    { entity: productOption, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [productOption.id, productOption.cacheKey],
  )

  return {
    ...productOptionForm,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ProductOptionRequestOptions,
}

function useProductOption(initEntity: Partial<ProductOptionModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: productOption }: { entity: ProductOptionModel } = useLatestEntity(initEntity, 'productOptions')
  const { id } = productOption

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'productOptions',
    'loadProductOption',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.productOptions)

  return {
    callbacks: {
      createProductOption: (
        productOptionParams: Partial<ProductOptionModel>,
        entityOptions?: ProductOptionRequestOptions,
      ) => (
        createProductOption({ productOptionParams, dispatch, requestOptions: entityOptions })
      ),
      createProductOptionValue: () => launchModal({
        callbacks,
        modalKey: 'CreateProductOptionValueModal',
        payload: { productOption },
      }),
      deleteProductOption: () => deleteProductOption({ dispatch, productOption }),
      duplicateProductOption: (productOptionParams: Partial<ProductOptionModel>, entityOptions?: ProductOptionRequestOptions) => (
        duplicateProductOption({ productOptionParams, dispatch, requestOptions: entityOptions })
      ),
      launchDuplicateProductOptionModal: () => launchModal({
        callbacks,
        modalKey: 'DuplicateProductOptionModal',
        payload: { productOption },
      }),
      updateProductOption: (
        productOptionParams: Partial<ProductOptionModel>,
        entityOptions?: ProductOptionRequestOptions,
      ) => (
        updateProductOption({
          productOption, productOptionParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    productOption,
    updating,
    urls: generateUrls(productOption),
  }
}

export default useProductOption
