import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'

import { faTachometer } from '@fortawesome/pro-light-svg-icons'

import { Box, Columns } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import getObjectivesList from '@functions/adType'

import type { AdQueueFilters } from '@hooks/useAdQueues'

import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import DeployingCampaigns from '../DeployingCampaigns'
import FailedCampaigns from '../FailedCampaigns'

const buildPageFilterFields = (adType?: string) => {
  const style = { marginBottom: 16 }
  const boxProps = { marginBottom: 'large' }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by campaign name or AIM#',
        style,
      },
      key: 'string',
      label: 'Search',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'status',
      label: 'Status',
      values: [
        { key: 'Failed', label: 'Failed' },
        { key: 'Loading', label: 'Deploying' },
        { key: 'Ready', label: 'Ready' },
        { key: 'Success', label: 'Success' },
      ],
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by listing ID',
        style,
      },
      key: 'externalId',
      label: 'Listing ID',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'objective',
      label: 'Campaign Objective',
      values: getObjectivesList(adType),
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'deploymentStack',
      label: 'Deployment Stack',
      values: [
        { key: 'v2', label: 'v2' },
        { key: 'v1', label: 'v1' },
      ],
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'clients',
        entityTitleKey: 'name',
      },
      key: 'clientId',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        style,
      },
      key: 'startsAt',
      label: 'Start Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        style,
      },
      key: 'endsAt',
      label: 'End Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'Latest First',
        inputComponent: 'select',
        style,
      },
      key: 'sortBy',
      label: 'Sort By',
      values: [
        { key: 'asc', label: 'Oldest First' },
      ],
    },
  ]
}

interface PageContentProps {
  pageFilters: AdQueueFilters,
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props
  const { status } = pageFilters

  const { adType } = pageFilters

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      resetFilters,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  return (
    <>
      <Helmet>
        <title>Ad Deployer | AIM</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="campaigns"
        tabBarItems={[
          {
            href: '#/adDeployer',
            icon: faTachometer,
            key: 'campaigns',
            title: 'All Campaigns',
          },
        ]}
        title="Ad Deployer"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            {status !== 'Failed' && <DeployingCampaigns pageFilters={pageFilters} />}

            {status === 'Failed' && <FailedCampaigns pageFilters={pageFilters} />}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields(adType)}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
