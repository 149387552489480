import GoogleMapReact from 'google-map-react'

import { Box } from '@campaignhub/suit-theme'

import type { Property } from '..'
import MapMarker from './MapMarker'

interface PropertyMapProps {
  property: Property,
}

const PropertyMap = (props: PropertyMapProps) => {
  const {
    property: {
      lat = -33.762390,
      lng = 151.271271,
    },
  } = props

  return (
    <Box height="300px" padding="large">
      <Box style={{ borderRadius: '10px', overflow: 'hidden' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_REACT_API_KEY }}
          defaultCenter={{ lat, lng }}
          defaultZoom={12}
        >
          <MapMarker lat={lat} lng={lng} />
        </GoogleMapReact>
      </Box>
    </Box>
  )
}

export default PropertyMap
