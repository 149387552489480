import { Route, Routes } from 'react-router-dom'

import ProductOptionEdit from './Edit'
import ProductOptionsList from './List'

const ProductOptionsRoutes = () => (
  <Routes>
    <Route path="/" element={<ProductOptionsList />} />
    <Route path="/:productOptionId/edit/*" element={<ProductOptionEdit />} />
  </Routes>
)

export default ProductOptionsRoutes
