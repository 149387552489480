import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOut } from '@fortawesome/pro-light-svg-icons'

import { Box, Link } from '@campaignhub/suit-theme'

import { generateUrls } from '@hooks/useCampaign'

interface ActionsProps {
  campaignId: number,
}

const Actions = (props: ActionsProps) => {
  const { campaignId } = props

  const { systemAdminOrderOverview } = generateUrls({ id: campaignId })

  return (
    <Box justifyContent="center" alignItems="center">
      <Link
        greyLink
        href={systemAdminOrderOverview}
        textProps={{ fontSize: 'medium', height: 'fit-content' }}
      >
        <FontAwesomeIcon icon={faSignOut} />
      </Link>
    </Box>
  )
}

export default Actions
