import { useMemo } from 'react'

import { matchFilterNumber, matchFilterString, sortArrayBy } from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ClientPriceListModel, ClientPriceListRequestOptions } from '@models/types'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['clientPriceLists']

type ClientPriceListFilters = {
  clientId?: number,
  clientName?: string,
  priceListId?: number,
  priceListName?: string,
}

type UseClientPriceListsOptions = {
  filters?: ClientPriceListFilters,
  performHttpRequests?: boolean,
  requestOptions?: ClientPriceListRequestOptions,
  sortKey?: 'client' | 'priceList',
}

export function useClientPriceLists(options: UseClientPriceListsOptions) {
  const { filters = {}, requestOptions, sortKey } = options
  const {
    clientId: filterClientId,
    clientName: filterClientName,
    priceListId: filterPriceListId,
    priceListName: filterPriceListName,
  } = filters

  const {
    updatedEntities: { clientPriceLists: clientPriceListsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { clientPriceLists, clients, priceLists } = useSelector(reduxState => reduxState.entities)

  const filteredClientPriceLists = useMemo(() => {
    const filtered = Object.values(clientPriceLists).filter((clientPriceList) => {
      const { clientId, priceListId } = clientPriceList

      const clientName = clients[clientId]?.name
      const priceListName = priceLists[priceListId]?.name

      const matchClientId = matchFilterNumber(clientId, filterClientId)
      const matchClientName = matchFilterString(clientName, filterClientName)
      const matchClientString = matchClientId && matchClientName

      const matchPriceListId = matchFilterNumber(priceListId, filterPriceListId)
      const matchPriceListName = matchFilterString(priceListName, filterPriceListName)
      const matchPriceListString = matchPriceListId && matchPriceListName

      return matchClientString && matchPriceListString
    })

    return sortArrayBy(
      filtered,
      'asc',
      ({ clientId, priceListId }) => (
        sortKey === 'client'
          ? clients[clientId]?.name
          : priceLists[priceListId]?.name
      ),
    )
  }, [clientPriceListsUpdatedAt, JSON.stringify(filters)])

  const filteredClientPriceListsCount = filteredClientPriceLists.length
  const hasFilteredClientPriceLists = !!filteredClientPriceListsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredClientPriceListsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingClientPriceLists } = useReduxAction(
    'clientPriceLists',
    'loadClientPriceLists',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredClientPriceLists,
    filteredClientPriceListsCount,
    hasFilteredClientPriceLists,
    loading: loadingClientPriceLists,
  }
}

export default useClientPriceLists
