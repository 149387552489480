import { Fragment, useMemo } from 'react'

import {
  Box, Image, NavigationLinkGroup, NestedNavigation, Text,
} from '@campaignhub/suit-theme'

import useCampaign, { useRelations } from '@hooks/useCampaign'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import generateNavigationItems from './utils/generateNavigationItems'

import PlaceholderImageUrl from './assets/placeholder.svg'

type NavigationProps = {
  campaignId: number,
}

const Navigation = (props: NavigationProps) => {
  const { campaignId } = props

  const campaignPayload = useCampaign({ id: campaignId }, {
    performHttpRequests: true,
    requestOptions: {
      ...defaultRequestOptions.campaign,
    },
  })

  const {
    campaign,
    campaign: {
      name,
      statusId,
    },
  } = campaignPayload

  const {
    coverImage: {
      filePath,
    },
  } = useRelations(campaign)

  const navigationItems = useMemo(() => {
    const items = generateNavigationItems(campaignId)
    return items
  }, [])

  return (
    <NestedNavigation>
      <Image placeholderUrl={PlaceholderImageUrl} width="100%" height={190} url={filePath} />

      <Box flexDirection="column" flexShrink="0" paddingX="large">
        {/* Address */}
        <Box
          borderBottom="1px solid"
          borderColor="lineColor"
          flexDirection="column"
          marginBottom="large"
          paddingY="large"
        >
          <Text fontSize="small">{name}</Text>
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {statusId}
          </Text>
        </Box>
        {/* End Address */}

        {/* Nav */}
        {navigationItems
          && navigationItems.sections.map(section => (
            <Fragment key={section.key}>
              {section.items
                && section.items.map(item => (
                  <NavigationLinkGroup key={item.key} link={item} secondaryNav sidebarMaximized />
                ))}
            </Fragment>
          ))}
        {/* End Nav */}
      </Box>
    </NestedNavigation>
  )
}

export default Navigation
