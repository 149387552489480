import { DashboardModule, Link, Text } from '@campaignhub/suit-theme'

import useCampaign from '@hooks/useCampaign'
import useCampaignUser from '@hooks/useCampaignUser'
import useCampaignUsers from '@hooks/useCampaignUsers'

import defaultRequestOptions from '@sections/Client/defaultRequestOptions'

import AgentListItem from './AgentListItem'

interface AgentsProps {
  campaignPayload: ReturnType<typeof useCampaign>,
}

const Agents = (props: AgentsProps) => {
  const {
    campaignPayload: {
      campaign,
    },
  } = props

  const campaignUsersPayload = useCampaignUsers({
    filters: { campaignId: campaign.id },
    performHttpRequests: true,
    requestOptions: defaultRequestOptions.campaignUser,
  })

  const {
    leadCampaignUsers,
    loading,
  } = campaignUsersPayload

  const [leadAgent, secondAgent] = leadCampaignUsers

  const {
    callbacks: {
      manageCampaignUsers,
    },
  } = useCampaignUser()

  return (
    <DashboardModule
      boxProps={{ borderBottom: leadAgent ? '1px solid lineColour' : 'none' }}
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={(
        <Link
          onClick={() => manageCampaignUsers({ campaignId: campaign.id })}
          textProps={{ fontSize: 'small' }}
        >
          Manage
        </Link>
      )}
      loading={loading}
      title="Agents"
    >
      {!loading && leadAgent && (
        <>
          <Text color="bodyFontLightColor" fontSize="xsmall" paddingLeft="large" paddingTop="large">
            Primary Agent
          </Text>

          <AgentListItem campaignUser={leadAgent} />
        </>
      )}

      {!loading && secondAgent && (
        <>
          <Text color="bodyFontLightColor" fontSize="xsmall" paddingLeft="large" paddingTop="large">
            Secondary Agent
          </Text>

          <AgentListItem campaignUser={secondAgent} />
        </>
      )}
    </DashboardModule>
  )
}

export default Agents
