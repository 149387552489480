import { useContext, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-light-svg-icons'

import { useDebounce } from '@campaignhub/react-hooks'

import {
  Button, DashboardModule, Form, FormField, Text,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import usePriceListItem, { usePriceListItemForm } from '@hooks/usePriceListItem'
import usePriceListItems from '@hooks/usePriceListItems'
import useProduct, { generateUrls } from '@hooks/useProduct'
import useProducts from '@hooks/useProducts'

import { PriceListModel } from '@models/types'

interface AddProductProps {
  priceList: PriceListModel,
  priceListItemsPayload: ReturnType<typeof usePriceListItems>,
}

const AddProduct = (props: AddProductProps) => {
  const {
    priceList,
    priceListItemsPayload: {
      filteredPriceListItems,
    },
  } = props

  const assignedProductIds = filteredPriceListItems.map(priceListItem => priceListItem.productId)

  const [searchString, setSearchString] = useState('')

  const debouncedString = useDebounce(searchString, 300)

  const productsPayload = useProducts({
    filters: {
      isArchived: false,
      sortBy: 'desc',
      string: debouncedString
    },
    performHttpRequests: true,
    sortKey: 'id',
  })

  const { filteredProducts } = productsPayload

  const unassignedProducts = filteredProducts.filter(product => !assignedProductIds.includes(product.id))

  const { productsIndexUrl } = generateUrls()

  const priceListItemForm = usePriceListItemForm({ priceListId: priceList.id }, { validateOn: 'change' })
  const {
    entityState,
    entityState: {
      descriptionOverride,
      nameOverride,
      price,
      productId,
    },
    errors,
    handlers,
    saveEnabled,
    setEntityState,
  } = priceListItemForm

  const {
    product: {
      basePrice,
    },
  } = useProduct({ id: productId })

  const {
    callbacks: {
      createPriceListItem: createFn,
    },
  } = usePriceListItem()

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      createPriceListItem,
    },
  } = pageContext

  const resetFormState = () => setEntityState({
    descriptionOverride: '',
    nameOverride: '',
    price: undefined,
    priceListId: priceList.id,
    productId: undefined,
  })

  const createPriceListItemPayload = {
    callbacks: {
      action: createFn,
      afterAction: resetFormState,
    },
    entityParams: entityState,
    toastText: 'Product Added',
  }

  return (
    <DashboardModule
      contentBoxProps={{ flexDirection: 'column' }}
      headerRight={(
        <DashboardModule.Link href={productsIndexUrl}>
          Manage Products
        </DashboardModule.Link>
      )}
      title="Add Products"
    >
      <Form boxProps={{ paddingTop: 'large', paddingX: 'large' }}>
        <FormField label="Search Products">
          <input onChange={e => setSearchString(e.target.value)} value={searchString} />
        </FormField>
      </Form>

      {!unassignedProducts.length && <Text fontSize="small" padding="large">No Available Products</Text>}

      {!!unassignedProducts.length && (
        <>
          <Form padding="large">
            <FormField label="Available Products">
              <select
                data-value-type="number"
                name="productId"
                value={productId}
                {...handlers}
              >
                <option value="">Please Select...</option>
                {unassignedProducts.map((product) => {
                  const { id, name } = product

                  return (
                    <option key={id} value={id}>
                      #{id} - {name}
                    </option>
                  )
                })}
              </select>
            </FormField>

            {!!productId && (
              <>
                <FormField label="Product Title (Override)" marginTop="large">
                  <input
                    name="nameOverride"
                    type="text"
                    value={nameOverride}
                    {...handlers}
                  />
                </FormField>

                <FormField label="Description (Override)" marginTop="large">
                  <input
                    name="descriptionOverride"
                    type="text"
                    value={descriptionOverride}
                    {...handlers}
                  />
                </FormField>

                <FormField errorMessage={errors.price} label="Client Price ($)" marginTop="large">
                  <input
                    data-value-type="number"
                    name="price"
                    // EntityState set manually to override empty string with undefined
                    onChange={e => setEntityState({ price: Number(e.target.value) || undefined })}
                    type="number"
                    value={price}
                  />
                </FormField>

                <FormField label="Cost Price ($)" marginTop="large">
                  <input
                    disabled
                    type="text"
                    value={basePrice || 0}
                  />
                </FormField>
              </>
            )}
          </Form>

          <DashboardModule.Footer>
            <DashboardModule.FooterRight>
              <Button
                buttonStyle="secondaryEdit"
                disabled={!saveEnabled}
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => createPriceListItem(createPriceListItemPayload)}
                size={['medium', 'small']}
                width={['100%', 'fit-content']}
              >
                Add Product
              </Button>
            </DashboardModule.FooterRight>
          </DashboardModule.Footer>
        </>
      )}
    </DashboardModule>
  )
}

export default AddProduct
