import { useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faSearch, faSync } from '@fortawesome/pro-solid-svg-icons'

import { formatDate } from '@campaignhub/javascript-utils'

import {
  Box, StatusBadge, Table, Text,
} from '@campaignhub/suit-theme'

import generateIconComponent, { getIssuesFromCampaignSummary } from '@functions/campaignSummary'
import systemColours from '@functions/colours'

import useCampaigns from '@hooks/useCampaigns'

import {
  AdCampaignSummary, CampaignModel, PreflightValidationSummary, LandingPageModel,
} from '@models/types'

import Actions from './Actions'

const buildColumns = () => [
  {
    title: 'Campaign',
    dataKey: 'name',
    cellProps: {
      maxWidth: 250,
      verticalAlign: 'middle',
      width: 250,
    },
    render: (name: string) => (
      <Text variant="ellipsis" width={250}>
        {name}
      </Text>
    ),
  },
  {
    title: 'AIM#',
    dataKey: 'id',
    cellProps: {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 40,
    },
  },
  {
    title: 'Due',
    dataKey: 'requiredAt',
    cellProps: {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 40,
    },
    render: (dueDate: string) => (
      <StatusBadge
        boxProps={{
          backgroundColor: 'backgroundColor',
          borderColor: 'lineColor',
          color: 'black',
        }}
        text={formatDate(dueDate, 'ISO8601', 'dd/MM/yyyy')}
      />
    ),
  },
  {
    title: 'Issues',
    dataKey: 'campaignSummary',
    cellProps: {
      verticalAlign: 'middle',
      width: 250,
    },
    render: (campaignSummary: CampaignModel['campaignSummary']) => {
      const issues = getIssuesFromCampaignSummary(campaignSummary)

      const count = issues.length

      if (!count){
        return (
          <Box alignItems="center">
            <StatusBadge
              boxProps={{ marginRight: 'medium' }}
              color={systemColours.grey}
              ghost
              pill
              text="-"
              textProps={{ fontWeight: 'bold' }}
            />
          </Box>
        )
      }

      const { description, isCritical } = issues[0]
      const color = isCritical ? systemColours.red : 'black'

      return (
        <Box alignItems="center">
          <StatusBadge
            boxProps={{ marginRight: 'medium' }}
            color={color}
            ghost
            pill
            text={count?.toString() || '-'}
            textProps={{ fontWeight: 'bold' }}
          />
          {count && (
            <Text color={color} variant="ellipsis" width={250}>
              {description}
            </Text>
          )}
        </Box>
      )
    },
  },
  {
    title: <FontAwesomeIcon icon={faSync} />,
    dataKey: 'campaignSummary.preflightValidation',
    cellProps: {
      textAlign: 'center',
      width: 20,
    },
    render: (summary: PreflightValidationSummary) => generateIconComponent(summary),
  },
  {
    title: <FontAwesomeIcon icon={faFacebook} />,
    dataKey: 'campaignSummary.facebook',
    cellProps: {
      textAlign: 'center',
      width: 20,
    },
    render: (summary: AdCampaignSummary) => generateIconComponent(summary),
  },
  {
    title: <FontAwesomeIcon icon={faGoogle} />,
    dataKey: 'campaignSummary.googleDisplay',
    cellProps: {
      textAlign: 'center',
      width: 20,
    },
    render: (summary: AdCampaignSummary) => generateIconComponent(summary),
  },
  {
    title: <FontAwesomeIcon icon={faSearch} />,
    dataKey: 'campaignSummary.googleSearch',
    cellProps: {
      textAlign: 'center',
      width: 20,
    },
    render: (summary: AdCampaignSummary) => generateIconComponent(summary),
  },
  {
    title: 'URL',
    dataKey: 'landingPage',
    cellProps: {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 20,
    },
    render: (landingPage: CampaignModel['landingPage']) => (
      <StatusBadge
        color={landingPage?.isValidated === true ? systemColours.teal : systemColours.grey}
        ghost
        pill
        text={landingPage?.url ? 'Valid' : 'None'}
        textProps={{ fontWeight: 'bold' }}
      />
    ),
  },
  {
    title: 'Actions',
    dataKey: 'id',
    cellProps: {
      key: 'actions',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    render: (campaignId: number) => <Actions campaignId={campaignId} />,
  },
]

interface OrdersTableProps {
  campaignsPayload: ReturnType<typeof useCampaigns>,
}

const OrdersTable = (props: OrdersTableProps) => {
  const {
    campaignsPayload: {
      filteredCampaigns,
      filteredCampaignsCount,
    },
  } = props

  const memoColumns = useMemo(() => buildColumns(), [filteredCampaignsCount])

  return (
    <Table
      boxProps={{ border: 'none', maxHeight: 1600, overflowY: 'auto' }}
      columns={memoColumns}
      data={filteredCampaigns}
      stickyHeader
    />
  )
}

export default OrdersTable
