import { useMemo } from 'react'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { ModuleState } from '@redux/modules/types'

const watchEntityKeys = ['campaignTypes']

type UseCampaignTypesOptions = {
  performHttpRequests?: boolean,
}

function useCampaignTypes(options: UseCampaignTypesOptions) {
  const {
    performHttpRequests = false,
  } = options || {}

  const {
    updatedEntities: { campaignTypes: campaignTypesUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { loading: loadingCampaignTypes } = useReduxAction(
    'campaignTypes',
    'loadCampaignTypes',
    {},
    [performHttpRequests],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { errors, loaded, loading } = entityReducer
        return performHttpRequests && !loaded && !loading && !errors?.length
      },
    },
  )

  const entityCampaignTypes = useSelector(reduxState => reduxState.entities.campaignTypes)

  const campaignTypes = useMemo(() => Object.values(entityCampaignTypes), [campaignTypesUpdatedAt]);

  return {
    loading: loadingCampaignTypes,
    campaignTypes,
  }
}

export default useCampaignTypes
