import { Box, ListItem, Text } from '@campaignhub/suit-theme'

interface AdContentListProps {
  label: string,
  list: string[],
}

const AdContentList = (props: AdContentListProps) => {
  const { label, list } = props

  const hasListItems = !!list.length

  return (
    <Box flexDirection="column">
      <Text
        color="bodyFontLightColor"
        display="flex"
        fontSize="xsmall"
        letterSpacing="1px"
        paddingLeft="large"
        paddingTop="large"
      >
        {label}
      </Text>

      {hasListItems && list.map(listItem => (
        <ListItem
          boxProps={{
            borderBottom: '1px dashed',
            padding: 'large',
          }}
          disableHover
          key={listItem}
        >
          <Text lineHeight="1.3">{listItem}</Text>
        </ListItem>
      ))}

      {!hasListItems && (
        <ListItem boxProps={{ padding: 'large' }} disableHover>
          <Text>None</Text>
        </ListItem>
      )}
    </Box>
  )
}

export default AdContentList