import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import buildDataStoreItemSummary from '@functions/dataStoreItem'

import useDataStoreItems from  '@hooks/useDataStoreItems'
import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as projectActions from '@redux/modules/project'

import defaultFormState, { requiredFields } from '@models/project'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { ProjectModel, ProjectRequestOptions } from '@models/types'

export const generateUrls = (project?: Partial<ProjectModel>) => {
  const { id } = project || {}

  return {
    editProjectUrl: `#/projects/${id}/edit/`,
    projectsIndexUrl: '#/projects',
  }
}

type CreateProjectParams = {
  dispatch: AppDispatch,
  projectParams: Partial<ProjectModel>,
  requestOptions?: ProjectRequestOptions,
}

const createProject = (params: CreateProjectParams) => {
  const { dispatch, projectParams, requestOptions } = params
  const { createProject: createFn } = projectActions

  return dispatch(createFn(projectParams, requestOptions))
}

type UpdateProjectParams = {
  dispatch: AppDispatch,
  project: ProjectModel,
  projectParams: Partial<ProjectModel>,
  requestOptions?: ProjectRequestOptions,
}

const updateProject = (params: UpdateProjectParams) => {
  const {
    dispatch, project, projectParams, requestOptions,
  } = params
  const { updateProject: updateFn } = projectActions

  const updatedParams = {
    id: project.id,
    ...projectParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteProjectParams = {
  dispatch: AppDispatch,
  project: DeleteParams<ProjectModel>,
}

const deleteProject = (params: DeleteProjectParams) => {
  const { dispatch, project } = params
  const { deleteProject: deleteFn } = projectActions

  return dispatch(deleteFn(project))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useProjectForm(
  project: Partial<ProjectModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const { filteredDataStoreItems } = useDataStoreItems({
    filters: {
      projectId: project.id,
    }
  })

  const projectForm = useForm(
    defaultFormState,
    {
      entity: { ...project, dataStoreItems: buildDataStoreItemSummary(filteredDataStoreItems) },
      requiredFields: [...requiredFields, ...customRequiredFields],
      validateOn,
    },
    [project.id, project.cacheKey],
  )

  return {
    ...projectForm,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ProjectRequestOptions,
}

function useProject(initEntity: Partial<ProjectModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: project }: { entity: ProjectModel } = useLatestEntity(initEntity, 'projects')
  const { id } = project

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  useReduxAction(
    'projects',
    'loadProject',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.projects)

  return {
    callbacks: {
      createProject: (projectParams: Partial<ProjectModel>, entityOptions?: ProjectRequestOptions) => (
        createProject({ projectParams, dispatch, requestOptions: entityOptions })
      ),
      deleteProject: () => deleteProject({ project, dispatch }),
      editProject: () => launchModal({
        callbacks,
        modalKey: 'EditProjectModal',
        payload: { project },
      }),
      updateProject: (projectParams: Partial<ProjectModel>, entityOptions?: ProjectRequestOptions) => (
        updateProject({
          project, projectParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    creating,
    deleting,
    loading,
    project,
    updating,
    urls: generateUrls(project),
  }
}

export default useProject
