import { isValidEmail } from '@campaignhub/javascript-utils'

export type ClientModel = {
  additionalNotificationEmail: string,
  addressLine1: string,
  addressLine2: string,
  bankAccountBsb: string,
  bankAccountName: string,
  bankAccountNumber: string,
  brandId: number,
  businessName?: string,
  businessNumber?: string,
  cacheKey: number,
  coverLetter?: string,
  email: string,
  fax?: string,
  id: number,
  isArchived: boolean,
  licenseNo?: string,
  name: string,
  phoneNo: string,
  postCode: string,
  principalEmail: string,
  principalMobile: string,
  principalName: string,
  realbaseId?: number,
  referenceId?: number,
  region: string,
  shippingAddress?: string,
  shortName?: string,
  state: string,
  status: boolean,
  website?: string,
}

export type ClientRequestOptions = {}

const state = {
  id: null,
  additionalNotificationEmail: '',
  addressLine1: '',
  addressLine2: '',
  businessName: '',
  name: '',
  postCode: '',
  realbaseId: '',
  region: '',
  shortName: '',
  state: '',
}

export const requiredFields = [
  {
    key: 'additionalNotificationEmail',
    validation: (email: string) => email === '' || isValidEmail(email),
    invalidMessage: 'Invalid Email format',
  },
]

export default state
