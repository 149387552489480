import { useContext } from 'react'

import {
  Button,
  Columns,
  LoadingModule,
  SaveFormModule,
  SectionDivider,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useProductOption, { useProductOptionForm } from '@hooks/useProductOption'

import ProductOptionDetails from '../ProductOptionDetails'
import ProductOptionValues from '../ProductOptionValues'
import ProductOptionTools from '../../../../components/ProductOptionTools'
import PageLayout from '../../../../components/PageLayout'

interface PageContentProps {
  productOptionId: number,
}

const PageContent = (props: PageContentProps) => {
  const { productOptionId } = props

  const pageContext = useContext(PageContext)
  const {
    callbacks: {
      updateProductOption,
    },
  } = pageContext

  const productOptionPayload = useProductOption({ id: productOptionId })
  const {
    productOption,
    callbacks: {
      updateProductOption: updateFn,
    },
    loading,
    updating,
  } = productOptionPayload

  const productOptionFormPayload = useProductOptionForm(productOption, { validateOn: 'change' })
  const { entityState, saveEnabled } = productOptionFormPayload

  const updateProductOptionPayload = {
    callbacks: {
      action: updateFn,
    },
    entityParams: entityState,
    toastText: 'Product Option Updated',
  }

  return (
    <PageLayout productOptionPayload={productOptionPayload} activeTabBarItemKey="details">
      <Columns.Main>
        <LoadingModule loading={loading} times={3} />

        {!loading && (
          <>
            <ProductOptionDetails productOptionFormPayload={productOptionFormPayload} />

            <SaveFormModule boxProps={{ marginBottom: 'none' }}>
              <Button
                buttonStyle="primaryCreate"
                disabled={!saveEnabled}
                loading={updating}
                marginLeft="auto"
                onClick={() => updateProductOption(updateProductOptionPayload)}
                size="medium"
              >
                Save Details
              </Button>
            </SaveFormModule>

            <SectionDivider>Values</SectionDivider>

            <ProductOptionValues productOptionId={productOptionId} />
          </>
        )}
      </Columns.Main>

      <Columns.Sidebar>
        <ProductOptionTools productOptionPayload={productOptionPayload} />
      </Columns.Sidebar>
    </PageLayout>
  )
}

export default PageContent
