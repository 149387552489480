import {
  Box, ListItem, Text, UserProfileImage,
} from '@campaignhub/suit-theme'

import { useRelations as useCampaignUserRelations } from '@hooks/useCampaignUser'
import { useRelations as useUserRelations } from '@hooks/useUser'

import { CampaignUserModel } from '@models/types'

interface AgentListItemProps {
  campaignUser: CampaignUserModel,
}

const AgentListItem = (props: AgentListItemProps) => {
  const { campaignUser } = props

  const {
    user,
    user: {
      firstName = '',
      lastName = '',
      mobileNumber,
    },
  } = useCampaignUserRelations(campaignUser)

  const {
    coverImage: {
      filePath,
    },
  } = useUserRelations(user)

  const initials = firstName[0] + lastName[0]

  return (
    <ListItem
      boxProps={{
        alignItems: 'center',
        borderBottom: '1px dashed',
        padding: 'large',
      }}
      disableHover
    >
      <Box marginRight="large" width="auto">
        <UserProfileImage imageUrl={filePath} width={40}>
          {initials}
        </UserProfileImage>
      </Box>

      <Box flexDirection="column" justifyContent="center">
        <Text fontSize="small">{firstName} {lastName}</Text>

        <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="small">{mobileNumber}</Text>
      </Box>
    </ListItem>
  )
}

export default AgentListItem
