export type BrandModel = {
  addressLine1: string,
  addressLine2: string,
  businessName: string,
  cacheKey: number,
  colourAccent?: string,
  colourGradientEnd?: string,
  colourGradientStart?: string,
  colourHighlight?: string,
  colourPrimary?: string,
  colourSecondary?: string,
  id: number,
  isArchived: boolean,
  name: string,
  postCode: string,
  realbaseId: number,
  referenceId?: number,
  region: string,
  state: string,
}

export type BrandRequestOptions = {}

const state = {
  addressLine1: '',
  addressLine2: '',
  businessName: '',
  colourAccent: '',
  colourGradientEnd: '',
  colourGradientStart: '',
  colourHighlight: '',
  colourPrimary: '',
  colourSecondary: '',
  id: null,
  name: '',
  postCode: '',
  realbaseId: '',
  region: '',
  state: '',
}

export const requiredFields = []

export default state
