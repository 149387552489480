import {
  Box, Image, ListItem, Text,
} from '@campaignhub/suit-theme'

import { ArtworkModel } from '@models/types'

interface ArtworkCardProps {
  artwork: ArtworkModel,
  isFirst: boolean,
}

const ArtworkCard = (props: ArtworkCardProps) => {
  const {
    artwork: {
      adTypeId,
      height,
      width,
      url,
    },
    isFirst,
  } = props

  return (
    <Box
      border="1px solid"
      borderRadius={5}
      borderColor="lineColor"
      flexDirection="column"
      flexGrow={1}
      marginBottom="large"
      marginLeft={['large', isFirst ? 'large' : 0]}
      marginRight="large"
      marginTop={[isFirst ? 'large' : 0, 'large']}
      minWidth={180}
      width={['calc(100% - 32px)', 180]}
    >
      <Image
        backgroundSize="contain"
        height={180}
        url={url}
      />

      <ListItem
        boxProps={{
          borderTop: '1px solid',
          padding: 'large',
        }}
        flexDirection="column"
        onClick={() => console.log('launch artwork details modal')}
        showAngleIcon
      >
        {height && width && <Text>{height} x {width}</Text>}

        <Text color="bodyFontLightColor" fontSize="small" marginTop="small">{adTypeId}</Text>
      </ListItem>
    </Box>
  )
}

export default ArtworkCard
