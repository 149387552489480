import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import defaultFormState, { requiredFields } from '@models/productPackage'
import type { ProductPackageModel, ProductPackageRequestOptions } from '@models/types'

import * as productPackageActions from '@redux/modules/productPackage'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { AppDispatch } from '@redux/store'

export const generateUrls = (productPackage?: Partial<ProductPackageModel>) => {
  const { id } = productPackage || {}

  return {
    editProductPackageUrl: `#/packages/${id}/edit/`,
    productPackagesIndexUrl: '#/packages',
  }
}

type CreateProductPackageParams = {

  dispatch: AppDispatch,
  productPackageParams: Partial<ProductPackageModel>,
  requestOptions?: ProductPackageRequestOptions,
}

const createProductPackage = (params: CreateProductPackageParams) => {
  const { dispatch, productPackageParams, requestOptions } = params
  const { createProductPackage: createFn } = productPackageActions

  return dispatch(createFn(productPackageParams, requestOptions))
}

type UpdateProductPackageParams = {

  dispatch: AppDispatch,
  productPackage: ProductPackageModel,
  productPackageParams: Partial<ProductPackageModel>,
  requestOptions?: ProductPackageRequestOptions,
}

const updateProductPackage = (params: UpdateProductPackageParams) => {
  const {
    dispatch, productPackage, productPackageParams, requestOptions,
  } = params
  const { updateProductPackage: updateFn } = productPackageActions

  const updatedParams = {
    id: productPackage.id,
    ...productPackageParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteProductPackageParams = {

  dispatch: AppDispatch,
  productPackage: DeleteParams<ProductPackageModel>,
}

const deleteProductPackage = (params: DeleteProductPackageParams) => {
  const { dispatch, productPackage } = params
  const { deleteProductPackage: deleteFn } = productPackageActions

  return dispatch(deleteFn(productPackage))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useProductPackageForm(
  productPackage: Partial<ProductPackageModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const productPackageForm = useForm(
    defaultFormState,
    { entity: productPackage, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [productPackage.id, productPackage.cacheKey],
  )

  return {
    ...productPackageForm,
  }
}

export const useRelations = (productPackage: Partial<ProductPackageModel> = {}) => {
  const { id } = productPackage

  const { productPackages } = useSelector(reduxState => reduxState.entities)

  const relatedEntity = id
  && productPackages[id] ? productPackages[id] : {}

  return {
    relatedEntity,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ProductPackageRequestOptions,
}

export function useProductPackage(initEntity: Partial<ProductPackageModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: productPackage }: { entity: ProductPackageModel} = useLatestEntity(initEntity, 'productPackages')
  const { id } = productPackage

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { relatedEntity } = useRelations(productPackage)

  useReduxAction(
    'productPackages',
    'loadProductPackage',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.productPackages)

  return {
    callbacks: {
      createProductPackage:
        (productPackageParams: Partial<ProductPackageModel>, entityOptions?: ProductPackageRequestOptions) => (
          createProductPackage({ productPackageParams, dispatch, requestOptions: entityOptions })
        ),
      createOrEditProductPackage: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditProductPackageModal',
        payload: { productPackage },
      }),
      deleteProductPackage: () => deleteProductPackage({ dispatch, productPackage }),
      updateProductPackage:
        (productPackageParams: Partial<ProductPackageModel>, entityOptions?: ProductPackageRequestOptions) => (
          updateProductPackage({
            productPackage, productPackageParams, dispatch, requestOptions: entityOptions,
          })
        ),
    },

    creating,
    deleting,
    loading,
    productPackage,
    relatedEntity,
    updating,
    urls: generateUrls(productPackage),
  }
}

export default useProductPackage
