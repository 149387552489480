import {
  DashboardModule, ModuleBlankState,
} from '@campaignhub/suit-theme'

import useCampaigns from '@hooks/useCampaigns'
import type { CampaignFilters } from '@hooks/useCampaigns'

import OrdersTable from './OrdersTable'
import TasksImageUrl from './assets/tasks.svg'

interface OrdersProps {
  pageFilters: CampaignFilters,
}

const Orders = (props: OrdersProps) => {
  const { pageFilters } = props

  const campaignsPayload = useCampaigns({
    filters: {
      ...pageFilters,
    },
    performHttpRequests: true,
    requestOptions: {
      include: ['CampaignSummary', 'CampaignType', 'LandingPage'],
    },
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCampaignsCount,
    hasFilteredCampaigns,
    loading,
  } = campaignsPayload

  return (
    <DashboardModule
      boxProps={{ borderBottom: loading && !hasFilteredCampaigns ? 'none' : '1px solid lineColor' }}
      contentBoxProps={{ flexDirection: 'column' }}
      loading={loading}
      title="Orders"
    >
      {!loading && !hasFilteredCampaigns && (
        <ModuleBlankState boxProps={{ 'data-test-id': 'blankState' }} imageUrl={TasksImageUrl}>
          <ModuleBlankState.Title>No Orders to display</ModuleBlankState.Title>

          <ModuleBlankState.Paragraph>
            Please adjust your filters or create an Order
          </ModuleBlankState.Paragraph>
        </ModuleBlankState>
      )}

      {!loading && hasFilteredCampaigns && (
        <OrdersTable campaignsPayload={campaignsPayload} />
      )}

      <DashboardModule.LoadMoreFooter
        boxProps={{ borderTop: loading ? 'none' : '1px solid' }}
        callbacks={{ loadMore }}
        canLoadMore={canLoadMore}
        entityCount={filteredCampaignsCount}
        loading={loading}
      />
    </DashboardModule>
  )
}

export default Orders
