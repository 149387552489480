import { useMemo } from 'react'

import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import EditProjectModal from '@modals/EditProjectModal'
import EditStartDateModal from '@modals/EditStartDateModal'
import ManageCaptionsModal from '@modals/ManageCaptionsModal'

import type { CampaignModel, ProjectModel } from '@models/types'

import PageContent from './components/PageContent'

const defaultState = {
  showEditProjectModal: false,
  showEditStartDateModal: false,
  showManageCaptionsModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    EditProjectModal: {
      closeModal: () => setState({ showEditProjectModal: false }),
      updateProject: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    EditStartDateModal: {
      closeModal: () => setState({ showEditStartDateModal: false }),
      editStartDate: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    ManageCaptionsModal: {
      closeModal: () => setState({ showManageCaptionsModal: false }),
      createOrUpdateCaptions: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Overview = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showEditProjectModal,
    showEditStartDateModal,
    showManageCaptionsModal,
  } = state

  const { campaignId } = useNumericParams()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showEditProjectModal: (payload: { project: ProjectModel }) => {
        setModalData('EditProjectModal', payload)
        setState({ showEditProjectModal: true })
      },
      showEditStartDateModal: (payload: { campaign: CampaignModel }) => {
        setModalData('EditStartDateModal', payload)
        setState({ showEditStartDateModal: true })
      },
      showManageCaptionsModal: (payload: { captionsParams: { campaignId: number } }) => {
        setModalData('ManageCaptionsModal', payload)
        setState({ showManageCaptionsModal: true })
      },
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent campaignId={campaignId} />

        <EditProjectModal
          callbacks={callbacks('EditProjectModal', setState)}
          showModal={showEditProjectModal}
        />

        <EditStartDateModal
          callbacks={callbacks('EditStartDateModal', setState)}
          showModal={showEditStartDateModal}
        />

        <ManageCaptionsModal
          callbacks={callbacks('ManageCaptionsModal', setState)}
          showModal={showManageCaptionsModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Overview
