import { useMemo } from 'react'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'

const callbacks = (component, setState) => {
  const componentCallbacks = {
    SampleModal: {
      closeModal: () => setState({ showSampleModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  showSampleModal: false,
}

const Overview = () => {
  const [state, setState] = useSetState(defaultState)
  const { showSampleModal } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showSampleModal: (payload) => {
        setModalData('SampleModal', payload)
        setState({ showSampleModal: true })
      },
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Overview
