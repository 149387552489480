import { useMemo } from 'react'

import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import ArchivePriceListModal from '@modals/ArchivePriceListModal'
import DuplicatePriceListModal from '@modals/DuplicatePriceListModal'

import type { PriceListModel } from '@models/types'

import PageContent from './components/PageContent'

const defaultState = {
  showArchivePriceListModal: false,
  showDuplicatePriceListModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    ArchivePriceListModal: {
      archivePriceList: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showArchivePriceListModal: false }),
    },
    DuplicatePriceListModal: {
      closeModal: () => setState({ showDuplicatePriceListModal: false }),
      duplicatePriceList: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const Details = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showArchivePriceListModal,
    showDuplicatePriceListModal,
  } = state

  const { priceListId } = useNumericParams()

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showArchivePriceListModal: (payload: { priceList: PriceListModel }) => {
        setModalData('ArchivePriceListModal', payload)
        setState({ showArchivePriceListModal: true })
      },
      showDuplicatePriceListModal: (payload: { priceList: PriceListModel }) => {
        setModalData('DuplicatePriceListModal', payload)
        setState({ showDuplicatePriceListModal: true })
      },
      updatePriceList: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent priceListId={priceListId} />

        <ArchivePriceListModal
          callbacks={callbacks('ArchivePriceListModal', setState)}
          showModal={showArchivePriceListModal}
        />

        <DuplicatePriceListModal
          callbacks={callbacks('DuplicatePriceListModal', setState)}
          showModal={showDuplicatePriceListModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Details
