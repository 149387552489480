import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'

import { Box, Text } from '@campaignhub/suit-theme'

interface DetailsListItemProps {
  icon?: IconDefinition,
  label: string,
  text: string | number,
}

const DetailsListItem = (props: DetailsListItemProps) => {
  const { icon, label, text } = props

  return (
    <Box alignItems="center" paddingY="medium">
      {icon && <FontAwesomeIcon icon={icon} />}

      <Box justifyContent="space-between">
        <Text color="bodyFontLightColor" fontSize="small" marginLeft="medium">{label}</Text>

        <Text fontSize="small">{text}</Text>
      </Box>
    </Box>
  )
}

export default DetailsListItem
