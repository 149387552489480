import { useContext } from 'react'

import {
  Button, EntityMultiSelect, Form, ModalContext, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'
import useBulkCreateArtworkTemplateGroupLinks from './hooks/useBulkCreateArtworkTemplateGroupLinks'

const MODAL_KEY = 'BulkCreateArtworkTemplateGroupLinksModal'

type ModalCallbacks = {
  bulkCreateArtworkTemplateGroupLinks: (payload: HandleCallbackActionParams) => void,
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const BulkCreateArtworkTemplateGroupLinksModal = (props: ModalProps) => {
  const {
    callbacks,
    showModal,
  } = props

  const { closeModal, bulkCreateArtworkTemplateGroupLinks } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const artworkTemplateGroupLink = digObject(modalPayload, 'artworkTemplateGroupLink', {})

  const {
    entityType,
  } = artworkTemplateGroupLink

  const {
    callbacks: {
      bulkCreateFn,
      setSelectedEntityIds,
      setState,
    },
    creating,
    entityParams,
    filteredClientIds,
    filteredClientIdsCount,
    filteredProductIds,
    filteredProductIdsCount,
    name,
    clients,
    clientsPayload,
    products,
    productsPayload,
    saveEnabled,
    selectedEntityIds,
  } = useBulkCreateArtworkTemplateGroupLinks(artworkTemplateGroupLink)

  const bulkCreateArtworkTemplateGroupLinksPayload = {
    callbacks: {
      action: bulkCreateFn,
      afterAction: closeModal,
    },
    entityParams,
    toastText: `${artworkTemplateGroupLink.entityType} Assigned`,
  }

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header callbacks={callbacks} title="Assign" titleSecondLine={artworkTemplateGroupLink.entityType} />

      <SidebarModal.Content>
        <Form.Field label={`Search ${entityType === 'Client' ? 'Organisations' : 'Products'}`}>
          <input onChange={e => setState({ name: e.target.value })} value={name} />
        </Form.Field>

        <SectionDivider>Multi Select Values</SectionDivider>

        {entityType === 'Client' && (
          <EntityMultiSelect
            boxProps={{ marginTop: 'large' }}
            callbacks={{
              loadMore: clientsPayload.callbacks.loadMore,
              setSelectedIds: setSelectedEntityIds,
            }}
            canLoadMore={clientsPayload.canLoadMore}
            entities={clients}
            entityCount={filteredClientIdsCount}
            entityTitleKey="name"
            filteredEntityIds={filteredClientIds}
            loading={clientsPayload.loading}
            selectedIds={selectedEntityIds}
          />
        )}

        {entityType === 'Product' && (
          <EntityMultiSelect
            boxProps={{ marginTop: 'large' }}
            callbacks={{
              loadMore: productsPayload.callbacks.loadMore,
              setSelectedIds: setSelectedEntityIds,
            }}
            canLoadMore={productsPayload.canLoadMore}
            entities={products}
            entityCount={filteredProductIdsCount}
            entityTitleKey="name"
            filteredEntityIds={filteredProductIds}
            loading={productsPayload.loading}
            selectedIds={selectedEntityIds}
          />
        )}

      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => bulkCreateArtworkTemplateGroupLinks(bulkCreateArtworkTemplateGroupLinksPayload)}
          size="large"
        >
          {saveEnabled ? 'Link Artwork Template Group' : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <BulkCreateArtworkTemplateGroupLinksModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
