import { Box, Button, Columns, MainContent, SaveFormModule } from '@campaignhub/suit-theme'

import PageHeader from '@components/PageHeader'

import handleCallbackAction from '@functions/handleCallbackAction'

import { useRelations as useOrderItemRelations } from '@hooks/useOrderItem'
import { useRelations as useOrderRelations } from '@hooks/useOrder'
import useReduxAction from '@hooks/useReduxAction'
import useTargeting from './hooks/useTargeting'

import AudienceTargeting from './AudienceTargeting'
import BlankState from './BlankState'
import CampaignDetails from './CampaignDetails'
import LocationTargeting from './LocationTargeting'
import OrderDetails from './OrderDetails'

const OrderItemTargeting = () => {
  const queryString = location.hash.split('?')[1]
  const queryParams = new URLSearchParams(queryString)

  const campaignId = queryParams.get('campaign_id')
  const orderId = queryParams.get('order_id')
  const orderItemId = queryParams.get('order_item_id')

  const findOrImportOrderItemPayload = useReduxAction(
    'orderItems',
    'findOrImportOrderItem',
    {
      externalCampaignId: Number(campaignId),
      externalOrderId: Number(orderId),
      externalOrderItemId: Number(orderItemId),
      externalPlatformTypeId: 2, // Realhub
      orderType: 'item',
    },
  )

  const {
    data: orderItem = {},
    loading,
  } = findOrImportOrderItemPayload

  const { order } = useOrderItemRelations(orderItem)
  const { project } = useOrderRelations(order)

  const {
    audiencePriceListItemOptionValues,
    callbacks: {
      patchOrderItem,
      toggleAudienceTargetingId,
      toggleGeoTargetingId,
    },
    geoPriceListItemOptionValues,
    selectedAudienceTargetingIds,
    selectedGeoTargetingIds,
    updating,
  } = useTargeting(orderItem)

  const hasAudienceTargeting = !!audiencePriceListItemOptionValues.length
  const hasGeoTargeting = !!geoPriceListItemOptionValues.length
  const hasTargeting = hasAudienceTargeting || hasGeoTargeting

  const patchOrderItemsParams = {
    callbacks: {
      action: patchOrderItem,
    },
    toastText: 'Targeting Updated',
  }

  return (
    <MainContent style={{ left: 0, width: '100%' }}>
      <PageHeader
        boxProps={{
          justifyContent: 'center',
          padding: 'xlarge',
          style: { left: 0 },
        }}
        title="Add Targeting"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            {!hasTargeting && <BlankState />}

            {hasAudienceTargeting && (
              <AudienceTargeting
                callbacks={{ toggleAudienceTargetingId }}
                priceListItemOptionValues={audiencePriceListItemOptionValues}
                loading={loading}
                selectedPriceListItemOptionValues={selectedAudienceTargetingIds}
              />
            )}

            {hasGeoTargeting && (
              <LocationTargeting
                callbacks={{ toggleGeoTargetingId }}
                priceListItemOptionValues={geoPriceListItemOptionValues}
                loading={loading}
                selectedPriceListItemOptionValues={selectedGeoTargetingIds}
              />
            )}

            {hasTargeting && (
              <SaveFormModule>
                <Button
                  buttonStyle="primaryCreate"
                  loading={updating}
                  marginLeft="auto"
                  onClick={() => handleCallbackAction(patchOrderItemsParams)}
                  size="medium"
                >
                  Save Details
                </Button>
              </SaveFormModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <CampaignDetails
              loading={loading}
              project={project}
            />

            <OrderDetails
              loading={loading}
              orderItem={orderItem}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </MainContent>
  )
}

export default OrderItemTargeting
