import { useState } from 'react'

import { useDebounce, useSetState } from '@campaignhub/react-hooks'

import useProductOptionProduct from '@hooks/useProductOptionProduct'
import useProductOptionProducts from '@hooks/useProductOptionProducts'
import useProductOptions from '@hooks/useProductOptions'
import useProducts from '@hooks/useProducts'
import useSelector from '@hooks/useSelector'

import { ProductOptionProductModel } from '@models/types'

const defaultState = {
  entityName: '',
}

type BulkCreateProductOptionProductParams = {
  productOptionProduct: Partial<ProductOptionProductModel>,
}

const useBulkCreateProductOptionProductsModal = (params: BulkCreateProductOptionProductParams) => {
  const {
    productOptionProduct,
    productOptionProduct: {
      productId,
      productOptionId,
    },
  } = params

  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const [state, setState] = useSetState(defaultState)
  const { entityName } = state

  const debouncedString = useDebounce(entityName, 200)

  const productsPayload = useProducts({
    filters: {
      string: debouncedString,
    },
    performHttpRequests: !!productOptionId,
  })

  const { filteredProducts } = productsPayload

  const productOptionsPayload = useProductOptions({
    filters: {
      string: debouncedString,
    },
    performHttpRequests: !!productId,
  })

  const { filteredProductOptions } = productOptionsPayload

  const productOptionProductsPayload = useProductOptionProducts({
    filters: {
      productId,
      productOptionId,
    },
  })

  const { filteredProductOptionProducts } = productOptionProductsPayload

  const assignedEntityIds = filteredProductOptionProducts.map(assignedProductOptionProduct => (
    !!productId ? assignedProductOptionProduct.productOptionId : assignedProductOptionProduct.productId
  ))

  // Filter out productOptions or products that are already assigned
  const filterEntities = productId ? filteredProductOptions : filteredProducts
  const filteredEntityIds = filterEntities.reduce<number[]>((acc, value) => {
    if (!assignedEntityIds.includes(value.id)){
      acc.push(value.id)
    }

    return acc
  }, [])

  const { products, productOptions } = useSelector(reduxState => reduxState.entities)

  const {
    callbacks: {
      bulkCreateProductOptionProducts,
    },
    creating,
  } = useProductOptionProduct(productOptionProduct)

  const entityParams = {
    productIds: productId ? [productId] : selectedIds,
    productOptionIds: productOptionId ? [productOptionId] : selectedIds,
  }

  return {
    callbacks: {
      bulkCreateProductOptionProducts,
      setSelectedIds,
      setState,
    },
    creating,
    entityParams,
    filteredEntityIds,
    filteredEntityIdsCount: filteredEntityIds.length,
    entityName,
    entities: !!productId ? productOptions : products,
    productOptionsPayload,
    saveEnabled: !!selectedIds.length,
    selectedIds,
  }
}

export default useBulkCreateProductOptionProductsModal
