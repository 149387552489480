import { useMemo } from 'react'

import type { UseFiltersPayload } from '@campaignhub/react-hooks'
import { useModals, useNumericParams, useSetState } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import BulkCreateClientPriceListsModal from '@modals/BulkCreateClientPriceListsModal'
import BulkCreateClientUsersModal from '@modals/BulkCreateClientUsersModal'

import { ClientPriceListModel, ClientUserModel } from '@models/types'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    deleteClientPriceList: (payload: HandleCallbackActionParams) => Promise<object>,
    deleteClientUser: (payload: HandleCallbackActionParams) => Promise<object>,
    showBulkCreateClientPriceListsModal: () => void,
  },
}

const defaultState = {
  showBulkCreateClientPriceListsModal: false,
  showBulkCreateClientUsersModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    BulkCreateClientPriceListsModal: {
      bulkCreateClientPriceLists: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showBulkCreateClientPriceListsModal: false }),
    },
    BulkCreateClientUsersModal: {
      bulkCreateClientUsers: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showBulkCreateClientUsersModal: false }),
    }
  }

  return componentCallbacks[component] || {}
}

const Relations = () => {
  const { clientId } = useNumericParams()

  const [state, setState] = useSetState(defaultState)
  const {
    showBulkCreateClientPriceListsModal,
    showBulkCreateClientUsersModal,
  } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      deleteClientPriceList: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deleteClientUser: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      showBulkCreateClientPriceListsModal: (payload: { clientPriceList: ClientPriceListModel }) => {
        setModalData('BulkCreateClientPriceListsModal', payload)
        setState({ showBulkCreateClientPriceListsModal: true })
      },
      showBulkCreateClientUsersModal: (payload: { clientUser: ClientUserModel }) => {
        setModalData('BulkCreateClientUsersModal', payload)
        setState({ showBulkCreateClientUsersModal: true })
      },
    },
  }), [])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent clientId={clientId} />

        <BulkCreateClientPriceListsModal
          callbacks={callbacks('BulkCreateClientPriceListsModal', setState)}
          showModal={showBulkCreateClientPriceListsModal}
        />

        <BulkCreateClientUsersModal
          callbacks={callbacks('BulkCreateClientUsersModal', setState)}
          showModal={showBulkCreateClientUsersModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default Relations
