import { useMemo } from 'react'

import { useNumericParams, } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'

const Relations = () => {
  const { priceListId } = useNumericParams()

  const pageContext = useMemo(() => ({}), [])

  return (
    <PageContext.Provider value={pageContext}>
      <PageContent priceListId={priceListId} />
    </PageContext.Provider>
  )
}

export default Relations
