export default {
  darkPurple: '#7b61ff',
  faintGrey: '#c7c7c8',
  green: '#43ac6a',
  grey: '#c7c7c8',
  lightBlue: '#81dbf1',
  mysteryGrey: '#8c8c8e',
  orange: '#e78d2d',
  red: '#e2001a',
  teal: '#00beab',
  yellow: '#f3b146',
}
