import { Fragment, useMemo } from 'react'

import {
  Box, NavigationLinkGroup, NestedNavigation, ProgressChart, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import getStatusBadgeProps from '@functions/statusBadge'
import { calculatePercentComplete } from '@functions/adCampaign'

import useAdCampaigns from '@hooks/useAdCampaigns'
import { useRelations } from '@hooks/useCampaign'

import { CampaignModel } from '@models/types'

import generateNavigationItems from './utils/generateNavigationItems'

interface NavigationProps {
  campaign: CampaignModel,
}

const Navigation = (props: NavigationProps) => {
  const {
    campaign,
    campaign: {
      id,
      name,
      statusId,
    },
  } = props

  const { campaignType } = useRelations(campaign)

  const statusBadgeProps = getStatusBadgeProps(statusId)

  const navigationItems = useMemo(() => generateNavigationItems({ id }), [id])

  const { filteredAdCampaigns } = useAdCampaigns({
    filters: {
      campaignId: id,
    },
    performHttpRequests: true,
  })

  const today = new Date().toISOString()
  const percentComplete = calculatePercentComplete(today, filteredAdCampaigns)

  return (
    <NestedNavigation>
      <Box padding="large" width="100%" height={132}>
        <ProgressChart
          decimal={percentComplete}
          innerRadius="80%"
          outerRadius="100%"
          height={100}
          width={100}
        />
      </Box>

      <Box flexDirection="column" flexShrink="0" paddingX="large">
        <Box
          borderBottom="1px solid"
          borderColor="lineColor"
          flexDirection="column"
          marginBottom="large"
          paddingY="large"
        >
          <Text color="bodyFontLightColor" fontSize="xsmall" marginTop="medium">
            {campaignType.displayName} Campaign
          </Text>

          <Text fontSize="small" marginTop="medium">{name}</Text>

          <StatusBadge boxProps={{ marginTop: 'medium' }} {...statusBadgeProps} />
        </Box>

        {navigationItems
          && navigationItems.sections.map(section => section.visible && (
            <Fragment key={section.key}>
              {section.items
                && section.items.map(item => (
                  <NavigationLinkGroup key={item.key} link={item} secondaryNav sidebarMaximized />
                ))}
            </Fragment>
          ))}
      </Box>
    </NestedNavigation>
  )
}

export default Navigation
