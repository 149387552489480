import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { Box, Button, Columns } from '@campaignhub/suit-theme'

import useProduct from '@hooks/useProduct'

import PageHeader from '@components/PageHeader'

import generateTabBarItems from '../../utils/generateTabBarItems'

interface PageLayoutProps {
  activeTabBarItemKey?: string,
  children: ReactNode,
  productPayload: ReturnType<typeof useProduct>,
}

const PageLayout = (props: PageLayoutProps) => {
  const {
    activeTabBarItemKey,
    productPayload: {
      product,
      urls: { productsIndexUrl },
    },
    children,
  } = props

  const { redirect } = parsePermittedQueryParams(getQueryParamsFromHash(), ['redirect'])

  return (
    <>
      <Helmet>
        <title>Edit Product | AIM Digital</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button
            as="a"
            buttonStyle="secondaryUtility"
            href={redirect || productsIndexUrl}
            size="medium"
            width="auto"
          >
            Back
          </Button>
        )}
        activeTabBarItemKey={activeTabBarItemKey}
        tabBarItems={generateTabBarItems(product)}
        title="Edit Product"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          {children}
        </Columns>
      </Box>
    </>
  )
}

export default PageLayout
