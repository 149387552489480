import {
  Box, ListItem, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import { AdQueueModel } from '@models/types'

interface AdQueueTargetingProps {
  adQueue: AdQueueModel,
  callbacks: {
    viewSelectedLocations: () => void,
    viewSelectedPlaybooks: () => void,
  },
}

const AdQueueTargeting = (props: AdQueueTargetingProps) => {
  const {
    adQueue: {
      demographics,
      locations,
      selectedPlaybooks,
    },
    callbacks: {
      viewSelectedLocations,
      viewSelectedPlaybooks,
    },
  } = props

  const { gender, maxAge, minAge } = demographics || {}

  const hasAgeData = maxAge && minAge
  const hasLocations = !!locations?.length
  const hasSelectedPlaybooks = !!selectedPlaybooks?.length

  return (
    <>
      <Text color="bodyFontLightColor" fontSize="small">Targeting</Text>

      <Box marginTop="medium">
        <ListItem
          boxProps={{
            border: '1px solid',
            borderRadius: 5,
            marginRight: 'medium',
          }}
          disableHover={!hasSelectedPlaybooks}
          forceBottomBorder
          onClick={hasSelectedPlaybooks ? () => viewSelectedPlaybooks() : null}
          showAngleIcon
        >
          <Box flexDirection="column" padding="large">
            <Text color="bodyFontLightColor" fontSize="xsmall">Playbooks</Text>
            <Text fontSize="small" marginTop="small">{hasSelectedPlaybooks ? selectedPlaybooks?.length : 0}</Text>
          </Box>
        </ListItem>

        <ListItem
          boxProps={{
            border: '1px solid',
            borderRadius: 5,
          }}
          disableHover={!hasLocations}
          forceBottomBorder
          onClick={hasLocations ? () => viewSelectedLocations() : null}
          showAngleIcon
        >
          <Box flexDirection="column" padding="large">
            <Text color="bodyFontLightColor" fontSize="xsmall">Locations</Text>
            <Text fontSize="small" marginTop="small">{hasLocations ? locations?.length : 0}</Text>
          </Box>
        </ListItem>
      </Box>

      <SidebarModal.ExpandableSection
        defaultOpen
        label="Demographics"
        style={{ marginTop: 16 }}
      >
        <Box justifyContent="space-between" marginTop="medium">
          <Text fontSize="small">Age</Text>
          <Text color="bodyFontLightColor" fontSize="small">
            {hasAgeData ? `${minAge}-${maxAge}` : 'No Data'}
          </Text>
        </Box>

        <Box justifyContent="space-between" marginTop="medium">
          <Text fontSize="small">Gender</Text>
          <Text color="bodyFontLightColor" fontSize="small">
            {gender || 'No Data'}
          </Text>
        </Box>
      </SidebarModal.ExpandableSection>
    </>
  )
}

export default AdQueueTargeting
