import { normalize } from 'normalizr'
import { Schemas } from '@redux/schema'
import { deleteEntity, updateEntities } from '@redux/entities'

import api from '@functions/api'

import type { ProductOptionModel } from '@models/types'
import type { AppDispatch } from '@redux/store'
import type {
  Action, DeleteParams, EntityOptions, Errors, FetchRequestPayload, RootReducerState, UpdateParams,
} from '@redux/modules/types'

import { handleError } from '../utils'

import { removePriceListItemOption } from './priceListItemOption'
import { removeProductOptionProduct } from './productOptionProduct'

const FETCH_REQUEST = 'realbase/productOption/FETCH_REQUEST'
const FETCH_SUCCESS = 'realbase/productOption/FETCH_SUCCESS'
const FETCH_FAILURE = 'realbase/productOption/FETCH_FAILURE'

const CREATE_REQUEST = 'realbase/productOption/CREATE_REQUEST'
const CREATE_SUCCESS = 'realbase/productOption/CREATE_SUCCESS'
const CREATE_FAILURE = 'realbase/productOption/CREATE_FAILURE'

const UPDATE_REQUEST = 'realbase/productOption/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'realbase/productOption/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'realbase/productOption/UPDATE_FAILURE'

const DELETE_REQUEST = 'realbase/productOption/DELETE_REQUEST'
const DELETE_SUCCESS = 'realbase/productOption/DELETE_SUCCESS'
const DELETE_FAILURE = 'realbase/productOption/DELETE_FAILURE'

export type ProductOptionModuleState = {
  creating: boolean,
  deleting: boolean,
  errors: string[],
  loaded: boolean,
  loadedForKeys: string[],
  loadedIds: number[],
  loading: boolean,
  updating: boolean,
}

// Initial State
const initialState: ProductOptionModuleState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedIds: [],
  loadedForKeys: [],
  loading: false,
  updating: false,
}

// Actions
export function fetchRequest(payload: FetchRequestPayload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
    loadedIds: payload.loadedIds,
  }
}

export function fetchSuccess() {
  return {
    type: FETCH_SUCCESS,
  }
}

export function fetchFailure(errors: Errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors: Errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors: Errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors: Errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadProductOptions(options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const loadedForKeys = [...getState().productOptions.loadedForKeys]

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = api('/product-options', options)
      .then(({ data }: { data: { data: ProductOptionModel[] } }) => {
        const normalizedJson = normalize(data.data, Schemas.PRODUCT_OPTION_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function loadProductOption(productOptionId: number, options: EntityOptions = {}) {
  const { entityKey } = options

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    const state = getState()
    const loadedIds = [...state.productOptions.loadedIds]
    const loadedForKeys = [...state.productOptions.loadedForKeys]

    if (productOptionId && !loadedIds.includes(productOptionId)){
      loadedIds.push(productOptionId)
    }

    if (entityKey && !loadedForKeys.includes(entityKey)){
      loadedForKeys.push(entityKey)
    }

    // Set Loading
    dispatch(fetchRequest({ loadedForKeys, loadedIds }))

    const promise = api(`/product-options/${productOptionId}`, options)
      .then(({ data }: { data: ProductOptionModel }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_OPTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function createProductOption(productOption: Partial<ProductOptionModel>, options: EntityOptions = {}) {
  const config = {
    method: 'POST',
    data: JSON.stringify({
      ...productOption,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api('/product-options', options, config)
      .then(({ data }: { data: ProductOptionModel }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_OPTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function updateProductOption(
  productOption: UpdateParams<ProductOptionModel>,
  options: EntityOptions = {},
) {
  const config = {
    method: 'PUT',
    data: JSON.stringify({
      ...productOption,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(updateRequest())

    const promise = api(`/product-options/${productOption.id}`, options, config)
      .then(({ data }: { data: ProductOptionModel }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_OPTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function duplicateProductOption(productOption: Partial<ProductOptionModel>, options: EntityOptions = {}) {
  const { name } = productOption

  const config = {
    method: 'POST',
    data: JSON.stringify({
      name,
    }),
  }

  return (dispatch: AppDispatch) => {
    dispatch(createRequest())

    const promise = api(`/product-options/${productOption.id}/duplicate`, options, config)
      .then(({ data }: { data: ProductOptionModel }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_OPTION)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true as const, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

export function deleteProductOption(productOption: DeleteParams<ProductOptionModel>) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch: AppDispatch, getState: () => RootReducerState) => {
    dispatch(deleteRequest())

    const promise = api(`/product-options/${productOption.id}`, {}, config)
      .then(({ status }) => {
        if (status !== 200) throw new Error()

        const deletedEntity = { id: productOption.id }
        const normalizedJson = normalize(deletedEntity, Schemas.PRODUCT_OPTION)
        dispatch(deleteEntity(normalizedJson))

        const { priceListItemOptions, productOptionProducts } = getState().entities

        // Remove any PriceListItemOptions related to the ProductOption
        Object.values(priceListItemOptions).forEach((priceListItemOption) => {
          if (priceListItemOption.productOptionId === productOption.id){
            removePriceListItemOption(dispatch, priceListItemOption)
          }
        })

        // Remove any ProductOptionProducts related to the ProductOption
        Object.values(productOptionProducts).forEach((productOptionProduct) => {
          if (productOptionProduct.productOptionId === productOption.id){
            removeProductOptionProduct(dispatch, productOptionProduct)
          }
        })

        dispatch(deleteSuccess())

        return { success: true as const, data: deletedEntity }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false as const, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(
  state: ProductOptionModuleState = initialState,
  action: Action = { type: '' },
): ProductOptionModuleState {
  switch (action.type){
    case FETCH_REQUEST:
      return {
        ...state,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
        loadedIds: action.loadedIds || state.loadedIds,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors: action.errors || [],
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors: action.errors || [],
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors: action.errors || [],
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors: action.errors || [],
      }
    default:
      return state
  }
}
