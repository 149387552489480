import { useModals, useSetState } from '@campaignhub/react-hooks'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import usePriceList from '@hooks/usePriceList'
import useSelector from '@hooks/useSelector'

import { PriceListItemOptionModel } from '@models/types'

type DefaultState = {
  selectedPriceListItemId?: number,
  selectedPriceListItemOptionId?: number,
  showBulkCreatePriceListItemOptionValuesModal: boolean,
  showBulkCreatePriceListItemOptionsModal: boolean,
}

const defaultState: DefaultState = {
  selectedPriceListItemId: undefined,
  selectedPriceListItemOptionId: undefined,
  showBulkCreatePriceListItemOptionValuesModal: false,
  showBulkCreatePriceListItemOptionsModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const modalCallbacks = (component: string, setState: SetState) => {
  const componentCallbacks = {
    BulkCreatePriceListItemOptionValuesModal: {
      bulkCreatePriceListItemOptionValues: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showBulkCreatePriceListItemOptionValuesModal: false }),
    },
    BulkCreatePriceListItemOptionsModal: {
      bulkCreatePriceListItemOptions: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showBulkCreatePriceListItemOptionsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const usePriceListManager = (priceListId: number) => {
  const [state, setState] = useSetState(defaultState)
  const {
    selectedPriceListItemId,
    selectedPriceListItemOptionId,
  } = state

  const priceListPayload = usePriceList({ id: priceListId })

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const { priceListItems, priceListItemOptions } = useSelector(reduxState => reduxState.entities)
  const selectedPriceListItem = priceListItems[selectedPriceListItemId] || {}
  const selectedPriceListItemOption = priceListItemOptions[selectedPriceListItemOptionId] || {}

  return {
    callbacks: {
      createPriceListItem: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deletePriceListItem: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      deletePriceListItemOption: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      modalCallbacks: (modalKey: string) => modalCallbacks(modalKey, setState),
      selectPriceListItemId: (priceListItemId: number) => {
        setState({ selectedPriceListItemId: priceListItemId })
      },
      selectPriceListItemOptionId: (priceListItemOptionId: number) => {
        setState({ selectedPriceListItemOptionId: priceListItemOptionId })
      },
      showBulkCreatePriceListItemOptionValuesModal: (payload: { priceListItemOption: PriceListItemOptionModel }) => {
        setModalData('BulkCreatePriceListItemOptionValuesModal', payload)
        setState({ showBulkCreatePriceListItemOptionValuesModal: true })
      },
      showBulkCreatePriceListItemOptionsModal: (payload: { priceListItemOption: PriceListItemOptionModel }) => {
        setModalData('BulkCreatePriceListItemOptionsModal', payload)
        setState({ showBulkCreatePriceListItemOptionsModal: true })
      },
    },
    modalContext,
    priceListPayload,
    selectedPriceListItem,
    selectedPriceListItemOption,
    state,
  }
}

export default usePriceListManager
