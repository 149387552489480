export default {
  adAccounts: {
    actionName: 'loadAdAccount',
    endpoint: 'ad-accounts',
    groupTitle: 'AD ACCOUNTS',
    key: 'adAccounts',
    label: 'Ad Account',
    labelPlural: 'Ad Accounts',
  },  
  campaigns: {
    actionName: 'loadCampaign',
    groupTitle: 'CAMPAIGNS',
    key: 'campaigns',
    label: 'Campaign',
    labelPlural: 'Campaigns',
  },
  clients: {
    actionName: 'loadClient',
    groupTitle: 'ORGANIZATIONS',
    key: 'organizations',
    label: 'Organization',
    labelPlural: 'Organizations',
  },
  priceLists: {
    actionName: 'loadPriceList',
    endpoint: 'price-lists',
    groupTitle: 'PRICE LISTS',
    key: 'priceLists',
    label: 'Price List',
    labelPlural: 'Price Lists',
  },
  productOptions: {
    actionName: 'loadProductOption',
    endpoint: 'product-options',
    groupTitle: 'PRODUCT OPTIONS',
    key: 'productOptions',
    label: 'Product Option',
    labelPlural: 'Product Options',
  },
  realhubArtworkTemplates: {
    actionName: 'loadRealhubArtworkTemplate',
    endpoint: 'realbase/artwork-templates',
    groupTitle: 'REALHUB TEMPLATE PAGES',
    key: 'realhubArtworkTemplates',
    label: 'Template Page',
    labelPlural: 'Template Pages',
  },
  cutterProfiles: {
    actionName: 'loadCutterProfile',
    endpoint: 'cutter-profiles',
    groupTitle: 'CUTTER PROFILES',
    key: 'cutterProfiles',
    label: 'Cutter Profile',
    labelPlural: 'Cutter Profiles',
  },
}
