import { useContext } from 'react'

import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import { launchModal } from '@campaignhub/javascript-utils'

import useDispatch from '@hooks/useDispatch'
import useSelector from '@hooks/useSelector'

import * as artworkTemplateGroupLinkActions from '@redux/modules/artworkTemplateGroupLink'

import defaultFormState, { requiredFields } from '@models/artworkTemplateGroupLink'

import PageContext from '@contexts/pageContext'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, UpdateParams } from '@redux/modules/types'
import type { ArtworkTemplateGroupLinkModel, ArtworkTemplateGroupLinkRequestOptions } from '@models/types'

export const generateUrls = (artworkTemplateGroupLink?: Partial<ArtworkTemplateGroupLinkModel>) => {
  const { id } = artworkTemplateGroupLink || {}

  return {
    editArtworkTemplateGroupLinkUrl: `#/artworkTemplateGroupLinks/${id}/edit/`,
    artworkTemplateGroupLinksIndexUrl: '#/artworkTemplateGroupLinks',
  }
}

type BulkCreateArtworkTemplateGroupLinkParams = {
  bulkCreateParams: { artworkTemplateGroupIds?: number[], entityIds?: number[], entityType: string },
  dispatch: AppDispatch,
  requestOptions?: ArtworkTemplateGroupLinkRequestOptions,
}

const bulkCreateArtworkTemplateGroupLinks = (params: BulkCreateArtworkTemplateGroupLinkParams) => {
  const {
    bulkCreateParams,
    dispatch,
    requestOptions,
  } = params
  const { bulkCreateArtworkTemplateGroupLinks: bulkCreateFn } = artworkTemplateGroupLinkActions

  return dispatch(bulkCreateFn(bulkCreateParams, requestOptions))
}

type DeleteArtworkTemplateGroupLinkParams = {
  artworkTemplateGroupLink: DeleteParams<ArtworkTemplateGroupLinkModel>,
  dispatch: AppDispatch,
}

const deleteArtworkTemplateGroupLink = (params: DeleteArtworkTemplateGroupLinkParams) => {
  const { dispatch, artworkTemplateGroupLink } = params
  const { deleteArtworkTemplateGroupLink: deleteFn } = artworkTemplateGroupLinkActions

  return dispatch(deleteFn(artworkTemplateGroupLink))
}

type SetDefaultArtworkTemplateGroupLinkParams = {
  artworkTemplateGroupLink: UpdateParams<ArtworkTemplateGroupLinkModel>,
  dispatch: AppDispatch,
}

const setDefaultArtworkTemplateGroupLink = (params: SetDefaultArtworkTemplateGroupLinkParams) => {
  const { dispatch, artworkTemplateGroupLink } = params
  const { setDefaultArtworkTemplateGroupLink: setDefaultFn } = artworkTemplateGroupLinkActions

  return dispatch(setDefaultFn(artworkTemplateGroupLink.id, true))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useArtworkTemplateGroupLinkForm(
  artworkTemplateGroupLink: Partial<ArtworkTemplateGroupLinkModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const artworkTemplateGroupLinkForm = useForm(
    defaultFormState,
    { entity: artworkTemplateGroupLink, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [artworkTemplateGroupLink.id, artworkTemplateGroupLink.cacheKey],
  )

  return {
    ...artworkTemplateGroupLinkForm,
  }
}

export const useRelations = (artworkTemplateGroupLink: Partial<ArtworkTemplateGroupLinkModel> = {}) => {
  const { artworkTemplateGroupId } = artworkTemplateGroupLink

  const { artworkTemplateGroups } = useSelector(reduxState => reduxState.entities)

  const artworkTemplateGroup = artworkTemplateGroupId && artworkTemplateGroups[artworkTemplateGroupId]
    ? artworkTemplateGroups[artworkTemplateGroupId] : {}

  return {
    artworkTemplateGroup,
  }
}

function useArtworkTemplateGroupLink(initEntity: Partial<ArtworkTemplateGroupLinkModel> = {}) {
  const { entity: artworkTemplateGroupLink }:
    { entity: ArtworkTemplateGroupLinkModel } = useLatestEntity(initEntity, 'artworkTemplateGroupLinks')

  const dispatch = useDispatch()

  const { callbacks } = useContext(PageContext)

  const { artworkTemplateGroup } = useRelations(artworkTemplateGroupLink)

  const {
    creating, deleting, loading,
  } = useSelector(reduxState => reduxState.artworkTemplateGroupLinks)

  return {
    artworkTemplateGroup,
    artworkTemplateGroupLink,
    callbacks: {
      launchBulkCreateArtworkTemplateGroupLinksModal: () => launchModal({
        callbacks,
        modalKey: 'BulkCreateArtworkTemplateGroupLinksModal',
        payload: { artworkTemplateGroupLink },
      }),
      bulkCreateArtworkTemplateGroupLinks: (
        bulkCreateParams: BulkCreateArtworkTemplateGroupLinkParams['bulkCreateParams'],
        requestOptions?: ArtworkTemplateGroupLinkRequestOptions,
      ) => (
        bulkCreateArtworkTemplateGroupLinks({
          bulkCreateParams,
          dispatch,
          requestOptions,
        })
      ),
      showCreateOrEditArtworkTemplateGroupLinkModal: () => launchModal({
        callbacks,
        modalKey: 'CreateOrEditArtworkTemplateGroupLinkModal',
        payload: { artworkTemplateGroupLink },
      }),
      deleteArtworkTemplateGroupLink: () => deleteArtworkTemplateGroupLink({ artworkTemplateGroupLink, dispatch }),
      setDefaultArtworkTemplateGroupLink: () => setDefaultArtworkTemplateGroupLink({ dispatch, artworkTemplateGroupLink }),
    },
    creating,
    deleting,
    loading,
    urls: generateUrls(artworkTemplateGroupLink),
  }
}

export default useArtworkTemplateGroupLink
